import { Button, Icon, useGoodlokAuth } from '@goodlok/react-sdk'
import { useCurrentLocale, useTranslate } from '@goodlok/react-sdk/src/translations/Translations'
import clsx from 'clsx'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { usePeopleCount } from '../hooks/useListPeople'
import { routes } from '../utils/routes'
import style from './SideMenu.module.sass'
import { UserInfo } from './UserInfo'

export const SideMenu: FunctionComponent<{ onSignInButtonClick: () => void }> = ({
	onSignInButtonClick,
}) => {
	const auth = useGoodlokAuth()
	const translate = useTranslate()
	const activeLocale = useCurrentLocale()

	const peopleCount = usePeopleCount()

	return (
		<div className={style.wrapper}>
			<div className={style.logo}>
				<Icon name="goodlokText" />
				<span className={style.logoLabel}>B2B</span>
			</div>
			{auth.signedIn && (
				<>
					<div className={style.primaryAction}>
						<Button type="link" href={routes.home} size="medium" variant="primary" outline>
							{translate('navigation.order')}
						</Button>
					</div>
					<div>
						<Link href={routes.history} className={style.action}>
							{translate('navigation.history')}
						</Link>
					</div>
					{auth.signedIn && peopleCount && peopleCount > 1 ? (
						<div>
							<Link href={routes.people} className={style.action}>
								{translate('navigation.people')}
							</Link>
						</div>
					) : null}
					<div>
						<Link href={routes.settings} className={style.action}>
							{translate('navigation.settings')}
						</Link>
					</div>
				</>
			)}
			<div>
				{[
					{ code: 'cs', label: 'Česky' },
					{ code: 'en', label: 'English' },
				].map((locale) => (
					<span key={locale.code} className={style.languageWrap}>
						{locale.code === activeLocale ? (
							<span className={clsx(style.language, style.is_active)}>{locale.label}</span>
						) : (
							<Link href="/" locale={locale.code} className={style.languageLink}>
								{locale.label}
							</Link>
						)}
					</span>
				))}
			</div>

			<div className={style.userInfo}>
				{auth.signedIn ? (
					<UserInfo
						signOutButton={
							<button onClick={() => auth.actions.signOut()} className={style.signOut}>
								{translate('navigation.signOut')}
							</button>
						}
					/>
				) : (
					<button
						type="button"
						className={clsx(style.action, style.signIn)}
						onClick={() => {
							onSignInButtonClick()
						}}>
						{translate('navigation.signIn')}
					</button>
				)}
			</div>
		</div>
	)
}
