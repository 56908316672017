/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		getCountry:{
			by:"CountryUniqueWhere",
			filter:"CountryWhere"
		},
		listCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		paginateCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		getChannel:{
			by:"ChannelUniqueWhere",
			filter:"ChannelWhere"
		},
		listChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		getDeliveryMethod:{
			by:"DeliveryMethodUniqueWhere",
			filter:"DeliveryMethodWhere"
		},
		listDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		getPaymentMethod:{
			by:"PaymentMethodUniqueWhere",
			filter:"PaymentMethodWhere"
		},
		listPaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginatePaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		getVatRate:{
			by:"VatRateUniqueWhere",
			filter:"VatRateWhere"
		},
		listVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		paginateVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductStock:{
			by:"ProductStockUniqueWhere",
			filter:"ProductStockWhere"
		},
		listProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		getProductPacking:{
			by:"ProductPackingUniqueWhere",
			filter:"ProductPackingWhere"
		},
		listProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		paginateProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		getProductRecipe:{
			by:"ProductRecipeUniqueWhere",
			filter:"ProductRecipeWhere"
		},
		listProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		paginateProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getStore:{
			by:"StoreUniqueWhere",
			filter:"StoreWhere"
		},
		listStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		paginateStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getBusinessCategory:{
			by:"BusinessCategoryUniqueWhere",
			filter:"BusinessCategoryWhere"
		},
		listBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		getProductList:{
			by:"ProductListUniqueWhere",
			filter:"ProductListWhere"
		},
		listProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		paginateProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		getProductListItem:{
			by:"ProductListItemUniqueWhere",
			filter:"ProductListItemWhere"
		},
		listProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		paginateProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getProductCategoryListItem:{
			by:"ProductCategoryListItemUniqueWhere",
			filter:"ProductCategoryListItemWhere"
		},
		listProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		getBusinessCategoryLocale:{
			by:"BusinessCategoryLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		listBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getProductCategoryListLocale:{
			by:"ProductCategoryListLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		listProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		getProductIngredientItem:{
			by:"ProductIngredientItemUniqueWhere",
			filter:"ProductIngredientItemWhere"
		},
		listProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		getProductIngredient:{
			by:"ProductIngredientUniqueWhere",
			filter:"ProductIngredientWhere"
		},
		listProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		paginateProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		getProductIngredientRating:{
			by:"ProductIngredientRatingUniqueWhere",
			filter:"ProductIngredientRatingWhere"
		},
		listProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		paginateProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		getProductIngredientLocale:{
			by:"ProductIngredientLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		listProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		getOrderRecurrence:{
			by:"OrderRecurrenceUniqueWhere",
			filter:"OrderRecurrenceWhere"
		},
		listOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		paginateOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		getOrderRecurrenceLocale:{
			by:"OrderRecurrenceLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		listOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		getBlog:{
			by:"BlogUniqueWhere",
			filter:"BlogWhere"
		},
		listBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		paginateBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		getBlogLocale:{
			by:"BlogLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		listBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getColorPallete:{
			by:"ColorPalleteUniqueWhere",
			filter:"ColorPalleteWhere"
		},
		listColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		paginateColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getBlogPostList:{
			by:"BlogPostListUniqueWhere",
			filter:"BlogPostListWhere"
		},
		listBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		paginateBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		getBlogPostListItem:{
			by:"BlogPostListItemUniqueWhere",
			filter:"BlogPostListItemWhere"
		},
		listBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getCover:{
			by:"CoverUniqueWhere",
			filter:"CoverWhere"
		},
		listCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		paginateCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getSocialsAndApps:{
			by:"SocialsAndAppsUniqueWhere",
			filter:"SocialsAndAppsWhere"
		},
		listSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		paginateSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		getProductGrid:{
			by:"ProductGridUniqueWhere",
			filter:"ProductGridWhere"
		},
		listProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		paginateProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		getProductGridItem:{
			by:"ProductGridItemUniqueWhere",
			filter:"ProductGridItemWhere"
		},
		listProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		paginateProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getHero:{
			by:"HeroUniqueWhere",
			filter:"HeroWhere"
		},
		listHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		paginateHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		getSiteMenu:{
			by:"SiteMenuUniqueWhere",
			filter:"SiteMenuWhere"
		},
		listSiteMenu:{
			filter:"SiteMenuWhere",
			orderBy:"SiteMenuOrderBy"
		},
		paginateSiteMenu:{
			filter:"SiteMenuWhere",
			orderBy:"SiteMenuOrderBy"
		},
		getMenuLocale:{
			by:"MenuLocaleUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		listMenuLocale:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		},
		paginateMenuLocale:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		},
		getMenuItem:{
			by:"MenuItemUniqueWhere",
			filter:"MenuItemWhere"
		},
		listMenuItem:{
			filter:"MenuItemWhere",
			orderBy:"MenuItemOrderBy"
		},
		paginateMenuItem:{
			filter:"MenuItemWhere",
			orderBy:"MenuItemOrderBy"
		},
		getPage:{
			by:"PageUniqueWhere",
			filter:"PageWhere"
		},
		listPage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		paginatePage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		getPageLocale:{
			by:"PageLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		listPageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginatePageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		getWikiPage:{
			by:"WikiPageUniqueWhere",
			filter:"WikiPageWhere"
		},
		listWikiPage:{
			filter:"WikiPageWhere",
			orderBy:"WikiPageOrderBy"
		},
		paginateWikiPage:{
			filter:"WikiPageWhere",
			orderBy:"WikiPageOrderBy"
		},
		getMenuSubitem:{
			by:"MenuSubitemUniqueWhere",
			filter:"MenuSubitemWhere"
		},
		listMenuSubitem:{
			filter:"MenuSubitemWhere",
			orderBy:"MenuSubitemOrderBy"
		},
		paginateMenuSubitem:{
			filter:"MenuSubitemWhere",
			orderBy:"MenuSubitemOrderBy"
		},
		getMenuLinkList:{
			by:"MenuLinkListUniqueWhere",
			filter:"MenuLinkListWhere"
		},
		listMenuLinkList:{
			filter:"MenuLinkListWhere",
			orderBy:"MenuLinkListOrderBy"
		},
		paginateMenuLinkList:{
			filter:"MenuLinkListWhere",
			orderBy:"MenuLinkListOrderBy"
		},
		getMenuLinkItem:{
			by:"MenuLinkItemUniqueWhere",
			filter:"MenuLinkItemWhere"
		},
		listMenuLinkItem:{
			filter:"MenuLinkItemWhere",
			orderBy:"MenuLinkItemOrderBy"
		},
		paginateMenuLinkItem:{
			filter:"MenuLinkItemWhere",
			orderBy:"MenuLinkItemOrderBy"
		},
		getMenuProducts:{
			by:"MenuProductsUniqueWhere",
			filter:"MenuProductsWhere"
		},
		listMenuProducts:{
			filter:"MenuProductsWhere",
			orderBy:"MenuProductsOrderBy"
		},
		paginateMenuProducts:{
			filter:"MenuProductsWhere",
			orderBy:"MenuProductsOrderBy"
		},
		getMenuDelivery:{
			by:"MenuDeliveryUniqueWhere",
			filter:"MenuDeliveryWhere"
		},
		listMenuDelivery:{
			filter:"MenuDeliveryWhere",
			orderBy:"MenuDeliveryOrderBy"
		},
		paginateMenuDelivery:{
			filter:"MenuDeliveryWhere",
			orderBy:"MenuDeliveryOrderBy"
		},
		getMenuContact:{
			by:"MenuContactUniqueWhere",
			filter:"MenuContactWhere"
		},
		listMenuContact:{
			filter:"MenuContactWhere",
			orderBy:"MenuContactOrderBy"
		},
		paginateMenuContact:{
			filter:"MenuContactWhere",
			orderBy:"MenuContactOrderBy"
		},
		getProductBanner:{
			by:"ProductBannerUniqueWhere",
			filter:"ProductBannerWhere"
		},
		listProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		paginateProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		getProductBannerItem:{
			by:"ProductBannerItemUniqueWhere",
			filter:"ProductBannerItemWhere"
		},
		listProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		getProductBannerList:{
			by:"ProductBannerListUniqueWhere",
			filter:"ProductBannerListWhere"
		},
		listProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		paginateProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		getAllergen:{
			by:"AllergenUniqueWhere",
			filter:"AllergenWhere"
		},
		listAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		paginateAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		getAllergenLocale:{
			by:"AllergenLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		listAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		getGlobalConfig:{
			by:"GlobalConfigUniqueWhere",
			filter:"GlobalConfigWhere"
		},
		listGlobalConfig:{
			filter:"GlobalConfigWhere",
			orderBy:"GlobalConfigOrderBy"
		},
		paginateGlobalConfig:{
			filter:"GlobalConfigWhere",
			orderBy:"GlobalConfigOrderBy"
		},
		getWeekHours:{
			by:"WeekHoursUniqueWhere",
			filter:"WeekHoursWhere"
		},
		listWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		paginateWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		getDeliveryZone:{
			by:"DeliveryZoneUniqueWhere",
			filter:"DeliveryZoneWhere"
		},
		listDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		getDeliveryTimelinePreset:{
			by:"DeliveryTimelinePresetUniqueWhere",
			filter:"DeliveryTimelinePresetWhere"
		},
		listDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		paginateDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		getProductPackingLocale:{
			by:"ProductPackingLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		listProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductRecipeLocale:{
			by:"ProductRecipeLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		listProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		getVirtualProduct:{
			by:"VirtualProductUniqueWhere",
			filter:"VirtualProductWhere"
		},
		listVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		paginateVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		getProductMetadata:{
			by:"ProductMetadataUniqueWhere",
			filter:"ProductMetadataWhere"
		},
		listProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		paginateProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		getProductGridCategory:{
			by:"ProductGridCategoryUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		listProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		paginateProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getFreshingContainer:{
			by:"FreshingContainerUniqueWhere",
			filter:"FreshingContainerWhere"
		},
		listFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		paginateFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getDeliveryRegions:{
			by:"DeliveryRegionsUniqueWhere",
			filter:"DeliveryRegionsWhere"
		},
		listDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		paginateDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		getDeliveryRegionsItem:{
			by:"DeliveryRegionsItemUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		listDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		paginateDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getDeliveryMethodLocale:{
			by:"DeliveryMethodLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		listDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		}
	},
	UUID: "String",
	CountryUniqueWhere:{
		id:"UUID"
	},
	CountryWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		and:"CountryWhere",
		or:"CountryWhere",
		not:"CountryWhere"
	},
	UUIDCondition:{
		and:"UUIDCondition",
		or:"UUIDCondition",
		not:"UUIDCondition",
		eq:"UUID",
		notEq:"UUID",
		in:"UUID",
		notIn:"UUID",
		lt:"UUID",
		lte:"UUID",
		gt:"UUID",
		gte:"UUID"
	},
	StringCondition:{
		and:"StringCondition",
		or:"StringCondition",
		not:"StringCondition"
	},
	CountryOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection"
	},
	OrderDirection: true,
	Channel:{
		addTagsOnRegistration:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		publicTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginatePublicTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		}
	},
	ChannelCode: true,
	Tag:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		categories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		addTagsOnRegistration:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		deliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		publicInChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateCategories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginatePublicInChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		}
	},
	Product:{
		packing:{
			filter:"ProductPackingWhere"
		},
		recipe:{
			filter:"ProductRecipeWhere"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		categories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		stocks:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		deliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		locales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		virtualProduct:{
			filter:"VirtualProductWhere"
		},
		meta:{
			filter:"ProductMetadataWhere"
		},
		page:{
			filter:"PageWhere"
		},
		stocksByStore:{
			by:"ProductStocksByStoreUniqueWhere",
			filter:"ProductStockWhere"
		},
		localesByLocale:{
			by:"ProductLocalesByLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		localesByFeatureList:{
			by:"ProductLocalesByFeatureListUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateCategories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateStocks:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateLocales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		}
	},
	ProductPacking:{
		image:{
			filter:"ImageWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		localesByLocale:{
			by:"ProductPackingLocalesByLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		paginateLocales:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		}
	},
	ImageWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		alt:"StringCondition",
		and:"ImageWhere",
		or:"ImageWhere",
		not:"ImageWhere"
	},
	IntCondition:{
		and:"IntCondition",
		or:"IntCondition",
		not:"IntCondition"
	},
	ProductPackingLocale:{
		root:{
			filter:"ProductPackingWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductPackingWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		image:"ImageWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		volumeMl:"IntCondition",
		icon:"ImageWhere",
		shortName:"StringCondition",
		locales:"ProductPackingLocaleWhere",
		isPackedInCardboard:"BooleanCondition",
		and:"ProductPackingWhere",
		or:"ProductPackingWhere",
		not:"ProductPackingWhere"
	},
	ProductPackingLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		shortName:"StringCondition",
		description:"StringCondition",
		root:"ProductPackingWhere",
		locale:"LocaleWhere",
		and:"ProductPackingLocaleWhere",
		or:"ProductPackingLocaleWhere",
		not:"ProductPackingLocaleWhere"
	},
	LocaleWhere:{
		id:"UUIDCondition",
		businessCategories:"BusinessCategoryLocaleWhere",
		productCategoryLists:"ProductCategoryListLocaleWhere",
		productIngredients:"ProductIngredientLocaleWhere",
		orderRecurrences:"OrderRecurrenceLocaleWhere",
		blogs:"BlogLocaleWhere",
		blogPosts:"BlogPostLocaleWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		menus:"MenuLocaleWhere",
		pages:"PageLocaleWhere",
		allergens:"AllergenLocaleWhere",
		productPackings:"ProductPackingLocaleWhere",
		products:"ProductLocaleWhere",
		productRecipes:"ProductRecipeLocaleWhere",
		deliveryMethods:"DeliveryMethodLocaleWhere",
		and:"LocaleWhere",
		or:"LocaleWhere",
		not:"LocaleWhere"
	},
	BusinessCategoryLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"BusinessCategoryWhere",
		locale:"LocaleWhere",
		title:"StringCondition",
		and:"BusinessCategoryLocaleWhere",
		or:"BusinessCategoryLocaleWhere",
		not:"BusinessCategoryLocaleWhere"
	},
	BusinessCategoryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		visibleToNewCustomers:"BooleanCondition",
		locales:"BusinessCategoryLocaleWhere",
		internalName:"StringCondition",
		recurrences:"OrderRecurrenceWhere",
		specificProducts:"ProductListWhere",
		seasonalProductCategories:"ProductCategoryListWhere",
		specificProductsProductCategories:"ProductCategoryListWhere",
		slug:"StringCondition",
		and:"BusinessCategoryWhere",
		or:"BusinessCategoryWhere",
		not:"BusinessCategoryWhere"
	},
	BooleanCondition:{
		and:"BooleanCondition",
		or:"BooleanCondition",
		not:"BooleanCondition"
	},
	OrderRecurrenceWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		locales:"OrderRecurrenceLocaleWhere",
		order:"IntCondition",
		businessCategory:"BusinessCategoryWhere",
		and:"OrderRecurrenceWhere",
		or:"OrderRecurrenceWhere",
		not:"OrderRecurrenceWhere"
	},
	OrderRecurrenceLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"OrderRecurrenceWhere",
		locale:"LocaleWhere",
		and:"OrderRecurrenceLocaleWhere",
		or:"OrderRecurrenceLocaleWhere",
		not:"OrderRecurrenceLocaleWhere"
	},
	ProductListWhere:{
		id:"UUIDCondition",
		items:"ProductListItemWhere",
		businessCategory:"BusinessCategoryWhere",
		and:"ProductListWhere",
		or:"ProductListWhere",
		not:"ProductListWhere"
	},
	ProductListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductListWhere",
		product:"ProductWhere",
		defaultQuantityForOrder:"IntCondition",
		and:"ProductListItemWhere",
		or:"ProductListItemWhere",
		not:"ProductListItemWhere"
	},
	ProductWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		priceCents:"IntCondition",
		notDiscountable:"BooleanCondition",
		packing:"ProductPackingWhere",
		recipe:"ProductRecipeWhere",
		tags:"TagWhere",
		categories:"ProductCategoryWhere",
		vatRate:"VatRateWhere",
		stocks:"ProductStockWhere",
		image:"ImageWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		availability:"ProductAvailabilityCondition",
		order:"FloatCondition",
		maximumQuantityPerOrder:"IntCondition",
		deliveryZones:"DeliveryZoneWhere",
		locales:"ProductLocaleWhere",
		archivedAt:"DateTimeCondition",
		virtualProduct:"VirtualProductWhere",
		meta:"ProductMetadataWhere",
		page:"PageWhere",
		and:"ProductWhere",
		or:"ProductWhere",
		not:"ProductWhere"
	},
	ProductRecipeWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		products:"ProductWhere",
		internalNote:"StringCondition",
		color:"StringCondition",
		image:"ImageWhere",
		icon:"ImageWhere",
		ingredients:"ProductIngredientItemWhere",
		needsPrepping:"BooleanCondition",
		locales:"ProductRecipeLocaleWhere",
		gutTuning:"FloatCondition",
		freshingContainer:"FreshingContainerWhere",
		description:"StringCondition",
		and:"ProductRecipeWhere",
		or:"ProductRecipeWhere",
		not:"ProductRecipeWhere"
	},
	ProductIngredientItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		internalOrder:"IntCondition",
		recipe:"ProductRecipeWhere",
		ingredient:"ProductIngredientWhere",
		and:"ProductIngredientItemWhere",
		or:"ProductIngredientItemWhere",
		not:"ProductIngredientItemWhere"
	},
	ProductIngredientWhere:{
		id:"UUIDCondition",
		internalNote:"StringCondition",
		locales:"ProductIngredientLocaleWhere",
		internalName:"StringCondition",
		allergens:"AllergenWhere",
		needsPrepping:"BooleanCondition",
		allowCustomerRating:"BooleanCondition",
		freshingContainer:"FreshingContainerWhere",
		and:"ProductIngredientWhere",
		or:"ProductIngredientWhere",
		not:"ProductIngredientWhere"
	},
	ProductIngredientLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductIngredientWhere",
		locale:"LocaleWhere",
		and:"ProductIngredientLocaleWhere",
		or:"ProductIngredientLocaleWhere",
		not:"ProductIngredientLocaleWhere"
	},
	AllergenWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		locales:"AllergenLocaleWhere",
		ingredients:"ProductIngredientWhere",
		code:"IntCondition",
		and:"AllergenWhere",
		or:"AllergenWhere",
		not:"AllergenWhere"
	},
	AllergenLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"AllergenWhere",
		locale:"LocaleWhere",
		and:"AllergenLocaleWhere",
		or:"AllergenLocaleWhere",
		not:"AllergenLocaleWhere"
	},
	FreshingContainerWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		config:"GlobalConfigWhere",
		volumeMl:"IntCondition",
		and:"FreshingContainerWhere",
		or:"FreshingContainerWhere",
		not:"FreshingContainerWhere"
	},
	GlobalConfigWhere:{
		id:"UUIDCondition",
		orderDeadlineTime:"StringCondition",
		ordersLoadingDurationHours:"IntCondition",
		openingHours:"WeekHoursWhere",
		unique:"OneCondition",
		workingHours:"WeekHoursWhere",
		freshingContainerVolumeMl:"IntCondition",
		checkoutUrlTemplate:"StringCondition",
		orderUrlTemplate:"StringCondition",
		paymentUrlTemplate:"StringCondition",
		customerProfileUrlTemplate:"StringCondition",
		userProfileUrlTemplate:"StringCondition",
		creditsProduct:"VirtualProductWhere",
		discountVatRate:"VatRateWhere",
		defaultFreshingContainer:"FreshingContainerWhere",
		freshingContainers:"FreshingContainerWhere",
		messengerUrl:"StringCondition",
		messengerIosUrl:"StringCondition",
		messengerAndroidUrl:"StringCondition",
		freshingPrepareOrderByOffsetMinutes:"IntCondition",
		and:"GlobalConfigWhere",
		or:"GlobalConfigWhere",
		not:"GlobalConfigWhere"
	},
	WeekHoursWhere:{
		id:"UUIDCondition",
		mon:"StringCondition",
		tue:"StringCondition",
		wed:"StringCondition",
		thu:"StringCondition",
		fri:"StringCondition",
		sat:"StringCondition",
		sun:"StringCondition",
		and:"WeekHoursWhere",
		or:"WeekHoursWhere",
		not:"WeekHoursWhere"
	},
	OneCondition:{
		and:"OneCondition",
		or:"OneCondition",
		not:"OneCondition",
		eq:"One",
		notEq:"One",
		in:"One",
		notIn:"One",
		lt:"One",
		lte:"One",
		gt:"One",
		gte:"One"
	},
	One: true,
	VirtualProductWhere:{
		id:"UUIDCondition",
		type:"VirtualProductTypeCondition",
		product:"ProductWhere",
		voucherCreditCents:"IntCondition",
		physicalRepresentation:"VirtualProductPhysicalRepresentationTypeCondition",
		recipientEmail:"BooleanCondition",
		and:"VirtualProductWhere",
		or:"VirtualProductWhere",
		not:"VirtualProductWhere"
	},
	VirtualProductTypeCondition:{
		and:"VirtualProductTypeCondition",
		or:"VirtualProductTypeCondition",
		not:"VirtualProductTypeCondition",
		eq:"VirtualProductType",
		notEq:"VirtualProductType",
		in:"VirtualProductType",
		notIn:"VirtualProductType",
		lt:"VirtualProductType",
		lte:"VirtualProductType",
		gt:"VirtualProductType",
		gte:"VirtualProductType"
	},
	VirtualProductType: true,
	VirtualProductPhysicalRepresentationTypeCondition:{
		and:"VirtualProductPhysicalRepresentationTypeCondition",
		or:"VirtualProductPhysicalRepresentationTypeCondition",
		not:"VirtualProductPhysicalRepresentationTypeCondition",
		eq:"VirtualProductPhysicalRepresentationType",
		notEq:"VirtualProductPhysicalRepresentationType",
		in:"VirtualProductPhysicalRepresentationType",
		notIn:"VirtualProductPhysicalRepresentationType",
		lt:"VirtualProductPhysicalRepresentationType",
		lte:"VirtualProductPhysicalRepresentationType",
		gt:"VirtualProductPhysicalRepresentationType",
		gte:"VirtualProductPhysicalRepresentationType"
	},
	VirtualProductPhysicalRepresentationType: true,
	VatRateWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		ratePermille:"IntCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		and:"VatRateWhere",
		or:"VatRateWhere",
		not:"VatRateWhere"
	},
	ProductRecipeLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductRecipeWhere",
		locale:"LocaleWhere",
		description:"StringCondition",
		and:"ProductRecipeLocaleWhere",
		or:"ProductRecipeLocaleWhere",
		not:"ProductRecipeLocaleWhere"
	},
	FloatCondition:{
		and:"FloatCondition",
		or:"FloatCondition",
		not:"FloatCondition"
	},
	TagWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		products:"ProductWhere",
		categories:"ProductCategoryWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		addTagsOnRegistration:"ChannelWhere",
		deliveryZones:"DeliveryZoneWhere",
		publicInChannels:"ChannelWhere",
		and:"TagWhere",
		or:"TagWhere",
		not:"TagWhere"
	},
	ProductCategoryWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		products:"ProductWhere",
		tags:"TagWhere",
		parent:"ProductCategoryWhere",
		children:"ProductCategoryWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		color:"StringCondition",
		image:"ImageWhere",
		order:"FloatCondition",
		and:"ProductCategoryWhere",
		or:"ProductCategoryWhere",
		not:"ProductCategoryWhere"
	},
	ChannelWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		disabled:"BooleanCondition",
		code:"ChannelCodeCondition",
		addTagsOnRegistration:"TagWhere",
		publicTags:"TagWhere",
		and:"ChannelWhere",
		or:"ChannelWhere",
		not:"ChannelWhere"
	},
	ChannelCodeCondition:{
		and:"ChannelCodeCondition",
		or:"ChannelCodeCondition",
		not:"ChannelCodeCondition",
		eq:"ChannelCode",
		notEq:"ChannelCode",
		in:"ChannelCode",
		notIn:"ChannelCode",
		lt:"ChannelCode",
		lte:"ChannelCode",
		gt:"ChannelCode",
		gte:"ChannelCode"
	},
	DeliveryZoneWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		type:"DeliveryZoneTypeCondition",
		suitableDeliveryMethods:"DeliveryMethodWhere",
		deliveryZonesOfTags:"TagWhere",
		deliveryZonesOfProducts:"ProductWhere",
		and:"DeliveryZoneWhere",
		or:"DeliveryZoneWhere",
		not:"DeliveryZoneWhere"
	},
	DeliveryZoneTypeCondition:{
		and:"DeliveryZoneTypeCondition",
		or:"DeliveryZoneTypeCondition",
		not:"DeliveryZoneTypeCondition",
		eq:"DeliveryZoneType",
		notEq:"DeliveryZoneType",
		in:"DeliveryZoneType",
		notIn:"DeliveryZoneType",
		lt:"DeliveryZoneType",
		lte:"DeliveryZoneType",
		gt:"DeliveryZoneType",
		gte:"DeliveryZoneType"
	},
	DeliveryZoneType: true,
	DeliveryMethodWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		enabled:"BooleanCondition",
		paymentMethods:"PaymentMethodWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		requireDeliveryAddress:"BooleanCondition",
		suitableForDeliveryZones:"DeliveryZoneWhere",
		timelinePreset:"DeliveryTimelinePresetWhere",
		enableDeliveryTracking:"BooleanCondition",
		locales:"DeliveryMethodLocaleWhere",
		and:"DeliveryMethodWhere",
		or:"DeliveryMethodWhere",
		not:"DeliveryMethodWhere"
	},
	PaymentMethodWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		enabled:"BooleanCondition",
		deliveryMethods:"DeliveryMethodWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		blockingDispatch:"BooleanCondition",
		type:"PaymentMethodTypeCondition",
		and:"PaymentMethodWhere",
		or:"PaymentMethodWhere",
		not:"PaymentMethodWhere"
	},
	PaymentMethodTypeCondition:{
		and:"PaymentMethodTypeCondition",
		or:"PaymentMethodTypeCondition",
		not:"PaymentMethodTypeCondition",
		eq:"PaymentMethodType",
		notEq:"PaymentMethodType",
		in:"PaymentMethodType",
		notIn:"PaymentMethodType",
		lt:"PaymentMethodType",
		lte:"PaymentMethodType",
		gt:"PaymentMethodType",
		gte:"PaymentMethodType"
	},
	PaymentMethodType: true,
	DeliveryTimelinePresetWhere:{
		id:"UUIDCondition",
		type:"DeliveryTimelineTypeCondition",
		duration:"StringCondition",
		window:"StringCondition",
		deliveryMethod:"DeliveryMethodWhere",
		and:"DeliveryTimelinePresetWhere",
		or:"DeliveryTimelinePresetWhere",
		not:"DeliveryTimelinePresetWhere"
	},
	DeliveryTimelineTypeCondition:{
		and:"DeliveryTimelineTypeCondition",
		or:"DeliveryTimelineTypeCondition",
		not:"DeliveryTimelineTypeCondition",
		eq:"DeliveryTimelineType",
		notEq:"DeliveryTimelineType",
		in:"DeliveryTimelineType",
		notIn:"DeliveryTimelineType",
		lt:"DeliveryTimelineType",
		lte:"DeliveryTimelineType",
		gt:"DeliveryTimelineType",
		gte:"DeliveryTimelineType"
	},
	DeliveryTimelineType: true,
	DeliveryMethodLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		description:"StringCondition",
		trackingCodeUrlTemplate:"StringCondition",
		processingTitle:"StringCondition",
		processingText:"StringCondition",
		processingIconUrl:"StringCondition",
		dispatchedTitle:"StringCondition",
		dispatchedText:"StringCondition",
		dispatchedIconUrl:"StringCondition",
		fulfilledTitle:"StringCondition",
		fulfilledText:"StringCondition",
		fulfilledIconUrl:"StringCondition",
		locale:"LocaleWhere",
		root:"DeliveryMethodWhere",
		and:"DeliveryMethodLocaleWhere",
		or:"DeliveryMethodLocaleWhere",
		not:"DeliveryMethodLocaleWhere"
	},
	ProductStockWhere:{
		id:"UUIDCondition",
		quantity:"IntCondition",
		product:"ProductWhere",
		store:"StoreWhere",
		and:"ProductStockWhere",
		or:"ProductStockWhere",
		not:"ProductStockWhere"
	},
	StoreWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		and:"StoreWhere",
		or:"StoreWhere",
		not:"StoreWhere"
	},
	ProductAvailabilityCondition:{
		and:"ProductAvailabilityCondition",
		or:"ProductAvailabilityCondition",
		not:"ProductAvailabilityCondition",
		eq:"ProductAvailability",
		notEq:"ProductAvailability",
		in:"ProductAvailability",
		notIn:"ProductAvailability",
		lt:"ProductAvailability",
		lte:"ProductAvailability",
		gt:"ProductAvailability",
		gte:"ProductAvailability"
	},
	ProductAvailability: true,
	ProductLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		description:"StringCondition",
		root:"ProductWhere",
		locale:"LocaleWhere",
		featureList:"TextListWhere",
		and:"ProductLocaleWhere",
		or:"ProductLocaleWhere",
		not:"ProductLocaleWhere"
	},
	TextListWhere:{
		id:"UUIDCondition",
		items:"TextItemWhere",
		and:"TextListWhere",
		or:"TextListWhere",
		not:"TextListWhere"
	},
	TextItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		list:"TextListWhere",
		and:"TextItemWhere",
		or:"TextItemWhere",
		not:"TextItemWhere"
	},
	DateTimeCondition:{
		and:"DateTimeCondition",
		or:"DateTimeCondition",
		not:"DateTimeCondition",
		eq:"DateTime",
		notEq:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime"
	},
	DateTime: "String",
	ProductMetadataWhere:{
		id:"UUIDCondition",
		archived:"BooleanCondition",
		available:"BooleanCondition",
		availableQuantity:"IntCondition",
		product:"ProductWhere",
		and:"ProductMetadataWhere",
		or:"ProductMetadataWhere",
		not:"ProductMetadataWhere"
	},
	PageWhere:{
		id:"UUIDCondition",
		locales:"PageLocaleWhere",
		and:"PageWhere",
		or:"PageWhere",
		not:"PageWhere"
	},
	PageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		link:"LinkableWhere",
		root:"PageWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		content:"ContentWhere",
		cover:"CoverWhere",
		theme:"PageThemeCondition",
		and:"PageLocaleWhere",
		or:"PageLocaleWhere",
		not:"PageLocaleWhere"
	},
	LinkableWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		blogPost:"BlogPostLocaleWhere",
		page:"PageLocaleWhere",
		wikiPage:"WikiPageWhere",
		redirect:"RedirectWhere",
		and:"LinkableWhere",
		or:"LinkableWhere",
		not:"LinkableWhere"
	},
	BlogPostLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		availableForWeb:"BooleanCondition",
		availableForMobile:"BooleanCondition",
		root:"BlogPostWhere",
		locale:"LocaleWhere",
		cover:"CoverWhere",
		content:"ContentWhere",
		link:"LinkableWhere",
		seo:"SeoWhere",
		products:"ProductWhere",
		and:"BlogPostLocaleWhere",
		or:"BlogPostLocaleWhere",
		not:"BlogPostLocaleWhere"
	},
	BlogPostWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		locales:"BlogPostLocaleWhere",
		and:"BlogPostWhere",
		or:"BlogPostWhere",
		not:"BlogPostWhere"
	},
	CoverWhere:{
		id:"UUIDCondition",
		primaryTitle:"StringCondition",
		secondaryTitle:"StringCondition",
		medium:"MediumWhere",
		buttonColors:"ColorPalleteWhere",
		and:"CoverWhere",
		or:"CoverWhere",
		not:"CoverWhere"
	},
	MediumWhere:{
		id:"UUIDCondition",
		type:"MediumTypeCondition",
		colorTheme:"ColorThemeCondition",
		image:"ImageWhere",
		video:"VideoWhere",
		and:"MediumWhere",
		or:"MediumWhere",
		not:"MediumWhere"
	},
	MediumTypeCondition:{
		and:"MediumTypeCondition",
		or:"MediumTypeCondition",
		not:"MediumTypeCondition",
		eq:"MediumType",
		notEq:"MediumType",
		in:"MediumType",
		notIn:"MediumType",
		lt:"MediumType",
		lte:"MediumType",
		gt:"MediumType",
		gte:"MediumType"
	},
	MediumType: true,
	ColorThemeCondition:{
		and:"ColorThemeCondition",
		or:"ColorThemeCondition",
		not:"ColorThemeCondition",
		eq:"ColorTheme",
		notEq:"ColorTheme",
		in:"ColorTheme",
		notIn:"ColorTheme",
		lt:"ColorTheme",
		lte:"ColorTheme",
		gt:"ColorTheme",
		gte:"ColorTheme"
	},
	ColorTheme: true,
	VideoWhere:{
		id:"UUIDCondition",
		src:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		duration:"FloatCondition",
		poster:"ImageWhere",
		and:"VideoWhere",
		or:"VideoWhere",
		not:"VideoWhere"
	},
	ColorPalleteWhere:{
		id:"UUIDCondition",
		background:"StringCondition",
		text:"StringCondition",
		and:"ColorPalleteWhere",
		or:"ColorPalleteWhere",
		not:"ColorPalleteWhere"
	},
	ContentWhere:{
		id:"UUIDCondition",
		blocks:"ContentBlockWhere",
		and:"ContentWhere",
		or:"ContentWhere",
		not:"ContentWhere"
	},
	ContentBlockWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		json:"StringCondition",
		content:"ContentWhere",
		references:"ContentReferenceWhere",
		and:"ContentBlockWhere",
		or:"ContentBlockWhere",
		not:"ContentBlockWhere"
	},
	ContentReferenceWhere:{
		id:"UUIDCondition",
		type:"ContentReferenceTypeCondition",
		primaryText:"StringCondition",
		secondaryText:"StringCondition",
		jsonContent:"StringCondition",
		block:"ContentBlockWhere",
		colorPallete:"ColorPalleteWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		blogPosts:"BlogPostListWhere",
		links:"LinkListWhere",
		products:"ProductListWhere",
		contentSize:"ContentSizeCondition",
		misc:"StringCondition",
		hero:"HeroWhere",
		gallery:"GalleryWhere",
		socialsAndApps:"SocialsAndAppsWhere",
		productGrid:"ProductGridWhere",
		productBanners:"ProductBannerListWhere",
		deliveryRegions:"DeliveryRegionsWhere",
		and:"ContentReferenceWhere",
		or:"ContentReferenceWhere",
		not:"ContentReferenceWhere"
	},
	ContentReferenceTypeCondition:{
		and:"ContentReferenceTypeCondition",
		or:"ContentReferenceTypeCondition",
		not:"ContentReferenceTypeCondition",
		eq:"ContentReferenceType",
		notEq:"ContentReferenceType",
		in:"ContentReferenceType",
		notIn:"ContentReferenceType",
		lt:"ContentReferenceType",
		lte:"ContentReferenceType",
		gt:"ContentReferenceType",
		gte:"ContentReferenceType"
	},
	ContentReferenceType: true,
	LinkWhere:{
		id:"UUIDCondition",
		type:"LinkTypeCondition",
		title:"StringCondition",
		externalLink:"StringCondition",
		internalLink:"LinkableWhere",
		and:"LinkWhere",
		or:"LinkWhere",
		not:"LinkWhere"
	},
	LinkTypeCondition:{
		and:"LinkTypeCondition",
		or:"LinkTypeCondition",
		not:"LinkTypeCondition",
		eq:"LinkType",
		notEq:"LinkType",
		in:"LinkType",
		notIn:"LinkType",
		lt:"LinkType",
		lte:"LinkType",
		gt:"LinkType",
		gte:"LinkType"
	},
	LinkType: true,
	BlogPostListWhere:{
		id:"UUIDCondition",
		items:"BlogPostListItemWhere",
		and:"BlogPostListWhere",
		or:"BlogPostListWhere",
		not:"BlogPostListWhere"
	},
	BlogPostListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		caption:"StringCondition",
		list:"BlogPostListWhere",
		blogPost:"BlogPostLocaleWhere",
		and:"BlogPostListItemWhere",
		or:"BlogPostListItemWhere",
		not:"BlogPostListItemWhere"
	},
	LinkListWhere:{
		id:"UUIDCondition",
		items:"LinkListItemWhere",
		and:"LinkListWhere",
		or:"LinkListWhere",
		not:"LinkListWhere"
	},
	LinkListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		isPrimary:"BooleanCondition",
		list:"LinkListWhere",
		link:"LinkWhere",
		and:"LinkListItemWhere",
		or:"LinkListItemWhere",
		not:"LinkListItemWhere"
	},
	ContentSizeCondition:{
		and:"ContentSizeCondition",
		or:"ContentSizeCondition",
		not:"ContentSizeCondition",
		eq:"ContentSize",
		notEq:"ContentSize",
		in:"ContentSize",
		notIn:"ContentSize",
		lt:"ContentSize",
		lte:"ContentSize",
		gt:"ContentSize",
		gte:"ContentSize"
	},
	ContentSize: true,
	HeroWhere:{
		id:"UUIDCondition",
		showLogo:"BooleanCondition",
		middleContent:"StringCondition",
		medium:"MediumWhere",
		and:"HeroWhere",
		or:"HeroWhere",
		not:"HeroWhere"
	},
	GalleryWhere:{
		id:"UUIDCondition",
		items:"GalleryItemWhere",
		and:"GalleryWhere",
		or:"GalleryWhere",
		not:"GalleryWhere"
	},
	GalleryItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		caption:"StringCondition",
		list:"GalleryWhere",
		medium:"MediumWhere",
		and:"GalleryItemWhere",
		or:"GalleryItemWhere",
		not:"GalleryItemWhere"
	},
	SocialsAndAppsWhere:{
		id:"UUIDCondition",
		instagram:"BooleanCondition",
		appStore:"BooleanCondition",
		googlePlay:"BooleanCondition",
		and:"SocialsAndAppsWhere",
		or:"SocialsAndAppsWhere",
		not:"SocialsAndAppsWhere"
	},
	ProductGridWhere:{
		id:"UUIDCondition",
		isCarousel:"BooleanCondition",
		categories:"ProductGridCategoryWhere",
		type:"ProductGridTypeCondition",
		and:"ProductGridWhere",
		or:"ProductGridWhere",
		not:"ProductGridWhere"
	},
	ProductGridCategoryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		root:"ProductGridWhere",
		items:"ProductGridItemWhere",
		and:"ProductGridCategoryWhere",
		or:"ProductGridCategoryWhere",
		not:"ProductGridCategoryWhere"
	},
	ProductGridItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		lead:"StringCondition",
		product:"ProductWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		category:"ProductGridCategoryWhere",
		description:"StringCondition",
		and:"ProductGridItemWhere",
		or:"ProductGridItemWhere",
		not:"ProductGridItemWhere"
	},
	ProductGridTypeCondition:{
		and:"ProductGridTypeCondition",
		or:"ProductGridTypeCondition",
		not:"ProductGridTypeCondition",
		eq:"ProductGridType",
		notEq:"ProductGridType",
		in:"ProductGridType",
		notIn:"ProductGridType",
		lt:"ProductGridType",
		lte:"ProductGridType",
		gt:"ProductGridType",
		gte:"ProductGridType"
	},
	ProductGridType: true,
	ProductBannerListWhere:{
		id:"UUIDCondition",
		items:"ProductBannerItemWhere",
		and:"ProductBannerListWhere",
		or:"ProductBannerListWhere",
		not:"ProductBannerListWhere"
	},
	ProductBannerItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductBannerListWhere",
		banner:"ProductBannerWhere",
		size:"ProductBannerItemSizeCondition",
		and:"ProductBannerItemWhere",
		or:"ProductBannerItemWhere",
		not:"ProductBannerItemWhere"
	},
	ProductBannerWhere:{
		id:"UUIDCondition",
		beforeTitle:"StringCondition",
		title:"StringCondition",
		label:"StringCondition",
		button:"LinkWhere",
		backgroundImage:"ImageWhere",
		productImage:"ImageWhere",
		product:"ProductWhere",
		and:"ProductBannerWhere",
		or:"ProductBannerWhere",
		not:"ProductBannerWhere"
	},
	ProductBannerItemSizeCondition:{
		and:"ProductBannerItemSizeCondition",
		or:"ProductBannerItemSizeCondition",
		not:"ProductBannerItemSizeCondition",
		eq:"ProductBannerItemSize",
		notEq:"ProductBannerItemSize",
		in:"ProductBannerItemSize",
		notIn:"ProductBannerItemSize",
		lt:"ProductBannerItemSize",
		lte:"ProductBannerItemSize",
		gt:"ProductBannerItemSize",
		gte:"ProductBannerItemSize"
	},
	ProductBannerItemSize: true,
	DeliveryRegionsWhere:{
		id:"UUIDCondition",
		items:"DeliveryRegionsItemWhere",
		and:"DeliveryRegionsWhere",
		or:"DeliveryRegionsWhere",
		not:"DeliveryRegionsWhere"
	},
	DeliveryRegionsItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		offerLabel:"StringCondition",
		list:"DeliveryRegionsWhere",
		image:"ImageWhere",
		textList:"TextListWhere",
		gallery:"ImageListWhere",
		and:"DeliveryRegionsItemWhere",
		or:"DeliveryRegionsItemWhere",
		not:"DeliveryRegionsItemWhere"
	},
	ImageListWhere:{
		id:"UUIDCondition",
		items:"ImageListItemWhere",
		and:"ImageListWhere",
		or:"ImageListWhere",
		not:"ImageListWhere"
	},
	ImageListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ImageListWhere",
		image:"ImageWhere",
		and:"ImageListItemWhere",
		or:"ImageListItemWhere",
		not:"ImageListItemWhere"
	},
	SeoWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		description:"StringCondition",
		ogTitle:"StringCondition",
		ogDescription:"StringCondition",
		ogImage:"ImageWhere",
		and:"SeoWhere",
		or:"SeoWhere",
		not:"SeoWhere"
	},
	WikiPageWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		emoji:"StringCondition",
		link:"LinkableWhere",
		parent:"WikiPageWhere",
		children:"WikiPageWhere",
		content:"ContentWhere",
		and:"WikiPageWhere",
		or:"WikiPageWhere",
		not:"WikiPageWhere"
	},
	RedirectWhere:{
		id:"UUIDCondition",
		link:"LinkableWhere",
		target:"LinkableWhere",
		and:"RedirectWhere",
		or:"RedirectWhere",
		not:"RedirectWhere"
	},
	PageThemeCondition:{
		and:"PageThemeCondition",
		or:"PageThemeCondition",
		not:"PageThemeCondition",
		eq:"PageTheme",
		notEq:"PageTheme",
		in:"PageTheme",
		notIn:"PageTheme",
		lt:"PageTheme",
		lte:"PageTheme",
		gt:"PageTheme",
		gte:"PageTheme"
	},
	PageTheme: true,
	ProductCategoryListWhere:{
		id:"UUIDCondition",
		items:"ProductCategoryListItemWhere",
		locales:"ProductCategoryListLocaleWhere",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryWhere",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryWhere",
		and:"ProductCategoryListWhere",
		or:"ProductCategoryListWhere",
		not:"ProductCategoryListWhere"
	},
	ProductCategoryListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductCategoryListWhere",
		productCategory:"ProductCategoryWhere",
		showRecipeImages:"BooleanCondition",
		showPackingImages:"BooleanCondition",
		showAllAvailableProducts:"BooleanCondition",
		and:"ProductCategoryListItemWhere",
		or:"ProductCategoryListItemWhere",
		not:"ProductCategoryListItemWhere"
	},
	ProductCategoryListLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductCategoryListWhere",
		locale:"LocaleWhere",
		and:"ProductCategoryListLocaleWhere",
		or:"ProductCategoryListLocaleWhere",
		not:"ProductCategoryListLocaleWhere"
	},
	BlogLocaleWhere:{
		id:"UUIDCondition",
		pageName:"StringCondition",
		title:"StringCondition",
		lead:"StringCondition",
		root:"BlogWhere",
		locale:"LocaleWhere",
		and:"BlogLocaleWhere",
		or:"BlogLocaleWhere",
		not:"BlogLocaleWhere"
	},
	BlogWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"BlogLocaleWhere",
		and:"BlogWhere",
		or:"BlogWhere",
		not:"BlogWhere"
	},
	TranslationCataloguesIdentifierWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		and:"TranslationCataloguesIdentifierWhere",
		or:"TranslationCataloguesIdentifierWhere",
		not:"TranslationCataloguesIdentifierWhere"
	},
	TranslationCatalogueWhere:{
		id:"UUIDCondition",
		domain:"TranslationDomainWhere",
		fallback:"TranslationCatalogueWhere",
		values:"TranslationValueWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		and:"TranslationCatalogueWhere",
		or:"TranslationCatalogueWhere",
		not:"TranslationCatalogueWhere"
	},
	TranslationDomainWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		name:"StringCondition",
		catalogues:"TranslationCatalogueWhere",
		keys:"TranslationKeyWhere",
		and:"TranslationDomainWhere",
		or:"TranslationDomainWhere",
		not:"TranslationDomainWhere"
	},
	TranslationKeyWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		contentType:"TranslationContentTypeCondition",
		note:"StringCondition",
		domain:"TranslationDomainWhere",
		values:"TranslationValueWhere",
		and:"TranslationKeyWhere",
		or:"TranslationKeyWhere",
		not:"TranslationKeyWhere"
	},
	TranslationContentTypeCondition:{
		and:"TranslationContentTypeCondition",
		or:"TranslationContentTypeCondition",
		not:"TranslationContentTypeCondition",
		eq:"TranslationContentType",
		notEq:"TranslationContentType",
		in:"TranslationContentType",
		notIn:"TranslationContentType",
		lt:"TranslationContentType",
		lte:"TranslationContentType",
		gt:"TranslationContentType",
		gte:"TranslationContentType"
	},
	TranslationContentType: true,
	TranslationValueWhere:{
		id:"UUIDCondition",
		value:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		key:"TranslationKeyWhere",
		and:"TranslationValueWhere",
		or:"TranslationValueWhere",
		not:"TranslationValueWhere"
	},
	MenuLocaleWhere:{
		id:"UUIDCondition",
		root:"SiteMenuWhere",
		locale:"LocaleWhere",
		items:"MenuItemWhere",
		secondaryItems:"LinkListWhere",
		eshopLink:"LinkWhere",
		and:"MenuLocaleWhere",
		or:"MenuLocaleWhere",
		not:"MenuLocaleWhere"
	},
	SiteMenuWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"MenuLocaleWhere",
		and:"SiteMenuWhere",
		or:"SiteMenuWhere",
		not:"SiteMenuWhere"
	},
	MenuItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		menu:"MenuLocaleWhere",
		link:"LinkWhere",
		subitems:"MenuSubitemWhere",
		and:"MenuItemWhere",
		or:"MenuItemWhere",
		not:"MenuItemWhere"
	},
	MenuSubitemWhere:{
		id:"UUIDCondition",
		type:"MenuSubitemTypeCondition",
		title:"StringCondition",
		item:"MenuItemWhere",
		links:"MenuLinkListWhere",
		products:"MenuProductsWhere",
		delivery:"MenuDeliveryWhere",
		contact:"MenuContactWhere",
		order:"IntCondition",
		and:"MenuSubitemWhere",
		or:"MenuSubitemWhere",
		not:"MenuSubitemWhere"
	},
	MenuSubitemTypeCondition:{
		and:"MenuSubitemTypeCondition",
		or:"MenuSubitemTypeCondition",
		not:"MenuSubitemTypeCondition",
		eq:"MenuSubitemType",
		notEq:"MenuSubitemType",
		in:"MenuSubitemType",
		notIn:"MenuSubitemType",
		lt:"MenuSubitemType",
		lte:"MenuSubitemType",
		gt:"MenuSubitemType",
		gte:"MenuSubitemType"
	},
	MenuSubitemType: true,
	MenuLinkListWhere:{
		id:"UUIDCondition",
		items:"MenuLinkItemWhere",
		and:"MenuLinkListWhere",
		or:"MenuLinkListWhere",
		not:"MenuLinkListWhere"
	},
	MenuLinkItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"MenuLinkListWhere",
		link:"LinkWhere",
		image:"ImageWhere",
		description:"StringCondition",
		and:"MenuLinkItemWhere",
		or:"MenuLinkItemWhere",
		not:"MenuLinkItemWhere"
	},
	MenuProductsWhere:{
		id:"UUIDCondition",
		links:"MenuLinkListWhere",
		buttons:"LinkListWhere",
		and:"MenuProductsWhere",
		or:"MenuProductsWhere",
		not:"MenuProductsWhere"
	},
	MenuDeliveryWhere:{
		id:"UUIDCondition",
		deliveryRegions:"DeliveryRegionsWhere",
		text:"StringCondition",
		socialsAndApps:"SocialsAndAppsWhere",
		and:"MenuDeliveryWhere",
		or:"MenuDeliveryWhere",
		not:"MenuDeliveryWhere"
	},
	MenuContactWhere:{
		id:"UUIDCondition",
		text:"StringCondition",
		image:"ImageWhere",
		and:"MenuContactWhere",
		or:"MenuContactWhere",
		not:"MenuContactWhere"
	},
	Locale:{
		businessCategories:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		productCategoryLists:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		productIngredients:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		orderRecurrences:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		blogs:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		blogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		menus:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		},
		pages:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		allergens:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		productPackings:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		products:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		productRecipes:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		deliveryMethods:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		businessCategoriesByRoot:{
			by:"LocaleBusinessCategoriesByRootUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		productCategoryListsByRoot:{
			by:"LocaleProductCategoryListsByRootUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		productIngredientsByRoot:{
			by:"LocaleProductIngredientsByRootUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		orderRecurrencesByRoot:{
			by:"LocaleOrderRecurrencesByRootUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		blogsByRoot:{
			by:"LocaleBlogsByRootUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		blogPostsByRoot:{
			by:"LocaleBlogPostsByRootUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByCover:{
			by:"LocaleBlogPostsByCoverUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByContent:{
			by:"LocaleBlogPostsByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByLink:{
			by:"LocaleBlogPostsByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsBySeo:{
			by:"LocaleBlogPostsBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		menusByRoot:{
			by:"LocaleMenusByRootUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		menusByItems:{
			by:"LocaleMenusByItemsUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		menusBySecondaryItems:{
			by:"LocaleMenusBySecondaryItemsUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		pagesByRoot:{
			by:"LocalePagesByRootUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByLink:{
			by:"LocalePagesByLinkUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesBySeo:{
			by:"LocalePagesBySeoUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByContent:{
			by:"LocalePagesByContentUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByCover:{
			by:"LocalePagesByCoverUniqueWhere",
			filter:"PageLocaleWhere"
		},
		allergensByRoot:{
			by:"LocaleAllergensByRootUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		productPackingsByRoot:{
			by:"LocaleProductPackingsByRootUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		productsByRoot:{
			by:"LocaleProductsByRootUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		productsByFeatureList:{
			by:"LocaleProductsByFeatureListUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		productRecipesByRoot:{
			by:"LocaleProductRecipesByRootUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		deliveryMethodsByRoot:{
			by:"LocaleDeliveryMethodsByRootUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		paginateBusinessCategories:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateProductCategoryLists:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductIngredients:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateOrderRecurrences:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateBlogs:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateMenus:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		},
		paginatePages:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginateAllergens:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateProductPackings:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProducts:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductRecipes:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		}
	},
	BusinessCategoryLocale:{
		root:{
			filter:"BusinessCategoryWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	BusinessCategory:{
		locales:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		recurrences:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		specificProducts:{
			filter:"ProductListWhere"
		},
		seasonalProductCategories:{
			filter:"ProductCategoryListWhere"
		},
		specificProductsProductCategories:{
			filter:"ProductCategoryListWhere"
		},
		localesByLocale:{
			by:"BusinessCategoryLocalesByLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		paginateLocales:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateRecurrences:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		}
	},
	BusinessCategoryLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"BusinessCategoryOrderBy",
		locale:"LocaleOrderBy",
		title:"OrderDirection"
	},
	BusinessCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		visibleToNewCustomers:"OrderDirection",
		internalName:"OrderDirection",
		specificProducts:"ProductListOrderBy",
		seasonalProductCategories:"ProductCategoryListOrderBy",
		specificProductsProductCategories:"ProductCategoryListOrderBy",
		slug:"OrderDirection"
	},
	ProductListOrderBy:{
		id:"OrderDirection",
		businessCategory:"BusinessCategoryOrderBy"
	},
	ProductCategoryListOrderBy:{
		id:"OrderDirection",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryOrderBy",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryOrderBy"
	},
	LocaleOrderBy:{
		id:"OrderDirection",
		identifier:"TranslationCataloguesIdentifierOrderBy"
	},
	TranslationCataloguesIdentifierOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection"
	},
	OrderRecurrence:{
		locales:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		businessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		localesByLocale:{
			by:"OrderRecurrenceLocalesByLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		paginateLocales:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		}
	},
	OrderRecurrenceLocale:{
		root:{
			filter:"OrderRecurrenceWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	OrderRecurrenceLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"OrderRecurrenceOrderBy",
		locale:"LocaleOrderBy"
	},
	OrderRecurrenceOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		order:"OrderDirection"
	},
	OrderRecurrenceLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleUniqueWhere:{
		id:"UUID",
		businessCategories:"BusinessCategoryLocaleUniqueWhere",
		productCategoryLists:"ProductCategoryListLocaleUniqueWhere",
		productIngredients:"ProductIngredientLocaleUniqueWhere",
		orderRecurrences:"OrderRecurrenceLocaleUniqueWhere",
		blogs:"BlogLocaleUniqueWhere",
		blogPosts:"BlogPostLocaleUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		menus:"MenuLocaleUniqueWhere",
		pages:"PageLocaleUniqueWhere",
		allergens:"AllergenLocaleUniqueWhere",
		productPackings:"ProductPackingLocaleUniqueWhere",
		products:"ProductLocaleUniqueWhere",
		productRecipes:"ProductRecipeLocaleUniqueWhere",
		deliveryMethods:"DeliveryMethodLocaleUniqueWhere"
	},
	BusinessCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"BusinessCategoryUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	BusinessCategoryUniqueWhere:{
		id:"UUID",
		locales:"BusinessCategoryLocaleUniqueWhere",
		specificProducts:"ProductListUniqueWhere",
		seasonalProductCategories:"ProductCategoryListUniqueWhere",
		specificProductsProductCategories:"ProductCategoryListUniqueWhere"
	},
	ProductListUniqueWhere:{
		id:"UUID",
		items:"ProductListItemUniqueWhere",
		businessCategory:"BusinessCategoryUniqueWhere"
	},
	ProductListItemUniqueWhere:{
		id:"UUID"
	},
	ProductCategoryListUniqueWhere:{
		id:"UUID",
		items:"ProductCategoryListItemUniqueWhere",
		locales:"ProductCategoryListLocaleUniqueWhere",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryUniqueWhere",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryUniqueWhere"
	},
	ProductCategoryListItemUniqueWhere:{
		id:"UUID"
	},
	ProductCategoryListLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductCategoryListUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductIngredientUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientUniqueWhere:{
		id:"UUID",
		locales:"ProductIngredientLocaleUniqueWhere"
	},
	OrderRecurrenceLocaleUniqueWhere:{
		id:"UUID",
		root:"OrderRecurrenceUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	OrderRecurrenceUniqueWhere:{
		id:"UUID",
		locales:"OrderRecurrenceLocaleUniqueWhere"
	},
	BlogLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	BlogUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"BlogLocaleUniqueWhere"
	},
	BlogPostLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogPostUniqueWhere",
		locale:"LocaleUniqueWhere",
		cover:"CoverUniqueWhere",
		content:"ContentUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	BlogPostUniqueWhere:{
		id:"UUID",
		locales:"BlogPostLocaleUniqueWhere"
	},
	CoverUniqueWhere:{
		id:"UUID"
	},
	ContentUniqueWhere:{
		id:"UUID",
		blocks:"ContentBlockUniqueWhere"
	},
	ContentBlockUniqueWhere:{
		id:"UUID",
		references:"ContentReferenceUniqueWhere"
	},
	ContentReferenceUniqueWhere:{
		id:"UUID",
		products:"ProductListUniqueWhere",
		productBanners:"ProductBannerListUniqueWhere",
		deliveryRegions:"DeliveryRegionsUniqueWhere"
	},
	ProductBannerListUniqueWhere:{
		id:"UUID",
		items:"ProductBannerItemUniqueWhere"
	},
	ProductBannerItemUniqueWhere:{
		id:"UUID"
	},
	DeliveryRegionsUniqueWhere:{
		id:"UUID",
		items:"DeliveryRegionsItemUniqueWhere"
	},
	DeliveryRegionsItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere",
		textList:"TextListUniqueWhere",
		gallery:"ImageListUniqueWhere"
	},
	ImageUniqueWhere:{
		id:"UUID"
	},
	TextListUniqueWhere:{
		id:"UUID",
		items:"TextItemUniqueWhere"
	},
	TextItemUniqueWhere:{
		id:"UUID"
	},
	ImageListUniqueWhere:{
		id:"UUID",
		items:"ImageListItemUniqueWhere"
	},
	ImageListItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	LinkableUniqueWhere:{
		id:"UUID",
		blogPost:"BlogPostLocaleUniqueWhere",
		page:"PageLocaleUniqueWhere",
		wikiPage:"WikiPageUniqueWhere",
		redirect:"RedirectUniqueWhere"
	},
	PageLocaleUniqueWhere:{
		id:"UUID",
		root:"PageUniqueWhere",
		locale:"LocaleUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere",
		content:"ContentUniqueWhere",
		cover:"CoverUniqueWhere"
	},
	PageUniqueWhere:{
		id:"UUID",
		locales:"PageLocaleUniqueWhere"
	},
	SeoUniqueWhere:{
		id:"UUID",
		ogImage:"ImageUniqueWhere"
	},
	WikiPageUniqueWhere:{
		id:"UUID",
		link:"LinkableUniqueWhere",
		children:"WikiPageUniqueWhere",
		content:"ContentUniqueWhere"
	},
	RedirectUniqueWhere:{
		id:"UUID",
		link:"LinkableUniqueWhere"
	},
	TranslationCataloguesIdentifierUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationCatalogueUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainUniqueWhere:{
		id:"UUID",
		catalogues:"TranslationCatalogueUniqueWhere",
		keys:"TranslationKeyUniqueWhere"
	},
	TranslationKeyUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationValueUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere",
		key:"TranslationKeyUniqueWhere"
	},
	MenuLocaleUniqueWhere:{
		id:"UUID",
		root:"SiteMenuUniqueWhere",
		locale:"LocaleUniqueWhere",
		items:"MenuItemUniqueWhere",
		secondaryItems:"LinkListUniqueWhere"
	},
	SiteMenuUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"MenuLocaleUniqueWhere"
	},
	MenuItemUniqueWhere:{
		id:"UUID",
		subitems:"MenuSubitemUniqueWhere"
	},
	MenuSubitemUniqueWhere:{
		id:"UUID"
	},
	LinkListUniqueWhere:{
		id:"UUID",
		items:"LinkListItemUniqueWhere"
	},
	LinkListItemUniqueWhere:{
		id:"UUID"
	},
	AllergenLocaleUniqueWhere:{
		id:"UUID",
		root:"AllergenUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	AllergenUniqueWhere:{
		id:"UUID",
		locales:"AllergenLocaleUniqueWhere"
	},
	ProductPackingLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductPackingUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductPackingUniqueWhere:{
		id:"UUID",
		locales:"ProductPackingLocaleUniqueWhere"
	},
	ProductLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductUniqueWhere",
		locale:"LocaleUniqueWhere",
		featureList:"TextListUniqueWhere"
	},
	ProductUniqueWhere:{
		id:"UUID",
		stocks:"ProductStockUniqueWhere",
		locales:"ProductLocaleUniqueWhere",
		virtualProduct:"VirtualProductUniqueWhere",
		meta:"ProductMetadataUniqueWhere",
		page:"PageUniqueWhere"
	},
	ProductStockUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere",
		store:"StoreUniqueWhere"
	},
	StoreUniqueWhere:{
		id:"UUID"
	},
	VirtualProductUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere"
	},
	ProductMetadataUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere"
	},
	ProductRecipeLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductRecipeUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductRecipeUniqueWhere:{
		id:"UUID",
		products:"ProductUniqueWhere",
		ingredients:"ProductIngredientItemUniqueWhere",
		locales:"ProductRecipeLocaleUniqueWhere"
	},
	ProductIngredientItemUniqueWhere:{
		id:"UUID"
	},
	DeliveryMethodLocaleUniqueWhere:{
		id:"UUID",
		locale:"LocaleUniqueWhere",
		root:"DeliveryMethodUniqueWhere"
	},
	DeliveryMethodUniqueWhere:{
		id:"UUID",
		timelinePreset:"DeliveryTimelinePresetUniqueWhere",
		locales:"DeliveryMethodLocaleUniqueWhere"
	},
	DeliveryTimelinePresetUniqueWhere:{
		id:"UUID",
		deliveryMethod:"DeliveryMethodUniqueWhere"
	},
	ProductList:{
		items:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		businessCategory:{
			filter:"BusinessCategoryWhere"
		},
		paginateItems:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		}
	},
	ProductListItem:{
		list:{
			filter:"ProductListWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	ProductListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductListOrderBy",
		product:"ProductOrderBy",
		defaultQuantityForOrder:"OrderDirection"
	},
	ProductOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		priceCents:"OrderDirection",
		notDiscountable:"OrderDirection",
		packing:"ProductPackingOrderBy",
		recipe:"ProductRecipeOrderBy",
		vatRate:"VatRateOrderBy",
		image:"ImageOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		availability:"OrderDirection",
		order:"OrderDirection",
		maximumQuantityPerOrder:"OrderDirection",
		archivedAt:"OrderDirection",
		virtualProduct:"VirtualProductOrderBy",
		meta:"ProductMetadataOrderBy",
		page:"PageOrderBy"
	},
	ProductPackingOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		image:"ImageOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		volumeMl:"OrderDirection",
		icon:"ImageOrderBy",
		shortName:"OrderDirection",
		isPackedInCardboard:"OrderDirection"
	},
	ImageOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		alt:"OrderDirection"
	},
	ProductRecipeOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		internalNote:"OrderDirection",
		color:"OrderDirection",
		image:"ImageOrderBy",
		icon:"ImageOrderBy",
		needsPrepping:"OrderDirection",
		gutTuning:"OrderDirection",
		freshingContainer:"FreshingContainerOrderBy",
		description:"OrderDirection"
	},
	FreshingContainerOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		config:"GlobalConfigOrderBy",
		volumeMl:"OrderDirection"
	},
	GlobalConfigOrderBy:{
		id:"OrderDirection",
		orderDeadlineTime:"OrderDirection",
		ordersLoadingDurationHours:"OrderDirection",
		openingHours:"WeekHoursOrderBy",
		unique:"OrderDirection",
		workingHours:"WeekHoursOrderBy",
		freshingContainerVolumeMl:"OrderDirection",
		checkoutUrlTemplate:"OrderDirection",
		orderUrlTemplate:"OrderDirection",
		paymentUrlTemplate:"OrderDirection",
		customerProfileUrlTemplate:"OrderDirection",
		userProfileUrlTemplate:"OrderDirection",
		creditsProduct:"VirtualProductOrderBy",
		discountVatRate:"VatRateOrderBy",
		defaultFreshingContainer:"FreshingContainerOrderBy",
		messengerUrl:"OrderDirection",
		messengerIosUrl:"OrderDirection",
		messengerAndroidUrl:"OrderDirection",
		freshingPrepareOrderByOffsetMinutes:"OrderDirection"
	},
	WeekHoursOrderBy:{
		id:"OrderDirection",
		mon:"OrderDirection",
		tue:"OrderDirection",
		wed:"OrderDirection",
		thu:"OrderDirection",
		fri:"OrderDirection",
		sat:"OrderDirection",
		sun:"OrderDirection"
	},
	VirtualProductOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		product:"ProductOrderBy",
		voucherCreditCents:"OrderDirection",
		physicalRepresentation:"OrderDirection",
		recipientEmail:"OrderDirection"
	},
	VatRateOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		ratePermille:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection"
	},
	ProductMetadataOrderBy:{
		id:"OrderDirection",
		archived:"OrderDirection",
		available:"OrderDirection",
		availableQuantity:"OrderDirection",
		product:"ProductOrderBy"
	},
	PageOrderBy:{
		id:"OrderDirection"
	},
	ProductCategoryList:{
		items:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		locales:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		seasonalProductCategoriesOfBusinessCategory:{
			filter:"BusinessCategoryWhere"
		},
		specificProductsProductCategoriesOfBusinessCategory:{
			filter:"BusinessCategoryWhere"
		},
		localesByLocale:{
			by:"ProductCategoryListLocalesByLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		paginateItems:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateLocales:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		}
	},
	ProductCategoryListItem:{
		list:{
			filter:"ProductCategoryListWhere"
		},
		productCategory:{
			filter:"ProductCategoryWhere"
		}
	},
	ProductCategory:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		parent:{
			filter:"ProductCategoryWhere"
		},
		children:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		childrenByCode:{
			by:"ProductCategoryChildrenByCodeUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		childrenByChildren:{
			by:"ProductCategoryChildrenByChildrenUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateChildren:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		}
	},
	TagOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection"
	},
	ProductCategoryOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		parent:"ProductCategoryOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		color:"OrderDirection",
		image:"ImageOrderBy",
		order:"OrderDirection"
	},
	ProductCategoryChildrenByCodeUniqueWhere:{

	},
	ProductCategoryChildrenByChildrenUniqueWhere:{
		children:"ProductCategoryUniqueWhere"
	},
	ProductCategoryUniqueWhere:{
		id:"UUID",
		children:"ProductCategoryUniqueWhere"
	},
	ProductCategoryListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductCategoryListOrderBy",
		productCategory:"ProductCategoryOrderBy",
		showRecipeImages:"OrderDirection",
		showPackingImages:"OrderDirection",
		showAllAvailableProducts:"OrderDirection"
	},
	ProductCategoryListLocale:{
		root:{
			filter:"ProductCategoryListWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductCategoryListLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductCategoryListOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductCategoryListLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BusinessCategoryLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientLocale:{
		root:{
			filter:"ProductIngredientWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductIngredient:{
		locales:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		allergens:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		freshingContainer:{
			filter:"FreshingContainerWhere"
		},
		localesByLocale:{
			by:"ProductIngredientLocalesByLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		paginateLocales:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateAllergens:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		}
	},
	ProductIngredientLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductIngredientOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductIngredientOrderBy:{
		id:"OrderDirection",
		internalNote:"OrderDirection",
		internalName:"OrderDirection",
		needsPrepping:"OrderDirection",
		allowCustomerRating:"OrderDirection",
		freshingContainer:"FreshingContainerOrderBy"
	},
	Allergen:{
		locales:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		ingredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		localesByLocale:{
			by:"AllergenLocalesByLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		paginateLocales:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateIngredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		}
	},
	AllergenLocale:{
		root:{
			filter:"AllergenWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	AllergenLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"AllergenOrderBy",
		locale:"LocaleOrderBy"
	},
	AllergenOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		code:"OrderDirection"
	},
	AllergenLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	FreshingContainer:{
		config:{
			filter:"GlobalConfigWhere"
		}
	},
	GlobalConfig:{
		openingHours:{
			filter:"WeekHoursWhere"
		},
		workingHours:{
			filter:"WeekHoursWhere"
		},
		creditsProduct:{
			filter:"VirtualProductWhere"
		},
		discountVatRate:{
			filter:"VatRateWhere"
		},
		defaultFreshingContainer:{
			filter:"FreshingContainerWhere"
		},
		freshingContainers:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		paginateFreshingContainers:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		}
	},
	VirtualProduct:{
		product:{
			filter:"ProductWhere"
		}
	},
	ProductIngredientLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogLocale:{
		root:{
			filter:"BlogWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	Blog:{
		locales:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		localesByLocale:{
			by:"BlogLocalesByLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		paginateLocales:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		}
	},
	BlogLocaleOrderBy:{
		id:"OrderDirection",
		pageName:"OrderDirection",
		title:"OrderDirection",
		lead:"OrderDirection",
		root:"BlogOrderBy",
		locale:"LocaleOrderBy"
	},
	BlogOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	BlogLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogPostLocale:{
		root:{
			filter:"BlogPostWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		cover:{
			filter:"CoverWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		}
	},
	BlogPost:{
		locales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		localesByLocale:{
			by:"BlogPostLocalesByLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByCover:{
			by:"BlogPostLocalesByCoverUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByContent:{
			by:"BlogPostLocalesByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByLink:{
			by:"BlogPostLocalesByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesBySeo:{
			by:"BlogPostLocalesBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		paginateLocales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		}
	},
	BlogPostLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		availableForWeb:"OrderDirection",
		availableForMobile:"OrderDirection",
		root:"BlogPostOrderBy",
		locale:"LocaleOrderBy",
		cover:"CoverOrderBy",
		content:"ContentOrderBy",
		link:"LinkableOrderBy",
		seo:"SeoOrderBy"
	},
	BlogPostOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection"
	},
	CoverOrderBy:{
		id:"OrderDirection",
		primaryTitle:"OrderDirection",
		secondaryTitle:"OrderDirection",
		medium:"MediumOrderBy",
		buttonColors:"ColorPalleteOrderBy"
	},
	MediumOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		colorTheme:"OrderDirection",
		image:"ImageOrderBy",
		video:"VideoOrderBy"
	},
	VideoOrderBy:{
		id:"OrderDirection",
		src:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		duration:"OrderDirection",
		poster:"ImageOrderBy"
	},
	ColorPalleteOrderBy:{
		id:"OrderDirection",
		background:"OrderDirection",
		text:"OrderDirection"
	},
	ContentOrderBy:{
		id:"OrderDirection"
	},
	LinkableOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		blogPost:"BlogPostLocaleOrderBy",
		page:"PageLocaleOrderBy",
		wikiPage:"WikiPageOrderBy",
		redirect:"RedirectOrderBy"
	},
	PageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		link:"LinkableOrderBy",
		root:"PageOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy",
		content:"ContentOrderBy",
		cover:"CoverOrderBy",
		theme:"OrderDirection"
	},
	SeoOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		ogTitle:"OrderDirection",
		ogDescription:"OrderDirection",
		ogImage:"ImageOrderBy"
	},
	WikiPageOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		emoji:"OrderDirection",
		link:"LinkableOrderBy",
		parent:"WikiPageOrderBy",
		content:"ContentOrderBy"
	},
	RedirectOrderBy:{
		id:"OrderDirection",
		link:"LinkableOrderBy",
		target:"LinkableOrderBy"
	},
	BlogPostLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogPostLocalesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	BlogPostLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	BlogPostLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	BlogPostLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	Cover:{
		medium:{
			filter:"MediumWhere"
		},
		buttonColors:{
			filter:"ColorPalleteWhere"
		}
	},
	Medium:{
		image:{
			filter:"ImageWhere"
		},
		video:{
			filter:"VideoWhere"
		}
	},
	Video:{
		poster:{
			filter:"ImageWhere"
		}
	},
	Content:{
		blocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		blocksByReferences:{
			by:"ContentBlocksByReferencesUniqueWhere",
			filter:"ContentBlockWhere"
		},
		paginateBlocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		}
	},
	ContentBlock:{
		content:{
			filter:"ContentWhere"
		},
		references:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		referencesByProducts:{
			by:"ContentBlockReferencesByProductsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByProductBanners:{
			by:"ContentBlockReferencesByProductBannersUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByDeliveryRegions:{
			by:"ContentBlockReferencesByDeliveryRegionsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		paginateReferences:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		}
	},
	ContentReference:{
		block:{
			filter:"ContentBlockWhere"
		},
		colorPallete:{
			filter:"ColorPalleteWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		blogPosts:{
			filter:"BlogPostListWhere"
		},
		links:{
			filter:"LinkListWhere"
		},
		products:{
			filter:"ProductListWhere"
		},
		hero:{
			filter:"HeroWhere"
		},
		gallery:{
			filter:"GalleryWhere"
		},
		socialsAndApps:{
			filter:"SocialsAndAppsWhere"
		},
		productGrid:{
			filter:"ProductGridWhere"
		},
		productBanners:{
			filter:"ProductBannerListWhere"
		},
		deliveryRegions:{
			filter:"DeliveryRegionsWhere"
		}
	},
	Link:{
		internalLink:{
			filter:"LinkableWhere"
		}
	},
	Linkable:{
		blogPost:{
			filter:"BlogPostLocaleWhere"
		},
		page:{
			filter:"PageLocaleWhere"
		},
		wikiPage:{
			filter:"WikiPageWhere"
		},
		redirect:{
			filter:"RedirectWhere"
		}
	},
	PageLocale:{
		link:{
			filter:"LinkableWhere"
		},
		root:{
			filter:"PageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		cover:{
			filter:"CoverWhere"
		}
	},
	Page:{
		locales:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		localesByLocale:{
			by:"PageLocalesByLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByLink:{
			by:"PageLocalesByLinkUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesBySeo:{
			by:"PageLocalesBySeoUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByContent:{
			by:"PageLocalesByContentUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByCover:{
			by:"PageLocalesByCoverUniqueWhere",
			filter:"PageLocaleWhere"
		},
		paginateLocales:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		}
	},
	PageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	PageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	PageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	PageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	PageLocalesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	Seo:{
		ogImage:{
			filter:"ImageWhere"
		}
	},
	WikiPage:{
		link:{
			filter:"LinkableWhere"
		},
		parent:{
			filter:"WikiPageWhere"
		},
		children:{
			filter:"WikiPageWhere",
			orderBy:"WikiPageOrderBy"
		},
		content:{
			filter:"ContentWhere"
		},
		childrenByLink:{
			by:"WikiPageChildrenByLinkUniqueWhere",
			filter:"WikiPageWhere"
		},
		childrenByChildren:{
			by:"WikiPageChildrenByChildrenUniqueWhere",
			filter:"WikiPageWhere"
		},
		childrenByContent:{
			by:"WikiPageChildrenByContentUniqueWhere",
			filter:"WikiPageWhere"
		},
		paginateChildren:{
			filter:"WikiPageWhere",
			orderBy:"WikiPageOrderBy"
		}
	},
	WikiPageChildrenByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	WikiPageChildrenByChildrenUniqueWhere:{
		children:"WikiPageUniqueWhere"
	},
	WikiPageChildrenByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	Redirect:{
		link:{
			filter:"LinkableWhere"
		},
		target:{
			filter:"LinkableWhere"
		}
	},
	BlogPostList:{
		items:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateItems:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		}
	},
	BlogPostListItem:{
		list:{
			filter:"BlogPostListWhere"
		},
		blogPost:{
			filter:"BlogPostLocaleWhere"
		}
	},
	BlogPostListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		caption:"OrderDirection",
		list:"BlogPostListOrderBy",
		blogPost:"BlogPostLocaleOrderBy"
	},
	BlogPostListOrderBy:{
		id:"OrderDirection"
	},
	LinkList:{
		items:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateItems:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		}
	},
	LinkListItem:{
		list:{
			filter:"LinkListWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	LinkListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		isPrimary:"OrderDirection",
		list:"LinkListOrderBy",
		link:"LinkOrderBy"
	},
	LinkListOrderBy:{
		id:"OrderDirection"
	},
	LinkOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		title:"OrderDirection",
		externalLink:"OrderDirection",
		internalLink:"LinkableOrderBy"
	},
	Hero:{
		medium:{
			filter:"MediumWhere"
		}
	},
	Gallery:{
		items:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateItems:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		}
	},
	GalleryItem:{
		list:{
			filter:"GalleryWhere"
		},
		medium:{
			filter:"MediumWhere"
		}
	},
	GalleryItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		caption:"OrderDirection",
		list:"GalleryOrderBy",
		medium:"MediumOrderBy"
	},
	GalleryOrderBy:{
		id:"OrderDirection"
	},
	ProductGrid:{
		categories:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		categoriesByItems:{
			by:"ProductGridCategoriesByItemsUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		paginateCategories:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		}
	},
	ProductGridCategory:{
		root:{
			filter:"ProductGridWhere"
		},
		items:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		paginateItems:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		}
	},
	ProductGridItem:{
		product:{
			filter:"ProductWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		category:{
			filter:"ProductGridCategoryWhere"
		}
	},
	ProductGridItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		lead:"OrderDirection",
		product:"ProductOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy",
		category:"ProductGridCategoryOrderBy",
		description:"OrderDirection"
	},
	ProductGridCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		root:"ProductGridOrderBy"
	},
	ProductGridOrderBy:{
		id:"OrderDirection",
		isCarousel:"OrderDirection",
		type:"OrderDirection"
	},
	ProductGridCategoriesByItemsUniqueWhere:{
		items:"ProductGridItemUniqueWhere"
	},
	ProductGridItemUniqueWhere:{
		id:"UUID"
	},
	ProductBannerList:{
		items:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateItems:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		}
	},
	ProductBannerItem:{
		list:{
			filter:"ProductBannerListWhere"
		},
		banner:{
			filter:"ProductBannerWhere"
		}
	},
	ProductBanner:{
		button:{
			filter:"LinkWhere"
		},
		backgroundImage:{
			filter:"ImageWhere"
		},
		productImage:{
			filter:"ImageWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	ProductBannerItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductBannerListOrderBy",
		banner:"ProductBannerOrderBy",
		size:"OrderDirection"
	},
	ProductBannerListOrderBy:{
		id:"OrderDirection"
	},
	ProductBannerOrderBy:{
		id:"OrderDirection",
		beforeTitle:"OrderDirection",
		title:"OrderDirection",
		label:"OrderDirection",
		button:"LinkOrderBy",
		backgroundImage:"ImageOrderBy",
		productImage:"ImageOrderBy",
		product:"ProductOrderBy"
	},
	DeliveryRegions:{
		items:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		itemsByImage:{
			by:"DeliveryRegionsItemsByImageUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		itemsByTextList:{
			by:"DeliveryRegionsItemsByTextListUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		itemsByGallery:{
			by:"DeliveryRegionsItemsByGalleryUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		paginateItems:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		}
	},
	DeliveryRegionsItem:{
		list:{
			filter:"DeliveryRegionsWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		textList:{
			filter:"TextListWhere"
		},
		gallery:{
			filter:"ImageListWhere"
		}
	},
	TextList:{
		items:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateItems:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		}
	},
	TextItem:{
		list:{
			filter:"TextListWhere"
		}
	},
	TextItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		list:"TextListOrderBy"
	},
	TextListOrderBy:{
		id:"OrderDirection"
	},
	ImageList:{
		items:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		itemsByImage:{
			by:"ImageListItemsByImageUniqueWhere",
			filter:"ImageListItemWhere"
		},
		paginateItems:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		}
	},
	ImageListItem:{
		list:{
			filter:"ImageListWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	ImageListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ImageListOrderBy",
		image:"ImageOrderBy"
	},
	ImageListOrderBy:{
		id:"OrderDirection"
	},
	ImageListItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	DeliveryRegionsItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		offerLabel:"OrderDirection",
		list:"DeliveryRegionsOrderBy",
		image:"ImageOrderBy",
		textList:"TextListOrderBy",
		gallery:"ImageListOrderBy"
	},
	DeliveryRegionsOrderBy:{
		id:"OrderDirection"
	},
	DeliveryRegionsItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	DeliveryRegionsItemsByTextListUniqueWhere:{
		textList:"TextListUniqueWhere"
	},
	DeliveryRegionsItemsByGalleryUniqueWhere:{
		gallery:"ImageListUniqueWhere"
	},
	ContentReferenceOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		primaryText:"OrderDirection",
		secondaryText:"OrderDirection",
		jsonContent:"OrderDirection",
		block:"ContentBlockOrderBy",
		colorPallete:"ColorPalleteOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy",
		blogPosts:"BlogPostListOrderBy",
		links:"LinkListOrderBy",
		products:"ProductListOrderBy",
		contentSize:"OrderDirection",
		misc:"OrderDirection",
		hero:"HeroOrderBy",
		gallery:"GalleryOrderBy",
		socialsAndApps:"SocialsAndAppsOrderBy",
		productGrid:"ProductGridOrderBy",
		productBanners:"ProductBannerListOrderBy",
		deliveryRegions:"DeliveryRegionsOrderBy"
	},
	ContentBlockOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		json:"OrderDirection",
		content:"ContentOrderBy"
	},
	HeroOrderBy:{
		id:"OrderDirection",
		showLogo:"OrderDirection",
		middleContent:"OrderDirection",
		medium:"MediumOrderBy"
	},
	SocialsAndAppsOrderBy:{
		id:"OrderDirection",
		instagram:"OrderDirection",
		appStore:"OrderDirection",
		googlePlay:"OrderDirection"
	},
	ContentBlockReferencesByProductsUniqueWhere:{
		products:"ProductListUniqueWhere"
	},
	ContentBlockReferencesByProductBannersUniqueWhere:{
		productBanners:"ProductBannerListUniqueWhere"
	},
	ContentBlockReferencesByDeliveryRegionsUniqueWhere:{
		deliveryRegions:"DeliveryRegionsUniqueWhere"
	},
	ContentBlocksByReferencesUniqueWhere:{
		references:"ContentReferenceUniqueWhere"
	},
	TranslationCataloguesIdentifier:{
		catalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		catalogueByDomain:{
			by:"TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		catalogueByValues:{
			by:"TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		paginateCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		}
	},
	TranslationCatalogue:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		fallback:{
			filter:"TranslationCatalogueWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		valuesByKey:{
			by:"TranslationCatalogueValuesByKeyUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationDomain:{
		catalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		keys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		cataloguesByIdentifier:{
			by:"TranslationDomainCataloguesByIdentifierUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		cataloguesByValues:{
			by:"TranslationDomainCataloguesByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		keysByIdentifier:{
			by:"TranslationDomainKeysByIdentifierUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		keysByValues:{
			by:"TranslationDomainKeysByValuesUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		paginateCatalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateKeys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		}
	},
	TranslationCatalogueOrderBy:{
		id:"OrderDirection",
		domain:"TranslationDomainOrderBy",
		fallback:"TranslationCatalogueOrderBy",
		identifier:"TranslationCataloguesIdentifierOrderBy"
	},
	TranslationDomainOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		name:"OrderDirection"
	},
	TranslationKey:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		valuesByCatalogue:{
			by:"TranslationKeyValuesByCatalogueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationValue:{
		catalogue:{
			filter:"TranslationCatalogueWhere"
		},
		key:{
			filter:"TranslationKeyWhere"
		}
	},
	TranslationValueOrderBy:{
		id:"OrderDirection",
		value:"OrderDirection",
		catalogue:"TranslationCatalogueOrderBy",
		key:"TranslationKeyOrderBy"
	},
	TranslationKeyOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		contentType:"OrderDirection",
		note:"OrderDirection",
		domain:"TranslationDomainOrderBy"
	},
	TranslationKeyValuesByCatalogueUniqueWhere:{
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationDomainCataloguesByIdentifierUniqueWhere:{
		identifier:"TranslationCataloguesIdentifierUniqueWhere"
	},
	TranslationDomainCataloguesByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainKeysByIdentifierUniqueWhere:{

	},
	TranslationDomainKeysByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationCatalogueValuesByKeyUniqueWhere:{
		key:"TranslationKeyUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere:{
		domain:"TranslationDomainUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	MenuLocale:{
		root:{
			filter:"SiteMenuWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		items:{
			filter:"MenuItemWhere",
			orderBy:"MenuItemOrderBy"
		},
		secondaryItems:{
			filter:"LinkListWhere"
		},
		eshopLink:{
			filter:"LinkWhere"
		},
		itemsBySubitems:{
			by:"MenuLocaleItemsBySubitemsUniqueWhere",
			filter:"MenuItemWhere"
		},
		paginateItems:{
			filter:"MenuItemWhere",
			orderBy:"MenuItemOrderBy"
		}
	},
	SiteMenu:{
		locales:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		},
		localesByLocale:{
			by:"SiteMenuLocalesByLocaleUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		localesByItems:{
			by:"SiteMenuLocalesByItemsUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		localesBySecondaryItems:{
			by:"SiteMenuLocalesBySecondaryItemsUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		paginateLocales:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		}
	},
	MenuLocaleOrderBy:{
		id:"OrderDirection",
		root:"SiteMenuOrderBy",
		locale:"LocaleOrderBy",
		secondaryItems:"LinkListOrderBy",
		eshopLink:"LinkOrderBy"
	},
	SiteMenuOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	SiteMenuLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	SiteMenuLocalesByItemsUniqueWhere:{
		items:"MenuItemUniqueWhere"
	},
	SiteMenuLocalesBySecondaryItemsUniqueWhere:{
		secondaryItems:"LinkListUniqueWhere"
	},
	MenuItem:{
		menu:{
			filter:"MenuLocaleWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		subitems:{
			filter:"MenuSubitemWhere",
			orderBy:"MenuSubitemOrderBy"
		},
		paginateSubitems:{
			filter:"MenuSubitemWhere",
			orderBy:"MenuSubitemOrderBy"
		}
	},
	MenuSubitem:{
		item:{
			filter:"MenuItemWhere"
		},
		links:{
			filter:"MenuLinkListWhere"
		},
		products:{
			filter:"MenuProductsWhere"
		},
		delivery:{
			filter:"MenuDeliveryWhere"
		},
		contact:{
			filter:"MenuContactWhere"
		}
	},
	MenuLinkList:{
		items:{
			filter:"MenuLinkItemWhere",
			orderBy:"MenuLinkItemOrderBy"
		},
		paginateItems:{
			filter:"MenuLinkItemWhere",
			orderBy:"MenuLinkItemOrderBy"
		}
	},
	MenuLinkItem:{
		list:{
			filter:"MenuLinkListWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	MenuLinkItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"MenuLinkListOrderBy",
		link:"LinkOrderBy",
		image:"ImageOrderBy",
		description:"OrderDirection"
	},
	MenuLinkListOrderBy:{
		id:"OrderDirection"
	},
	MenuProducts:{
		links:{
			filter:"MenuLinkListWhere"
		},
		buttons:{
			filter:"LinkListWhere"
		}
	},
	MenuDelivery:{
		deliveryRegions:{
			filter:"DeliveryRegionsWhere"
		},
		socialsAndApps:{
			filter:"SocialsAndAppsWhere"
		}
	},
	MenuContact:{
		image:{
			filter:"ImageWhere"
		}
	},
	MenuSubitemOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		title:"OrderDirection",
		item:"MenuItemOrderBy",
		links:"MenuLinkListOrderBy",
		products:"MenuProductsOrderBy",
		delivery:"MenuDeliveryOrderBy",
		contact:"MenuContactOrderBy",
		order:"OrderDirection"
	},
	MenuItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		menu:"MenuLocaleOrderBy",
		link:"LinkOrderBy"
	},
	MenuProductsOrderBy:{
		id:"OrderDirection",
		links:"MenuLinkListOrderBy",
		buttons:"LinkListOrderBy"
	},
	MenuDeliveryOrderBy:{
		id:"OrderDirection",
		deliveryRegions:"DeliveryRegionsOrderBy",
		text:"OrderDirection",
		socialsAndApps:"SocialsAndAppsOrderBy"
	},
	MenuContactOrderBy:{
		id:"OrderDirection",
		text:"OrderDirection",
		image:"ImageOrderBy"
	},
	MenuLocaleItemsBySubitemsUniqueWhere:{
		subitems:"MenuSubitemUniqueWhere"
	},
	ProductPackingLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		shortName:"OrderDirection",
		description:"OrderDirection",
		root:"ProductPackingOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductLocale:{
		root:{
			filter:"ProductWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		featureList:{
			filter:"TextListWhere"
		}
	},
	ProductLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		description:"OrderDirection",
		root:"ProductOrderBy",
		locale:"LocaleOrderBy",
		featureList:"TextListOrderBy"
	},
	ProductRecipeLocale:{
		root:{
			filter:"ProductRecipeWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductRecipe:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		ingredients:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		locales:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		freshingContainer:{
			filter:"FreshingContainerWhere"
		},
		productsByCode:{
			by:"ProductRecipeProductsByCodeUniqueWhere",
			filter:"ProductWhere"
		},
		productsByStocks:{
			by:"ProductRecipeProductsByStocksUniqueWhere",
			filter:"ProductWhere"
		},
		productsByLocales:{
			by:"ProductRecipeProductsByLocalesUniqueWhere",
			filter:"ProductWhere"
		},
		productsByVirtualProduct:{
			by:"ProductRecipeProductsByVirtualProductUniqueWhere",
			filter:"ProductWhere"
		},
		productsByMeta:{
			by:"ProductRecipeProductsByMetaUniqueWhere",
			filter:"ProductWhere"
		},
		productsByPage:{
			by:"ProductRecipeProductsByPageUniqueWhere",
			filter:"ProductWhere"
		},
		localesByLocale:{
			by:"ProductRecipeLocalesByLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateIngredients:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateLocales:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		}
	},
	ProductIngredientItem:{
		recipe:{
			filter:"ProductRecipeWhere"
		},
		ingredient:{
			filter:"ProductIngredientWhere"
		}
	},
	ProductIngredientItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		internalOrder:"OrderDirection",
		recipe:"ProductRecipeOrderBy",
		ingredient:"ProductIngredientOrderBy"
	},
	ProductRecipeLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductRecipeOrderBy",
		locale:"LocaleOrderBy",
		description:"OrderDirection"
	},
	ProductRecipeProductsByCodeUniqueWhere:{

	},
	ProductRecipeProductsByStocksUniqueWhere:{
		stocks:"ProductStockUniqueWhere"
	},
	ProductRecipeProductsByLocalesUniqueWhere:{
		locales:"ProductLocaleUniqueWhere"
	},
	ProductRecipeProductsByVirtualProductUniqueWhere:{
		virtualProduct:"VirtualProductUniqueWhere"
	},
	ProductRecipeProductsByMetaUniqueWhere:{
		meta:"ProductMetadataUniqueWhere"
	},
	ProductRecipeProductsByPageUniqueWhere:{
		page:"PageUniqueWhere"
	},
	ProductRecipeLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	DeliveryMethodLocale:{
		locale:{
			filter:"LocaleWhere"
		},
		root:{
			filter:"DeliveryMethodWhere"
		}
	},
	DeliveryMethod:{
		paymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		suitableForDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		timelinePreset:{
			filter:"DeliveryTimelinePresetWhere"
		},
		locales:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		localesByLocale:{
			by:"DeliveryMethodLocalesByLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		paginatePaymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginateSuitableForDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateLocales:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		}
	},
	PaymentMethod:{
		deliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		}
	},
	DeliveryMethodOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		enabled:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		requireDeliveryAddress:"OrderDirection",
		timelinePreset:"DeliveryTimelinePresetOrderBy",
		enableDeliveryTracking:"OrderDirection"
	},
	DeliveryTimelinePresetOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		duration:"OrderDirection",
		window:"OrderDirection",
		deliveryMethod:"DeliveryMethodOrderBy"
	},
	PaymentMethodOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		enabled:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		blockingDispatch:"OrderDirection",
		type:"OrderDirection"
	},
	DeliveryZone:{
		suitableDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		deliveryZonesOfTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		deliveryZonesOfProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateSuitableDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryZonesOfTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateDeliveryZonesOfProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		}
	},
	DeliveryZoneOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		type:"OrderDirection"
	},
	DeliveryTimelinePreset:{
		deliveryMethod:{
			filter:"DeliveryMethodWhere"
		}
	},
	DeliveryMethodLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		description:"OrderDirection",
		trackingCodeUrlTemplate:"OrderDirection",
		processingTitle:"OrderDirection",
		processingText:"OrderDirection",
		processingIconUrl:"OrderDirection",
		dispatchedTitle:"OrderDirection",
		dispatchedText:"OrderDirection",
		dispatchedIconUrl:"OrderDirection",
		fulfilledTitle:"OrderDirection",
		fulfilledText:"OrderDirection",
		fulfilledIconUrl:"OrderDirection",
		locale:"LocaleOrderBy",
		root:"DeliveryMethodOrderBy"
	},
	DeliveryMethodLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleBusinessCategoriesByRootUniqueWhere:{
		root:"BusinessCategoryUniqueWhere"
	},
	LocaleProductCategoryListsByRootUniqueWhere:{
		root:"ProductCategoryListUniqueWhere"
	},
	LocaleProductIngredientsByRootUniqueWhere:{
		root:"ProductIngredientUniqueWhere"
	},
	LocaleOrderRecurrencesByRootUniqueWhere:{
		root:"OrderRecurrenceUniqueWhere"
	},
	LocaleBlogsByRootUniqueWhere:{
		root:"BlogUniqueWhere"
	},
	LocaleBlogPostsByRootUniqueWhere:{
		root:"BlogPostUniqueWhere"
	},
	LocaleBlogPostsByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	LocaleBlogPostsByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleBlogPostsByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleBlogPostsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleMenusByRootUniqueWhere:{
		root:"SiteMenuUniqueWhere"
	},
	LocaleMenusByItemsUniqueWhere:{
		items:"MenuItemUniqueWhere"
	},
	LocaleMenusBySecondaryItemsUniqueWhere:{
		secondaryItems:"LinkListUniqueWhere"
	},
	LocalePagesByRootUniqueWhere:{
		root:"PageUniqueWhere"
	},
	LocalePagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocalePagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocalePagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocalePagesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	LocaleAllergensByRootUniqueWhere:{
		root:"AllergenUniqueWhere"
	},
	LocaleProductPackingsByRootUniqueWhere:{
		root:"ProductPackingUniqueWhere"
	},
	LocaleProductsByRootUniqueWhere:{
		root:"ProductUniqueWhere"
	},
	LocaleProductsByFeatureListUniqueWhere:{
		featureList:"TextListUniqueWhere"
	},
	LocaleProductRecipesByRootUniqueWhere:{
		root:"ProductRecipeUniqueWhere"
	},
	LocaleDeliveryMethodsByRootUniqueWhere:{
		root:"DeliveryMethodUniqueWhere"
	},
	ProductPackingLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductStock:{
		product:{
			filter:"ProductWhere"
		},
		store:{
			filter:"StoreWhere"
		}
	},
	ProductStockOrderBy:{
		id:"OrderDirection",
		quantity:"OrderDirection",
		product:"ProductOrderBy",
		store:"StoreOrderBy"
	},
	StoreOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection"
	},
	ProductMetadata:{
		product:{
			filter:"ProductWhere"
		}
	},
	ProductStocksByStoreUniqueWhere:{
		store:"StoreUniqueWhere"
	},
	ProductLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductLocalesByFeatureListUniqueWhere:{
		featureList:"TextListUniqueWhere"
	},
	ChannelOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		disabled:"OrderDirection",
		code:"OrderDirection"
	},
	ChannelUniqueWhere:{
		id:"UUID",
		code:"ChannelCode"
	},
	PaymentMethodUniqueWhere:{
		id:"UUID"
	},
	VatRateUniqueWhere:{
		id:"UUID"
	},
	TagUniqueWhere:{
		id:"UUID"
	},
	ProductIngredientRating:{
		icon:{
			filter:"ImageWhere"
		}
	},
	ProductIngredientRatingUniqueWhere:{
		id:"UUID"
	},
	ProductIngredientRatingWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		name:"StringCondition",
		icon:"ImageWhere",
		and:"ProductIngredientRatingWhere",
		or:"ProductIngredientRatingWhere",
		not:"ProductIngredientRatingWhere"
	},
	ProductIngredientRatingOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		name:"OrderDirection",
		icon:"ImageOrderBy"
	},
	ColorPalleteUniqueWhere:{
		id:"UUID"
	},
	BlogPostListUniqueWhere:{
		id:"UUID",
		items:"BlogPostListItemUniqueWhere"
	},
	BlogPostListItemUniqueWhere:{
		id:"UUID"
	},
	LinkUniqueWhere:{
		id:"UUID"
	},
	MediumUniqueWhere:{
		id:"UUID"
	},
	VideoUniqueWhere:{
		id:"UUID"
	},
	SocialsAndAppsUniqueWhere:{
		id:"UUID"
	},
	ProductGridUniqueWhere:{
		id:"UUID",
		categories:"ProductGridCategoryUniqueWhere"
	},
	ProductGridCategoryUniqueWhere:{
		id:"UUID",
		items:"ProductGridItemUniqueWhere"
	},
	GalleryUniqueWhere:{
		id:"UUID",
		items:"GalleryItemUniqueWhere"
	},
	GalleryItemUniqueWhere:{
		id:"UUID"
	},
	HeroUniqueWhere:{
		id:"UUID"
	},
	MenuLinkListUniqueWhere:{
		id:"UUID",
		items:"MenuLinkItemUniqueWhere"
	},
	MenuLinkItemUniqueWhere:{
		id:"UUID"
	},
	MenuProductsUniqueWhere:{
		id:"UUID",
		buttons:"LinkListUniqueWhere"
	},
	MenuDeliveryUniqueWhere:{
		id:"UUID",
		deliveryRegions:"DeliveryRegionsUniqueWhere"
	},
	MenuContactUniqueWhere:{
		id:"UUID"
	},
	ProductBannerUniqueWhere:{
		id:"UUID",
		button:"LinkUniqueWhere"
	},
	GlobalConfigUniqueWhere:{
		id:"UUID",
		unique:"One",
		openingHours:"WeekHoursUniqueWhere",
		workingHours:"WeekHoursUniqueWhere",
		creditsProduct:"VirtualProductUniqueWhere",
		discountVatRate:"VatRateUniqueWhere",
		freshingContainers:"FreshingContainerUniqueWhere"
	},
	WeekHoursUniqueWhere:{
		id:"UUID"
	},
	FreshingContainerUniqueWhere:{
		id:"UUID"
	},
	DeliveryZoneUniqueWhere:{
		id:"UUID"
	},
	QueryTransaction:{
		getCountry:{
			by:"CountryUniqueWhere",
			filter:"CountryWhere"
		},
		listCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		paginateCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		getChannel:{
			by:"ChannelUniqueWhere",
			filter:"ChannelWhere"
		},
		listChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		getDeliveryMethod:{
			by:"DeliveryMethodUniqueWhere",
			filter:"DeliveryMethodWhere"
		},
		listDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		getPaymentMethod:{
			by:"PaymentMethodUniqueWhere",
			filter:"PaymentMethodWhere"
		},
		listPaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginatePaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		getVatRate:{
			by:"VatRateUniqueWhere",
			filter:"VatRateWhere"
		},
		listVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		paginateVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductStock:{
			by:"ProductStockUniqueWhere",
			filter:"ProductStockWhere"
		},
		listProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		getProductPacking:{
			by:"ProductPackingUniqueWhere",
			filter:"ProductPackingWhere"
		},
		listProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		paginateProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		getProductRecipe:{
			by:"ProductRecipeUniqueWhere",
			filter:"ProductRecipeWhere"
		},
		listProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		paginateProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getStore:{
			by:"StoreUniqueWhere",
			filter:"StoreWhere"
		},
		listStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		paginateStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getBusinessCategory:{
			by:"BusinessCategoryUniqueWhere",
			filter:"BusinessCategoryWhere"
		},
		listBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		getProductList:{
			by:"ProductListUniqueWhere",
			filter:"ProductListWhere"
		},
		listProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		paginateProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		getProductListItem:{
			by:"ProductListItemUniqueWhere",
			filter:"ProductListItemWhere"
		},
		listProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		paginateProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getProductCategoryListItem:{
			by:"ProductCategoryListItemUniqueWhere",
			filter:"ProductCategoryListItemWhere"
		},
		listProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		getBusinessCategoryLocale:{
			by:"BusinessCategoryLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		listBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getProductCategoryListLocale:{
			by:"ProductCategoryListLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		listProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		getProductIngredientItem:{
			by:"ProductIngredientItemUniqueWhere",
			filter:"ProductIngredientItemWhere"
		},
		listProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		getProductIngredient:{
			by:"ProductIngredientUniqueWhere",
			filter:"ProductIngredientWhere"
		},
		listProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		paginateProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		getProductIngredientRating:{
			by:"ProductIngredientRatingUniqueWhere",
			filter:"ProductIngredientRatingWhere"
		},
		listProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		paginateProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		getProductIngredientLocale:{
			by:"ProductIngredientLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		listProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		getOrderRecurrence:{
			by:"OrderRecurrenceUniqueWhere",
			filter:"OrderRecurrenceWhere"
		},
		listOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		paginateOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		getOrderRecurrenceLocale:{
			by:"OrderRecurrenceLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		listOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		getBlog:{
			by:"BlogUniqueWhere",
			filter:"BlogWhere"
		},
		listBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		paginateBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		getBlogLocale:{
			by:"BlogLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		listBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getColorPallete:{
			by:"ColorPalleteUniqueWhere",
			filter:"ColorPalleteWhere"
		},
		listColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		paginateColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getBlogPostList:{
			by:"BlogPostListUniqueWhere",
			filter:"BlogPostListWhere"
		},
		listBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		paginateBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		getBlogPostListItem:{
			by:"BlogPostListItemUniqueWhere",
			filter:"BlogPostListItemWhere"
		},
		listBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getCover:{
			by:"CoverUniqueWhere",
			filter:"CoverWhere"
		},
		listCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		paginateCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getSocialsAndApps:{
			by:"SocialsAndAppsUniqueWhere",
			filter:"SocialsAndAppsWhere"
		},
		listSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		paginateSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		getProductGrid:{
			by:"ProductGridUniqueWhere",
			filter:"ProductGridWhere"
		},
		listProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		paginateProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		getProductGridItem:{
			by:"ProductGridItemUniqueWhere",
			filter:"ProductGridItemWhere"
		},
		listProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		paginateProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getHero:{
			by:"HeroUniqueWhere",
			filter:"HeroWhere"
		},
		listHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		paginateHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		getSiteMenu:{
			by:"SiteMenuUniqueWhere",
			filter:"SiteMenuWhere"
		},
		listSiteMenu:{
			filter:"SiteMenuWhere",
			orderBy:"SiteMenuOrderBy"
		},
		paginateSiteMenu:{
			filter:"SiteMenuWhere",
			orderBy:"SiteMenuOrderBy"
		},
		getMenuLocale:{
			by:"MenuLocaleUniqueWhere",
			filter:"MenuLocaleWhere"
		},
		listMenuLocale:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		},
		paginateMenuLocale:{
			filter:"MenuLocaleWhere",
			orderBy:"MenuLocaleOrderBy"
		},
		getMenuItem:{
			by:"MenuItemUniqueWhere",
			filter:"MenuItemWhere"
		},
		listMenuItem:{
			filter:"MenuItemWhere",
			orderBy:"MenuItemOrderBy"
		},
		paginateMenuItem:{
			filter:"MenuItemWhere",
			orderBy:"MenuItemOrderBy"
		},
		getPage:{
			by:"PageUniqueWhere",
			filter:"PageWhere"
		},
		listPage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		paginatePage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		getPageLocale:{
			by:"PageLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		listPageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginatePageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		getWikiPage:{
			by:"WikiPageUniqueWhere",
			filter:"WikiPageWhere"
		},
		listWikiPage:{
			filter:"WikiPageWhere",
			orderBy:"WikiPageOrderBy"
		},
		paginateWikiPage:{
			filter:"WikiPageWhere",
			orderBy:"WikiPageOrderBy"
		},
		getMenuSubitem:{
			by:"MenuSubitemUniqueWhere",
			filter:"MenuSubitemWhere"
		},
		listMenuSubitem:{
			filter:"MenuSubitemWhere",
			orderBy:"MenuSubitemOrderBy"
		},
		paginateMenuSubitem:{
			filter:"MenuSubitemWhere",
			orderBy:"MenuSubitemOrderBy"
		},
		getMenuLinkList:{
			by:"MenuLinkListUniqueWhere",
			filter:"MenuLinkListWhere"
		},
		listMenuLinkList:{
			filter:"MenuLinkListWhere",
			orderBy:"MenuLinkListOrderBy"
		},
		paginateMenuLinkList:{
			filter:"MenuLinkListWhere",
			orderBy:"MenuLinkListOrderBy"
		},
		getMenuLinkItem:{
			by:"MenuLinkItemUniqueWhere",
			filter:"MenuLinkItemWhere"
		},
		listMenuLinkItem:{
			filter:"MenuLinkItemWhere",
			orderBy:"MenuLinkItemOrderBy"
		},
		paginateMenuLinkItem:{
			filter:"MenuLinkItemWhere",
			orderBy:"MenuLinkItemOrderBy"
		},
		getMenuProducts:{
			by:"MenuProductsUniqueWhere",
			filter:"MenuProductsWhere"
		},
		listMenuProducts:{
			filter:"MenuProductsWhere",
			orderBy:"MenuProductsOrderBy"
		},
		paginateMenuProducts:{
			filter:"MenuProductsWhere",
			orderBy:"MenuProductsOrderBy"
		},
		getMenuDelivery:{
			by:"MenuDeliveryUniqueWhere",
			filter:"MenuDeliveryWhere"
		},
		listMenuDelivery:{
			filter:"MenuDeliveryWhere",
			orderBy:"MenuDeliveryOrderBy"
		},
		paginateMenuDelivery:{
			filter:"MenuDeliveryWhere",
			orderBy:"MenuDeliveryOrderBy"
		},
		getMenuContact:{
			by:"MenuContactUniqueWhere",
			filter:"MenuContactWhere"
		},
		listMenuContact:{
			filter:"MenuContactWhere",
			orderBy:"MenuContactOrderBy"
		},
		paginateMenuContact:{
			filter:"MenuContactWhere",
			orderBy:"MenuContactOrderBy"
		},
		getProductBanner:{
			by:"ProductBannerUniqueWhere",
			filter:"ProductBannerWhere"
		},
		listProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		paginateProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		getProductBannerItem:{
			by:"ProductBannerItemUniqueWhere",
			filter:"ProductBannerItemWhere"
		},
		listProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		getProductBannerList:{
			by:"ProductBannerListUniqueWhere",
			filter:"ProductBannerListWhere"
		},
		listProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		paginateProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		getAllergen:{
			by:"AllergenUniqueWhere",
			filter:"AllergenWhere"
		},
		listAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		paginateAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		getAllergenLocale:{
			by:"AllergenLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		listAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		getGlobalConfig:{
			by:"GlobalConfigUniqueWhere",
			filter:"GlobalConfigWhere"
		},
		listGlobalConfig:{
			filter:"GlobalConfigWhere",
			orderBy:"GlobalConfigOrderBy"
		},
		paginateGlobalConfig:{
			filter:"GlobalConfigWhere",
			orderBy:"GlobalConfigOrderBy"
		},
		getWeekHours:{
			by:"WeekHoursUniqueWhere",
			filter:"WeekHoursWhere"
		},
		listWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		paginateWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		getDeliveryZone:{
			by:"DeliveryZoneUniqueWhere",
			filter:"DeliveryZoneWhere"
		},
		listDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		getDeliveryTimelinePreset:{
			by:"DeliveryTimelinePresetUniqueWhere",
			filter:"DeliveryTimelinePresetWhere"
		},
		listDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		paginateDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		getProductPackingLocale:{
			by:"ProductPackingLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		listProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductRecipeLocale:{
			by:"ProductRecipeLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		listProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		getVirtualProduct:{
			by:"VirtualProductUniqueWhere",
			filter:"VirtualProductWhere"
		},
		listVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		paginateVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		getProductMetadata:{
			by:"ProductMetadataUniqueWhere",
			filter:"ProductMetadataWhere"
		},
		listProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		paginateProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		getProductGridCategory:{
			by:"ProductGridCategoryUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		listProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		paginateProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getFreshingContainer:{
			by:"FreshingContainerUniqueWhere",
			filter:"FreshingContainerWhere"
		},
		listFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		paginateFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getDeliveryRegions:{
			by:"DeliveryRegionsUniqueWhere",
			filter:"DeliveryRegionsWhere"
		},
		listDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		paginateDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		getDeliveryRegionsItem:{
			by:"DeliveryRegionsItemUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		listDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		paginateDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getDeliveryMethodLocale:{
			by:"DeliveryMethodLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		listDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		}
	},
	Json: "String",
	_OnDeleteBehaviour: true,
	_RelationSide: true,
	_OrderByDirection: true
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		getCountry:"Country",
		listCountry:"Country",
		paginateCountry:"CountryConnection",
		getChannel:"Channel",
		listChannel:"Channel",
		paginateChannel:"ChannelConnection",
		getDeliveryMethod:"DeliveryMethod",
		listDeliveryMethod:"DeliveryMethod",
		paginateDeliveryMethod:"DeliveryMethodConnection",
		getPaymentMethod:"PaymentMethod",
		listPaymentMethod:"PaymentMethod",
		paginatePaymentMethod:"PaymentMethodConnection",
		getVatRate:"VatRate",
		listVatRate:"VatRate",
		paginateVatRate:"VatRateConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductStock:"ProductStock",
		listProductStock:"ProductStock",
		paginateProductStock:"ProductStockConnection",
		getProductPacking:"ProductPacking",
		listProductPacking:"ProductPacking",
		paginateProductPacking:"ProductPackingConnection",
		getProductRecipe:"ProductRecipe",
		listProductRecipe:"ProductRecipe",
		paginateProductRecipe:"ProductRecipeConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getBusinessCategory:"BusinessCategory",
		listBusinessCategory:"BusinessCategory",
		paginateBusinessCategory:"BusinessCategoryConnection",
		getProductList:"ProductList",
		listProductList:"ProductList",
		paginateProductList:"ProductListConnection",
		getProductListItem:"ProductListItem",
		listProductListItem:"ProductListItem",
		paginateProductListItem:"ProductListItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getProductCategoryListItem:"ProductCategoryListItem",
		listProductCategoryListItem:"ProductCategoryListItem",
		paginateProductCategoryListItem:"ProductCategoryListItemConnection",
		getBusinessCategoryLocale:"BusinessCategoryLocale",
		listBusinessCategoryLocale:"BusinessCategoryLocale",
		paginateBusinessCategoryLocale:"BusinessCategoryLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getProductCategoryListLocale:"ProductCategoryListLocale",
		listProductCategoryListLocale:"ProductCategoryListLocale",
		paginateProductCategoryListLocale:"ProductCategoryListLocaleConnection",
		getProductIngredientItem:"ProductIngredientItem",
		listProductIngredientItem:"ProductIngredientItem",
		paginateProductIngredientItem:"ProductIngredientItemConnection",
		getProductIngredient:"ProductIngredient",
		listProductIngredient:"ProductIngredient",
		paginateProductIngredient:"ProductIngredientConnection",
		getProductIngredientRating:"ProductIngredientRating",
		listProductIngredientRating:"ProductIngredientRating",
		paginateProductIngredientRating:"ProductIngredientRatingConnection",
		getProductIngredientLocale:"ProductIngredientLocale",
		listProductIngredientLocale:"ProductIngredientLocale",
		paginateProductIngredientLocale:"ProductIngredientLocaleConnection",
		getOrderRecurrence:"OrderRecurrence",
		listOrderRecurrence:"OrderRecurrence",
		paginateOrderRecurrence:"OrderRecurrenceConnection",
		getOrderRecurrenceLocale:"OrderRecurrenceLocale",
		listOrderRecurrenceLocale:"OrderRecurrenceLocale",
		paginateOrderRecurrenceLocale:"OrderRecurrenceLocaleConnection",
		getBlog:"Blog",
		listBlog:"Blog",
		paginateBlog:"BlogConnection",
		getBlogLocale:"BlogLocale",
		listBlogLocale:"BlogLocale",
		paginateBlogLocale:"BlogLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getColorPallete:"ColorPallete",
		listColorPallete:"ColorPallete",
		paginateColorPallete:"ColorPalleteConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getBlogPostList:"BlogPostList",
		listBlogPostList:"BlogPostList",
		paginateBlogPostList:"BlogPostListConnection",
		getBlogPostListItem:"BlogPostListItem",
		listBlogPostListItem:"BlogPostListItem",
		paginateBlogPostListItem:"BlogPostListItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getCover:"Cover",
		listCover:"Cover",
		paginateCover:"CoverConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getSocialsAndApps:"SocialsAndApps",
		listSocialsAndApps:"SocialsAndApps",
		paginateSocialsAndApps:"SocialsAndAppsConnection",
		getProductGrid:"ProductGrid",
		listProductGrid:"ProductGrid",
		paginateProductGrid:"ProductGridConnection",
		getProductGridItem:"ProductGridItem",
		listProductGridItem:"ProductGridItem",
		paginateProductGridItem:"ProductGridItemConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getHero:"Hero",
		listHero:"Hero",
		paginateHero:"HeroConnection",
		getSiteMenu:"SiteMenu",
		listSiteMenu:"SiteMenu",
		paginateSiteMenu:"SiteMenuConnection",
		getMenuLocale:"MenuLocale",
		listMenuLocale:"MenuLocale",
		paginateMenuLocale:"MenuLocaleConnection",
		getMenuItem:"MenuItem",
		listMenuItem:"MenuItem",
		paginateMenuItem:"MenuItemConnection",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		getWikiPage:"WikiPage",
		listWikiPage:"WikiPage",
		paginateWikiPage:"WikiPageConnection",
		getMenuSubitem:"MenuSubitem",
		listMenuSubitem:"MenuSubitem",
		paginateMenuSubitem:"MenuSubitemConnection",
		getMenuLinkList:"MenuLinkList",
		listMenuLinkList:"MenuLinkList",
		paginateMenuLinkList:"MenuLinkListConnection",
		getMenuLinkItem:"MenuLinkItem",
		listMenuLinkItem:"MenuLinkItem",
		paginateMenuLinkItem:"MenuLinkItemConnection",
		getMenuProducts:"MenuProducts",
		listMenuProducts:"MenuProducts",
		paginateMenuProducts:"MenuProductsConnection",
		getMenuDelivery:"MenuDelivery",
		listMenuDelivery:"MenuDelivery",
		paginateMenuDelivery:"MenuDeliveryConnection",
		getMenuContact:"MenuContact",
		listMenuContact:"MenuContact",
		paginateMenuContact:"MenuContactConnection",
		getProductBanner:"ProductBanner",
		listProductBanner:"ProductBanner",
		paginateProductBanner:"ProductBannerConnection",
		getProductBannerItem:"ProductBannerItem",
		listProductBannerItem:"ProductBannerItem",
		paginateProductBannerItem:"ProductBannerItemConnection",
		getProductBannerList:"ProductBannerList",
		listProductBannerList:"ProductBannerList",
		paginateProductBannerList:"ProductBannerListConnection",
		getAllergen:"Allergen",
		listAllergen:"Allergen",
		paginateAllergen:"AllergenConnection",
		getAllergenLocale:"AllergenLocale",
		listAllergenLocale:"AllergenLocale",
		paginateAllergenLocale:"AllergenLocaleConnection",
		getGlobalConfig:"GlobalConfig",
		listGlobalConfig:"GlobalConfig",
		paginateGlobalConfig:"GlobalConfigConnection",
		getWeekHours:"WeekHours",
		listWeekHours:"WeekHours",
		paginateWeekHours:"WeekHoursConnection",
		getDeliveryZone:"DeliveryZone",
		listDeliveryZone:"DeliveryZone",
		paginateDeliveryZone:"DeliveryZoneConnection",
		getDeliveryTimelinePreset:"DeliveryTimelinePreset",
		listDeliveryTimelinePreset:"DeliveryTimelinePreset",
		paginateDeliveryTimelinePreset:"DeliveryTimelinePresetConnection",
		getProductPackingLocale:"ProductPackingLocale",
		listProductPackingLocale:"ProductPackingLocale",
		paginateProductPackingLocale:"ProductPackingLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductRecipeLocale:"ProductRecipeLocale",
		listProductRecipeLocale:"ProductRecipeLocale",
		paginateProductRecipeLocale:"ProductRecipeLocaleConnection",
		getVirtualProduct:"VirtualProduct",
		listVirtualProduct:"VirtualProduct",
		paginateVirtualProduct:"VirtualProductConnection",
		getProductMetadata:"ProductMetadata",
		listProductMetadata:"ProductMetadata",
		paginateProductMetadata:"ProductMetadataConnection",
		getProductGridCategory:"ProductGridCategory",
		listProductGridCategory:"ProductGridCategory",
		paginateProductGridCategory:"ProductGridCategoryConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getFreshingContainer:"FreshingContainer",
		listFreshingContainer:"FreshingContainer",
		paginateFreshingContainer:"FreshingContainerConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getDeliveryRegions:"DeliveryRegions",
		listDeliveryRegions:"DeliveryRegions",
		paginateDeliveryRegions:"DeliveryRegionsConnection",
		getDeliveryRegionsItem:"DeliveryRegionsItem",
		listDeliveryRegionsItem:"DeliveryRegionsItem",
		paginateDeliveryRegionsItem:"DeliveryRegionsItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getDeliveryMethodLocale:"DeliveryMethodLocale",
		listDeliveryMethodLocale:"DeliveryMethodLocale",
		paginateDeliveryMethodLocale:"DeliveryMethodLocaleConnection",
		transaction:"QueryTransaction",
		_info:"Info",
		schema:"_Schema"
	},
	Country:{
		_meta:"CountryMeta",
		id:"UUID",
		code:"String"
	},
	CountryMeta:{
		id:"FieldMeta",
		code:"FieldMeta"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	CountryConnection:{
		pageInfo:"PageInfo",
		edges:"CountryEdge"
	},
	PageInfo:{
		totalCount:"Int"
	},
	CountryEdge:{
		node:"Country"
	},
	Channel:{
		_meta:"ChannelMeta",
		id:"UUID",
		name:"String",
		disabled:"Boolean",
		code:"ChannelCode",
		addTagsOnRegistration:"Tag",
		publicTags:"Tag",
		paginateAddTagsOnRegistration:"TagConnection",
		paginatePublicTags:"TagConnection"
	},
	ChannelMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		disabled:"FieldMeta",
		code:"FieldMeta",
		addTagsOnRegistration:"FieldMeta",
		publicTags:"FieldMeta"
	},
	Tag:{
		_meta:"TagMeta",
		id:"UUID",
		name:"String",
		code:"String",
		products:"Product",
		categories:"ProductCategory",
		description:"String",
		internalNote:"String",
		addTagsOnRegistration:"Channel",
		deliveryZones:"DeliveryZone",
		publicInChannels:"Channel",
		paginateProducts:"ProductConnection",
		paginateCategories:"ProductCategoryConnection",
		paginateAddTagsOnRegistration:"ChannelConnection",
		paginateDeliveryZones:"DeliveryZoneConnection",
		paginatePublicInChannels:"ChannelConnection"
	},
	TagMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		products:"FieldMeta",
		categories:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		addTagsOnRegistration:"FieldMeta",
		deliveryZones:"FieldMeta",
		publicInChannels:"FieldMeta"
	},
	Product:{
		_meta:"ProductMeta",
		id:"UUID",
		name:"String",
		code:"String",
		priceCents:"Int",
		notDiscountable:"Boolean",
		packing:"ProductPacking",
		recipe:"ProductRecipe",
		tags:"Tag",
		categories:"ProductCategory",
		vatRate:"VatRate",
		stocks:"ProductStock",
		image:"Image",
		description:"String",
		internalNote:"String",
		availability:"ProductAvailability",
		order:"Float",
		maximumQuantityPerOrder:"Int",
		deliveryZones:"DeliveryZone",
		locales:"ProductLocale",
		archivedAt:"DateTime",
		virtualProduct:"VirtualProduct",
		meta:"ProductMetadata",
		page:"Page",
		stocksByStore:"ProductStock",
		localesByLocale:"ProductLocale",
		localesByFeatureList:"ProductLocale",
		paginateTags:"TagConnection",
		paginateCategories:"ProductCategoryConnection",
		paginateStocks:"ProductStockConnection",
		paginateDeliveryZones:"DeliveryZoneConnection",
		paginateLocales:"ProductLocaleConnection"
	},
	ProductMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		priceCents:"FieldMeta",
		notDiscountable:"FieldMeta",
		packing:"FieldMeta",
		recipe:"FieldMeta",
		tags:"FieldMeta",
		categories:"FieldMeta",
		vatRate:"FieldMeta",
		stocks:"FieldMeta",
		image:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		availability:"FieldMeta",
		order:"FieldMeta",
		maximumQuantityPerOrder:"FieldMeta",
		deliveryZones:"FieldMeta",
		locales:"FieldMeta",
		archivedAt:"FieldMeta",
		virtualProduct:"FieldMeta",
		meta:"FieldMeta",
		page:"FieldMeta"
	},
	ProductPacking:{
		_meta:"ProductPackingMeta",
		id:"UUID",
		name:"String",
		image:"Image",
		description:"String",
		internalNote:"String",
		volumeMl:"Int",
		icon:"Image",
		shortName:"String",
		locales:"ProductPackingLocale",
		isPackedInCardboard:"Boolean",
		localesByLocale:"ProductPackingLocale",
		paginateLocales:"ProductPackingLocaleConnection"
	},
	ProductPackingMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		image:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		volumeMl:"FieldMeta",
		icon:"FieldMeta",
		shortName:"FieldMeta",
		locales:"FieldMeta",
		isPackedInCardboard:"FieldMeta"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		alt:"FieldMeta"
	},
	ProductPackingLocale:{
		_meta:"ProductPackingLocaleMeta",
		id:"UUID",
		name:"String",
		shortName:"String",
		description:"String",
		root:"ProductPacking",
		locale:"Locale"
	},
	ProductPackingLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		shortName:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	Locale:{
		_meta:"LocaleMeta",
		id:"UUID",
		businessCategories:"BusinessCategoryLocale",
		productCategoryLists:"ProductCategoryListLocale",
		productIngredients:"ProductIngredientLocale",
		orderRecurrences:"OrderRecurrenceLocale",
		blogs:"BlogLocale",
		blogPosts:"BlogPostLocale",
		identifier:"TranslationCataloguesIdentifier",
		menus:"MenuLocale",
		pages:"PageLocale",
		allergens:"AllergenLocale",
		productPackings:"ProductPackingLocale",
		products:"ProductLocale",
		productRecipes:"ProductRecipeLocale",
		deliveryMethods:"DeliveryMethodLocale",
		businessCategoriesByRoot:"BusinessCategoryLocale",
		productCategoryListsByRoot:"ProductCategoryListLocale",
		productIngredientsByRoot:"ProductIngredientLocale",
		orderRecurrencesByRoot:"OrderRecurrenceLocale",
		blogsByRoot:"BlogLocale",
		blogPostsByRoot:"BlogPostLocale",
		blogPostsByCover:"BlogPostLocale",
		blogPostsByContent:"BlogPostLocale",
		blogPostsByLink:"BlogPostLocale",
		blogPostsBySeo:"BlogPostLocale",
		menusByRoot:"MenuLocale",
		menusByItems:"MenuLocale",
		menusBySecondaryItems:"MenuLocale",
		pagesByRoot:"PageLocale",
		pagesByLink:"PageLocale",
		pagesBySeo:"PageLocale",
		pagesByContent:"PageLocale",
		pagesByCover:"PageLocale",
		allergensByRoot:"AllergenLocale",
		productPackingsByRoot:"ProductPackingLocale",
		productsByRoot:"ProductLocale",
		productsByFeatureList:"ProductLocale",
		productRecipesByRoot:"ProductRecipeLocale",
		deliveryMethodsByRoot:"DeliveryMethodLocale",
		paginateBusinessCategories:"BusinessCategoryLocaleConnection",
		paginateProductCategoryLists:"ProductCategoryListLocaleConnection",
		paginateProductIngredients:"ProductIngredientLocaleConnection",
		paginateOrderRecurrences:"OrderRecurrenceLocaleConnection",
		paginateBlogs:"BlogLocaleConnection",
		paginateBlogPosts:"BlogPostLocaleConnection",
		paginateMenus:"MenuLocaleConnection",
		paginatePages:"PageLocaleConnection",
		paginateAllergens:"AllergenLocaleConnection",
		paginateProductPackings:"ProductPackingLocaleConnection",
		paginateProducts:"ProductLocaleConnection",
		paginateProductRecipes:"ProductRecipeLocaleConnection",
		paginateDeliveryMethods:"DeliveryMethodLocaleConnection"
	},
	LocaleMeta:{
		id:"FieldMeta",
		businessCategories:"FieldMeta",
		productCategoryLists:"FieldMeta",
		productIngredients:"FieldMeta",
		orderRecurrences:"FieldMeta",
		blogs:"FieldMeta",
		blogPosts:"FieldMeta",
		identifier:"FieldMeta",
		menus:"FieldMeta",
		pages:"FieldMeta",
		allergens:"FieldMeta",
		productPackings:"FieldMeta",
		products:"FieldMeta",
		productRecipes:"FieldMeta",
		deliveryMethods:"FieldMeta"
	},
	BusinessCategoryLocale:{
		_meta:"BusinessCategoryLocaleMeta",
		id:"UUID",
		name:"String",
		root:"BusinessCategory",
		locale:"Locale",
		title:"String"
	},
	BusinessCategoryLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		title:"FieldMeta"
	},
	BusinessCategory:{
		_meta:"BusinessCategoryMeta",
		id:"UUID",
		order:"Int",
		visibleToNewCustomers:"Boolean",
		locales:"BusinessCategoryLocale",
		internalName:"String",
		recurrences:"OrderRecurrence",
		specificProducts:"ProductList",
		seasonalProductCategories:"ProductCategoryList",
		specificProductsProductCategories:"ProductCategoryList",
		slug:"String",
		localesByLocale:"BusinessCategoryLocale",
		paginateLocales:"BusinessCategoryLocaleConnection",
		paginateRecurrences:"OrderRecurrenceConnection"
	},
	BusinessCategoryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		visibleToNewCustomers:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta",
		recurrences:"FieldMeta",
		specificProducts:"FieldMeta",
		seasonalProductCategories:"FieldMeta",
		specificProductsProductCategories:"FieldMeta",
		slug:"FieldMeta"
	},
	OrderRecurrence:{
		_meta:"OrderRecurrenceMeta",
		id:"UUID",
		internalName:"String",
		locales:"OrderRecurrenceLocale",
		order:"Int",
		businessCategory:"BusinessCategory",
		localesByLocale:"OrderRecurrenceLocale",
		paginateLocales:"OrderRecurrenceLocaleConnection",
		paginateBusinessCategory:"BusinessCategoryConnection"
	},
	OrderRecurrenceMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		locales:"FieldMeta",
		order:"FieldMeta",
		businessCategory:"FieldMeta"
	},
	OrderRecurrenceLocale:{
		_meta:"OrderRecurrenceLocaleMeta",
		id:"UUID",
		name:"String",
		root:"OrderRecurrence",
		locale:"Locale"
	},
	OrderRecurrenceLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	OrderRecurrenceLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"OrderRecurrenceLocaleEdge"
	},
	OrderRecurrenceLocaleEdge:{
		node:"OrderRecurrenceLocale"
	},
	BusinessCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCategoryEdge"
	},
	BusinessCategoryEdge:{
		node:"BusinessCategory"
	},
	ProductList:{
		_meta:"ProductListMeta",
		id:"UUID",
		items:"ProductListItem",
		businessCategory:"BusinessCategory",
		paginateItems:"ProductListItemConnection"
	},
	ProductListMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		businessCategory:"FieldMeta"
	},
	ProductListItem:{
		_meta:"ProductListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductList",
		product:"Product",
		defaultQuantityForOrder:"Int"
	},
	ProductListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		product:"FieldMeta",
		defaultQuantityForOrder:"FieldMeta"
	},
	ProductListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductListItemEdge"
	},
	ProductListItemEdge:{
		node:"ProductListItem"
	},
	ProductCategoryList:{
		_meta:"ProductCategoryListMeta",
		id:"UUID",
		items:"ProductCategoryListItem",
		locales:"ProductCategoryListLocale",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategory",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategory",
		localesByLocale:"ProductCategoryListLocale",
		paginateItems:"ProductCategoryListItemConnection",
		paginateLocales:"ProductCategoryListLocaleConnection"
	},
	ProductCategoryListMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		locales:"FieldMeta",
		seasonalProductCategoriesOfBusinessCategory:"FieldMeta",
		specificProductsProductCategoriesOfBusinessCategory:"FieldMeta"
	},
	ProductCategoryListItem:{
		_meta:"ProductCategoryListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductCategoryList",
		productCategory:"ProductCategory",
		showRecipeImages:"Boolean",
		showPackingImages:"Boolean",
		showAllAvailableProducts:"Boolean"
	},
	ProductCategoryListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		productCategory:"FieldMeta",
		showRecipeImages:"FieldMeta",
		showPackingImages:"FieldMeta",
		showAllAvailableProducts:"FieldMeta"
	},
	ProductCategory:{
		_meta:"ProductCategoryMeta",
		id:"UUID",
		name:"String",
		code:"String",
		products:"Product",
		tags:"Tag",
		parent:"ProductCategory",
		children:"ProductCategory",
		description:"String",
		internalNote:"String",
		color:"String",
		image:"Image",
		order:"Float",
		childrenByCode:"ProductCategory",
		childrenByChildren:"ProductCategory",
		paginateProducts:"ProductConnection",
		paginateTags:"TagConnection",
		paginateChildren:"ProductCategoryConnection"
	},
	ProductCategoryMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		products:"FieldMeta",
		tags:"FieldMeta",
		parent:"FieldMeta",
		children:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		color:"FieldMeta",
		image:"FieldMeta",
		order:"FieldMeta"
	},
	ProductConnection:{
		pageInfo:"PageInfo",
		edges:"ProductEdge"
	},
	ProductEdge:{
		node:"Product"
	},
	TagConnection:{
		pageInfo:"PageInfo",
		edges:"TagEdge"
	},
	TagEdge:{
		node:"Tag"
	},
	ProductCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryEdge"
	},
	ProductCategoryEdge:{
		node:"ProductCategory"
	},
	ProductCategoryListLocale:{
		_meta:"ProductCategoryListLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductCategoryList",
		locale:"Locale"
	},
	ProductCategoryListLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductCategoryListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListItemEdge"
	},
	ProductCategoryListItemEdge:{
		node:"ProductCategoryListItem"
	},
	ProductCategoryListLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListLocaleEdge"
	},
	ProductCategoryListLocaleEdge:{
		node:"ProductCategoryListLocale"
	},
	BusinessCategoryLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCategoryLocaleEdge"
	},
	BusinessCategoryLocaleEdge:{
		node:"BusinessCategoryLocale"
	},
	OrderRecurrenceConnection:{
		pageInfo:"PageInfo",
		edges:"OrderRecurrenceEdge"
	},
	OrderRecurrenceEdge:{
		node:"OrderRecurrence"
	},
	ProductIngredientLocale:{
		_meta:"ProductIngredientLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductIngredient",
		locale:"Locale"
	},
	ProductIngredientLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductIngredient:{
		_meta:"ProductIngredientMeta",
		id:"UUID",
		internalNote:"String",
		locales:"ProductIngredientLocale",
		internalName:"String",
		allergens:"Allergen",
		needsPrepping:"Boolean",
		allowCustomerRating:"Boolean",
		freshingContainer:"FreshingContainer",
		localesByLocale:"ProductIngredientLocale",
		paginateLocales:"ProductIngredientLocaleConnection",
		paginateAllergens:"AllergenConnection"
	},
	ProductIngredientMeta:{
		id:"FieldMeta",
		internalNote:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta",
		allergens:"FieldMeta",
		needsPrepping:"FieldMeta",
		allowCustomerRating:"FieldMeta",
		freshingContainer:"FieldMeta"
	},
	Allergen:{
		_meta:"AllergenMeta",
		id:"UUID",
		internalName:"String",
		locales:"AllergenLocale",
		ingredients:"ProductIngredient",
		code:"Int",
		localesByLocale:"AllergenLocale",
		paginateLocales:"AllergenLocaleConnection",
		paginateIngredients:"ProductIngredientConnection"
	},
	AllergenMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		locales:"FieldMeta",
		ingredients:"FieldMeta",
		code:"FieldMeta"
	},
	AllergenLocale:{
		_meta:"AllergenLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Allergen",
		locale:"Locale"
	},
	AllergenLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	AllergenLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"AllergenLocaleEdge"
	},
	AllergenLocaleEdge:{
		node:"AllergenLocale"
	},
	ProductIngredientConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientEdge"
	},
	ProductIngredientEdge:{
		node:"ProductIngredient"
	},
	FreshingContainer:{
		_meta:"FreshingContainerMeta",
		id:"UUID",
		order:"Int",
		config:"GlobalConfig",
		volumeMl:"Int"
	},
	FreshingContainerMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		config:"FieldMeta",
		volumeMl:"FieldMeta"
	},
	GlobalConfig:{
		_meta:"GlobalConfigMeta",
		id:"UUID",
		orderDeadlineTime:"String",
		ordersLoadingDurationHours:"Int",
		openingHours:"WeekHours",
		unique:"One",
		workingHours:"WeekHours",
		freshingContainerVolumeMl:"Int",
		checkoutUrlTemplate:"String",
		orderUrlTemplate:"String",
		paymentUrlTemplate:"String",
		customerProfileUrlTemplate:"String",
		userProfileUrlTemplate:"String",
		creditsProduct:"VirtualProduct",
		discountVatRate:"VatRate",
		defaultFreshingContainer:"FreshingContainer",
		freshingContainers:"FreshingContainer",
		messengerUrl:"String",
		messengerIosUrl:"String",
		messengerAndroidUrl:"String",
		freshingPrepareOrderByOffsetMinutes:"Int",
		paginateFreshingContainers:"FreshingContainerConnection"
	},
	GlobalConfigMeta:{
		id:"FieldMeta",
		orderDeadlineTime:"FieldMeta",
		ordersLoadingDurationHours:"FieldMeta",
		openingHours:"FieldMeta",
		unique:"FieldMeta",
		workingHours:"FieldMeta",
		freshingContainerVolumeMl:"FieldMeta",
		checkoutUrlTemplate:"FieldMeta",
		orderUrlTemplate:"FieldMeta",
		paymentUrlTemplate:"FieldMeta",
		customerProfileUrlTemplate:"FieldMeta",
		userProfileUrlTemplate:"FieldMeta",
		creditsProduct:"FieldMeta",
		discountVatRate:"FieldMeta",
		defaultFreshingContainer:"FieldMeta",
		freshingContainers:"FieldMeta",
		messengerUrl:"FieldMeta",
		messengerIosUrl:"FieldMeta",
		messengerAndroidUrl:"FieldMeta",
		freshingPrepareOrderByOffsetMinutes:"FieldMeta"
	},
	WeekHours:{
		_meta:"WeekHoursMeta",
		id:"UUID",
		mon:"String",
		tue:"String",
		wed:"String",
		thu:"String",
		fri:"String",
		sat:"String",
		sun:"String"
	},
	WeekHoursMeta:{
		id:"FieldMeta",
		mon:"FieldMeta",
		tue:"FieldMeta",
		wed:"FieldMeta",
		thu:"FieldMeta",
		fri:"FieldMeta",
		sat:"FieldMeta",
		sun:"FieldMeta"
	},
	VirtualProduct:{
		_meta:"VirtualProductMeta",
		id:"UUID",
		type:"VirtualProductType",
		product:"Product",
		voucherCreditCents:"Int",
		physicalRepresentation:"VirtualProductPhysicalRepresentationType",
		recipientEmail:"Boolean"
	},
	VirtualProductMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		product:"FieldMeta",
		voucherCreditCents:"FieldMeta",
		physicalRepresentation:"FieldMeta",
		recipientEmail:"FieldMeta"
	},
	VatRate:{
		_meta:"VatRateMeta",
		id:"UUID",
		name:"String",
		ratePermille:"Int",
		description:"String",
		internalNote:"String"
	},
	VatRateMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		ratePermille:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta"
	},
	FreshingContainerConnection:{
		pageInfo:"PageInfo",
		edges:"FreshingContainerEdge"
	},
	FreshingContainerEdge:{
		node:"FreshingContainer"
	},
	ProductIngredientLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientLocaleEdge"
	},
	ProductIngredientLocaleEdge:{
		node:"ProductIngredientLocale"
	},
	AllergenConnection:{
		pageInfo:"PageInfo",
		edges:"AllergenEdge"
	},
	AllergenEdge:{
		node:"Allergen"
	},
	BlogLocale:{
		_meta:"BlogLocaleMeta",
		id:"UUID",
		pageName:"String",
		title:"String",
		lead:"String",
		root:"Blog",
		locale:"Locale"
	},
	BlogLocaleMeta:{
		id:"FieldMeta",
		pageName:"FieldMeta",
		title:"FieldMeta",
		lead:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	Blog:{
		_meta:"BlogMeta",
		id:"UUID",
		unique:"One",
		locales:"BlogLocale",
		localesByLocale:"BlogLocale",
		paginateLocales:"BlogLocaleConnection"
	},
	BlogMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	BlogLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogLocaleEdge"
	},
	BlogLocaleEdge:{
		node:"BlogLocale"
	},
	BlogPostLocale:{
		_meta:"BlogPostLocaleMeta",
		id:"UUID",
		title:"String",
		availableForWeb:"Boolean",
		availableForMobile:"Boolean",
		root:"BlogPost",
		locale:"Locale",
		cover:"Cover",
		content:"Content",
		link:"Linkable",
		seo:"Seo",
		products:"Product",
		paginateProducts:"ProductConnection"
	},
	BlogPostLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		availableForWeb:"FieldMeta",
		availableForMobile:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		cover:"FieldMeta",
		content:"FieldMeta",
		link:"FieldMeta",
		seo:"FieldMeta",
		products:"FieldMeta"
	},
	BlogPost:{
		_meta:"BlogPostMeta",
		id:"UUID",
		order:"Int",
		locales:"BlogPostLocale",
		localesByLocale:"BlogPostLocale",
		localesByCover:"BlogPostLocale",
		localesByContent:"BlogPostLocale",
		localesByLink:"BlogPostLocale",
		localesBySeo:"BlogPostLocale",
		paginateLocales:"BlogPostLocaleConnection"
	},
	BlogPostMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		locales:"FieldMeta"
	},
	BlogPostLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostLocaleEdge"
	},
	BlogPostLocaleEdge:{
		node:"BlogPostLocale"
	},
	Cover:{
		_meta:"CoverMeta",
		id:"UUID",
		primaryTitle:"String",
		secondaryTitle:"String",
		medium:"Medium",
		buttonColors:"ColorPallete"
	},
	CoverMeta:{
		id:"FieldMeta",
		primaryTitle:"FieldMeta",
		secondaryTitle:"FieldMeta",
		medium:"FieldMeta",
		buttonColors:"FieldMeta"
	},
	Medium:{
		_meta:"MediumMeta",
		id:"UUID",
		type:"MediumType",
		colorTheme:"ColorTheme",
		image:"Image",
		video:"Video"
	},
	MediumMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		colorTheme:"FieldMeta",
		image:"FieldMeta",
		video:"FieldMeta"
	},
	Video:{
		_meta:"VideoMeta",
		id:"UUID",
		src:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		duration:"Float",
		poster:"Image"
	},
	VideoMeta:{
		id:"FieldMeta",
		src:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		duration:"FieldMeta",
		poster:"FieldMeta"
	},
	ColorPallete:{
		_meta:"ColorPalleteMeta",
		id:"UUID",
		background:"String",
		text:"String"
	},
	ColorPalleteMeta:{
		id:"FieldMeta",
		background:"FieldMeta",
		text:"FieldMeta"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		blocksByReferences:"ContentBlock",
		paginateBlocks:"ContentBlockConnection"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Content",
		references:"ContentReference",
		referencesByProducts:"ContentReference",
		referencesByProductBanners:"ContentReference",
		referencesByDeliveryRegions:"ContentReference",
		paginateReferences:"ContentReferenceConnection"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		json:"FieldMeta",
		content:"FieldMeta",
		references:"FieldMeta"
	},
	ContentReference:{
		_meta:"ContentReferenceMeta",
		id:"UUID",
		type:"ContentReferenceType",
		primaryText:"String",
		secondaryText:"String",
		jsonContent:"String",
		block:"ContentBlock",
		colorPallete:"ColorPallete",
		medium:"Medium",
		link:"Link",
		blogPosts:"BlogPostList",
		links:"LinkList",
		products:"ProductList",
		contentSize:"ContentSize",
		misc:"String",
		hero:"Hero",
		gallery:"Gallery",
		socialsAndApps:"SocialsAndApps",
		productGrid:"ProductGrid",
		productBanners:"ProductBannerList",
		deliveryRegions:"DeliveryRegions"
	},
	ContentReferenceMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		primaryText:"FieldMeta",
		secondaryText:"FieldMeta",
		jsonContent:"FieldMeta",
		block:"FieldMeta",
		colorPallete:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta",
		blogPosts:"FieldMeta",
		links:"FieldMeta",
		products:"FieldMeta",
		contentSize:"FieldMeta",
		misc:"FieldMeta",
		hero:"FieldMeta",
		gallery:"FieldMeta",
		socialsAndApps:"FieldMeta",
		productGrid:"FieldMeta",
		productBanners:"FieldMeta",
		deliveryRegions:"FieldMeta"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		type:"LinkType",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable"
	},
	LinkMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		blogPost:"BlogPostLocale",
		page:"PageLocale",
		wikiPage:"WikiPage",
		redirect:"Redirect"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		blogPost:"FieldMeta",
		page:"FieldMeta",
		wikiPage:"FieldMeta",
		redirect:"FieldMeta"
	},
	PageLocale:{
		_meta:"PageLocaleMeta",
		id:"UUID",
		title:"String",
		link:"Linkable",
		root:"Page",
		locale:"Locale",
		seo:"Seo",
		content:"Content",
		cover:"Cover",
		theme:"PageTheme"
	},
	PageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		link:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta",
		content:"FieldMeta",
		cover:"FieldMeta",
		theme:"FieldMeta"
	},
	Page:{
		_meta:"PageMeta",
		id:"UUID",
		locales:"PageLocale",
		localesByLocale:"PageLocale",
		localesByLink:"PageLocale",
		localesBySeo:"PageLocale",
		localesByContent:"PageLocale",
		localesByCover:"PageLocale",
		paginateLocales:"PageLocaleConnection"
	},
	PageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta"
	},
	PageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"PageLocaleEdge"
	},
	PageLocaleEdge:{
		node:"PageLocale"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		ogImage:"Image"
	},
	SeoMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		ogImage:"FieldMeta"
	},
	WikiPage:{
		_meta:"WikiPageMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		emoji:"String",
		link:"Linkable",
		parent:"WikiPage",
		children:"WikiPage",
		content:"Content",
		childrenByLink:"WikiPage",
		childrenByChildren:"WikiPage",
		childrenByContent:"WikiPage",
		paginateChildren:"WikiPageConnection"
	},
	WikiPageMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		emoji:"FieldMeta",
		link:"FieldMeta",
		parent:"FieldMeta",
		children:"FieldMeta",
		content:"FieldMeta"
	},
	WikiPageConnection:{
		pageInfo:"PageInfo",
		edges:"WikiPageEdge"
	},
	WikiPageEdge:{
		node:"WikiPage"
	},
	Redirect:{
		_meta:"RedirectMeta",
		id:"UUID",
		link:"Linkable",
		target:"Linkable"
	},
	RedirectMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		target:"FieldMeta"
	},
	BlogPostList:{
		_meta:"BlogPostListMeta",
		id:"UUID",
		items:"BlogPostListItem",
		paginateItems:"BlogPostListItemConnection"
	},
	BlogPostListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	BlogPostListItem:{
		_meta:"BlogPostListItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"BlogPostList",
		blogPost:"BlogPostLocale"
	},
	BlogPostListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caption:"FieldMeta",
		list:"FieldMeta",
		blogPost:"FieldMeta"
	},
	BlogPostListItemConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostListItemEdge"
	},
	BlogPostListItemEdge:{
		node:"BlogPostListItem"
	},
	LinkList:{
		_meta:"LinkListMeta",
		id:"UUID",
		items:"LinkListItem",
		paginateItems:"LinkListItemConnection"
	},
	LinkListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	LinkListItem:{
		_meta:"LinkListItemMeta",
		id:"UUID",
		order:"Int",
		isPrimary:"Boolean",
		list:"LinkList",
		link:"Link"
	},
	LinkListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		isPrimary:"FieldMeta",
		list:"FieldMeta",
		link:"FieldMeta"
	},
	LinkListItemConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListItemEdge"
	},
	LinkListItemEdge:{
		node:"LinkListItem"
	},
	Hero:{
		_meta:"HeroMeta",
		id:"UUID",
		showLogo:"Boolean",
		middleContent:"String",
		medium:"Medium"
	},
	HeroMeta:{
		id:"FieldMeta",
		showLogo:"FieldMeta",
		middleContent:"FieldMeta",
		medium:"FieldMeta"
	},
	Gallery:{
		_meta:"GalleryMeta",
		id:"UUID",
		items:"GalleryItem",
		paginateItems:"GalleryItemConnection"
	},
	GalleryMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	GalleryItem:{
		_meta:"GalleryItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"Gallery",
		medium:"Medium"
	},
	GalleryItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caption:"FieldMeta",
		list:"FieldMeta",
		medium:"FieldMeta"
	},
	GalleryItemConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryItemEdge"
	},
	GalleryItemEdge:{
		node:"GalleryItem"
	},
	SocialsAndApps:{
		_meta:"SocialsAndAppsMeta",
		id:"UUID",
		instagram:"Boolean",
		appStore:"Boolean",
		googlePlay:"Boolean"
	},
	SocialsAndAppsMeta:{
		id:"FieldMeta",
		instagram:"FieldMeta",
		appStore:"FieldMeta",
		googlePlay:"FieldMeta"
	},
	ProductGrid:{
		_meta:"ProductGridMeta",
		id:"UUID",
		isCarousel:"Boolean",
		categories:"ProductGridCategory",
		type:"ProductGridType",
		categoriesByItems:"ProductGridCategory",
		paginateCategories:"ProductGridCategoryConnection"
	},
	ProductGridMeta:{
		id:"FieldMeta",
		isCarousel:"FieldMeta",
		categories:"FieldMeta",
		type:"FieldMeta"
	},
	ProductGridCategory:{
		_meta:"ProductGridCategoryMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		root:"ProductGrid",
		items:"ProductGridItem",
		paginateItems:"ProductGridItemConnection"
	},
	ProductGridCategoryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		items:"FieldMeta"
	},
	ProductGridItem:{
		_meta:"ProductGridItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		lead:"String",
		product:"Product",
		medium:"Medium",
		link:"Link",
		category:"ProductGridCategory",
		description:"String"
	},
	ProductGridItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		lead:"FieldMeta",
		product:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta",
		category:"FieldMeta",
		description:"FieldMeta"
	},
	ProductGridItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridItemEdge"
	},
	ProductGridItemEdge:{
		node:"ProductGridItem"
	},
	ProductGridCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridCategoryEdge"
	},
	ProductGridCategoryEdge:{
		node:"ProductGridCategory"
	},
	ProductBannerList:{
		_meta:"ProductBannerListMeta",
		id:"UUID",
		items:"ProductBannerItem",
		paginateItems:"ProductBannerItemConnection"
	},
	ProductBannerListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ProductBannerItem:{
		_meta:"ProductBannerItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductBannerList",
		banner:"ProductBanner",
		size:"ProductBannerItemSize"
	},
	ProductBannerItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		banner:"FieldMeta",
		size:"FieldMeta"
	},
	ProductBanner:{
		_meta:"ProductBannerMeta",
		id:"UUID",
		beforeTitle:"String",
		title:"String",
		label:"String",
		button:"Link",
		backgroundImage:"Image",
		productImage:"Image",
		product:"Product"
	},
	ProductBannerMeta:{
		id:"FieldMeta",
		beforeTitle:"FieldMeta",
		title:"FieldMeta",
		label:"FieldMeta",
		button:"FieldMeta",
		backgroundImage:"FieldMeta",
		productImage:"FieldMeta",
		product:"FieldMeta"
	},
	ProductBannerItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerItemEdge"
	},
	ProductBannerItemEdge:{
		node:"ProductBannerItem"
	},
	DeliveryRegions:{
		_meta:"DeliveryRegionsMeta",
		id:"UUID",
		items:"DeliveryRegionsItem",
		itemsByImage:"DeliveryRegionsItem",
		itemsByTextList:"DeliveryRegionsItem",
		itemsByGallery:"DeliveryRegionsItem",
		paginateItems:"DeliveryRegionsItemConnection"
	},
	DeliveryRegionsMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	DeliveryRegionsItem:{
		_meta:"DeliveryRegionsItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		offerLabel:"String",
		list:"DeliveryRegions",
		image:"Image",
		textList:"TextList",
		gallery:"ImageList"
	},
	DeliveryRegionsItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		offerLabel:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta",
		textList:"FieldMeta",
		gallery:"FieldMeta"
	},
	TextList:{
		_meta:"TextListMeta",
		id:"UUID",
		items:"TextItem",
		paginateItems:"TextItemConnection"
	},
	TextListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TextItem:{
		_meta:"TextItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"TextList"
	},
	TextItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta"
	},
	TextItemConnection:{
		pageInfo:"PageInfo",
		edges:"TextItemEdge"
	},
	TextItemEdge:{
		node:"TextItem"
	},
	ImageList:{
		_meta:"ImageListMeta",
		id:"UUID",
		items:"ImageListItem",
		itemsByImage:"ImageListItem",
		paginateItems:"ImageListItemConnection"
	},
	ImageListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ImageListItem:{
		_meta:"ImageListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ImageList",
		image:"Image"
	},
	ImageListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	ImageListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListItemEdge"
	},
	ImageListItemEdge:{
		node:"ImageListItem"
	},
	DeliveryRegionsItemConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryRegionsItemEdge"
	},
	DeliveryRegionsItemEdge:{
		node:"DeliveryRegionsItem"
	},
	ContentReferenceConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceEdge"
	},
	ContentReferenceEdge:{
		node:"ContentReference"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	TranslationCataloguesIdentifier:{
		_meta:"TranslationCataloguesIdentifierMeta",
		id:"UUID",
		name:"String",
		code:"String",
		catalogue:"TranslationCatalogue",
		catalogueByDomain:"TranslationCatalogue",
		catalogueByValues:"TranslationCatalogue",
		paginateCatalogue:"TranslationCatalogueConnection"
	},
	TranslationCataloguesIdentifierMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		catalogue:"FieldMeta"
	},
	TranslationCatalogue:{
		_meta:"TranslationCatalogueMeta",
		id:"UUID",
		domain:"TranslationDomain",
		fallback:"TranslationCatalogue",
		values:"TranslationValue",
		identifier:"TranslationCataloguesIdentifier",
		valuesByKey:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationCatalogueMeta:{
		id:"FieldMeta",
		domain:"FieldMeta",
		fallback:"FieldMeta",
		values:"FieldMeta",
		identifier:"FieldMeta"
	},
	TranslationDomain:{
		_meta:"TranslationDomainMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		catalogues:"TranslationCatalogue",
		keys:"TranslationKey",
		cataloguesByIdentifier:"TranslationCatalogue",
		cataloguesByValues:"TranslationCatalogue",
		keysByIdentifier:"TranslationKey",
		keysByValues:"TranslationKey",
		paginateCatalogues:"TranslationCatalogueConnection",
		paginateKeys:"TranslationKeyConnection"
	},
	TranslationDomainMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		name:"FieldMeta",
		catalogues:"FieldMeta",
		keys:"FieldMeta"
	},
	TranslationKey:{
		_meta:"TranslationKeyMeta",
		id:"UUID",
		identifier:"String",
		contentType:"TranslationContentType",
		note:"String",
		domain:"TranslationDomain",
		values:"TranslationValue",
		valuesByCatalogue:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationKeyMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		contentType:"FieldMeta",
		note:"FieldMeta",
		domain:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationValue:{
		_meta:"TranslationValueMeta",
		id:"UUID",
		value:"String",
		catalogue:"TranslationCatalogue",
		key:"TranslationKey"
	},
	TranslationValueMeta:{
		id:"FieldMeta",
		value:"FieldMeta",
		catalogue:"FieldMeta",
		key:"FieldMeta"
	},
	TranslationValueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationValueEdge"
	},
	TranslationValueEdge:{
		node:"TranslationValue"
	},
	TranslationCatalogueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCatalogueEdge"
	},
	TranslationCatalogueEdge:{
		node:"TranslationCatalogue"
	},
	TranslationKeyConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationKeyEdge"
	},
	TranslationKeyEdge:{
		node:"TranslationKey"
	},
	MenuLocale:{
		_meta:"MenuLocaleMeta",
		id:"UUID",
		root:"SiteMenu",
		locale:"Locale",
		items:"MenuItem",
		secondaryItems:"LinkList",
		eshopLink:"Link",
		itemsBySubitems:"MenuItem",
		paginateItems:"MenuItemConnection"
	},
	MenuLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		items:"FieldMeta",
		secondaryItems:"FieldMeta",
		eshopLink:"FieldMeta"
	},
	SiteMenu:{
		_meta:"SiteMenuMeta",
		id:"UUID",
		unique:"One",
		locales:"MenuLocale",
		localesByLocale:"MenuLocale",
		localesByItems:"MenuLocale",
		localesBySecondaryItems:"MenuLocale",
		paginateLocales:"MenuLocaleConnection"
	},
	SiteMenuMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	MenuLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"MenuLocaleEdge"
	},
	MenuLocaleEdge:{
		node:"MenuLocale"
	},
	MenuItem:{
		_meta:"MenuItemMeta",
		id:"UUID",
		order:"Int",
		menu:"MenuLocale",
		link:"Link",
		subitems:"MenuSubitem",
		paginateSubitems:"MenuSubitemConnection"
	},
	MenuItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		menu:"FieldMeta",
		link:"FieldMeta",
		subitems:"FieldMeta"
	},
	MenuSubitem:{
		_meta:"MenuSubitemMeta",
		id:"UUID",
		type:"MenuSubitemType",
		title:"String",
		item:"MenuItem",
		links:"MenuLinkList",
		products:"MenuProducts",
		delivery:"MenuDelivery",
		contact:"MenuContact",
		order:"Int"
	},
	MenuSubitemMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		item:"FieldMeta",
		links:"FieldMeta",
		products:"FieldMeta",
		delivery:"FieldMeta",
		contact:"FieldMeta",
		order:"FieldMeta"
	},
	MenuLinkList:{
		_meta:"MenuLinkListMeta",
		id:"UUID",
		items:"MenuLinkItem",
		paginateItems:"MenuLinkItemConnection"
	},
	MenuLinkListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	MenuLinkItem:{
		_meta:"MenuLinkItemMeta",
		id:"UUID",
		order:"Int",
		list:"MenuLinkList",
		link:"Link",
		image:"Image",
		description:"String"
	},
	MenuLinkItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		link:"FieldMeta",
		image:"FieldMeta",
		description:"FieldMeta"
	},
	MenuLinkItemConnection:{
		pageInfo:"PageInfo",
		edges:"MenuLinkItemEdge"
	},
	MenuLinkItemEdge:{
		node:"MenuLinkItem"
	},
	MenuProducts:{
		_meta:"MenuProductsMeta",
		id:"UUID",
		links:"MenuLinkList",
		buttons:"LinkList"
	},
	MenuProductsMeta:{
		id:"FieldMeta",
		links:"FieldMeta",
		buttons:"FieldMeta"
	},
	MenuDelivery:{
		_meta:"MenuDeliveryMeta",
		id:"UUID",
		deliveryRegions:"DeliveryRegions",
		text:"String",
		socialsAndApps:"SocialsAndApps"
	},
	MenuDeliveryMeta:{
		id:"FieldMeta",
		deliveryRegions:"FieldMeta",
		text:"FieldMeta",
		socialsAndApps:"FieldMeta"
	},
	MenuContact:{
		_meta:"MenuContactMeta",
		id:"UUID",
		text:"String",
		image:"Image"
	},
	MenuContactMeta:{
		id:"FieldMeta",
		text:"FieldMeta",
		image:"FieldMeta"
	},
	MenuSubitemConnection:{
		pageInfo:"PageInfo",
		edges:"MenuSubitemEdge"
	},
	MenuSubitemEdge:{
		node:"MenuSubitem"
	},
	MenuItemConnection:{
		pageInfo:"PageInfo",
		edges:"MenuItemEdge"
	},
	MenuItemEdge:{
		node:"MenuItem"
	},
	ProductLocale:{
		_meta:"ProductLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		root:"Product",
		locale:"Locale",
		featureList:"TextList"
	},
	ProductLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		featureList:"FieldMeta"
	},
	ProductRecipeLocale:{
		_meta:"ProductRecipeLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductRecipe",
		locale:"Locale",
		description:"String"
	},
	ProductRecipeLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		description:"FieldMeta"
	},
	ProductRecipe:{
		_meta:"ProductRecipeMeta",
		id:"UUID",
		name:"String",
		products:"Product",
		internalNote:"String",
		color:"String",
		image:"Image",
		icon:"Image",
		ingredients:"ProductIngredientItem",
		needsPrepping:"Boolean",
		locales:"ProductRecipeLocale",
		gutTuning:"Float",
		freshingContainer:"FreshingContainer",
		description:"String",
		productsByCode:"Product",
		productsByStocks:"Product",
		productsByLocales:"Product",
		productsByVirtualProduct:"Product",
		productsByMeta:"Product",
		productsByPage:"Product",
		localesByLocale:"ProductRecipeLocale",
		paginateProducts:"ProductConnection",
		paginateIngredients:"ProductIngredientItemConnection",
		paginateLocales:"ProductRecipeLocaleConnection"
	},
	ProductRecipeMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		products:"FieldMeta",
		internalNote:"FieldMeta",
		color:"FieldMeta",
		image:"FieldMeta",
		icon:"FieldMeta",
		ingredients:"FieldMeta",
		needsPrepping:"FieldMeta",
		locales:"FieldMeta",
		gutTuning:"FieldMeta",
		freshingContainer:"FieldMeta",
		description:"FieldMeta"
	},
	ProductIngredientItem:{
		_meta:"ProductIngredientItemMeta",
		id:"UUID",
		order:"Int",
		internalOrder:"Int",
		recipe:"ProductRecipe",
		ingredient:"ProductIngredient"
	},
	ProductIngredientItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		internalOrder:"FieldMeta",
		recipe:"FieldMeta",
		ingredient:"FieldMeta"
	},
	ProductIngredientItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientItemEdge"
	},
	ProductIngredientItemEdge:{
		node:"ProductIngredientItem"
	},
	ProductRecipeLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductRecipeLocaleEdge"
	},
	ProductRecipeLocaleEdge:{
		node:"ProductRecipeLocale"
	},
	DeliveryMethodLocale:{
		_meta:"DeliveryMethodLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		trackingCodeUrlTemplate:"String",
		processingTitle:"String",
		processingText:"String",
		processingIconUrl:"String",
		dispatchedTitle:"String",
		dispatchedText:"String",
		dispatchedIconUrl:"String",
		fulfilledTitle:"String",
		fulfilledText:"String",
		fulfilledIconUrl:"String",
		locale:"Locale",
		root:"DeliveryMethod"
	},
	DeliveryMethodLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		description:"FieldMeta",
		trackingCodeUrlTemplate:"FieldMeta",
		processingTitle:"FieldMeta",
		processingText:"FieldMeta",
		processingIconUrl:"FieldMeta",
		dispatchedTitle:"FieldMeta",
		dispatchedText:"FieldMeta",
		dispatchedIconUrl:"FieldMeta",
		fulfilledTitle:"FieldMeta",
		fulfilledText:"FieldMeta",
		fulfilledIconUrl:"FieldMeta",
		locale:"FieldMeta",
		root:"FieldMeta"
	},
	DeliveryMethod:{
		_meta:"DeliveryMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		paymentMethods:"PaymentMethod",
		description:"String",
		internalNote:"String",
		requireDeliveryAddress:"Boolean",
		suitableForDeliveryZones:"DeliveryZone",
		timelinePreset:"DeliveryTimelinePreset",
		enableDeliveryTracking:"Boolean",
		locales:"DeliveryMethodLocale",
		localesByLocale:"DeliveryMethodLocale",
		paginatePaymentMethods:"PaymentMethodConnection",
		paginateSuitableForDeliveryZones:"DeliveryZoneConnection",
		paginateLocales:"DeliveryMethodLocaleConnection"
	},
	DeliveryMethodMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		enabled:"FieldMeta",
		paymentMethods:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		requireDeliveryAddress:"FieldMeta",
		suitableForDeliveryZones:"FieldMeta",
		timelinePreset:"FieldMeta",
		enableDeliveryTracking:"FieldMeta",
		locales:"FieldMeta"
	},
	PaymentMethod:{
		_meta:"PaymentMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		deliveryMethods:"DeliveryMethod",
		description:"String",
		internalNote:"String",
		blockingDispatch:"Boolean",
		type:"PaymentMethodType",
		paginateDeliveryMethods:"DeliveryMethodConnection"
	},
	PaymentMethodMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		enabled:"FieldMeta",
		deliveryMethods:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		blockingDispatch:"FieldMeta",
		type:"FieldMeta"
	},
	DeliveryMethodConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryMethodEdge"
	},
	DeliveryMethodEdge:{
		node:"DeliveryMethod"
	},
	DeliveryZone:{
		_meta:"DeliveryZoneMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String",
		type:"DeliveryZoneType",
		suitableDeliveryMethods:"DeliveryMethod",
		deliveryZonesOfTags:"Tag",
		deliveryZonesOfProducts:"Product",
		paginateSuitableDeliveryMethods:"DeliveryMethodConnection",
		paginateDeliveryZonesOfTags:"TagConnection",
		paginateDeliveryZonesOfProducts:"ProductConnection"
	},
	DeliveryZoneMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		type:"FieldMeta",
		suitableDeliveryMethods:"FieldMeta",
		deliveryZonesOfTags:"FieldMeta",
		deliveryZonesOfProducts:"FieldMeta"
	},
	DeliveryTimelinePreset:{
		_meta:"DeliveryTimelinePresetMeta",
		id:"UUID",
		type:"DeliveryTimelineType",
		duration:"String",
		window:"String",
		deliveryMethod:"DeliveryMethod"
	},
	DeliveryTimelinePresetMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		duration:"FieldMeta",
		window:"FieldMeta",
		deliveryMethod:"FieldMeta"
	},
	PaymentMethodConnection:{
		pageInfo:"PageInfo",
		edges:"PaymentMethodEdge"
	},
	PaymentMethodEdge:{
		node:"PaymentMethod"
	},
	DeliveryZoneConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryZoneEdge"
	},
	DeliveryZoneEdge:{
		node:"DeliveryZone"
	},
	DeliveryMethodLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryMethodLocaleEdge"
	},
	DeliveryMethodLocaleEdge:{
		node:"DeliveryMethodLocale"
	},
	ProductPackingLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductPackingLocaleEdge"
	},
	ProductPackingLocaleEdge:{
		node:"ProductPackingLocale"
	},
	ProductLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductLocaleEdge"
	},
	ProductLocaleEdge:{
		node:"ProductLocale"
	},
	ProductStock:{
		_meta:"ProductStockMeta",
		id:"UUID",
		quantity:"Int",
		product:"Product",
		store:"Store"
	},
	ProductStockMeta:{
		id:"FieldMeta",
		quantity:"FieldMeta",
		product:"FieldMeta",
		store:"FieldMeta"
	},
	Store:{
		_meta:"StoreMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String"
	},
	StoreMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta"
	},
	ProductMetadata:{
		_meta:"ProductMetadataMeta",
		id:"UUID",
		archived:"Boolean",
		available:"Boolean",
		availableQuantity:"Int",
		product:"Product"
	},
	ProductMetadataMeta:{
		id:"FieldMeta",
		archived:"FieldMeta",
		available:"FieldMeta",
		availableQuantity:"FieldMeta",
		product:"FieldMeta"
	},
	ProductStockConnection:{
		pageInfo:"PageInfo",
		edges:"ProductStockEdge"
	},
	ProductStockEdge:{
		node:"ProductStock"
	},
	ChannelConnection:{
		pageInfo:"PageInfo",
		edges:"ChannelEdge"
	},
	ChannelEdge:{
		node:"Channel"
	},
	VatRateConnection:{
		pageInfo:"PageInfo",
		edges:"VatRateEdge"
	},
	VatRateEdge:{
		node:"VatRate"
	},
	ProductPackingConnection:{
		pageInfo:"PageInfo",
		edges:"ProductPackingEdge"
	},
	ProductPackingEdge:{
		node:"ProductPacking"
	},
	ProductRecipeConnection:{
		pageInfo:"PageInfo",
		edges:"ProductRecipeEdge"
	},
	ProductRecipeEdge:{
		node:"ProductRecipe"
	},
	StoreConnection:{
		pageInfo:"PageInfo",
		edges:"StoreEdge"
	},
	StoreEdge:{
		node:"Store"
	},
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	ImageEdge:{
		node:"Image"
	},
	ProductListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductListEdge"
	},
	ProductListEdge:{
		node:"ProductList"
	},
	ProductCategoryListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListEdge"
	},
	ProductCategoryListEdge:{
		node:"ProductCategoryList"
	},
	LocaleConnection:{
		pageInfo:"PageInfo",
		edges:"LocaleEdge"
	},
	LocaleEdge:{
		node:"Locale"
	},
	ProductIngredientRating:{
		_meta:"ProductIngredientRatingMeta",
		id:"UUID",
		order:"Int",
		name:"String",
		icon:"Image"
	},
	ProductIngredientRatingMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		name:"FieldMeta",
		icon:"FieldMeta"
	},
	ProductIngredientRatingConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientRatingEdge"
	},
	ProductIngredientRatingEdge:{
		node:"ProductIngredientRating"
	},
	BlogConnection:{
		pageInfo:"PageInfo",
		edges:"BlogEdge"
	},
	BlogEdge:{
		node:"Blog"
	},
	BlogPostConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostEdge"
	},
	BlogPostEdge:{
		node:"BlogPost"
	},
	ColorPalleteConnection:{
		pageInfo:"PageInfo",
		edges:"ColorPalleteEdge"
	},
	ColorPalleteEdge:{
		node:"ColorPallete"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentEdge:{
		node:"Content"
	},
	BlogPostListConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostListEdge"
	},
	BlogPostListEdge:{
		node:"BlogPostList"
	},
	LinkListConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListEdge"
	},
	LinkListEdge:{
		node:"LinkList"
	},
	CoverConnection:{
		pageInfo:"PageInfo",
		edges:"CoverEdge"
	},
	CoverEdge:{
		node:"Cover"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkEdge:{
		node:"Link"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	MediumConnection:{
		pageInfo:"PageInfo",
		edges:"MediumEdge"
	},
	MediumEdge:{
		node:"Medium"
	},
	VideoConnection:{
		pageInfo:"PageInfo",
		edges:"VideoEdge"
	},
	VideoEdge:{
		node:"Video"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoEdge:{
		node:"Seo"
	},
	TranslationDomainConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationDomainEdge"
	},
	TranslationDomainEdge:{
		node:"TranslationDomain"
	},
	TranslationCataloguesIdentifierConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCataloguesIdentifierEdge"
	},
	TranslationCataloguesIdentifierEdge:{
		node:"TranslationCataloguesIdentifier"
	},
	SocialsAndAppsConnection:{
		pageInfo:"PageInfo",
		edges:"SocialsAndAppsEdge"
	},
	SocialsAndAppsEdge:{
		node:"SocialsAndApps"
	},
	ProductGridConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridEdge"
	},
	ProductGridEdge:{
		node:"ProductGrid"
	},
	GalleryConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryEdge"
	},
	GalleryEdge:{
		node:"Gallery"
	},
	HeroConnection:{
		pageInfo:"PageInfo",
		edges:"HeroEdge"
	},
	HeroEdge:{
		node:"Hero"
	},
	SiteMenuConnection:{
		pageInfo:"PageInfo",
		edges:"SiteMenuEdge"
	},
	SiteMenuEdge:{
		node:"SiteMenu"
	},
	PageConnection:{
		pageInfo:"PageInfo",
		edges:"PageEdge"
	},
	PageEdge:{
		node:"Page"
	},
	MenuLinkListConnection:{
		pageInfo:"PageInfo",
		edges:"MenuLinkListEdge"
	},
	MenuLinkListEdge:{
		node:"MenuLinkList"
	},
	MenuProductsConnection:{
		pageInfo:"PageInfo",
		edges:"MenuProductsEdge"
	},
	MenuProductsEdge:{
		node:"MenuProducts"
	},
	MenuDeliveryConnection:{
		pageInfo:"PageInfo",
		edges:"MenuDeliveryEdge"
	},
	MenuDeliveryEdge:{
		node:"MenuDelivery"
	},
	MenuContactConnection:{
		pageInfo:"PageInfo",
		edges:"MenuContactEdge"
	},
	MenuContactEdge:{
		node:"MenuContact"
	},
	ProductBannerConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerEdge"
	},
	ProductBannerEdge:{
		node:"ProductBanner"
	},
	ProductBannerListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerListEdge"
	},
	ProductBannerListEdge:{
		node:"ProductBannerList"
	},
	GlobalConfigConnection:{
		pageInfo:"PageInfo",
		edges:"GlobalConfigEdge"
	},
	GlobalConfigEdge:{
		node:"GlobalConfig"
	},
	WeekHoursConnection:{
		pageInfo:"PageInfo",
		edges:"WeekHoursEdge"
	},
	WeekHoursEdge:{
		node:"WeekHours"
	},
	DeliveryTimelinePresetConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryTimelinePresetEdge"
	},
	DeliveryTimelinePresetEdge:{
		node:"DeliveryTimelinePreset"
	},
	VirtualProductConnection:{
		pageInfo:"PageInfo",
		edges:"VirtualProductEdge"
	},
	VirtualProductEdge:{
		node:"VirtualProduct"
	},
	ProductMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"ProductMetadataEdge"
	},
	ProductMetadataEdge:{
		node:"ProductMetadata"
	},
	RedirectConnection:{
		pageInfo:"PageInfo",
		edges:"RedirectEdge"
	},
	RedirectEdge:{
		node:"Redirect"
	},
	TextListConnection:{
		pageInfo:"PageInfo",
		edges:"TextListEdge"
	},
	TextListEdge:{
		node:"TextList"
	},
	DeliveryRegionsConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryRegionsEdge"
	},
	DeliveryRegionsEdge:{
		node:"DeliveryRegions"
	},
	ImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListEdge"
	},
	ImageListEdge:{
		node:"ImageList"
	},
	QueryTransaction:{
		getCountry:"Country",
		listCountry:"Country",
		paginateCountry:"CountryConnection",
		getChannel:"Channel",
		listChannel:"Channel",
		paginateChannel:"ChannelConnection",
		getDeliveryMethod:"DeliveryMethod",
		listDeliveryMethod:"DeliveryMethod",
		paginateDeliveryMethod:"DeliveryMethodConnection",
		getPaymentMethod:"PaymentMethod",
		listPaymentMethod:"PaymentMethod",
		paginatePaymentMethod:"PaymentMethodConnection",
		getVatRate:"VatRate",
		listVatRate:"VatRate",
		paginateVatRate:"VatRateConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductStock:"ProductStock",
		listProductStock:"ProductStock",
		paginateProductStock:"ProductStockConnection",
		getProductPacking:"ProductPacking",
		listProductPacking:"ProductPacking",
		paginateProductPacking:"ProductPackingConnection",
		getProductRecipe:"ProductRecipe",
		listProductRecipe:"ProductRecipe",
		paginateProductRecipe:"ProductRecipeConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getBusinessCategory:"BusinessCategory",
		listBusinessCategory:"BusinessCategory",
		paginateBusinessCategory:"BusinessCategoryConnection",
		getProductList:"ProductList",
		listProductList:"ProductList",
		paginateProductList:"ProductListConnection",
		getProductListItem:"ProductListItem",
		listProductListItem:"ProductListItem",
		paginateProductListItem:"ProductListItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getProductCategoryListItem:"ProductCategoryListItem",
		listProductCategoryListItem:"ProductCategoryListItem",
		paginateProductCategoryListItem:"ProductCategoryListItemConnection",
		getBusinessCategoryLocale:"BusinessCategoryLocale",
		listBusinessCategoryLocale:"BusinessCategoryLocale",
		paginateBusinessCategoryLocale:"BusinessCategoryLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getProductCategoryListLocale:"ProductCategoryListLocale",
		listProductCategoryListLocale:"ProductCategoryListLocale",
		paginateProductCategoryListLocale:"ProductCategoryListLocaleConnection",
		getProductIngredientItem:"ProductIngredientItem",
		listProductIngredientItem:"ProductIngredientItem",
		paginateProductIngredientItem:"ProductIngredientItemConnection",
		getProductIngredient:"ProductIngredient",
		listProductIngredient:"ProductIngredient",
		paginateProductIngredient:"ProductIngredientConnection",
		getProductIngredientRating:"ProductIngredientRating",
		listProductIngredientRating:"ProductIngredientRating",
		paginateProductIngredientRating:"ProductIngredientRatingConnection",
		getProductIngredientLocale:"ProductIngredientLocale",
		listProductIngredientLocale:"ProductIngredientLocale",
		paginateProductIngredientLocale:"ProductIngredientLocaleConnection",
		getOrderRecurrence:"OrderRecurrence",
		listOrderRecurrence:"OrderRecurrence",
		paginateOrderRecurrence:"OrderRecurrenceConnection",
		getOrderRecurrenceLocale:"OrderRecurrenceLocale",
		listOrderRecurrenceLocale:"OrderRecurrenceLocale",
		paginateOrderRecurrenceLocale:"OrderRecurrenceLocaleConnection",
		getBlog:"Blog",
		listBlog:"Blog",
		paginateBlog:"BlogConnection",
		getBlogLocale:"BlogLocale",
		listBlogLocale:"BlogLocale",
		paginateBlogLocale:"BlogLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getColorPallete:"ColorPallete",
		listColorPallete:"ColorPallete",
		paginateColorPallete:"ColorPalleteConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getBlogPostList:"BlogPostList",
		listBlogPostList:"BlogPostList",
		paginateBlogPostList:"BlogPostListConnection",
		getBlogPostListItem:"BlogPostListItem",
		listBlogPostListItem:"BlogPostListItem",
		paginateBlogPostListItem:"BlogPostListItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getCover:"Cover",
		listCover:"Cover",
		paginateCover:"CoverConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getSocialsAndApps:"SocialsAndApps",
		listSocialsAndApps:"SocialsAndApps",
		paginateSocialsAndApps:"SocialsAndAppsConnection",
		getProductGrid:"ProductGrid",
		listProductGrid:"ProductGrid",
		paginateProductGrid:"ProductGridConnection",
		getProductGridItem:"ProductGridItem",
		listProductGridItem:"ProductGridItem",
		paginateProductGridItem:"ProductGridItemConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getHero:"Hero",
		listHero:"Hero",
		paginateHero:"HeroConnection",
		getSiteMenu:"SiteMenu",
		listSiteMenu:"SiteMenu",
		paginateSiteMenu:"SiteMenuConnection",
		getMenuLocale:"MenuLocale",
		listMenuLocale:"MenuLocale",
		paginateMenuLocale:"MenuLocaleConnection",
		getMenuItem:"MenuItem",
		listMenuItem:"MenuItem",
		paginateMenuItem:"MenuItemConnection",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		getWikiPage:"WikiPage",
		listWikiPage:"WikiPage",
		paginateWikiPage:"WikiPageConnection",
		getMenuSubitem:"MenuSubitem",
		listMenuSubitem:"MenuSubitem",
		paginateMenuSubitem:"MenuSubitemConnection",
		getMenuLinkList:"MenuLinkList",
		listMenuLinkList:"MenuLinkList",
		paginateMenuLinkList:"MenuLinkListConnection",
		getMenuLinkItem:"MenuLinkItem",
		listMenuLinkItem:"MenuLinkItem",
		paginateMenuLinkItem:"MenuLinkItemConnection",
		getMenuProducts:"MenuProducts",
		listMenuProducts:"MenuProducts",
		paginateMenuProducts:"MenuProductsConnection",
		getMenuDelivery:"MenuDelivery",
		listMenuDelivery:"MenuDelivery",
		paginateMenuDelivery:"MenuDeliveryConnection",
		getMenuContact:"MenuContact",
		listMenuContact:"MenuContact",
		paginateMenuContact:"MenuContactConnection",
		getProductBanner:"ProductBanner",
		listProductBanner:"ProductBanner",
		paginateProductBanner:"ProductBannerConnection",
		getProductBannerItem:"ProductBannerItem",
		listProductBannerItem:"ProductBannerItem",
		paginateProductBannerItem:"ProductBannerItemConnection",
		getProductBannerList:"ProductBannerList",
		listProductBannerList:"ProductBannerList",
		paginateProductBannerList:"ProductBannerListConnection",
		getAllergen:"Allergen",
		listAllergen:"Allergen",
		paginateAllergen:"AllergenConnection",
		getAllergenLocale:"AllergenLocale",
		listAllergenLocale:"AllergenLocale",
		paginateAllergenLocale:"AllergenLocaleConnection",
		getGlobalConfig:"GlobalConfig",
		listGlobalConfig:"GlobalConfig",
		paginateGlobalConfig:"GlobalConfigConnection",
		getWeekHours:"WeekHours",
		listWeekHours:"WeekHours",
		paginateWeekHours:"WeekHoursConnection",
		getDeliveryZone:"DeliveryZone",
		listDeliveryZone:"DeliveryZone",
		paginateDeliveryZone:"DeliveryZoneConnection",
		getDeliveryTimelinePreset:"DeliveryTimelinePreset",
		listDeliveryTimelinePreset:"DeliveryTimelinePreset",
		paginateDeliveryTimelinePreset:"DeliveryTimelinePresetConnection",
		getProductPackingLocale:"ProductPackingLocale",
		listProductPackingLocale:"ProductPackingLocale",
		paginateProductPackingLocale:"ProductPackingLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductRecipeLocale:"ProductRecipeLocale",
		listProductRecipeLocale:"ProductRecipeLocale",
		paginateProductRecipeLocale:"ProductRecipeLocaleConnection",
		getVirtualProduct:"VirtualProduct",
		listVirtualProduct:"VirtualProduct",
		paginateVirtualProduct:"VirtualProductConnection",
		getProductMetadata:"ProductMetadata",
		listProductMetadata:"ProductMetadata",
		paginateProductMetadata:"ProductMetadataConnection",
		getProductGridCategory:"ProductGridCategory",
		listProductGridCategory:"ProductGridCategory",
		paginateProductGridCategory:"ProductGridCategoryConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getFreshingContainer:"FreshingContainer",
		listFreshingContainer:"FreshingContainer",
		paginateFreshingContainer:"FreshingContainerConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getDeliveryRegions:"DeliveryRegions",
		listDeliveryRegions:"DeliveryRegions",
		paginateDeliveryRegions:"DeliveryRegionsConnection",
		getDeliveryRegionsItem:"DeliveryRegionsItem",
		listDeliveryRegionsItem:"DeliveryRegionsItem",
		paginateDeliveryRegionsItem:"DeliveryRegionsItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getDeliveryMethodLocale:"DeliveryMethodLocale",
		listDeliveryMethodLocale:"DeliveryMethodLocale",
		paginateDeliveryMethodLocale:"DeliveryMethodLocaleConnection"
	},
	Info:{
		description:"String"
	},
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_Entity:{
		name:"String",
		customPrimaryAllowed:"Boolean",
		fields:"_Field",
		unique:"_UniqueConstraint"
	},
	_UniqueConstraint:{
		fields:"String"
	},
	_Field:{
		"...on _Column": "_Column",
		"...on _Relation": "_Relation",
		name:"String",
		type:"String",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Column:{
		name:"String",
		type:"String",
		enumName:"String",
		defaultValue:"Json",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_OrderBy:{
		path:"String",
		direction:"_OrderByDirection"
	},
	_Relation:{
		name:"String",
		type:"String",
		side:"_RelationSide",
		targetEntity:"String",
		ownedBy:"String",
		inversedBy:"String",
		nullable:"Boolean",
		onDelete:"_OnDeleteBehaviour",
		orphanRemoval:"Boolean",
		orderBy:"_OrderBy",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	_PathArgument:{
		path:"String"
	},
	_LiteralArgument:{
		value:"Json"
	},
	_RuleMessage:{
		text:"String"
	},
	_Enum:{
		name:"String",
		values:"String"
	}
}

export const Ops = {
query: "Query" as const
}


export const HOST="Specify host"



const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, unknown> = {}) => {
  const fetchOptions = options[1] || {};
  if (fetchOptions.method && fetchOptions.method === 'GET') {
    return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  }
  return fetch(`${options[0]}`, {
    body: JSON.stringify({ query, variables }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...fetchOptions,
  })
    .then(handleFetchResponse)
    .then((response: GraphQLResponse) => {
      if (response.errors) {
        throw new GraphQLError(response);
      }
      return response.data;
    });
};




export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};






export const InternalsBuildQuery = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  options?: OperationOptions,
) => {
  const ibb = (k: string, o: InputValueType | VType, p = '', root = true): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt(props, returns, ops, options?.variables?.values)(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const hasVariables = root && options?.variables?.$params ? `(${options.variables?.$params})` : '';
    const keyForDirectives = o.__directives ?? '';
    return `${k} ${keyForDirectives}${hasOperationName}${hasVariables}{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false))
      .join('\n')}}`;
  };
  return ibb;
};










export const Thunder = (fn: FetchFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation)(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullSubscriptionConstruct(fn)(operation)(o as any, ops) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: OperationOptions,
) => InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, ops)(operation, o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();

export const Gql = Chain(HOST);






export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o), options?.variables?.values);
};






export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o));
};





export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | boolean
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string | undefined>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions<Z extends Record<string, unknown> = Record<string, unknown>> = {
  variables?: VariableInput<Z>;
  operationName?: string;
};

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;


export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');




const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (typeof propsP1 === 'boolean' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'object') {
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  variables?: Record<string, unknown>,
) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (a.startsWith('$') && variables?.[a.slice(1)]) {
        return a;
      }
      const checkType = ResolveFromPath(props, returns, ops)(p);
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};




export const resolverFor = <X, T extends keyof ValueTypes, Z extends keyof ValueTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;


export type SelectionFunction<V> = <T>(t: T | V) => T;
export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;




export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : Record<string, unknown>
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>[keyof MapType<SRC, R[P]>];
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};


export const useZeusVariables =
  <T extends Record<string, unknown>>(variables: T) =>
  <
    Z extends {
      [P in keyof T]: unknown;
    },
  >(
    values: Z,
  ) => {
    return {
      $params: Object.keys(variables)
        .map((k) => `$${k}: ${variables[k as keyof T]}`)
        .join(', '),
      $: <U extends keyof Z>(variable: U) => {
        return `$${String(variable)}` as unknown as Z[U];
      },
      values,
    };
  };

export type VariableInput<Z extends Record<string, unknown>> = {
  $params: ReturnType<ReturnType<typeof useZeusVariables>>['$params'];
  values: Z;
};


type ZEUS_INTERFACES = GraphQLTypes["_Field"]
type ZEUS_UNIONS = GraphQLTypes["_Argument"]

export type ValueTypes = {
    ["Query"]: AliasType<{
getCountry?: [{	by: ValueTypes["CountryUniqueWhere"],	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
listCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Country"]],
paginateCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CountryConnection"]],
getChannel?: [{	by: ValueTypes["ChannelUniqueWhere"],	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
listChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
paginateChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
getDeliveryMethod?: [{	by: ValueTypes["DeliveryMethodUniqueWhere"],	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
listDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
paginateDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
getPaymentMethod?: [{	by: ValueTypes["PaymentMethodUniqueWhere"],	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
listPaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
paginatePaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
getVatRate?: [{	by: ValueTypes["VatRateUniqueWhere"],	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
listVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VatRate"]],
paginateVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VatRateConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
getProductStock?: [{	by: ValueTypes["ProductStockUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
listProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
paginateProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
getProductPacking?: [{	by: ValueTypes["ProductPackingUniqueWhere"],	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
listProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPacking"]],
paginateProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingConnection"]],
getProductRecipe?: [{	by: ValueTypes["ProductRecipeUniqueWhere"],	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
listProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipe"]],
paginateProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
getStore?: [{	by: ValueTypes["StoreUniqueWhere"],	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
listStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Store"]],
paginateStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StoreConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"],	filter?: ValueTypes["TagWhere"] | undefined | null},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"],	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageConnection"]],
getBusinessCategory?: [{	by: ValueTypes["BusinessCategoryUniqueWhere"],	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
listBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
getProductList?: [{	by: ValueTypes["ProductListUniqueWhere"],	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
listProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductList"]],
paginateProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListConnection"]],
getProductListItem?: [{	by: ValueTypes["ProductListItemUniqueWhere"],	filter?: ValueTypes["ProductListItemWhere"] | undefined | null},ValueTypes["ProductListItem"]],
listProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
paginateProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"],	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListConnection"]],
getProductCategoryListItem?: [{	by: ValueTypes["ProductCategoryListItemUniqueWhere"],	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null},ValueTypes["ProductCategoryListItem"]],
listProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
paginateProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
getBusinessCategoryLocale?: [{	by: ValueTypes["BusinessCategoryLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
listBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"],	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LocaleConnection"]],
getProductCategoryListLocale?: [{	by: ValueTypes["ProductCategoryListLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
listProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
getProductIngredientItem?: [{	by: ValueTypes["ProductIngredientItemUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null},ValueTypes["ProductIngredientItem"]],
listProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
paginateProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
getProductIngredient?: [{	by: ValueTypes["ProductIngredientUniqueWhere"],	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
listProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
paginateProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
getProductIngredientRating?: [{	by: ValueTypes["ProductIngredientRatingUniqueWhere"],	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null},ValueTypes["ProductIngredientRating"]],
listProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientRating"]],
paginateProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientRatingConnection"]],
getProductIngredientLocale?: [{	by: ValueTypes["ProductIngredientLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
listProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
getOrderRecurrence?: [{	by: ValueTypes["OrderRecurrenceUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
listOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
paginateOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
getOrderRecurrenceLocale?: [{	by: ValueTypes["OrderRecurrenceLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
listOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
getBlog?: [{	by: ValueTypes["BlogUniqueWhere"],	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
listBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Blog"]],
paginateBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogConnection"]],
getBlogLocale?: [{	by: ValueTypes["BlogLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
listBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
paginateBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"],	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
getColorPallete?: [{	by: ValueTypes["ColorPalleteUniqueWhere"],	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
listColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ColorPallete"]],
paginateColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ColorPalleteConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"],	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
getBlogPostList?: [{	by: ValueTypes["BlogPostListUniqueWhere"],	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
listBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostList"]],
paginateBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListConnection"]],
getBlogPostListItem?: [{	by: ValueTypes["BlogPostListItemUniqueWhere"],	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null},ValueTypes["BlogPostListItem"]],
listBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"],	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"],	filter?: ValueTypes["LinkListItemWhere"] | undefined | null},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
getCover?: [{	by: ValueTypes["CoverUniqueWhere"],	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
listCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cover"]],
paginateCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CoverConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"],	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"],	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkableConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"],	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MediumConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"],	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VideoConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"],	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SeoConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"],	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationDomainConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getSocialsAndApps?: [{	by: ValueTypes["SocialsAndAppsUniqueWhere"],	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
listSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SocialsAndApps"]],
paginateSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SocialsAndAppsConnection"]],
getProductGrid?: [{	by: ValueTypes["ProductGridUniqueWhere"],	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
listProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGrid"]],
paginateProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridConnection"]],
getProductGridItem?: [{	by: ValueTypes["ProductGridItemUniqueWhere"],	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null},ValueTypes["ProductGridItem"]],
listProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
paginateProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"],	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"],	filter?: ValueTypes["GalleryItemWhere"] | undefined | null},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
getHero?: [{	by: ValueTypes["HeroUniqueWhere"],	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
listHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Hero"]],
paginateHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["HeroConnection"]],
getSiteMenu?: [{	by: ValueTypes["SiteMenuUniqueWhere"],	filter?: ValueTypes["SiteMenuWhere"] | undefined | null},ValueTypes["SiteMenu"]],
listSiteMenu?: [{	filter?: ValueTypes["SiteMenuWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SiteMenuOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SiteMenu"]],
paginateSiteMenu?: [{	filter?: ValueTypes["SiteMenuWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SiteMenuOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SiteMenuConnection"]],
getMenuLocale?: [{	by: ValueTypes["MenuLocaleUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
listMenuLocale?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLocale"]],
paginateMenuLocale?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLocaleConnection"]],
getMenuItem?: [{	by: ValueTypes["MenuItemUniqueWhere"],	filter?: ValueTypes["MenuItemWhere"] | undefined | null},ValueTypes["MenuItem"]],
listMenuItem?: [{	filter?: ValueTypes["MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuItem"]],
paginateMenuItem?: [{	filter?: ValueTypes["MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuItemConnection"]],
getPage?: [{	by: ValueTypes["PageUniqueWhere"],	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
listPage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Page"]],
paginatePage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageConnection"]],
getPageLocale?: [{	by: ValueTypes["PageLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
getWikiPage?: [{	by: ValueTypes["WikiPageUniqueWhere"],	filter?: ValueTypes["WikiPageWhere"] | undefined | null},ValueTypes["WikiPage"]],
listWikiPage?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WikiPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WikiPage"]],
paginateWikiPage?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WikiPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WikiPageConnection"]],
getMenuSubitem?: [{	by: ValueTypes["MenuSubitemUniqueWhere"],	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null},ValueTypes["MenuSubitem"]],
listMenuSubitem?: [{	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuSubitemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuSubitem"]],
paginateMenuSubitem?: [{	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuSubitemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuSubitemConnection"]],
getMenuLinkList?: [{	by: ValueTypes["MenuLinkListUniqueWhere"],	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null},ValueTypes["MenuLinkList"]],
listMenuLinkList?: [{	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLinkList"]],
paginateMenuLinkList?: [{	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLinkListConnection"]],
getMenuLinkItem?: [{	by: ValueTypes["MenuLinkItemUniqueWhere"],	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null},ValueTypes["MenuLinkItem"]],
listMenuLinkItem?: [{	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLinkItem"]],
paginateMenuLinkItem?: [{	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLinkItemConnection"]],
getMenuProducts?: [{	by: ValueTypes["MenuProductsUniqueWhere"],	filter?: ValueTypes["MenuProductsWhere"] | undefined | null},ValueTypes["MenuProducts"]],
listMenuProducts?: [{	filter?: ValueTypes["MenuProductsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuProductsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuProducts"]],
paginateMenuProducts?: [{	filter?: ValueTypes["MenuProductsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuProductsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuProductsConnection"]],
getMenuDelivery?: [{	by: ValueTypes["MenuDeliveryUniqueWhere"],	filter?: ValueTypes["MenuDeliveryWhere"] | undefined | null},ValueTypes["MenuDelivery"]],
listMenuDelivery?: [{	filter?: ValueTypes["MenuDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuDelivery"]],
paginateMenuDelivery?: [{	filter?: ValueTypes["MenuDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuDeliveryConnection"]],
getMenuContact?: [{	by: ValueTypes["MenuContactUniqueWhere"],	filter?: ValueTypes["MenuContactWhere"] | undefined | null},ValueTypes["MenuContact"]],
listMenuContact?: [{	filter?: ValueTypes["MenuContactWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuContactOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuContact"]],
paginateMenuContact?: [{	filter?: ValueTypes["MenuContactWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuContactOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuContactConnection"]],
getProductBanner?: [{	by: ValueTypes["ProductBannerUniqueWhere"],	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
listProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBanner"]],
paginateProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerConnection"]],
getProductBannerItem?: [{	by: ValueTypes["ProductBannerItemUniqueWhere"],	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null},ValueTypes["ProductBannerItem"]],
listProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
getProductBannerList?: [{	by: ValueTypes["ProductBannerListUniqueWhere"],	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
listProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerList"]],
paginateProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerListConnection"]],
getAllergen?: [{	by: ValueTypes["AllergenUniqueWhere"],	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
listAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
paginateAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
getAllergenLocale?: [{	by: ValueTypes["AllergenLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
listAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
paginateAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
getGlobalConfig?: [{	by: ValueTypes["GlobalConfigUniqueWhere"],	filter?: ValueTypes["GlobalConfigWhere"] | undefined | null},ValueTypes["GlobalConfig"]],
listGlobalConfig?: [{	filter?: ValueTypes["GlobalConfigWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GlobalConfigOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GlobalConfig"]],
paginateGlobalConfig?: [{	filter?: ValueTypes["GlobalConfigWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GlobalConfigOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GlobalConfigConnection"]],
getWeekHours?: [{	by: ValueTypes["WeekHoursUniqueWhere"],	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
listWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WeekHours"]],
paginateWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WeekHoursConnection"]],
getDeliveryZone?: [{	by: ValueTypes["DeliveryZoneUniqueWhere"],	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
listDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
paginateDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
getDeliveryTimelinePreset?: [{	by: ValueTypes["DeliveryTimelinePresetUniqueWhere"],	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
listDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
paginateDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryTimelinePresetConnection"]],
getProductPackingLocale?: [{	by: ValueTypes["ProductPackingLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
listProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
getProductRecipeLocale?: [{	by: ValueTypes["ProductRecipeLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
listProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
getVirtualProduct?: [{	by: ValueTypes["VirtualProductUniqueWhere"],	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
listVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProduct"]],
paginateVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductConnection"]],
getProductMetadata?: [{	by: ValueTypes["ProductMetadataUniqueWhere"],	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
listProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductMetadata"]],
paginateProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductMetadataConnection"]],
getProductGridCategory?: [{	by: ValueTypes["ProductGridCategoryUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
listProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
paginateProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"],	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["RedirectConnection"]],
getFreshingContainer?: [{	by: ValueTypes["FreshingContainerUniqueWhere"],	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
listFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
paginateFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"],	filter?: ValueTypes["TextItemWhere"] | undefined | null},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"],	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextListConnection"]],
getDeliveryRegions?: [{	by: ValueTypes["DeliveryRegionsUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
listDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegions"]],
paginateDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsConnection"]],
getDeliveryRegionsItem?: [{	by: ValueTypes["DeliveryRegionsItemUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
listDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"],	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
getDeliveryMethodLocale?: [{	by: ValueTypes["DeliveryMethodLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
listDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginateDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
	schema?:ValueTypes["_Schema"],
		__typename?: boolean | `@${string}`
}>;
	["Country"]: AliasType<{
	_meta?:ValueTypes["CountryMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CountryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:string;
	["CountryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["CountryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["CountryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CountryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CountryWhere"] | undefined | null
};
	["UUIDCondition"]: {
	and?: Array<ValueTypes["UUIDCondition"]> | undefined | null,
	or?: Array<ValueTypes["UUIDCondition"]> | undefined | null,
	not?: ValueTypes["UUIDCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["UUID"] | undefined | null,
	notEq?: ValueTypes["UUID"] | undefined | null,
	in?: Array<ValueTypes["UUID"]> | undefined | null,
	notIn?: Array<ValueTypes["UUID"]> | undefined | null,
	lt?: ValueTypes["UUID"] | undefined | null,
	lte?: ValueTypes["UUID"] | undefined | null,
	gt?: ValueTypes["UUID"] | undefined | null,
	gte?: ValueTypes["UUID"] | undefined | null
};
	["StringCondition"]: {
	and?: Array<ValueTypes["StringCondition"]> | undefined | null,
	or?: Array<ValueTypes["StringCondition"]> | undefined | null,
	not?: ValueTypes["StringCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: string | undefined | null,
	notEq?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	containsCI?: string | undefined | null,
	startsWithCI?: string | undefined | null,
	endsWithCI?: string | undefined | null
};
	["CountryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderDirection"]:OrderDirection;
	["CountryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CountryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CountryEdge"]: AliasType<{
	node?:ValueTypes["Country"],
		__typename?: boolean | `@${string}`
}>;
	["Channel"]: AliasType<{
	_meta?:ValueTypes["ChannelMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	disabled?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
publicTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginatePublicTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ChannelMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	disabled?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	addTagsOnRegistration?:ValueTypes["FieldMeta"],
	publicTags?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelCode"]:ChannelCode;
	["Tag"]: AliasType<{
	_meta?:ValueTypes["TagMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
categories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
deliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
publicInChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateCategories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginatePublicInChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TagMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	addTagsOnRegistration?:ValueTypes["FieldMeta"],
	deliveryZones?:ValueTypes["FieldMeta"],
	publicInChannels?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	_meta?:ValueTypes["ProductMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	notDiscountable?:boolean | `@${string}`,
packing?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
categories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
stocks?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	availability?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	maximumQuantityPerOrder?:boolean | `@${string}`,
deliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
locales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
	archivedAt?:boolean | `@${string}`,
virtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
meta?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
page?: [{	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
stocksByStore?: [{	by: ValueTypes["ProductStocksByStoreUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
localesByLocale?: [{	by: ValueTypes["ProductLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
localesByFeatureList?: [{	by: ValueTypes["ProductLocalesByFeatureListUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateCategories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
paginateStocks?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	notDiscountable?:ValueTypes["FieldMeta"],
	packing?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	stocks?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	availability?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	maximumQuantityPerOrder?:ValueTypes["FieldMeta"],
	deliveryZones?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	archivedAt?:ValueTypes["FieldMeta"],
	virtualProduct?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPacking"]: AliasType<{
	_meta?:ValueTypes["ProductPackingMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	volumeMl?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	shortName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
	isPackedInCardboard?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["ProductPackingLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	volumeMl?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	shortName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	isPackedInCardboard?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	url?: ValueTypes["StringCondition"] | undefined | null,
	width?: ValueTypes["IntCondition"] | undefined | null,
	height?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StringCondition"] | undefined | null,
	alt?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageWhere"] | undefined | null
};
	["IntCondition"]: {
	and?: Array<ValueTypes["IntCondition"]> | undefined | null,
	or?: Array<ValueTypes["IntCondition"]> | undefined | null,
	not?: ValueTypes["IntCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ProductPackingLocale"]: AliasType<{
	_meta?:ValueTypes["ProductPackingLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	shortName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	shortName?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	volumeMl?: ValueTypes["IntCondition"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	shortName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,
	isPackedInCardboard?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductPackingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductPackingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductPackingWhere"] | undefined | null
};
	["ProductPackingLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	shortName?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductPackingWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null
};
	["LocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	businessCategories?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,
	productIngredients?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,
	blogs?: ValueTypes["BlogLocaleWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	menus?: ValueTypes["MenuLocaleWhere"] | undefined | null,
	pages?: ValueTypes["PageLocaleWhere"] | undefined | null,
	allergens?: ValueTypes["AllergenLocaleWhere"] | undefined | null,
	productPackings?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,
	products?: ValueTypes["ProductLocaleWhere"] | undefined | null,
	productRecipes?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LocaleWhere"] | undefined | null
};
	["BusinessCategoryLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null
};
	["BusinessCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	recurrences?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,
	specificProducts?: ValueTypes["ProductListWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	slug?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["BusinessCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BusinessCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BusinessCategoryWhere"] | undefined | null
};
	["BooleanCondition"]: {
	and?: Array<ValueTypes["BooleanCondition"]> | undefined | null,
	or?: Array<ValueTypes["BooleanCondition"]> | undefined | null,
	not?: ValueTypes["BooleanCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: boolean | undefined | null,
	notEq?: boolean | undefined | null,
	in?: Array<boolean> | undefined | null,
	notIn?: Array<boolean> | undefined | null,
	lt?: boolean | undefined | null,
	lte?: boolean | undefined | null,
	gt?: boolean | undefined | null,
	gte?: boolean | undefined | null
};
	["OrderRecurrenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderRecurrenceWhere"] | undefined | null
};
	["OrderRecurrenceLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null
};
	["ProductListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductListItemWhere"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductListWhere"] | undefined | null
};
	["ProductListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductListWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductListItemWhere"] | undefined | null
};
	["ProductWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	notDiscountable?: ValueTypes["BooleanCondition"] | undefined | null,
	packing?: ValueTypes["ProductPackingWhere"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	categories?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	stocks?: ValueTypes["ProductStockWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	availability?: ValueTypes["ProductAvailabilityCondition"] | undefined | null,
	order?: ValueTypes["FloatCondition"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["IntCondition"] | undefined | null,
	deliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	locales?: ValueTypes["ProductLocaleWhere"] | undefined | null,
	archivedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductWhere"] | undefined | null,
	meta?: ValueTypes["ProductMetadataWhere"] | undefined | null,
	page?: ValueTypes["PageWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductWhere"] | undefined | null
};
	["ProductRecipeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	color?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,
	needsPrepping?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,
	gutTuning?: ValueTypes["FloatCondition"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductRecipeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductRecipeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductRecipeWhere"] | undefined | null
};
	["ProductIngredientItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	internalOrder?: ValueTypes["IntCondition"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientItemWhere"] | undefined | null
};
	["ProductIngredientWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	allergens?: ValueTypes["AllergenWhere"] | undefined | null,
	needsPrepping?: ValueTypes["BooleanCondition"] | undefined | null,
	allowCustomerRating?: ValueTypes["BooleanCondition"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientWhere"] | undefined | null
};
	["ProductIngredientLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null
};
	["AllergenWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["AllergenLocaleWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	code?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["AllergenWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AllergenWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AllergenWhere"] | undefined | null
};
	["AllergenLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["AllergenWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["AllergenLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AllergenLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AllergenLocaleWhere"] | undefined | null
};
	["FreshingContainerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	config?: ValueTypes["GlobalConfigWhere"] | undefined | null,
	volumeMl?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["FreshingContainerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["FreshingContainerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["FreshingContainerWhere"] | undefined | null
};
	["GlobalConfigWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	orderDeadlineTime?: ValueTypes["StringCondition"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["IntCondition"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursWhere"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursWhere"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["IntCondition"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	orderUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductWhere"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	freshingContainers?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	messengerUrl?: ValueTypes["StringCondition"] | undefined | null,
	messengerIosUrl?: ValueTypes["StringCondition"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["StringCondition"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["GlobalConfigWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GlobalConfigWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GlobalConfigWhere"] | undefined | null
};
	["WeekHoursWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	mon?: ValueTypes["StringCondition"] | undefined | null,
	tue?: ValueTypes["StringCondition"] | undefined | null,
	wed?: ValueTypes["StringCondition"] | undefined | null,
	thu?: ValueTypes["StringCondition"] | undefined | null,
	fri?: ValueTypes["StringCondition"] | undefined | null,
	sat?: ValueTypes["StringCondition"] | undefined | null,
	sun?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["WeekHoursWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["WeekHoursWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["WeekHoursWhere"] | undefined | null
};
	["OneCondition"]: {
	and?: Array<ValueTypes["OneCondition"]> | undefined | null,
	or?: Array<ValueTypes["OneCondition"]> | undefined | null,
	not?: ValueTypes["OneCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["One"] | undefined | null,
	notEq?: ValueTypes["One"] | undefined | null,
	in?: Array<ValueTypes["One"]> | undefined | null,
	notIn?: Array<ValueTypes["One"]> | undefined | null,
	lt?: ValueTypes["One"] | undefined | null,
	lte?: ValueTypes["One"] | undefined | null,
	gt?: ValueTypes["One"] | undefined | null,
	gte?: ValueTypes["One"] | undefined | null
};
	["One"]:One;
	["VirtualProductWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["VirtualProductTypeCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	voucherCreditCents?: ValueTypes["IntCondition"] | undefined | null,
	physicalRepresentation?: ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	recipientEmail?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["VirtualProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VirtualProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VirtualProductWhere"] | undefined | null
};
	["VirtualProductTypeCondition"]: {
	and?: Array<ValueTypes["VirtualProductTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["VirtualProductTypeCondition"]> | undefined | null,
	not?: ValueTypes["VirtualProductTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VirtualProductType"] | undefined | null,
	notEq?: ValueTypes["VirtualProductType"] | undefined | null,
	in?: Array<ValueTypes["VirtualProductType"]> | undefined | null,
	notIn?: Array<ValueTypes["VirtualProductType"]> | undefined | null,
	lt?: ValueTypes["VirtualProductType"] | undefined | null,
	lte?: ValueTypes["VirtualProductType"] | undefined | null,
	gt?: ValueTypes["VirtualProductType"] | undefined | null,
	gte?: ValueTypes["VirtualProductType"] | undefined | null
};
	["VirtualProductType"]:VirtualProductType;
	["VirtualProductPhysicalRepresentationTypeCondition"]: {
	and?: Array<ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	not?: ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	notEq?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	in?: Array<ValueTypes["VirtualProductPhysicalRepresentationType"]> | undefined | null,
	notIn?: Array<ValueTypes["VirtualProductPhysicalRepresentationType"]> | undefined | null,
	lt?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	lte?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	gt?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	gte?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null
};
	["VirtualProductPhysicalRepresentationType"]:VirtualProductPhysicalRepresentationType;
	["VatRateWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	ratePermille?: ValueTypes["IntCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["VatRateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VatRateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VatRateWhere"] | undefined | null
};
	["ProductRecipeLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null
};
	["FloatCondition"]: {
	and?: Array<ValueTypes["FloatCondition"]> | undefined | null,
	or?: Array<ValueTypes["FloatCondition"]> | undefined | null,
	not?: ValueTypes["FloatCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["TagWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	categories?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["ChannelWhere"] | undefined | null,
	deliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	publicInChannels?: ValueTypes["ChannelWhere"] | undefined | null,
	and?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TagWhere"] | undefined | null
};
	["ProductCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	parent?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	children?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	color?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	order?: ValueTypes["FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryWhere"] | undefined | null
};
	["ChannelWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	disabled?: ValueTypes["BooleanCondition"] | undefined | null,
	code?: ValueTypes["ChannelCodeCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["TagWhere"] | undefined | null,
	publicTags?: ValueTypes["TagWhere"] | undefined | null,
	and?: Array<ValueTypes["ChannelWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ChannelWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ChannelWhere"] | undefined | null
};
	["ChannelCodeCondition"]: {
	and?: Array<ValueTypes["ChannelCodeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ChannelCodeCondition"]> | undefined | null,
	not?: ValueTypes["ChannelCodeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ChannelCode"] | undefined | null,
	notEq?: ValueTypes["ChannelCode"] | undefined | null,
	in?: Array<ValueTypes["ChannelCode"]> | undefined | null,
	notIn?: Array<ValueTypes["ChannelCode"]> | undefined | null,
	lt?: ValueTypes["ChannelCode"] | undefined | null,
	lte?: ValueTypes["ChannelCode"] | undefined | null,
	gt?: ValueTypes["ChannelCode"] | undefined | null,
	gte?: ValueTypes["ChannelCode"] | undefined | null
};
	["DeliveryZoneWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	type?: ValueTypes["DeliveryZoneTypeCondition"] | undefined | null,
	suitableDeliveryMethods?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	deliveryZonesOfTags?: ValueTypes["TagWhere"] | undefined | null,
	deliveryZonesOfProducts?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryZoneWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryZoneWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryZoneWhere"] | undefined | null
};
	["DeliveryZoneTypeCondition"]: {
	and?: Array<ValueTypes["DeliveryZoneTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DeliveryZoneTypeCondition"]> | undefined | null,
	not?: ValueTypes["DeliveryZoneTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DeliveryZoneType"] | undefined | null,
	notEq?: ValueTypes["DeliveryZoneType"] | undefined | null,
	in?: Array<ValueTypes["DeliveryZoneType"]> | undefined | null,
	notIn?: Array<ValueTypes["DeliveryZoneType"]> | undefined | null,
	lt?: ValueTypes["DeliveryZoneType"] | undefined | null,
	lte?: ValueTypes["DeliveryZoneType"] | undefined | null,
	gt?: ValueTypes["DeliveryZoneType"] | undefined | null,
	gte?: ValueTypes["DeliveryZoneType"] | undefined | null
};
	["DeliveryZoneType"]:DeliveryZoneType;
	["DeliveryMethodWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	paymentMethods?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["BooleanCondition"] | undefined | null,
	suitableForDeliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryMethodWhere"] | undefined | null
};
	["PaymentMethodWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	blockingDispatch?: ValueTypes["BooleanCondition"] | undefined | null,
	type?: ValueTypes["PaymentMethodTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["PaymentMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PaymentMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PaymentMethodWhere"] | undefined | null
};
	["PaymentMethodTypeCondition"]: {
	and?: Array<ValueTypes["PaymentMethodTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["PaymentMethodTypeCondition"]> | undefined | null,
	not?: ValueTypes["PaymentMethodTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["PaymentMethodType"] | undefined | null,
	notEq?: ValueTypes["PaymentMethodType"] | undefined | null,
	in?: Array<ValueTypes["PaymentMethodType"]> | undefined | null,
	notIn?: Array<ValueTypes["PaymentMethodType"]> | undefined | null,
	lt?: ValueTypes["PaymentMethodType"] | undefined | null,
	lte?: ValueTypes["PaymentMethodType"] | undefined | null,
	gt?: ValueTypes["PaymentMethodType"] | undefined | null,
	gte?: ValueTypes["PaymentMethodType"] | undefined | null
};
	["PaymentMethodType"]:PaymentMethodType;
	["DeliveryTimelinePresetWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["DeliveryTimelineTypeCondition"] | undefined | null,
	duration?: ValueTypes["StringCondition"] | undefined | null,
	window?: ValueTypes["StringCondition"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null
};
	["DeliveryTimelineTypeCondition"]: {
	and?: Array<ValueTypes["DeliveryTimelineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DeliveryTimelineTypeCondition"]> | undefined | null,
	not?: ValueTypes["DeliveryTimelineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	notEq?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	in?: Array<ValueTypes["DeliveryTimelineType"]> | undefined | null,
	notIn?: Array<ValueTypes["DeliveryTimelineType"]> | undefined | null,
	lt?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	lte?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	gt?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	gte?: ValueTypes["DeliveryTimelineType"] | undefined | null
};
	["DeliveryTimelineType"]:DeliveryTimelineType;
	["DeliveryMethodLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	processingTitle?: ValueTypes["StringCondition"] | undefined | null,
	processingText?: ValueTypes["StringCondition"] | undefined | null,
	processingIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedTitle?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedText?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledTitle?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledText?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	root?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null
};
	["ProductStockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	store?: ValueTypes["StoreWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductStockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductStockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductStockWhere"] | undefined | null
};
	["StoreWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["StoreWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StoreWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StoreWhere"] | undefined | null
};
	["ProductAvailabilityCondition"]: {
	and?: Array<ValueTypes["ProductAvailabilityCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductAvailabilityCondition"]> | undefined | null,
	not?: ValueTypes["ProductAvailabilityCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductAvailability"] | undefined | null,
	notEq?: ValueTypes["ProductAvailability"] | undefined | null,
	in?: Array<ValueTypes["ProductAvailability"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductAvailability"]> | undefined | null,
	lt?: ValueTypes["ProductAvailability"] | undefined | null,
	lte?: ValueTypes["ProductAvailability"] | undefined | null,
	gt?: ValueTypes["ProductAvailability"] | undefined | null,
	gte?: ValueTypes["ProductAvailability"] | undefined | null
};
	["ProductAvailability"]:ProductAvailability;
	["ProductLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	featureList?: ValueTypes["TextListWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductLocaleWhere"] | undefined | null
};
	["TextListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["TextItemWhere"] | undefined | null,
	and?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TextListWhere"] | undefined | null
};
	["TextItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["TextListWhere"] | undefined | null,
	and?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TextItemWhere"] | undefined | null
};
	["DateTimeCondition"]: {
	and?: Array<ValueTypes["DateTimeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DateTimeCondition"]> | undefined | null,
	not?: ValueTypes["DateTimeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DateTime"] | undefined | null,
	notEq?: ValueTypes["DateTime"] | undefined | null,
	in?: Array<ValueTypes["DateTime"]> | undefined | null,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null,
	lt?: ValueTypes["DateTime"] | undefined | null,
	lte?: ValueTypes["DateTime"] | undefined | null,
	gt?: ValueTypes["DateTime"] | undefined | null,
	gte?: ValueTypes["DateTime"] | undefined | null
};
	["DateTime"]:string;
	["ProductMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	archived?: ValueTypes["BooleanCondition"] | undefined | null,
	available?: ValueTypes["BooleanCondition"] | undefined | null,
	availableQuantity?: ValueTypes["IntCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductMetadataWhere"] | undefined | null
};
	["PageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	locales?: ValueTypes["PageLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["PageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PageWhere"] | undefined | null
};
	["PageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	root?: ValueTypes["PageWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	seo?: ValueTypes["SeoWhere"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	cover?: ValueTypes["CoverWhere"] | undefined | null,
	theme?: ValueTypes["PageThemeCondition"] | undefined | null,
	and?: Array<ValueTypes["PageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PageLocaleWhere"] | undefined | null
};
	["LinkableWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	url?: ValueTypes["StringCondition"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	page?: ValueTypes["PageLocaleWhere"] | undefined | null,
	wikiPage?: ValueTypes["WikiPageWhere"] | undefined | null,
	redirect?: ValueTypes["RedirectWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkableWhere"] | undefined | null
};
	["BlogPostLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	availableForWeb?: ValueTypes["BooleanCondition"] | undefined | null,
	availableForMobile?: ValueTypes["BooleanCondition"] | undefined | null,
	root?: ValueTypes["BlogPostWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	cover?: ValueTypes["CoverWhere"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	seo?: ValueTypes["SeoWhere"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostLocaleWhere"] | undefined | null
};
	["BlogPostWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	locales?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostWhere"] | undefined | null
};
	["CoverWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	primaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	secondaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	buttonColors?: ValueTypes["ColorPalleteWhere"] | undefined | null,
	and?: Array<ValueTypes["CoverWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CoverWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CoverWhere"] | undefined | null
};
	["MediumWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["MediumTypeCondition"] | undefined | null,
	colorTheme?: ValueTypes["ColorThemeCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	video?: ValueTypes["VideoWhere"] | undefined | null,
	and?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MediumWhere"] | undefined | null
};
	["MediumTypeCondition"]: {
	and?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null,
	not?: ValueTypes["MediumTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["MediumType"] | undefined | null,
	notEq?: ValueTypes["MediumType"] | undefined | null,
	in?: Array<ValueTypes["MediumType"]> | undefined | null,
	notIn?: Array<ValueTypes["MediumType"]> | undefined | null,
	lt?: ValueTypes["MediumType"] | undefined | null,
	lte?: ValueTypes["MediumType"] | undefined | null,
	gt?: ValueTypes["MediumType"] | undefined | null,
	gte?: ValueTypes["MediumType"] | undefined | null
};
	["MediumType"]:MediumType;
	["ColorThemeCondition"]: {
	and?: Array<ValueTypes["ColorThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ColorThemeCondition"]> | undefined | null,
	not?: ValueTypes["ColorThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ColorTheme"] | undefined | null,
	notEq?: ValueTypes["ColorTheme"] | undefined | null,
	in?: Array<ValueTypes["ColorTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["ColorTheme"]> | undefined | null,
	lt?: ValueTypes["ColorTheme"] | undefined | null,
	lte?: ValueTypes["ColorTheme"] | undefined | null,
	gt?: ValueTypes["ColorTheme"] | undefined | null,
	gte?: ValueTypes["ColorTheme"] | undefined | null
};
	["ColorTheme"]:ColorTheme;
	["VideoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	src?: ValueTypes["StringCondition"] | undefined | null,
	width?: ValueTypes["IntCondition"] | undefined | null,
	height?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StringCondition"] | undefined | null,
	duration?: ValueTypes["FloatCondition"] | undefined | null,
	poster?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VideoWhere"] | undefined | null
};
	["ColorPalleteWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	background?: ValueTypes["StringCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ColorPalleteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ColorPalleteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ColorPalleteWhere"] | undefined | null
};
	["ContentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	blocks?: ValueTypes["ContentBlockWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentWhere"] | undefined | null
};
	["ContentBlockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	json?: ValueTypes["StringCondition"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	references?: ValueTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentBlockWhere"] | undefined | null
};
	["ContentReferenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null,
	primaryText?: ValueTypes["StringCondition"] | undefined | null,
	secondaryText?: ValueTypes["StringCondition"] | undefined | null,
	jsonContent?: ValueTypes["StringCondition"] | undefined | null,
	block?: ValueTypes["ContentBlockWhere"] | undefined | null,
	colorPallete?: ValueTypes["ColorPalleteWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostListWhere"] | undefined | null,
	links?: ValueTypes["LinkListWhere"] | undefined | null,
	products?: ValueTypes["ProductListWhere"] | undefined | null,
	contentSize?: ValueTypes["ContentSizeCondition"] | undefined | null,
	misc?: ValueTypes["StringCondition"] | undefined | null,
	hero?: ValueTypes["HeroWhere"] | undefined | null,
	gallery?: ValueTypes["GalleryWhere"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,
	productGrid?: ValueTypes["ProductGridWhere"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentReferenceWhere"] | undefined | null
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null,
	not?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ContentReferenceType"] | undefined | null,
	notEq?: ValueTypes["ContentReferenceType"] | undefined | null,
	in?: Array<ValueTypes["ContentReferenceType"]> | undefined | null,
	notIn?: Array<ValueTypes["ContentReferenceType"]> | undefined | null,
	lt?: ValueTypes["ContentReferenceType"] | undefined | null,
	lte?: ValueTypes["ContentReferenceType"] | undefined | null,
	gt?: ValueTypes["ContentReferenceType"] | undefined | null,
	gte?: ValueTypes["ContentReferenceType"] | undefined | null
};
	["ContentReferenceType"]:ContentReferenceType;
	["LinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["LinkTypeCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	externalLink?: ValueTypes["StringCondition"] | undefined | null,
	internalLink?: ValueTypes["LinkableWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkWhere"] | undefined | null
};
	["LinkTypeCondition"]: {
	and?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null,
	not?: ValueTypes["LinkTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["LinkType"] | undefined | null,
	notEq?: ValueTypes["LinkType"] | undefined | null,
	in?: Array<ValueTypes["LinkType"]> | undefined | null,
	notIn?: Array<ValueTypes["LinkType"]> | undefined | null,
	lt?: ValueTypes["LinkType"] | undefined | null,
	lte?: ValueTypes["LinkType"] | undefined | null,
	gt?: ValueTypes["LinkType"] | undefined | null,
	gte?: ValueTypes["LinkType"] | undefined | null
};
	["LinkType"]:LinkType;
	["BlogPostListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["BlogPostListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostListWhere"] | undefined | null
};
	["BlogPostListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	caption?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["BlogPostListWhere"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostListItemWhere"] | undefined | null
};
	["LinkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["LinkListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkListWhere"] | undefined | null
};
	["LinkListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	isPrimary?: ValueTypes["BooleanCondition"] | undefined | null,
	list?: ValueTypes["LinkListWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkListItemWhere"] | undefined | null
};
	["ContentSizeCondition"]: {
	and?: Array<ValueTypes["ContentSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ContentSizeCondition"]> | undefined | null,
	not?: ValueTypes["ContentSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ContentSize"] | undefined | null,
	notEq?: ValueTypes["ContentSize"] | undefined | null,
	in?: Array<ValueTypes["ContentSize"]> | undefined | null,
	notIn?: Array<ValueTypes["ContentSize"]> | undefined | null,
	lt?: ValueTypes["ContentSize"] | undefined | null,
	lte?: ValueTypes["ContentSize"] | undefined | null,
	gt?: ValueTypes["ContentSize"] | undefined | null,
	gte?: ValueTypes["ContentSize"] | undefined | null
};
	["ContentSize"]:ContentSize;
	["HeroWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	showLogo?: ValueTypes["BooleanCondition"] | undefined | null,
	middleContent?: ValueTypes["StringCondition"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["HeroWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["HeroWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["HeroWhere"] | undefined | null
};
	["GalleryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["GalleryItemWhere"] | undefined | null,
	and?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GalleryWhere"] | undefined | null
};
	["GalleryItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	caption?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["GalleryWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GalleryItemWhere"] | undefined | null
};
	["SocialsAndAppsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	instagram?: ValueTypes["BooleanCondition"] | undefined | null,
	appStore?: ValueTypes["BooleanCondition"] | undefined | null,
	googlePlay?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SocialsAndAppsWhere"] | undefined | null
};
	["ProductGridWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	isCarousel?: ValueTypes["BooleanCondition"] | undefined | null,
	categories?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,
	type?: ValueTypes["ProductGridTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductGridWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridWhere"] | undefined | null
};
	["ProductGridCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductGridWhere"] | undefined | null,
	items?: ValueTypes["ProductGridItemWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridCategoryWhere"] | undefined | null
};
	["ProductGridItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	lead?: ValueTypes["StringCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	category?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductGridItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridItemWhere"] | undefined | null
};
	["ProductGridTypeCondition"]: {
	and?: Array<ValueTypes["ProductGridTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductGridTypeCondition"]> | undefined | null,
	not?: ValueTypes["ProductGridTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductGridType"] | undefined | null,
	notEq?: ValueTypes["ProductGridType"] | undefined | null,
	in?: Array<ValueTypes["ProductGridType"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductGridType"]> | undefined | null,
	lt?: ValueTypes["ProductGridType"] | undefined | null,
	lte?: ValueTypes["ProductGridType"] | undefined | null,
	gt?: ValueTypes["ProductGridType"] | undefined | null,
	gte?: ValueTypes["ProductGridType"] | undefined | null
};
	["ProductGridType"]:ProductGridType;
	["ProductBannerListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductBannerItemWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerListWhere"] | undefined | null
};
	["ProductBannerItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductBannerListWhere"] | undefined | null,
	banner?: ValueTypes["ProductBannerWhere"] | undefined | null,
	size?: ValueTypes["ProductBannerItemSizeCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerItemWhere"] | undefined | null
};
	["ProductBannerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	beforeTitle?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	label?: ValueTypes["StringCondition"] | undefined | null,
	button?: ValueTypes["LinkWhere"] | undefined | null,
	backgroundImage?: ValueTypes["ImageWhere"] | undefined | null,
	productImage?: ValueTypes["ImageWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerWhere"] | undefined | null
};
	["ProductBannerItemSizeCondition"]: {
	and?: Array<ValueTypes["ProductBannerItemSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductBannerItemSizeCondition"]> | undefined | null,
	not?: ValueTypes["ProductBannerItemSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	notEq?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	in?: Array<ValueTypes["ProductBannerItemSize"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductBannerItemSize"]> | undefined | null,
	lt?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	lte?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	gt?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	gte?: ValueTypes["ProductBannerItemSize"] | undefined | null
};
	["ProductBannerItemSize"]:ProductBannerItemSize;
	["DeliveryRegionsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryRegionsWhere"] | undefined | null
};
	["DeliveryRegionsItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	offerLabel?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	textList?: ValueTypes["TextListWhere"] | undefined | null,
	gallery?: ValueTypes["ImageListWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null
};
	["ImageListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ImageListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageListWhere"] | undefined | null
};
	["ImageListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ImageListWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageListItemWhere"] | undefined | null
};
	["SeoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	ogTitle?: ValueTypes["StringCondition"] | undefined | null,
	ogDescription?: ValueTypes["StringCondition"] | undefined | null,
	ogImage?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SeoWhere"] | undefined | null
};
	["WikiPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	emoji?: ValueTypes["StringCondition"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	parent?: ValueTypes["WikiPageWhere"] | undefined | null,
	children?: ValueTypes["WikiPageWhere"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	and?: Array<ValueTypes["WikiPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["WikiPageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["WikiPageWhere"] | undefined | null
};
	["RedirectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	target?: ValueTypes["LinkableWhere"] | undefined | null,
	and?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["RedirectWhere"] | undefined | null
};
	["PageThemeCondition"]: {
	and?: Array<ValueTypes["PageThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["PageThemeCondition"]> | undefined | null,
	not?: ValueTypes["PageThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["PageTheme"] | undefined | null,
	notEq?: ValueTypes["PageTheme"] | undefined | null,
	in?: Array<ValueTypes["PageTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["PageTheme"]> | undefined | null,
	lt?: ValueTypes["PageTheme"] | undefined | null,
	lte?: ValueTypes["PageTheme"] | undefined | null,
	gt?: ValueTypes["PageTheme"] | undefined | null,
	gte?: ValueTypes["PageTheme"] | undefined | null
};
	["PageTheme"]:PageTheme;
	["ProductCategoryListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,
	locales?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListWhere"] | undefined | null
};
	["ProductCategoryListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	showRecipeImages?: ValueTypes["BooleanCondition"] | undefined | null,
	showPackingImages?: ValueTypes["BooleanCondition"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null
};
	["ProductCategoryListLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null
};
	["BlogLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	pageName?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	lead?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["BlogWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogLocaleWhere"] | undefined | null
};
	["BlogWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	locales?: ValueTypes["BlogLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null
};
	["TranslationCatalogueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null,
	fallback?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationCatalogueWhere"] | undefined | null
};
	["TranslationDomainWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["StringCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	catalogues?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	keys?: ValueTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationDomainWhere"] | undefined | null
};
	["TranslationKeyWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["StringCondition"] | undefined | null,
	contentType?: ValueTypes["TranslationContentTypeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationKeyWhere"] | undefined | null
};
	["TranslationContentTypeCondition"]: {
	and?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null,
	not?: ValueTypes["TranslationContentTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["TranslationContentType"] | undefined | null,
	notEq?: ValueTypes["TranslationContentType"] | undefined | null,
	in?: Array<ValueTypes["TranslationContentType"]> | undefined | null,
	notIn?: Array<ValueTypes["TranslationContentType"]> | undefined | null,
	lt?: ValueTypes["TranslationContentType"] | undefined | null,
	lte?: ValueTypes["TranslationContentType"] | undefined | null,
	gt?: ValueTypes["TranslationContentType"] | undefined | null,
	gte?: ValueTypes["TranslationContentType"] | undefined | null
};
	["TranslationContentType"]:TranslationContentType;
	["TranslationValueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	value?: ValueTypes["StringCondition"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	key?: ValueTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationValueWhere"] | undefined | null
};
	["MenuLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	root?: ValueTypes["SiteMenuWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	items?: ValueTypes["MenuItemWhere"] | undefined | null,
	secondaryItems?: ValueTypes["LinkListWhere"] | undefined | null,
	eshopLink?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["MenuLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuLocaleWhere"] | undefined | null
};
	["SiteMenuWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	locales?: ValueTypes["MenuLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["SiteMenuWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SiteMenuWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SiteMenuWhere"] | undefined | null
};
	["MenuItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	menu?: ValueTypes["MenuLocaleWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	subitems?: ValueTypes["MenuSubitemWhere"] | undefined | null,
	and?: Array<ValueTypes["MenuItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuItemWhere"] | undefined | null
};
	["MenuSubitemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["MenuSubitemTypeCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	item?: ValueTypes["MenuItemWhere"] | undefined | null,
	links?: ValueTypes["MenuLinkListWhere"] | undefined | null,
	products?: ValueTypes["MenuProductsWhere"] | undefined | null,
	delivery?: ValueTypes["MenuDeliveryWhere"] | undefined | null,
	contact?: ValueTypes["MenuContactWhere"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["MenuSubitemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuSubitemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuSubitemWhere"] | undefined | null
};
	["MenuSubitemTypeCondition"]: {
	and?: Array<ValueTypes["MenuSubitemTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["MenuSubitemTypeCondition"]> | undefined | null,
	not?: ValueTypes["MenuSubitemTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["MenuSubitemType"] | undefined | null,
	notEq?: ValueTypes["MenuSubitemType"] | undefined | null,
	in?: Array<ValueTypes["MenuSubitemType"]> | undefined | null,
	notIn?: Array<ValueTypes["MenuSubitemType"]> | undefined | null,
	lt?: ValueTypes["MenuSubitemType"] | undefined | null,
	lte?: ValueTypes["MenuSubitemType"] | undefined | null,
	gt?: ValueTypes["MenuSubitemType"] | undefined | null,
	gte?: ValueTypes["MenuSubitemType"] | undefined | null
};
	["MenuSubitemType"]:MenuSubitemType;
	["MenuLinkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["MenuLinkItemWhere"] | undefined | null,
	and?: Array<ValueTypes["MenuLinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuLinkListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuLinkListWhere"] | undefined | null
};
	["MenuLinkItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["MenuLinkListWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["MenuLinkItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuLinkItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuLinkItemWhere"] | undefined | null
};
	["MenuProductsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	links?: ValueTypes["MenuLinkListWhere"] | undefined | null,
	buttons?: ValueTypes["LinkListWhere"] | undefined | null,
	and?: Array<ValueTypes["MenuProductsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuProductsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuProductsWhere"] | undefined | null
};
	["MenuDeliveryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,
	and?: Array<ValueTypes["MenuDeliveryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuDeliveryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuDeliveryWhere"] | undefined | null
};
	["MenuContactWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["MenuContactWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MenuContactWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MenuContactWhere"] | undefined | null
};
	["Locale"]: AliasType<{
	_meta?:ValueTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
businessCategories?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
productCategoryLists?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
productIngredients?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
orderRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
blogs?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
menus?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLocale"]],
pages?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
allergens?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
productPackings?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
products?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
productRecipes?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
deliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
businessCategoriesByRoot?: [{	by: ValueTypes["LocaleBusinessCategoriesByRootUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
productCategoryListsByRoot?: [{	by: ValueTypes["LocaleProductCategoryListsByRootUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
productIngredientsByRoot?: [{	by: ValueTypes["LocaleProductIngredientsByRootUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
orderRecurrencesByRoot?: [{	by: ValueTypes["LocaleOrderRecurrencesByRootUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
blogsByRoot?: [{	by: ValueTypes["LocaleBlogsByRootUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
blogPostsByRoot?: [{	by: ValueTypes["LocaleBlogPostsByRootUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByCover?: [{	by: ValueTypes["LocaleBlogPostsByCoverUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByContent?: [{	by: ValueTypes["LocaleBlogPostsByContentUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByLink?: [{	by: ValueTypes["LocaleBlogPostsByLinkUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsBySeo?: [{	by: ValueTypes["LocaleBlogPostsBySeoUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
menusByRoot?: [{	by: ValueTypes["LocaleMenusByRootUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
menusByItems?: [{	by: ValueTypes["LocaleMenusByItemsUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
menusBySecondaryItems?: [{	by: ValueTypes["LocaleMenusBySecondaryItemsUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
pagesByRoot?: [{	by: ValueTypes["LocalePagesByRootUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByLink?: [{	by: ValueTypes["LocalePagesByLinkUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesBySeo?: [{	by: ValueTypes["LocalePagesBySeoUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByContent?: [{	by: ValueTypes["LocalePagesByContentUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByCover?: [{	by: ValueTypes["LocalePagesByCoverUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
allergensByRoot?: [{	by: ValueTypes["LocaleAllergensByRootUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
productPackingsByRoot?: [{	by: ValueTypes["LocaleProductPackingsByRootUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
productsByRoot?: [{	by: ValueTypes["LocaleProductsByRootUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
productsByFeatureList?: [{	by: ValueTypes["LocaleProductsByFeatureListUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
productRecipesByRoot?: [{	by: ValueTypes["LocaleProductRecipesByRootUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
deliveryMethodsByRoot?: [{	by: ValueTypes["LocaleDeliveryMethodsByRootUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginateBusinessCategories?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
paginateProductCategoryLists?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
paginateProductIngredients?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
paginateOrderRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
paginateBlogs?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
paginateMenus?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLocaleConnection"]],
paginatePages?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
paginateProductPackings?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
paginateProducts?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
paginateProductRecipes?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
paginateDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	businessCategories?:ValueTypes["FieldMeta"],
	productCategoryLists?:ValueTypes["FieldMeta"],
	productIngredients?:ValueTypes["FieldMeta"],
	orderRecurrences?:ValueTypes["FieldMeta"],
	blogs?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	menus?:ValueTypes["FieldMeta"],
	pages?:ValueTypes["FieldMeta"],
	allergens?:ValueTypes["FieldMeta"],
	productPackings?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	productRecipes?:ValueTypes["FieldMeta"],
	deliveryMethods?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["BusinessCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategory"]: AliasType<{
	_meta?:ValueTypes["BusinessCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	visibleToNewCustomers?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
	internalName?:boolean | `@${string}`,
recurrences?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
specificProducts?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
seasonalProductCategories?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
specificProductsProductCategories?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
	slug?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["BusinessCategoryLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
paginateRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	visibleToNewCustomers?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	recurrences?:ValueTypes["FieldMeta"],
	specificProducts?:ValueTypes["FieldMeta"],
	seasonalProductCategories?:ValueTypes["FieldMeta"],
	specificProductsProductCategories?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null
};
	["BusinessCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	specificProducts?: ValueTypes["ProductListOrderBy"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	slug?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null
};
	["ProductCategoryListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderRecurrence"]: AliasType<{
	_meta?:ValueTypes["OrderRecurrenceMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
	order?:boolean | `@${string}`,
businessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["OrderRecurrenceLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateLocales?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	businessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocale"]: AliasType<{
	_meta?:ValueTypes["OrderRecurrenceLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["OrderRecurrenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["LocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: ValueTypes["BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	productIngredients?: ValueTypes["ProductIngredientLocaleUniqueWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	blogs?: ValueTypes["BlogLocaleUniqueWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	menus?: ValueTypes["MenuLocaleUniqueWhere"] | undefined | null,
	pages?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null,
	allergens?: ValueTypes["AllergenLocaleUniqueWhere"] | undefined | null,
	productPackings?: ValueTypes["ProductPackingLocaleUniqueWhere"] | undefined | null,
	products?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null,
	productRecipes?: ValueTypes["ProductRecipeLocaleUniqueWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodLocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locales?: ValueTypes["BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	specificProducts?: ValueTypes["ProductListUniqueWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null
};
	["ProductListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductListItemUniqueWhere"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["ProductListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductCategoryListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductCategoryListItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["ProductCategoryListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductCategoryListLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["ProductIngredientLocaleUniqueWhere"] | undefined | null
};
	["OrderRecurrenceLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["OrderRecurrenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined | null
};
	["BlogLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BlogUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	locales?: ValueTypes["BlogLocaleUniqueWhere"] | undefined | null
};
	["BlogPostLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["BlogPostUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null
};
	["CoverUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	blocks?: ValueTypes["ContentBlockUniqueWhere"] | undefined | null
};
	["ContentBlockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentReferenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["ProductListUniqueWhere"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListUniqueWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsUniqueWhere"] | undefined | null
};
	["ProductBannerListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductBannerItemUniqueWhere"] | undefined | null
};
	["ProductBannerItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["DeliveryRegionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["DeliveryRegionsItemUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null,
	textList?: ValueTypes["TextListUniqueWhere"] | undefined | null,
	gallery?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["ImageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["TextListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["TextItemUniqueWhere"] | undefined | null
};
	["TextItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ImageListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ImageListItemUniqueWhere"] | undefined | null
};
	["ImageListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["LinkableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	page?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null,
	wikiPage?: ValueTypes["WikiPageUniqueWhere"] | undefined | null,
	redirect?: ValueTypes["RedirectUniqueWhere"] | undefined | null
};
	["PageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["PageUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null,
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["PageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null
};
	["SeoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	ogImage?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["WikiPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null,
	children?: ValueTypes["WikiPageUniqueWhere"] | undefined | null,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["RedirectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	catalogues?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	keys?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationKeyUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: string | undefined | null,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationValueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["MenuLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["SiteMenuUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	items?: ValueTypes["MenuItemUniqueWhere"] | undefined | null,
	secondaryItems?: ValueTypes["LinkListUniqueWhere"] | undefined | null
};
	["SiteMenuUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	locales?: ValueTypes["MenuLocaleUniqueWhere"] | undefined | null
};
	["MenuItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	subitems?: ValueTypes["MenuSubitemUniqueWhere"] | undefined | null
};
	["MenuSubitemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["LinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["LinkListItemUniqueWhere"] | undefined | null
};
	["LinkListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["AllergenLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["AllergenUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["AllergenUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: number | undefined | null,
	locales?: ValueTypes["AllergenLocaleUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductPackingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["ProductPackingLocaleUniqueWhere"] | undefined | null
};
	["ProductLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["ProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	stocks?: ValueTypes["ProductStockUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null,
	meta?: ValueTypes["ProductMetadataUniqueWhere"] | undefined | null,
	page?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["ProductStockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	store?: ValueTypes["StoreUniqueWhere"] | undefined | null
};
	["StoreUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["VirtualProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["ProductMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["ProductRecipeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductRecipeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductRecipeLocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["DeliveryMethodLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	root?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["DeliveryMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetUniqueWhere"] | undefined | null,
	locales?: ValueTypes["DeliveryMethodLocaleUniqueWhere"] | undefined | null
};
	["DeliveryTimelinePresetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["OrderRecurrenceLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderRecurrenceLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleEdge"]: AliasType<{
	node?:ValueTypes["OrderRecurrenceLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryEdge"]: AliasType<{
	node?:ValueTypes["BusinessCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductList"]: AliasType<{
	_meta?:ValueTypes["ProductListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
businessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
paginateItems?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	businessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItem"]: AliasType<{
	_meta?:ValueTypes["ProductListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	defaultQuantityForOrder?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	defaultQuantityForOrder?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductListOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	notDiscountable?: ValueTypes["OrderDirection"] | undefined | null,
	packing?: ValueTypes["ProductPackingOrderBy"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	availability?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["OrderDirection"] | undefined | null,
	archivedAt?: ValueTypes["OrderDirection"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductOrderBy"] | undefined | null,
	meta?: ValueTypes["ProductMetadataOrderBy"] | undefined | null,
	page?: ValueTypes["PageOrderBy"] | undefined | null
};
	["ProductPackingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	volumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	shortName?: ValueTypes["OrderDirection"] | undefined | null,
	isPackedInCardboard?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	url?: ValueTypes["OrderDirection"] | undefined | null,
	width?: ValueTypes["OrderDirection"] | undefined | null,
	height?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	alt?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	color?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	needsPrepping?: ValueTypes["OrderDirection"] | undefined | null,
	gutTuning?: ValueTypes["OrderDirection"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["FreshingContainerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	config?: ValueTypes["GlobalConfigOrderBy"] | undefined | null,
	volumeMl?: ValueTypes["OrderDirection"] | undefined | null
};
	["GlobalConfigOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	orderDeadlineTime?: ValueTypes["OrderDirection"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["OrderDirection"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursOrderBy"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursOrderBy"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	orderUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductOrderBy"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	messengerUrl?: ValueTypes["OrderDirection"] | undefined | null,
	messengerIosUrl?: ValueTypes["OrderDirection"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["OrderDirection"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["OrderDirection"] | undefined | null
};
	["WeekHoursOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	mon?: ValueTypes["OrderDirection"] | undefined | null,
	tue?: ValueTypes["OrderDirection"] | undefined | null,
	wed?: ValueTypes["OrderDirection"] | undefined | null,
	thu?: ValueTypes["OrderDirection"] | undefined | null,
	fri?: ValueTypes["OrderDirection"] | undefined | null,
	sat?: ValueTypes["OrderDirection"] | undefined | null,
	sun?: ValueTypes["OrderDirection"] | undefined | null
};
	["VirtualProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	voucherCreditCents?: ValueTypes["OrderDirection"] | undefined | null,
	physicalRepresentation?: ValueTypes["OrderDirection"] | undefined | null,
	recipientEmail?: ValueTypes["OrderDirection"] | undefined | null
};
	["VatRateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	ratePermille?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	archived?: ValueTypes["OrderDirection"] | undefined | null,
	available?: ValueTypes["OrderDirection"] | undefined | null,
	availableQuantity?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["PageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemEdge"]: AliasType<{
	node?:ValueTypes["ProductListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryList"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
locales?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
seasonalProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
specificProductsProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["ProductCategoryListLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateItems?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	seasonalProductCategoriesOfBusinessCategory?:ValueTypes["FieldMeta"],
	specificProductsProductCategoriesOfBusinessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItem"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
productCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
	showRecipeImages?:boolean | `@${string}`,
	showPackingImages?:boolean | `@${string}`,
	showAllAvailableProducts?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	productCategory?:ValueTypes["FieldMeta"],
	showRecipeImages?:ValueTypes["FieldMeta"],
	showPackingImages?:ValueTypes["FieldMeta"],
	showAllAvailableProducts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategory"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
parent?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
children?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	order?:boolean | `@${string}`,
childrenByCode?: [{	by: ValueTypes["ProductCategoryChildrenByCodeUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
childrenByChildren?: [{	by: ValueTypes["ProductCategoryChildrenByChildrenUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateChildren?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	parent?:ValueTypes["FieldMeta"],
	children?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	color?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	parent?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	color?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryChildrenByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["ProductCategoryChildrenByChildrenUniqueWhere"]: {
	children?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null
};
	["ProductCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	children?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null
};
	["ProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductEdge"]: AliasType<{
	node?:ValueTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["TagConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TagEdge"]: AliasType<{
	node?:ValueTypes["Tag"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	showRecipeImages?: ValueTypes["OrderDirection"] | undefined | null,
	showPackingImages?: ValueTypes["OrderDirection"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryListLocale"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductCategoryListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryListLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["BusinessCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderRecurrenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceEdge"]: AliasType<{
	node?:ValueTypes["OrderRecurrence"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocale"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredient"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientMeta"],
	id?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
	internalName?:boolean | `@${string}`,
allergens?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
	needsPrepping?:boolean | `@${string}`,
	allowCustomerRating?:boolean | `@${string}`,
freshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
localesByLocale?: [{	by: ValueTypes["ProductIngredientLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	allergens?:ValueTypes["FieldMeta"],
	needsPrepping?:ValueTypes["FieldMeta"],
	allowCustomerRating?:ValueTypes["FieldMeta"],
	freshingContainer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductIngredientOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductIngredientOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	needsPrepping?: ValueTypes["OrderDirection"] | undefined | null,
	allowCustomerRating?: ValueTypes["OrderDirection"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null
};
	["Allergen"]: AliasType<{
	_meta?:ValueTypes["AllergenMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
ingredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
	code?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["AllergenLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
paginateLocales?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AllergenMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	ingredients?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocale"]: AliasType<{
	_meta?:ValueTypes["AllergenLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["AllergenOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["AllergenOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["AllergenLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["AllergenLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AllergenLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleEdge"]: AliasType<{
	node?:ValueTypes["AllergenLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredient"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainer"]: AliasType<{
	_meta?:ValueTypes["FreshingContainerMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
config?: [{	filter?: ValueTypes["GlobalConfigWhere"] | undefined | null},ValueTypes["GlobalConfig"]],
	volumeMl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	config?:ValueTypes["FieldMeta"],
	volumeMl?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GlobalConfig"]: AliasType<{
	_meta?:ValueTypes["GlobalConfigMeta"],
	id?:boolean | `@${string}`,
	orderDeadlineTime?:boolean | `@${string}`,
	ordersLoadingDurationHours?:boolean | `@${string}`,
openingHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
	unique?:boolean | `@${string}`,
workingHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
	freshingContainerVolumeMl?:boolean | `@${string}`,
	checkoutUrlTemplate?:boolean | `@${string}`,
	orderUrlTemplate?:boolean | `@${string}`,
	paymentUrlTemplate?:boolean | `@${string}`,
	customerProfileUrlTemplate?:boolean | `@${string}`,
	userProfileUrlTemplate?:boolean | `@${string}`,
creditsProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
discountVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
defaultFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
freshingContainers?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
	messengerUrl?:boolean | `@${string}`,
	messengerIosUrl?:boolean | `@${string}`,
	messengerAndroidUrl?:boolean | `@${string}`,
	freshingPrepareOrderByOffsetMinutes?:boolean | `@${string}`,
paginateFreshingContainers?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GlobalConfigMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	orderDeadlineTime?:ValueTypes["FieldMeta"],
	ordersLoadingDurationHours?:ValueTypes["FieldMeta"],
	openingHours?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	workingHours?:ValueTypes["FieldMeta"],
	freshingContainerVolumeMl?:ValueTypes["FieldMeta"],
	checkoutUrlTemplate?:ValueTypes["FieldMeta"],
	orderUrlTemplate?:ValueTypes["FieldMeta"],
	paymentUrlTemplate?:ValueTypes["FieldMeta"],
	customerProfileUrlTemplate?:ValueTypes["FieldMeta"],
	userProfileUrlTemplate?:ValueTypes["FieldMeta"],
	creditsProduct?:ValueTypes["FieldMeta"],
	discountVatRate?:ValueTypes["FieldMeta"],
	defaultFreshingContainer?:ValueTypes["FieldMeta"],
	freshingContainers?:ValueTypes["FieldMeta"],
	messengerUrl?:ValueTypes["FieldMeta"],
	messengerIosUrl?:ValueTypes["FieldMeta"],
	messengerAndroidUrl?:ValueTypes["FieldMeta"],
	freshingPrepareOrderByOffsetMinutes?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHours"]: AliasType<{
	_meta?:ValueTypes["WeekHoursMeta"],
	id?:boolean | `@${string}`,
	mon?:boolean | `@${string}`,
	tue?:boolean | `@${string}`,
	wed?:boolean | `@${string}`,
	thu?:boolean | `@${string}`,
	fri?:boolean | `@${string}`,
	sat?:boolean | `@${string}`,
	sun?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	mon?:ValueTypes["FieldMeta"],
	tue?:ValueTypes["FieldMeta"],
	wed?:ValueTypes["FieldMeta"],
	thu?:ValueTypes["FieldMeta"],
	fri?:ValueTypes["FieldMeta"],
	sat?:ValueTypes["FieldMeta"],
	sun?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProduct"]: AliasType<{
	_meta?:ValueTypes["VirtualProductMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	voucherCreditCents?:boolean | `@${string}`,
	physicalRepresentation?:boolean | `@${string}`,
	recipientEmail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	voucherCreditCents?:ValueTypes["FieldMeta"],
	physicalRepresentation?:ValueTypes["FieldMeta"],
	recipientEmail?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VatRate"]: AliasType<{
	_meta?:ValueTypes["VatRateMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	ratePermille?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VatRateMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	ratePermille?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FreshingContainerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerEdge"]: AliasType<{
	node?:ValueTypes["FreshingContainer"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientLocale"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AllergenEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenEdge"]: AliasType<{
	node?:ValueTypes["Allergen"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocale"]: AliasType<{
	_meta?:ValueTypes["BlogLocaleMeta"],
	id?:boolean | `@${string}`,
	pageName?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	pageName?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Blog"]: AliasType<{
	_meta?:ValueTypes["BlogMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
localesByLocale?: [{	by: ValueTypes["BlogLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	pageName?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	lead?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BlogOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["BlogOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null
};
	["BlogLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocale"]: AliasType<{
	_meta?:ValueTypes["BlogPostLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	availableForWeb?:boolean | `@${string}`,
	availableForMobile?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
cover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	availableForWeb?:ValueTypes["FieldMeta"],
	availableForMobile?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	cover?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPost"]: AliasType<{
	_meta?:ValueTypes["BlogPostMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
localesByLocale?: [{	by: ValueTypes["BlogPostLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByCover?: [{	by: ValueTypes["BlogPostLocalesByCoverUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByContent?: [{	by: ValueTypes["BlogPostLocalesByContentUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByLink?: [{	by: ValueTypes["BlogPostLocalesByLinkUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesBySeo?: [{	by: ValueTypes["BlogPostLocalesBySeoUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	availableForWeb?: ValueTypes["OrderDirection"] | undefined | null,
	availableForMobile?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BlogPostOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	cover?: ValueTypes["CoverOrderBy"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null
};
	["BlogPostOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["CoverOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	primaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	secondaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	buttonColors?: ValueTypes["ColorPalleteOrderBy"] | undefined | null
};
	["MediumOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	colorTheme?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	video?: ValueTypes["VideoOrderBy"] | undefined | null
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	src?: ValueTypes["OrderDirection"] | undefined | null,
	width?: ValueTypes["OrderDirection"] | undefined | null,
	height?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	poster?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ColorPalleteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	background?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	url?: ValueTypes["OrderDirection"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleOrderBy"] | undefined | null,
	page?: ValueTypes["PageLocaleOrderBy"] | undefined | null,
	wikiPage?: ValueTypes["WikiPageOrderBy"] | undefined | null,
	redirect?: ValueTypes["RedirectOrderBy"] | undefined | null
};
	["PageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	root?: ValueTypes["PageOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null,
	cover?: ValueTypes["CoverOrderBy"] | undefined | null,
	theme?: ValueTypes["OrderDirection"] | undefined | null
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	ogTitle?: ValueTypes["OrderDirection"] | undefined | null,
	ogDescription?: ValueTypes["OrderDirection"] | undefined | null,
	ogImage?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["WikiPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	emoji?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	parent?: ValueTypes["WikiPageOrderBy"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	target?: ValueTypes["LinkableOrderBy"] | undefined | null
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["BlogPostLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogPostLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Cover"]: AliasType<{
	_meta?:ValueTypes["CoverMeta"],
	id?:boolean | `@${string}`,
	primaryTitle?:boolean | `@${string}`,
	secondaryTitle?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
buttonColors?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
		__typename?: boolean | `@${string}`
}>;
	["CoverMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	primaryTitle?:ValueTypes["FieldMeta"],
	secondaryTitle?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	buttonColors?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Medium"]: AliasType<{
	_meta?:ValueTypes["MediumMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	colorTheme?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
video?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	colorTheme?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ValueTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	src?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	poster?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPallete"]: AliasType<{
	_meta?:ValueTypes["ColorPalleteMeta"],
	id?:boolean | `@${string}`,
	background?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	background?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
blocksByReferences?: [{	by: ValueTypes["ContentBlocksByReferencesUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
references?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
referencesByProducts?: [{	by: ValueTypes["ContentBlockReferencesByProductsUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
referencesByProductBanners?: [{	by: ValueTypes["ContentBlockReferencesByProductBannersUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
referencesByDeliveryRegions?: [{	by: ValueTypes["ContentBlockReferencesByDeliveryRegionsUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
paginateReferences?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	json?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	references?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
	secondaryText?:boolean | `@${string}`,
	jsonContent?:boolean | `@${string}`,
block?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
colorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
links?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
products?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
	contentSize?:boolean | `@${string}`,
	misc?:boolean | `@${string}`,
hero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
gallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
socialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
productGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
productBanners?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
deliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	secondaryText?:ValueTypes["FieldMeta"],
	jsonContent?:ValueTypes["FieldMeta"],
	block?:ValueTypes["FieldMeta"],
	colorPallete?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	links?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	contentSize?:ValueTypes["FieldMeta"],
	misc?:ValueTypes["FieldMeta"],
	hero?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	socialsAndApps?:ValueTypes["FieldMeta"],
	productGrid?:ValueTypes["FieldMeta"],
	productBanners?:ValueTypes["FieldMeta"],
	deliveryRegions?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
blogPost?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
page?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
wikiPage?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null},ValueTypes["WikiPage"]],
redirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
	wikiPage?:ValueTypes["FieldMeta"],
	redirect?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocale"]: AliasType<{
	_meta?:ValueTypes["PageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
root?: [{	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
cover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
	theme?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	cover?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Page"]: AliasType<{
	_meta?:ValueTypes["PageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
localesByLocale?: [{	by: ValueTypes["PageLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByLink?: [{	by: ValueTypes["PageLocalesByLinkUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesBySeo?: [{	by: ValueTypes["PageLocalesBySeoUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByContent?: [{	by: ValueTypes["PageLocalesByContentUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByCover?: [{	by: ValueTypes["PageLocalesByCoverUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["PageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["PageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["PageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["PageLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["PageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocaleEdge"]: AliasType<{
	node?:ValueTypes["PageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
ogImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["WikiPage"]: AliasType<{
	_meta?:ValueTypes["WikiPageMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	emoji?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
parent?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null},ValueTypes["WikiPage"]],
children?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WikiPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WikiPage"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
childrenByLink?: [{	by: ValueTypes["WikiPageChildrenByLinkUniqueWhere"],	filter?: ValueTypes["WikiPageWhere"] | undefined | null},ValueTypes["WikiPage"]],
childrenByChildren?: [{	by: ValueTypes["WikiPageChildrenByChildrenUniqueWhere"],	filter?: ValueTypes["WikiPageWhere"] | undefined | null},ValueTypes["WikiPage"]],
childrenByContent?: [{	by: ValueTypes["WikiPageChildrenByContentUniqueWhere"],	filter?: ValueTypes["WikiPageWhere"] | undefined | null},ValueTypes["WikiPage"]],
paginateChildren?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WikiPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WikiPageConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["WikiPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	emoji?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	parent?:ValueTypes["FieldMeta"],
	children?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["WikiPageChildrenByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["WikiPageChildrenByChildrenUniqueWhere"]: {
	children?: ValueTypes["WikiPageUniqueWhere"] | undefined | null
};
	["WikiPageChildrenByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["WikiPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["WikiPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["WikiPageEdge"]: AliasType<{
	node?:ValueTypes["WikiPage"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ValueTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
target?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	target?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostList"]: AliasType<{
	_meta?:ValueTypes["BlogPostListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateItems?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItem"]: AliasType<{
	_meta?:ValueTypes["BlogPostListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
blogPost?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	caption?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["BlogPostListOrderBy"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleOrderBy"] | undefined | null
};
	["BlogPostListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["BlogPostListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemEdge"]: AliasType<{
	node?:ValueTypes["BlogPostListItem"],
		__typename?: boolean | `@${string}`
}>;
	["LinkList"]: AliasType<{
	_meta?:ValueTypes["LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateItems?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItem"]: AliasType<{
	_meta?:ValueTypes["LinkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	isPrimary?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	isPrimary?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	isPrimary?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["LinkListOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	externalLink?: ValueTypes["OrderDirection"] | undefined | null,
	internalLink?: ValueTypes["LinkableOrderBy"] | undefined | null
};
	["LinkListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemEdge"]: AliasType<{
	node?:ValueTypes["LinkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Hero"]: AliasType<{
	_meta?:ValueTypes["HeroMeta"],
	id?:boolean | `@${string}`,
	showLogo?:boolean | `@${string}`,
	middleContent?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["HeroMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	showLogo?:ValueTypes["FieldMeta"],
	middleContent?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Gallery"]: AliasType<{
	_meta?:ValueTypes["GalleryMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateItems?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItem"]: AliasType<{
	_meta?:ValueTypes["GalleryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	caption?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["GalleryOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null
};
	["GalleryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["GalleryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemEdge"]: AliasType<{
	node?:ValueTypes["GalleryItem"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndApps"]: AliasType<{
	_meta?:ValueTypes["SocialsAndAppsMeta"],
	id?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	appStore?:boolean | `@${string}`,
	googlePlay?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	instagram?:ValueTypes["FieldMeta"],
	appStore?:ValueTypes["FieldMeta"],
	googlePlay?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGrid"]: AliasType<{
	_meta?:ValueTypes["ProductGridMeta"],
	id?:boolean | `@${string}`,
	isCarousel?:boolean | `@${string}`,
categories?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
	type?:boolean | `@${string}`,
categoriesByItems?: [{	by: ValueTypes["ProductGridCategoriesByItemsUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
paginateCategories?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	isCarousel?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategory"]: AliasType<{
	_meta?:ValueTypes["ProductGridCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
items?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
paginateItems?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItem"]: AliasType<{
	_meta?:ValueTypes["ProductGridItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
category?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	lead?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null,
	category?: ValueTypes["ProductGridCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductGridCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductGridOrderBy"] | undefined | null
};
	["ProductGridOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	isCarousel?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductGridItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemEdge"]: AliasType<{
	node?:ValueTypes["ProductGridItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoriesByItemsUniqueWhere"]: {
	items?: ValueTypes["ProductGridItemUniqueWhere"] | undefined | null
};
	["ProductGridItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductGridCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductGridCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerList"]: AliasType<{
	_meta?:ValueTypes["ProductBannerListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateItems?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItem"]: AliasType<{
	_meta?:ValueTypes["ProductBannerItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
banner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	banner?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBanner"]: AliasType<{
	_meta?:ValueTypes["ProductBannerMeta"],
	id?:boolean | `@${string}`,
	beforeTitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
button?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
backgroundImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
productImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	beforeTitle?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	button?:ValueTypes["FieldMeta"],
	backgroundImage?:ValueTypes["FieldMeta"],
	productImage?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductBannerListOrderBy"] | undefined | null,
	banner?: ValueTypes["ProductBannerOrderBy"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductBannerListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductBannerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	beforeTitle?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	label?: ValueTypes["OrderDirection"] | undefined | null,
	button?: ValueTypes["LinkOrderBy"] | undefined | null,
	backgroundImage?: ValueTypes["ImageOrderBy"] | undefined | null,
	productImage?: ValueTypes["ImageOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["ProductBannerItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemEdge"]: AliasType<{
	node?:ValueTypes["ProductBannerItem"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegions"]: AliasType<{
	_meta?:ValueTypes["DeliveryRegionsMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByImage?: [{	by: ValueTypes["DeliveryRegionsItemsByImageUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByTextList?: [{	by: ValueTypes["DeliveryRegionsItemsByTextListUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByGallery?: [{	by: ValueTypes["DeliveryRegionsItemsByGalleryUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateItems?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItem"]: AliasType<{
	_meta?:ValueTypes["DeliveryRegionsItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	offerLabel?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
textList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
gallery?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	offerLabel?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	textList?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextList"]: AliasType<{
	_meta?:ValueTypes["TextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateItems?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TextListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItem"]: AliasType<{
	_meta?:ValueTypes["TextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
		__typename?: boolean | `@${string}`
}>;
	["TextItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["TextListOrderBy"] | undefined | null
};
	["TextListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["TextItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemEdge"]: AliasType<{
	node?:ValueTypes["TextItem"],
		__typename?: boolean | `@${string}`
}>;
	["ImageList"]: AliasType<{
	_meta?:ValueTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
itemsByImage?: [{	by: ValueTypes["ImageListItemsByImageUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
paginateItems?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ValueTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ImageListOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ValueTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	offerLabel?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["DeliveryRegionsOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	textList?: ValueTypes["TextListOrderBy"] | undefined | null,
	gallery?: ValueTypes["ImageListOrderBy"] | undefined | null
};
	["DeliveryRegionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryRegionsItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemsByTextListUniqueWhere"]: {
	textList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: {
	gallery?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryRegionsItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemEdge"]: AliasType<{
	node?:ValueTypes["DeliveryRegionsItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	primaryText?: ValueTypes["OrderDirection"] | undefined | null,
	secondaryText?: ValueTypes["OrderDirection"] | undefined | null,
	jsonContent?: ValueTypes["OrderDirection"] | undefined | null,
	block?: ValueTypes["ContentBlockOrderBy"] | undefined | null,
	colorPallete?: ValueTypes["ColorPalleteOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostListOrderBy"] | undefined | null,
	links?: ValueTypes["LinkListOrderBy"] | undefined | null,
	products?: ValueTypes["ProductListOrderBy"] | undefined | null,
	contentSize?: ValueTypes["OrderDirection"] | undefined | null,
	misc?: ValueTypes["OrderDirection"] | undefined | null,
	hero?: ValueTypes["HeroOrderBy"] | undefined | null,
	gallery?: ValueTypes["GalleryOrderBy"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsOrderBy"] | undefined | null,
	productGrid?: ValueTypes["ProductGridOrderBy"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListOrderBy"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsOrderBy"] | undefined | null
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	json?: ValueTypes["OrderDirection"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null
};
	["HeroOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	showLogo?: ValueTypes["OrderDirection"] | undefined | null,
	middleContent?: ValueTypes["OrderDirection"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null
};
	["SocialsAndAppsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	instagram?: ValueTypes["OrderDirection"] | undefined | null,
	appStore?: ValueTypes["OrderDirection"] | undefined | null,
	googlePlay?: ValueTypes["OrderDirection"] | undefined | null
};
	["ContentBlockReferencesByProductsUniqueWhere"]: {
	products?: ValueTypes["ProductListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByProductBannersUniqueWhere"]: {
	productBanners?: ValueTypes["ProductBannerListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
	deliveryRegions?: ValueTypes["DeliveryRegionsUniqueWhere"] | undefined | null
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ValueTypes["TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
catalogueByDomain?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
catalogueByValues?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogue"]: AliasType<{
	_meta?:ValueTypes["TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
fallback?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
valuesByKey?: [{	by: ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	fallback?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomain"]: AliasType<{
	_meta?:ValueTypes["TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
keys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by: ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by: ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by: ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
keysByValues?: [{	by: ValueTypes["TranslationDomainKeysByValuesUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
paginateCatalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	catalogues?:ValueTypes["FieldMeta"],
	keys?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null,
	fallback?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null
};
	["TranslationKey"]: AliasType<{
	_meta?:ValueTypes["TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
valuesByCatalogue?: [{	by: ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	contentType?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValue"]: AliasType<{
	_meta?:ValueTypes["TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
key?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
	key?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	value?: ValueTypes["OrderDirection"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null,
	key?: ValueTypes["TranslationKeyOrderBy"] | undefined | null
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["OrderDirection"] | undefined | null,
	contentType?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ValueTypes["TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ValueTypes["TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ValueTypes["TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["MenuLocale"]: AliasType<{
	_meta?:ValueTypes["MenuLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["SiteMenuWhere"] | undefined | null},ValueTypes["SiteMenu"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
items?: [{	filter?: ValueTypes["MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuItem"]],
secondaryItems?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
eshopLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
itemsBySubitems?: [{	by: ValueTypes["MenuLocaleItemsBySubitemsUniqueWhere"],	filter?: ValueTypes["MenuItemWhere"] | undefined | null},ValueTypes["MenuItem"]],
paginateItems?: [{	filter?: ValueTypes["MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["MenuLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	secondaryItems?:ValueTypes["FieldMeta"],
	eshopLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SiteMenu"]: AliasType<{
	_meta?:ValueTypes["SiteMenuMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLocale"]],
localesByLocale?: [{	by: ValueTypes["SiteMenuLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
localesByItems?: [{	by: ValueTypes["SiteMenuLocalesByItemsUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
localesBySecondaryItems?: [{	by: ValueTypes["SiteMenuLocalesBySecondaryItemsUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
paginateLocales?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SiteMenuMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["SiteMenuOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	secondaryItems?: ValueTypes["LinkListOrderBy"] | undefined | null,
	eshopLink?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["SiteMenuOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null
};
	["SiteMenuLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["SiteMenuLocalesByItemsUniqueWhere"]: {
	items?: ValueTypes["MenuItemUniqueWhere"] | undefined | null
};
	["SiteMenuLocalesBySecondaryItemsUniqueWhere"]: {
	secondaryItems?: ValueTypes["LinkListUniqueWhere"] | undefined | null
};
	["MenuLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLocaleEdge"]: AliasType<{
	node?:ValueTypes["MenuLocale"],
		__typename?: boolean | `@${string}`
}>;
	["MenuItem"]: AliasType<{
	_meta?:ValueTypes["MenuItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
menu?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
subitems?: [{	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuSubitemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuSubitem"]],
paginateSubitems?: [{	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuSubitemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuSubitemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["MenuItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	menu?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	subitems?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuSubitem"]: AliasType<{
	_meta?:ValueTypes["MenuSubitemMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["MenuItemWhere"] | undefined | null},ValueTypes["MenuItem"]],
links?: [{	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null},ValueTypes["MenuLinkList"]],
products?: [{	filter?: ValueTypes["MenuProductsWhere"] | undefined | null},ValueTypes["MenuProducts"]],
delivery?: [{	filter?: ValueTypes["MenuDeliveryWhere"] | undefined | null},ValueTypes["MenuDelivery"]],
contact?: [{	filter?: ValueTypes["MenuContactWhere"] | undefined | null},ValueTypes["MenuContact"]],
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MenuSubitemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
	links?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	delivery?:ValueTypes["FieldMeta"],
	contact?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkList"]: AliasType<{
	_meta?:ValueTypes["MenuLinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLinkItem"]],
paginateItems?: [{	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkItem"]: AliasType<{
	_meta?:ValueTypes["MenuLinkItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null},ValueTypes["MenuLinkList"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["MenuLinkListOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["MenuLinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["MenuLinkItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkItemEdge"]: AliasType<{
	node?:ValueTypes["MenuLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["MenuProducts"]: AliasType<{
	_meta?:ValueTypes["MenuProductsMeta"],
	id?:boolean | `@${string}`,
links?: [{	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null},ValueTypes["MenuLinkList"]],
buttons?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
		__typename?: boolean | `@${string}`
}>;
	["MenuProductsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	links?:ValueTypes["FieldMeta"],
	buttons?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuDelivery"]: AliasType<{
	_meta?:ValueTypes["MenuDeliveryMeta"],
	id?:boolean | `@${string}`,
deliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
	text?:boolean | `@${string}`,
socialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
		__typename?: boolean | `@${string}`
}>;
	["MenuDeliveryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	deliveryRegions?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	socialsAndApps?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuContact"]: AliasType<{
	_meta?:ValueTypes["MenuContactMeta"],
	id?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["MenuContactMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["MenuSubitemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	item?: ValueTypes["MenuItemOrderBy"] | undefined | null,
	links?: ValueTypes["MenuLinkListOrderBy"] | undefined | null,
	products?: ValueTypes["MenuProductsOrderBy"] | undefined | null,
	delivery?: ValueTypes["MenuDeliveryOrderBy"] | undefined | null,
	contact?: ValueTypes["MenuContactOrderBy"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["MenuItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	menu?: ValueTypes["MenuLocaleOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["MenuProductsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	links?: ValueTypes["MenuLinkListOrderBy"] | undefined | null,
	buttons?: ValueTypes["LinkListOrderBy"] | undefined | null
};
	["MenuDeliveryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsOrderBy"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsOrderBy"] | undefined | null
};
	["MenuContactOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["MenuSubitemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuSubitemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuSubitemEdge"]: AliasType<{
	node?:ValueTypes["MenuSubitem"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLocaleItemsBySubitemsUniqueWhere"]: {
	subitems?: ValueTypes["MenuSubitemUniqueWhere"] | undefined | null
};
	["MenuItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuItemEdge"]: AliasType<{
	node?:ValueTypes["MenuItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	shortName?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductPackingOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductLocale"]: AliasType<{
	_meta?:ValueTypes["ProductLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
featureList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	featureList?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	featureList?: ValueTypes["TextListOrderBy"] | undefined | null
};
	["ProductRecipeLocale"]: AliasType<{
	_meta?:ValueTypes["ProductRecipeLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipe"]: AliasType<{
	_meta?:ValueTypes["ProductRecipeMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
ingredients?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
	needsPrepping?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
	gutTuning?:boolean | `@${string}`,
freshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
	description?:boolean | `@${string}`,
productsByCode?: [{	by: ValueTypes["ProductRecipeProductsByCodeUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByStocks?: [{	by: ValueTypes["ProductRecipeProductsByStocksUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByLocales?: [{	by: ValueTypes["ProductRecipeProductsByLocalesUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByVirtualProduct?: [{	by: ValueTypes["ProductRecipeProductsByVirtualProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByMeta?: [{	by: ValueTypes["ProductRecipeProductsByMetaUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByPage?: [{	by: ValueTypes["ProductRecipeProductsByPageUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
localesByLocale?: [{	by: ValueTypes["ProductRecipeLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	color?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	ingredients?:ValueTypes["FieldMeta"],
	needsPrepping?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	gutTuning?:ValueTypes["FieldMeta"],
	freshingContainer?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItem"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	internalOrder?:boolean | `@${string}`,
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
ingredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	internalOrder?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	ingredient?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	internalOrder?: ValueTypes["OrderDirection"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientOrderBy"] | undefined | null
};
	["ProductRecipeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeProductsByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["ProductRecipeProductsByStocksUniqueWhere"]: {
	stocks?: ValueTypes["ProductStockUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: {
	virtualProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByMetaUniqueWhere"]: {
	meta?: ValueTypes["ProductMetadataUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByPageUniqueWhere"]: {
	page?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["ProductRecipeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductRecipeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductRecipeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocale"]: AliasType<{
	_meta?:ValueTypes["DeliveryMethodLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	trackingCodeUrlTemplate?:boolean | `@${string}`,
	processingTitle?:boolean | `@${string}`,
	processingText?:boolean | `@${string}`,
	processingIconUrl?:boolean | `@${string}`,
	dispatchedTitle?:boolean | `@${string}`,
	dispatchedText?:boolean | `@${string}`,
	dispatchedIconUrl?:boolean | `@${string}`,
	fulfilledTitle?:boolean | `@${string}`,
	fulfilledText?:boolean | `@${string}`,
	fulfilledIconUrl?:boolean | `@${string}`,
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
root?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	trackingCodeUrlTemplate?:ValueTypes["FieldMeta"],
	processingTitle?:ValueTypes["FieldMeta"],
	processingText?:ValueTypes["FieldMeta"],
	processingIconUrl?:ValueTypes["FieldMeta"],
	dispatchedTitle?:ValueTypes["FieldMeta"],
	dispatchedText?:ValueTypes["FieldMeta"],
	dispatchedIconUrl?:ValueTypes["FieldMeta"],
	fulfilledTitle?:ValueTypes["FieldMeta"],
	fulfilledText?:ValueTypes["FieldMeta"],
	fulfilledIconUrl?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethod"]: AliasType<{
	_meta?:ValueTypes["DeliveryMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
paymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	requireDeliveryAddress?:boolean | `@${string}`,
suitableForDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
timelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
	enableDeliveryTracking?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
localesByLocale?: [{	by: ValueTypes["DeliveryMethodLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginatePaymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
paginateSuitableForDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginateLocales?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	paymentMethods?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	requireDeliveryAddress?:ValueTypes["FieldMeta"],
	suitableForDeliveryZones?:ValueTypes["FieldMeta"],
	timelinePreset?:ValueTypes["FieldMeta"],
	enableDeliveryTracking?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethod"]: AliasType<{
	_meta?:ValueTypes["PaymentMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
deliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	blockingDispatch?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
paginateDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	deliveryMethods?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	blockingDispatch?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["OrderDirection"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetOrderBy"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryTimelinePresetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	window?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null
};
	["DeliveryMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodEdge"]: AliasType<{
	node?:ValueTypes["DeliveryMethod"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	blockingDispatch?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryZone"]: AliasType<{
	_meta?:ValueTypes["DeliveryZoneMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
suitableDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
deliveryZonesOfTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
deliveryZonesOfProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateSuitableDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
paginateDeliveryZonesOfTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateDeliveryZonesOfProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	suitableDeliveryMethods?:ValueTypes["FieldMeta"],
	deliveryZonesOfTags?:ValueTypes["FieldMeta"],
	deliveryZonesOfProducts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryTimelinePreset"]: AliasType<{
	_meta?:ValueTypes["DeliveryTimelinePresetMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
deliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelinePresetMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	window?:ValueTypes["FieldMeta"],
	deliveryMethod?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	processingTitle?: ValueTypes["OrderDirection"] | undefined | null,
	processingText?: ValueTypes["OrderDirection"] | undefined | null,
	processingIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedTitle?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedText?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledTitle?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledText?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	root?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null
};
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["PaymentMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PaymentMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodEdge"]: AliasType<{
	node?:ValueTypes["PaymentMethod"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryZoneEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneEdge"]: AliasType<{
	node?:ValueTypes["DeliveryZone"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryMethodLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleEdge"]: AliasType<{
	node?:ValueTypes["DeliveryMethodLocale"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleBusinessCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["LocaleProductCategoryListsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null
};
	["LocaleProductIngredientsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["LocaleOrderRecurrencesByRootUniqueWhere"]: {
	root?: ValueTypes["OrderRecurrenceUniqueWhere"] | undefined | null
};
	["LocaleBlogsByRootUniqueWhere"]: {
	root?: ValueTypes["BlogUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleMenusByRootUniqueWhere"]: {
	root?: ValueTypes["SiteMenuUniqueWhere"] | undefined | null
};
	["LocaleMenusByItemsUniqueWhere"]: {
	items?: ValueTypes["MenuItemUniqueWhere"] | undefined | null
};
	["LocaleMenusBySecondaryItemsUniqueWhere"]: {
	secondaryItems?: ValueTypes["LinkListUniqueWhere"] | undefined | null
};
	["LocalePagesByRootUniqueWhere"]: {
	root?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["LocalePagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocalePagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["LocalePagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["LocalePagesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["LocaleAllergensByRootUniqueWhere"]: {
	root?: ValueTypes["AllergenUniqueWhere"] | undefined | null
};
	["LocaleProductPackingsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null
};
	["LocaleProductsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["LocaleProductsByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["LocaleProductRecipesByRootUniqueWhere"]: {
	root?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["LocaleDeliveryMethodsByRootUniqueWhere"]: {
	root?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductPackingLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductPackingLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductStock"]: AliasType<{
	_meta?:ValueTypes["ProductStockMeta"],
	id?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
store?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Store"]: AliasType<{
	_meta?:ValueTypes["StoreMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StoreMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	store?: ValueTypes["StoreOrderBy"] | undefined | null
};
	["StoreOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductMetadata"]: AliasType<{
	_meta?:ValueTypes["ProductMetadataMeta"],
	id?:boolean | `@${string}`,
	archived?:boolean | `@${string}`,
	available?:boolean | `@${string}`,
	availableQuantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	archived?:ValueTypes["FieldMeta"],
	available?:ValueTypes["FieldMeta"],
	availableQuantity?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStocksByStoreUniqueWhere"]: {
	store?: ValueTypes["StoreUniqueWhere"] | undefined | null
};
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductLocalesByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["ProductStockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductStockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockEdge"]: AliasType<{
	node?:ValueTypes["ProductStock"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	disabled?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["ChannelConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ChannelEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelEdge"]: AliasType<{
	node?:ValueTypes["Channel"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: ValueTypes["ChannelCode"] | undefined | null
};
	["PaymentMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["VatRateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["VatRateConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VatRateEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VatRateEdge"]: AliasType<{
	node?:ValueTypes["VatRate"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductPackingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingEdge"]: AliasType<{
	node?:ValueTypes["ProductPacking"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductRecipeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeEdge"]: AliasType<{
	node?:ValueTypes["ProductRecipe"],
		__typename?: boolean | `@${string}`
}>;
	["StoreConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StoreEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StoreEdge"]: AliasType<{
	node?:ValueTypes["Store"],
		__typename?: boolean | `@${string}`
}>;
	["TagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListEdge"]: AliasType<{
	node?:ValueTypes["ProductList"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryList"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ValueTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRating"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientRatingMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductIngredientRatingWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null
};
	["ProductIngredientRatingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ProductIngredientRatingConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientRatingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientRating"],
		__typename?: boolean | `@${string}`
}>;
	["BlogConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogEdge"]: AliasType<{
	node?:ValueTypes["Blog"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostEdge"]: AliasType<{
	node?:ValueTypes["BlogPost"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ColorPalleteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ColorPalleteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteEdge"]: AliasType<{
	node?:ValueTypes["ColorPallete"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["BlogPostListItemUniqueWhere"] | undefined | null
};
	["BlogPostListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["BlogPostListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListEdge"]: AliasType<{
	node?:ValueTypes["BlogPostList"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListEdge"]: AliasType<{
	node?:ValueTypes["LinkList"],
		__typename?: boolean | `@${string}`
}>;
	["CoverConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CoverEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CoverEdge"]: AliasType<{
	node?:ValueTypes["Cover"],
		__typename?: boolean | `@${string}`
}>;
	["LinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["MediumUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["MediumConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MediumEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumEdge"]: AliasType<{
	node?:ValueTypes["Medium"],
		__typename?: boolean | `@${string}`
}>;
	["VideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ValueTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationDomainEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ValueTypes["TranslationDomain"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCataloguesIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierEdge"]: AliasType<{
	node?:ValueTypes["TranslationCataloguesIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["SocialsAndAppsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SocialsAndAppsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsEdge"]: AliasType<{
	node?:ValueTypes["SocialsAndApps"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	categories?: ValueTypes["ProductGridCategoryUniqueWhere"] | undefined | null
};
	["ProductGridCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductGridItemUniqueWhere"] | undefined | null
};
	["ProductGridConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridEdge"]: AliasType<{
	node?:ValueTypes["ProductGrid"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["GalleryItemUniqueWhere"] | undefined | null
};
	["GalleryItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["GalleryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryEdge"]: AliasType<{
	node?:ValueTypes["Gallery"],
		__typename?: boolean | `@${string}`
}>;
	["HeroUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["HeroConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeroEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeroEdge"]: AliasType<{
	node?:ValueTypes["Hero"],
		__typename?: boolean | `@${string}`
}>;
	["SiteMenuConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SiteMenuEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SiteMenuEdge"]: AliasType<{
	node?:ValueTypes["SiteMenu"],
		__typename?: boolean | `@${string}`
}>;
	["PageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageEdge"]: AliasType<{
	node?:ValueTypes["Page"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["MenuLinkItemUniqueWhere"] | undefined | null
};
	["MenuLinkItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["MenuLinkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuLinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuLinkListEdge"]: AliasType<{
	node?:ValueTypes["MenuLinkList"],
		__typename?: boolean | `@${string}`
}>;
	["MenuProductsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	buttons?: ValueTypes["LinkListUniqueWhere"] | undefined | null
};
	["MenuProductsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuProductsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuProductsEdge"]: AliasType<{
	node?:ValueTypes["MenuProducts"],
		__typename?: boolean | `@${string}`
}>;
	["MenuDeliveryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsUniqueWhere"] | undefined | null
};
	["MenuDeliveryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuDeliveryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuDeliveryEdge"]: AliasType<{
	node?:ValueTypes["MenuDelivery"],
		__typename?: boolean | `@${string}`
}>;
	["MenuContactUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["MenuContactConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MenuContactEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MenuContactEdge"]: AliasType<{
	node?:ValueTypes["MenuContact"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null
};
	["ProductBannerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerEdge"]: AliasType<{
	node?:ValueTypes["ProductBanner"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListEdge"]: AliasType<{
	node?:ValueTypes["ProductBannerList"],
		__typename?: boolean | `@${string}`
}>;
	["GlobalConfigUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursUniqueWhere"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursUniqueWhere"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateUniqueWhere"] | undefined | null,
	freshingContainers?: ValueTypes["FreshingContainerUniqueWhere"] | undefined | null
};
	["WeekHoursUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["FreshingContainerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["GlobalConfigConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GlobalConfigEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GlobalConfigEdge"]: AliasType<{
	node?:ValueTypes["GlobalConfig"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["WeekHoursEdge"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursEdge"]: AliasType<{
	node?:ValueTypes["WeekHours"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["DeliveryTimelinePresetConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryTimelinePresetEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelinePresetEdge"]: AliasType<{
	node?:ValueTypes["DeliveryTimelinePreset"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VirtualProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductEdge"]: AliasType<{
	node?:ValueTypes["VirtualProduct"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataEdge"]: AliasType<{
	node?:ValueTypes["ProductMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ValueTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["TextListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextListEdge"]: AliasType<{
	node?:ValueTypes["TextList"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryRegionsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsEdge"]: AliasType<{
	node?:ValueTypes["DeliveryRegions"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["QueryTransaction"]: AliasType<{
getCountry?: [{	by: ValueTypes["CountryUniqueWhere"],	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
listCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Country"]],
paginateCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CountryConnection"]],
getChannel?: [{	by: ValueTypes["ChannelUniqueWhere"],	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
listChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
paginateChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
getDeliveryMethod?: [{	by: ValueTypes["DeliveryMethodUniqueWhere"],	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
listDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
paginateDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
getPaymentMethod?: [{	by: ValueTypes["PaymentMethodUniqueWhere"],	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
listPaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
paginatePaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
getVatRate?: [{	by: ValueTypes["VatRateUniqueWhere"],	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
listVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VatRate"]],
paginateVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VatRateConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
getProductStock?: [{	by: ValueTypes["ProductStockUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
listProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
paginateProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
getProductPacking?: [{	by: ValueTypes["ProductPackingUniqueWhere"],	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
listProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPacking"]],
paginateProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingConnection"]],
getProductRecipe?: [{	by: ValueTypes["ProductRecipeUniqueWhere"],	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
listProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipe"]],
paginateProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
getStore?: [{	by: ValueTypes["StoreUniqueWhere"],	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
listStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Store"]],
paginateStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StoreConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"],	filter?: ValueTypes["TagWhere"] | undefined | null},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"],	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageConnection"]],
getBusinessCategory?: [{	by: ValueTypes["BusinessCategoryUniqueWhere"],	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
listBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
getProductList?: [{	by: ValueTypes["ProductListUniqueWhere"],	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
listProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductList"]],
paginateProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListConnection"]],
getProductListItem?: [{	by: ValueTypes["ProductListItemUniqueWhere"],	filter?: ValueTypes["ProductListItemWhere"] | undefined | null},ValueTypes["ProductListItem"]],
listProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
paginateProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"],	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListConnection"]],
getProductCategoryListItem?: [{	by: ValueTypes["ProductCategoryListItemUniqueWhere"],	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null},ValueTypes["ProductCategoryListItem"]],
listProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
paginateProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
getBusinessCategoryLocale?: [{	by: ValueTypes["BusinessCategoryLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
listBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"],	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LocaleConnection"]],
getProductCategoryListLocale?: [{	by: ValueTypes["ProductCategoryListLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
listProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
getProductIngredientItem?: [{	by: ValueTypes["ProductIngredientItemUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null},ValueTypes["ProductIngredientItem"]],
listProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
paginateProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
getProductIngredient?: [{	by: ValueTypes["ProductIngredientUniqueWhere"],	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
listProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
paginateProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
getProductIngredientRating?: [{	by: ValueTypes["ProductIngredientRatingUniqueWhere"],	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null},ValueTypes["ProductIngredientRating"]],
listProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientRating"]],
paginateProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientRatingConnection"]],
getProductIngredientLocale?: [{	by: ValueTypes["ProductIngredientLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
listProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
getOrderRecurrence?: [{	by: ValueTypes["OrderRecurrenceUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
listOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
paginateOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
getOrderRecurrenceLocale?: [{	by: ValueTypes["OrderRecurrenceLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
listOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
getBlog?: [{	by: ValueTypes["BlogUniqueWhere"],	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
listBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Blog"]],
paginateBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogConnection"]],
getBlogLocale?: [{	by: ValueTypes["BlogLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
listBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
paginateBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"],	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
getColorPallete?: [{	by: ValueTypes["ColorPalleteUniqueWhere"],	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
listColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ColorPallete"]],
paginateColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ColorPalleteConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"],	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
getBlogPostList?: [{	by: ValueTypes["BlogPostListUniqueWhere"],	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
listBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostList"]],
paginateBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListConnection"]],
getBlogPostListItem?: [{	by: ValueTypes["BlogPostListItemUniqueWhere"],	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null},ValueTypes["BlogPostListItem"]],
listBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"],	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"],	filter?: ValueTypes["LinkListItemWhere"] | undefined | null},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
getCover?: [{	by: ValueTypes["CoverUniqueWhere"],	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
listCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cover"]],
paginateCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CoverConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"],	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"],	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkableConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"],	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MediumConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"],	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VideoConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"],	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SeoConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"],	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationDomainConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getSocialsAndApps?: [{	by: ValueTypes["SocialsAndAppsUniqueWhere"],	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
listSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SocialsAndApps"]],
paginateSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SocialsAndAppsConnection"]],
getProductGrid?: [{	by: ValueTypes["ProductGridUniqueWhere"],	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
listProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGrid"]],
paginateProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridConnection"]],
getProductGridItem?: [{	by: ValueTypes["ProductGridItemUniqueWhere"],	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null},ValueTypes["ProductGridItem"]],
listProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
paginateProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"],	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"],	filter?: ValueTypes["GalleryItemWhere"] | undefined | null},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
getHero?: [{	by: ValueTypes["HeroUniqueWhere"],	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
listHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Hero"]],
paginateHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["HeroConnection"]],
getSiteMenu?: [{	by: ValueTypes["SiteMenuUniqueWhere"],	filter?: ValueTypes["SiteMenuWhere"] | undefined | null},ValueTypes["SiteMenu"]],
listSiteMenu?: [{	filter?: ValueTypes["SiteMenuWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SiteMenuOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SiteMenu"]],
paginateSiteMenu?: [{	filter?: ValueTypes["SiteMenuWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SiteMenuOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SiteMenuConnection"]],
getMenuLocale?: [{	by: ValueTypes["MenuLocaleUniqueWhere"],	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null},ValueTypes["MenuLocale"]],
listMenuLocale?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLocale"]],
paginateMenuLocale?: [{	filter?: ValueTypes["MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLocaleConnection"]],
getMenuItem?: [{	by: ValueTypes["MenuItemUniqueWhere"],	filter?: ValueTypes["MenuItemWhere"] | undefined | null},ValueTypes["MenuItem"]],
listMenuItem?: [{	filter?: ValueTypes["MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuItem"]],
paginateMenuItem?: [{	filter?: ValueTypes["MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuItemConnection"]],
getPage?: [{	by: ValueTypes["PageUniqueWhere"],	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
listPage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Page"]],
paginatePage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageConnection"]],
getPageLocale?: [{	by: ValueTypes["PageLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
getWikiPage?: [{	by: ValueTypes["WikiPageUniqueWhere"],	filter?: ValueTypes["WikiPageWhere"] | undefined | null},ValueTypes["WikiPage"]],
listWikiPage?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WikiPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WikiPage"]],
paginateWikiPage?: [{	filter?: ValueTypes["WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WikiPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WikiPageConnection"]],
getMenuSubitem?: [{	by: ValueTypes["MenuSubitemUniqueWhere"],	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null},ValueTypes["MenuSubitem"]],
listMenuSubitem?: [{	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuSubitemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuSubitem"]],
paginateMenuSubitem?: [{	filter?: ValueTypes["MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuSubitemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuSubitemConnection"]],
getMenuLinkList?: [{	by: ValueTypes["MenuLinkListUniqueWhere"],	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null},ValueTypes["MenuLinkList"]],
listMenuLinkList?: [{	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLinkList"]],
paginateMenuLinkList?: [{	filter?: ValueTypes["MenuLinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLinkListConnection"]],
getMenuLinkItem?: [{	by: ValueTypes["MenuLinkItemUniqueWhere"],	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null},ValueTypes["MenuLinkItem"]],
listMenuLinkItem?: [{	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuLinkItem"]],
paginateMenuLinkItem?: [{	filter?: ValueTypes["MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuLinkItemConnection"]],
getMenuProducts?: [{	by: ValueTypes["MenuProductsUniqueWhere"],	filter?: ValueTypes["MenuProductsWhere"] | undefined | null},ValueTypes["MenuProducts"]],
listMenuProducts?: [{	filter?: ValueTypes["MenuProductsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuProductsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuProducts"]],
paginateMenuProducts?: [{	filter?: ValueTypes["MenuProductsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuProductsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuProductsConnection"]],
getMenuDelivery?: [{	by: ValueTypes["MenuDeliveryUniqueWhere"],	filter?: ValueTypes["MenuDeliveryWhere"] | undefined | null},ValueTypes["MenuDelivery"]],
listMenuDelivery?: [{	filter?: ValueTypes["MenuDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuDelivery"]],
paginateMenuDelivery?: [{	filter?: ValueTypes["MenuDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuDeliveryConnection"]],
getMenuContact?: [{	by: ValueTypes["MenuContactUniqueWhere"],	filter?: ValueTypes["MenuContactWhere"] | undefined | null},ValueTypes["MenuContact"]],
listMenuContact?: [{	filter?: ValueTypes["MenuContactWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuContactOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["MenuContact"]],
paginateMenuContact?: [{	filter?: ValueTypes["MenuContactWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MenuContactOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MenuContactConnection"]],
getProductBanner?: [{	by: ValueTypes["ProductBannerUniqueWhere"],	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
listProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBanner"]],
paginateProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerConnection"]],
getProductBannerItem?: [{	by: ValueTypes["ProductBannerItemUniqueWhere"],	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null},ValueTypes["ProductBannerItem"]],
listProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
getProductBannerList?: [{	by: ValueTypes["ProductBannerListUniqueWhere"],	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
listProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerList"]],
paginateProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerListConnection"]],
getAllergen?: [{	by: ValueTypes["AllergenUniqueWhere"],	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
listAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
paginateAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
getAllergenLocale?: [{	by: ValueTypes["AllergenLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
listAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
paginateAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
getGlobalConfig?: [{	by: ValueTypes["GlobalConfigUniqueWhere"],	filter?: ValueTypes["GlobalConfigWhere"] | undefined | null},ValueTypes["GlobalConfig"]],
listGlobalConfig?: [{	filter?: ValueTypes["GlobalConfigWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GlobalConfigOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GlobalConfig"]],
paginateGlobalConfig?: [{	filter?: ValueTypes["GlobalConfigWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GlobalConfigOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GlobalConfigConnection"]],
getWeekHours?: [{	by: ValueTypes["WeekHoursUniqueWhere"],	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
listWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WeekHours"]],
paginateWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WeekHoursConnection"]],
getDeliveryZone?: [{	by: ValueTypes["DeliveryZoneUniqueWhere"],	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
listDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
paginateDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
getDeliveryTimelinePreset?: [{	by: ValueTypes["DeliveryTimelinePresetUniqueWhere"],	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
listDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
paginateDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryTimelinePresetConnection"]],
getProductPackingLocale?: [{	by: ValueTypes["ProductPackingLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
listProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
getProductRecipeLocale?: [{	by: ValueTypes["ProductRecipeLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
listProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
getVirtualProduct?: [{	by: ValueTypes["VirtualProductUniqueWhere"],	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
listVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProduct"]],
paginateVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductConnection"]],
getProductMetadata?: [{	by: ValueTypes["ProductMetadataUniqueWhere"],	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
listProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductMetadata"]],
paginateProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductMetadataConnection"]],
getProductGridCategory?: [{	by: ValueTypes["ProductGridCategoryUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
listProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
paginateProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"],	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["RedirectConnection"]],
getFreshingContainer?: [{	by: ValueTypes["FreshingContainerUniqueWhere"],	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
listFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
paginateFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"],	filter?: ValueTypes["TextItemWhere"] | undefined | null},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"],	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextListConnection"]],
getDeliveryRegions?: [{	by: ValueTypes["DeliveryRegionsUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
listDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegions"]],
paginateDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsConnection"]],
getDeliveryRegionsItem?: [{	by: ValueTypes["DeliveryRegionsItemUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
listDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"],	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
getDeliveryMethodLocale?: [{	by: ValueTypes["DeliveryMethodLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
listDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginateDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Json"]:unknown;
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ValueTypes["_Field"],
	unique?:ValueTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"];
		['...on _Column']?: Omit<ValueTypes["_Column"],keyof ValueTypes["_Field"]>;
		['...on _Relation']?: Omit<ValueTypes["_Relation"],keyof ValueTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_RelationSide"]:_RelationSide;
	["_OrderByDirection"]:_OrderByDirection;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ValueTypes["_OrderBy"],
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ValueTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getSiteMenu?: GraphQLTypes["SiteMenu"] | undefined,
	listSiteMenu: Array<GraphQLTypes["SiteMenu"]>,
	paginateSiteMenu: GraphQLTypes["SiteMenuConnection"],
	getMenuLocale?: GraphQLTypes["MenuLocale"] | undefined,
	listMenuLocale: Array<GraphQLTypes["MenuLocale"]>,
	paginateMenuLocale: GraphQLTypes["MenuLocaleConnection"],
	getMenuItem?: GraphQLTypes["MenuItem"] | undefined,
	listMenuItem: Array<GraphQLTypes["MenuItem"]>,
	paginateMenuItem: GraphQLTypes["MenuItemConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getWikiPage?: GraphQLTypes["WikiPage"] | undefined,
	listWikiPage: Array<GraphQLTypes["WikiPage"]>,
	paginateWikiPage: GraphQLTypes["WikiPageConnection"],
	getMenuSubitem?: GraphQLTypes["MenuSubitem"] | undefined,
	listMenuSubitem: Array<GraphQLTypes["MenuSubitem"]>,
	paginateMenuSubitem: GraphQLTypes["MenuSubitemConnection"],
	getMenuLinkList?: GraphQLTypes["MenuLinkList"] | undefined,
	listMenuLinkList: Array<GraphQLTypes["MenuLinkList"]>,
	paginateMenuLinkList: GraphQLTypes["MenuLinkListConnection"],
	getMenuLinkItem?: GraphQLTypes["MenuLinkItem"] | undefined,
	listMenuLinkItem: Array<GraphQLTypes["MenuLinkItem"]>,
	paginateMenuLinkItem: GraphQLTypes["MenuLinkItemConnection"],
	getMenuProducts?: GraphQLTypes["MenuProducts"] | undefined,
	listMenuProducts: Array<GraphQLTypes["MenuProducts"]>,
	paginateMenuProducts: GraphQLTypes["MenuProductsConnection"],
	getMenuDelivery?: GraphQLTypes["MenuDelivery"] | undefined,
	listMenuDelivery: Array<GraphQLTypes["MenuDelivery"]>,
	paginateMenuDelivery: GraphQLTypes["MenuDeliveryConnection"],
	getMenuContact?: GraphQLTypes["MenuContact"] | undefined,
	listMenuContact: Array<GraphQLTypes["MenuContact"]>,
	paginateMenuContact: GraphQLTypes["MenuContactConnection"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGlobalConfig?: GraphQLTypes["GlobalConfig"] | undefined,
	listGlobalConfig: Array<GraphQLTypes["GlobalConfig"]>,
	paginateGlobalConfig: GraphQLTypes["GlobalConfigConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined,
	schema?: GraphQLTypes["_Schema"] | undefined
};
	["Country"]: {
		_meta?: GraphQLTypes["CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string
};
	["CountryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
		readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]:string;
	["CountryUniqueWhere"]: GraphQLTypes["CountryUniqueWhere"];
	["CountryWhere"]: GraphQLTypes["CountryWhere"];
	["UUIDCondition"]: GraphQLTypes["UUIDCondition"];
	["StringCondition"]: GraphQLTypes["StringCondition"];
	["CountryOrderBy"]: GraphQLTypes["CountryOrderBy"];
	["OrderDirection"]: GraphQLTypes["OrderDirection"];
	["CountryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CountryEdge"]>
};
	["PageInfo"]: {
		totalCount: number
};
	["CountryEdge"]: {
		node: GraphQLTypes["Country"]
};
	["Channel"]: {
		_meta?: GraphQLTypes["ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	code?: GraphQLTypes["ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Tag"]>,
	publicTags: Array<GraphQLTypes["Tag"]>,
	paginateAddTagsOnRegistration: GraphQLTypes["TagConnection"],
	paginatePublicTags: GraphQLTypes["TagConnection"]
};
	["ChannelMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	disabled?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["FieldMeta"] | undefined
};
	["ChannelCode"]: GraphQLTypes["ChannelCode"];
	["Tag"]: {
		_meta?: GraphQLTypes["TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Channel"]>,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Channel"]>,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["ChannelConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["ChannelConnection"]
};
	["TagMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["FieldMeta"] | undefined
};
	["Product"]: {
		_meta?: GraphQLTypes["ProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["ProductStock"]>,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	availability: GraphQLTypes["ProductAvailability"],
	order: number,
	maximumQuantityPerOrder?: number | undefined,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	locales: Array<GraphQLTypes["ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	meta?: GraphQLTypes["ProductMetadata"] | undefined,
	page?: GraphQLTypes["Page"] | undefined,
	stocksByStore?: GraphQLTypes["ProductStock"] | undefined,
	localesByLocale?: GraphQLTypes["ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["ProductStockConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["ProductLocaleConnection"]
};
	["ProductMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	stocks?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	availability?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPacking"]: {
		_meta?: GraphQLTypes["ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["ProductPackingLocale"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductPackingLocaleConnection"]
};
	["ProductPackingMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
		_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["ImageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageWhere"]: GraphQLTypes["ImageWhere"];
	["IntCondition"]: GraphQLTypes["IntCondition"];
	["ProductPackingLocale"]: {
		_meta?: GraphQLTypes["ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["ProductPacking"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductPackingLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPackingWhere"]: GraphQLTypes["ProductPackingWhere"];
	["ProductPackingLocaleWhere"]: GraphQLTypes["ProductPackingLocaleWhere"];
	["LocaleWhere"]: GraphQLTypes["LocaleWhere"];
	["BusinessCategoryLocaleWhere"]: GraphQLTypes["BusinessCategoryLocaleWhere"];
	["BusinessCategoryWhere"]: GraphQLTypes["BusinessCategoryWhere"];
	["BooleanCondition"]: GraphQLTypes["BooleanCondition"];
	["OrderRecurrenceWhere"]: GraphQLTypes["OrderRecurrenceWhere"];
	["OrderRecurrenceLocaleWhere"]: GraphQLTypes["OrderRecurrenceLocaleWhere"];
	["ProductListWhere"]: GraphQLTypes["ProductListWhere"];
	["ProductListItemWhere"]: GraphQLTypes["ProductListItemWhere"];
	["ProductWhere"]: GraphQLTypes["ProductWhere"];
	["ProductRecipeWhere"]: GraphQLTypes["ProductRecipeWhere"];
	["ProductIngredientItemWhere"]: GraphQLTypes["ProductIngredientItemWhere"];
	["ProductIngredientWhere"]: GraphQLTypes["ProductIngredientWhere"];
	["ProductIngredientLocaleWhere"]: GraphQLTypes["ProductIngredientLocaleWhere"];
	["AllergenWhere"]: GraphQLTypes["AllergenWhere"];
	["AllergenLocaleWhere"]: GraphQLTypes["AllergenLocaleWhere"];
	["FreshingContainerWhere"]: GraphQLTypes["FreshingContainerWhere"];
	["GlobalConfigWhere"]: GraphQLTypes["GlobalConfigWhere"];
	["WeekHoursWhere"]: GraphQLTypes["WeekHoursWhere"];
	["OneCondition"]: GraphQLTypes["OneCondition"];
	["One"]: GraphQLTypes["One"];
	["VirtualProductWhere"]: GraphQLTypes["VirtualProductWhere"];
	["VirtualProductTypeCondition"]: GraphQLTypes["VirtualProductTypeCondition"];
	["VirtualProductType"]: GraphQLTypes["VirtualProductType"];
	["VirtualProductPhysicalRepresentationTypeCondition"]: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"];
	["VirtualProductPhysicalRepresentationType"]: GraphQLTypes["VirtualProductPhysicalRepresentationType"];
	["VatRateWhere"]: GraphQLTypes["VatRateWhere"];
	["ProductRecipeLocaleWhere"]: GraphQLTypes["ProductRecipeLocaleWhere"];
	["FloatCondition"]: GraphQLTypes["FloatCondition"];
	["TagWhere"]: GraphQLTypes["TagWhere"];
	["ProductCategoryWhere"]: GraphQLTypes["ProductCategoryWhere"];
	["ChannelWhere"]: GraphQLTypes["ChannelWhere"];
	["ChannelCodeCondition"]: GraphQLTypes["ChannelCodeCondition"];
	["DeliveryZoneWhere"]: GraphQLTypes["DeliveryZoneWhere"];
	["DeliveryZoneTypeCondition"]: GraphQLTypes["DeliveryZoneTypeCondition"];
	["DeliveryZoneType"]: GraphQLTypes["DeliveryZoneType"];
	["DeliveryMethodWhere"]: GraphQLTypes["DeliveryMethodWhere"];
	["PaymentMethodWhere"]: GraphQLTypes["PaymentMethodWhere"];
	["PaymentMethodTypeCondition"]: GraphQLTypes["PaymentMethodTypeCondition"];
	["PaymentMethodType"]: GraphQLTypes["PaymentMethodType"];
	["DeliveryTimelinePresetWhere"]: GraphQLTypes["DeliveryTimelinePresetWhere"];
	["DeliveryTimelineTypeCondition"]: GraphQLTypes["DeliveryTimelineTypeCondition"];
	["DeliveryTimelineType"]: GraphQLTypes["DeliveryTimelineType"];
	["DeliveryMethodLocaleWhere"]: GraphQLTypes["DeliveryMethodLocaleWhere"];
	["ProductStockWhere"]: GraphQLTypes["ProductStockWhere"];
	["StoreWhere"]: GraphQLTypes["StoreWhere"];
	["ProductAvailabilityCondition"]: GraphQLTypes["ProductAvailabilityCondition"];
	["ProductAvailability"]: GraphQLTypes["ProductAvailability"];
	["ProductLocaleWhere"]: GraphQLTypes["ProductLocaleWhere"];
	["TextListWhere"]: GraphQLTypes["TextListWhere"];
	["TextItemWhere"]: GraphQLTypes["TextItemWhere"];
	["DateTimeCondition"]: GraphQLTypes["DateTimeCondition"];
	["DateTime"]:string;
	["ProductMetadataWhere"]: GraphQLTypes["ProductMetadataWhere"];
	["PageWhere"]: GraphQLTypes["PageWhere"];
	["PageLocaleWhere"]: GraphQLTypes["PageLocaleWhere"];
	["LinkableWhere"]: GraphQLTypes["LinkableWhere"];
	["BlogPostLocaleWhere"]: GraphQLTypes["BlogPostLocaleWhere"];
	["BlogPostWhere"]: GraphQLTypes["BlogPostWhere"];
	["CoverWhere"]: GraphQLTypes["CoverWhere"];
	["MediumWhere"]: GraphQLTypes["MediumWhere"];
	["MediumTypeCondition"]: GraphQLTypes["MediumTypeCondition"];
	["MediumType"]: GraphQLTypes["MediumType"];
	["ColorThemeCondition"]: GraphQLTypes["ColorThemeCondition"];
	["ColorTheme"]: GraphQLTypes["ColorTheme"];
	["VideoWhere"]: GraphQLTypes["VideoWhere"];
	["ColorPalleteWhere"]: GraphQLTypes["ColorPalleteWhere"];
	["ContentWhere"]: GraphQLTypes["ContentWhere"];
	["ContentBlockWhere"]: GraphQLTypes["ContentBlockWhere"];
	["ContentReferenceWhere"]: GraphQLTypes["ContentReferenceWhere"];
	["ContentReferenceTypeCondition"]: GraphQLTypes["ContentReferenceTypeCondition"];
	["ContentReferenceType"]: GraphQLTypes["ContentReferenceType"];
	["LinkWhere"]: GraphQLTypes["LinkWhere"];
	["LinkTypeCondition"]: GraphQLTypes["LinkTypeCondition"];
	["LinkType"]: GraphQLTypes["LinkType"];
	["BlogPostListWhere"]: GraphQLTypes["BlogPostListWhere"];
	["BlogPostListItemWhere"]: GraphQLTypes["BlogPostListItemWhere"];
	["LinkListWhere"]: GraphQLTypes["LinkListWhere"];
	["LinkListItemWhere"]: GraphQLTypes["LinkListItemWhere"];
	["ContentSizeCondition"]: GraphQLTypes["ContentSizeCondition"];
	["ContentSize"]: GraphQLTypes["ContentSize"];
	["HeroWhere"]: GraphQLTypes["HeroWhere"];
	["GalleryWhere"]: GraphQLTypes["GalleryWhere"];
	["GalleryItemWhere"]: GraphQLTypes["GalleryItemWhere"];
	["SocialsAndAppsWhere"]: GraphQLTypes["SocialsAndAppsWhere"];
	["ProductGridWhere"]: GraphQLTypes["ProductGridWhere"];
	["ProductGridCategoryWhere"]: GraphQLTypes["ProductGridCategoryWhere"];
	["ProductGridItemWhere"]: GraphQLTypes["ProductGridItemWhere"];
	["ProductGridTypeCondition"]: GraphQLTypes["ProductGridTypeCondition"];
	["ProductGridType"]: GraphQLTypes["ProductGridType"];
	["ProductBannerListWhere"]: GraphQLTypes["ProductBannerListWhere"];
	["ProductBannerItemWhere"]: GraphQLTypes["ProductBannerItemWhere"];
	["ProductBannerWhere"]: GraphQLTypes["ProductBannerWhere"];
	["ProductBannerItemSizeCondition"]: GraphQLTypes["ProductBannerItemSizeCondition"];
	["ProductBannerItemSize"]: GraphQLTypes["ProductBannerItemSize"];
	["DeliveryRegionsWhere"]: GraphQLTypes["DeliveryRegionsWhere"];
	["DeliveryRegionsItemWhere"]: GraphQLTypes["DeliveryRegionsItemWhere"];
	["ImageListWhere"]: GraphQLTypes["ImageListWhere"];
	["ImageListItemWhere"]: GraphQLTypes["ImageListItemWhere"];
	["SeoWhere"]: GraphQLTypes["SeoWhere"];
	["WikiPageWhere"]: GraphQLTypes["WikiPageWhere"];
	["RedirectWhere"]: GraphQLTypes["RedirectWhere"];
	["PageThemeCondition"]: GraphQLTypes["PageThemeCondition"];
	["PageTheme"]: GraphQLTypes["PageTheme"];
	["ProductCategoryListWhere"]: GraphQLTypes["ProductCategoryListWhere"];
	["ProductCategoryListItemWhere"]: GraphQLTypes["ProductCategoryListItemWhere"];
	["ProductCategoryListLocaleWhere"]: GraphQLTypes["ProductCategoryListLocaleWhere"];
	["BlogLocaleWhere"]: GraphQLTypes["BlogLocaleWhere"];
	["BlogWhere"]: GraphQLTypes["BlogWhere"];
	["TranslationCataloguesIdentifierWhere"]: GraphQLTypes["TranslationCataloguesIdentifierWhere"];
	["TranslationCatalogueWhere"]: GraphQLTypes["TranslationCatalogueWhere"];
	["TranslationDomainWhere"]: GraphQLTypes["TranslationDomainWhere"];
	["TranslationKeyWhere"]: GraphQLTypes["TranslationKeyWhere"];
	["TranslationContentTypeCondition"]: GraphQLTypes["TranslationContentTypeCondition"];
	["TranslationContentType"]: GraphQLTypes["TranslationContentType"];
	["TranslationValueWhere"]: GraphQLTypes["TranslationValueWhere"];
	["MenuLocaleWhere"]: GraphQLTypes["MenuLocaleWhere"];
	["SiteMenuWhere"]: GraphQLTypes["SiteMenuWhere"];
	["MenuItemWhere"]: GraphQLTypes["MenuItemWhere"];
	["MenuSubitemWhere"]: GraphQLTypes["MenuSubitemWhere"];
	["MenuSubitemTypeCondition"]: GraphQLTypes["MenuSubitemTypeCondition"];
	["MenuSubitemType"]: GraphQLTypes["MenuSubitemType"];
	["MenuLinkListWhere"]: GraphQLTypes["MenuLinkListWhere"];
	["MenuLinkItemWhere"]: GraphQLTypes["MenuLinkItemWhere"];
	["MenuProductsWhere"]: GraphQLTypes["MenuProductsWhere"];
	["MenuDeliveryWhere"]: GraphQLTypes["MenuDeliveryWhere"];
	["MenuContactWhere"]: GraphQLTypes["MenuContactWhere"];
	["Locale"]: {
		_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPostLocale"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	menus: Array<GraphQLTypes["MenuLocale"]>,
	pages: Array<GraphQLTypes["PageLocale"]>,
	allergens: Array<GraphQLTypes["AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["ProductPackingLocale"]>,
	products: Array<GraphQLTypes["ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["ProductRecipeLocale"]>,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	menusByRoot?: GraphQLTypes["MenuLocale"] | undefined,
	menusByItems?: GraphQLTypes["MenuLocale"] | undefined,
	menusBySecondaryItems?: GraphQLTypes["MenuLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["PageLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostLocaleConnection"],
	paginateMenus: GraphQLTypes["MenuLocaleConnection"],
	paginatePages: GraphQLTypes["PageLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["ProductRecipeLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["LocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["FieldMeta"] | undefined,
	blogs?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	menus?: GraphQLTypes["FieldMeta"] | undefined,
	pages?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocale"]: {
		_meta?: GraphQLTypes["BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	title?: string | undefined
};
	["BusinessCategoryLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategory"]: {
		_meta?: GraphQLTypes["BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	internalName: string,
	recurrences: Array<GraphQLTypes["OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	paginateLocales: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateRecurrences: GraphQLTypes["OrderRecurrenceConnection"]
};
	["BusinessCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocaleOrderBy"]: GraphQLTypes["BusinessCategoryLocaleOrderBy"];
	["BusinessCategoryOrderBy"]: GraphQLTypes["BusinessCategoryOrderBy"];
	["ProductListOrderBy"]: GraphQLTypes["ProductListOrderBy"];
	["ProductCategoryListOrderBy"]: GraphQLTypes["ProductCategoryListOrderBy"];
	["LocaleOrderBy"]: GraphQLTypes["LocaleOrderBy"];
	["TranslationCataloguesIdentifierOrderBy"]: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"];
	["OrderRecurrence"]: {
		_meta?: GraphQLTypes["OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"]
};
	["OrderRecurrenceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocale"]: {
		_meta?: GraphQLTypes["OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["OrderRecurrenceLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocaleOrderBy"]: GraphQLTypes["OrderRecurrenceLocaleOrderBy"];
	["OrderRecurrenceOrderBy"]: GraphQLTypes["OrderRecurrenceOrderBy"];
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: GraphQLTypes["OrderRecurrenceLocalesByLocaleUniqueWhere"];
	["LocaleUniqueWhere"]: GraphQLTypes["LocaleUniqueWhere"];
	["BusinessCategoryLocaleUniqueWhere"]: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"];
	["BusinessCategoryUniqueWhere"]: GraphQLTypes["BusinessCategoryUniqueWhere"];
	["ProductListUniqueWhere"]: GraphQLTypes["ProductListUniqueWhere"];
	["ProductListItemUniqueWhere"]: GraphQLTypes["ProductListItemUniqueWhere"];
	["ProductCategoryListUniqueWhere"]: GraphQLTypes["ProductCategoryListUniqueWhere"];
	["ProductCategoryListItemUniqueWhere"]: GraphQLTypes["ProductCategoryListItemUniqueWhere"];
	["ProductCategoryListLocaleUniqueWhere"]: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"];
	["ProductIngredientLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientLocaleUniqueWhere"];
	["ProductIngredientUniqueWhere"]: GraphQLTypes["ProductIngredientUniqueWhere"];
	["OrderRecurrenceLocaleUniqueWhere"]: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"];
	["OrderRecurrenceUniqueWhere"]: GraphQLTypes["OrderRecurrenceUniqueWhere"];
	["BlogLocaleUniqueWhere"]: GraphQLTypes["BlogLocaleUniqueWhere"];
	["BlogUniqueWhere"]: GraphQLTypes["BlogUniqueWhere"];
	["BlogPostLocaleUniqueWhere"]: GraphQLTypes["BlogPostLocaleUniqueWhere"];
	["BlogPostUniqueWhere"]: GraphQLTypes["BlogPostUniqueWhere"];
	["CoverUniqueWhere"]: GraphQLTypes["CoverUniqueWhere"];
	["ContentUniqueWhere"]: GraphQLTypes["ContentUniqueWhere"];
	["ContentBlockUniqueWhere"]: GraphQLTypes["ContentBlockUniqueWhere"];
	["ContentReferenceUniqueWhere"]: GraphQLTypes["ContentReferenceUniqueWhere"];
	["ProductBannerListUniqueWhere"]: GraphQLTypes["ProductBannerListUniqueWhere"];
	["ProductBannerItemUniqueWhere"]: GraphQLTypes["ProductBannerItemUniqueWhere"];
	["DeliveryRegionsUniqueWhere"]: GraphQLTypes["DeliveryRegionsUniqueWhere"];
	["DeliveryRegionsItemUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemUniqueWhere"];
	["ImageUniqueWhere"]: GraphQLTypes["ImageUniqueWhere"];
	["TextListUniqueWhere"]: GraphQLTypes["TextListUniqueWhere"];
	["TextItemUniqueWhere"]: GraphQLTypes["TextItemUniqueWhere"];
	["ImageListUniqueWhere"]: GraphQLTypes["ImageListUniqueWhere"];
	["ImageListItemUniqueWhere"]: GraphQLTypes["ImageListItemUniqueWhere"];
	["LinkableUniqueWhere"]: GraphQLTypes["LinkableUniqueWhere"];
	["PageLocaleUniqueWhere"]: GraphQLTypes["PageLocaleUniqueWhere"];
	["PageUniqueWhere"]: GraphQLTypes["PageUniqueWhere"];
	["SeoUniqueWhere"]: GraphQLTypes["SeoUniqueWhere"];
	["WikiPageUniqueWhere"]: GraphQLTypes["WikiPageUniqueWhere"];
	["RedirectUniqueWhere"]: GraphQLTypes["RedirectUniqueWhere"];
	["TranslationCataloguesIdentifierUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"];
	["TranslationCatalogueUniqueWhere"]: GraphQLTypes["TranslationCatalogueUniqueWhere"];
	["TranslationDomainUniqueWhere"]: GraphQLTypes["TranslationDomainUniqueWhere"];
	["TranslationKeyUniqueWhere"]: GraphQLTypes["TranslationKeyUniqueWhere"];
	["TranslationValueUniqueWhere"]: GraphQLTypes["TranslationValueUniqueWhere"];
	["MenuLocaleUniqueWhere"]: GraphQLTypes["MenuLocaleUniqueWhere"];
	["SiteMenuUniqueWhere"]: GraphQLTypes["SiteMenuUniqueWhere"];
	["MenuItemUniqueWhere"]: GraphQLTypes["MenuItemUniqueWhere"];
	["MenuSubitemUniqueWhere"]: GraphQLTypes["MenuSubitemUniqueWhere"];
	["LinkListUniqueWhere"]: GraphQLTypes["LinkListUniqueWhere"];
	["LinkListItemUniqueWhere"]: GraphQLTypes["LinkListItemUniqueWhere"];
	["AllergenLocaleUniqueWhere"]: GraphQLTypes["AllergenLocaleUniqueWhere"];
	["AllergenUniqueWhere"]: GraphQLTypes["AllergenUniqueWhere"];
	["ProductPackingLocaleUniqueWhere"]: GraphQLTypes["ProductPackingLocaleUniqueWhere"];
	["ProductPackingUniqueWhere"]: GraphQLTypes["ProductPackingUniqueWhere"];
	["ProductLocaleUniqueWhere"]: GraphQLTypes["ProductLocaleUniqueWhere"];
	["ProductUniqueWhere"]: GraphQLTypes["ProductUniqueWhere"];
	["ProductStockUniqueWhere"]: GraphQLTypes["ProductStockUniqueWhere"];
	["StoreUniqueWhere"]: GraphQLTypes["StoreUniqueWhere"];
	["VirtualProductUniqueWhere"]: GraphQLTypes["VirtualProductUniqueWhere"];
	["ProductMetadataUniqueWhere"]: GraphQLTypes["ProductMetadataUniqueWhere"];
	["ProductRecipeLocaleUniqueWhere"]: GraphQLTypes["ProductRecipeLocaleUniqueWhere"];
	["ProductRecipeUniqueWhere"]: GraphQLTypes["ProductRecipeUniqueWhere"];
	["ProductIngredientItemUniqueWhere"]: GraphQLTypes["ProductIngredientItemUniqueWhere"];
	["DeliveryMethodLocaleUniqueWhere"]: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"];
	["DeliveryMethodUniqueWhere"]: GraphQLTypes["DeliveryMethodUniqueWhere"];
	["DeliveryTimelinePresetUniqueWhere"]: GraphQLTypes["DeliveryTimelinePresetUniqueWhere"];
	["OrderRecurrenceLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceLocaleEdge"]>
};
	["OrderRecurrenceLocaleEdge"]: {
		node: GraphQLTypes["OrderRecurrenceLocale"]
};
	["BusinessCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryEdge"]>
};
	["BusinessCategoryEdge"]: {
		node: GraphQLTypes["BusinessCategory"]
};
	["ProductList"]: {
		_meta?: GraphQLTypes["ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductListItem"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["ProductListItemConnection"]
};
	["ProductListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItem"]: {
		_meta?: GraphQLTypes["ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["ProductListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItemOrderBy"]: GraphQLTypes["ProductListItemOrderBy"];
	["ProductOrderBy"]: GraphQLTypes["ProductOrderBy"];
	["ProductPackingOrderBy"]: GraphQLTypes["ProductPackingOrderBy"];
	["ImageOrderBy"]: GraphQLTypes["ImageOrderBy"];
	["ProductRecipeOrderBy"]: GraphQLTypes["ProductRecipeOrderBy"];
	["FreshingContainerOrderBy"]: GraphQLTypes["FreshingContainerOrderBy"];
	["GlobalConfigOrderBy"]: GraphQLTypes["GlobalConfigOrderBy"];
	["WeekHoursOrderBy"]: GraphQLTypes["WeekHoursOrderBy"];
	["VirtualProductOrderBy"]: GraphQLTypes["VirtualProductOrderBy"];
	["VatRateOrderBy"]: GraphQLTypes["VatRateOrderBy"];
	["ProductMetadataOrderBy"]: GraphQLTypes["ProductMetadataOrderBy"];
	["PageOrderBy"]: GraphQLTypes["PageOrderBy"];
	["ProductListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListItemEdge"]>
};
	["ProductListItemEdge"]: {
		node: GraphQLTypes["ProductListItem"]
};
	["ProductCategoryList"]: {
		_meta?: GraphQLTypes["ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["ProductCategoryListLocaleConnection"]
};
	["ProductCategoryListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListItem"]: {
		_meta?: GraphQLTypes["ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["ProductCategoryListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
		_meta?: GraphQLTypes["ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	parent?: GraphQLTypes["ProductCategory"] | undefined,
	children: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	order: number,
	childrenByCode?: GraphQLTypes["ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateChildren: GraphQLTypes["ProductCategoryConnection"]
};
	["ProductCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagOrderBy"]: GraphQLTypes["TagOrderBy"];
	["ProductCategoryOrderBy"]: GraphQLTypes["ProductCategoryOrderBy"];
	["ProductCategoryChildrenByCodeUniqueWhere"]: GraphQLTypes["ProductCategoryChildrenByCodeUniqueWhere"];
	["ProductCategoryChildrenByChildrenUniqueWhere"]: GraphQLTypes["ProductCategoryChildrenByChildrenUniqueWhere"];
	["ProductCategoryUniqueWhere"]: GraphQLTypes["ProductCategoryUniqueWhere"];
	["ProductConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
		node: GraphQLTypes["Product"]
};
	["TagConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagEdge"]>
};
	["TagEdge"]: {
		node: GraphQLTypes["Tag"]
};
	["ProductCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
		node: GraphQLTypes["ProductCategory"]
};
	["ProductCategoryListItemOrderBy"]: GraphQLTypes["ProductCategoryListItemOrderBy"];
	["ProductCategoryListLocale"]: {
		_meta?: GraphQLTypes["ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductCategoryListLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListLocaleOrderBy"]: GraphQLTypes["ProductCategoryListLocaleOrderBy"];
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductCategoryListLocalesByLocaleUniqueWhere"];
	["ProductCategoryListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListItemEdge"]>
};
	["ProductCategoryListItemEdge"]: {
		node: GraphQLTypes["ProductCategoryListItem"]
};
	["ProductCategoryListLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListLocaleEdge"]>
};
	["ProductCategoryListLocaleEdge"]: {
		node: GraphQLTypes["ProductCategoryListLocale"]
};
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: GraphQLTypes["BusinessCategoryLocalesByLocaleUniqueWhere"];
	["BusinessCategoryLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryLocaleEdge"]>
};
	["BusinessCategoryLocaleEdge"]: {
		node: GraphQLTypes["BusinessCategoryLocale"]
};
	["OrderRecurrenceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceEdge"]>
};
	["OrderRecurrenceEdge"]: {
		node: GraphQLTypes["OrderRecurrence"]
};
	["ProductIngredientLocale"]: {
		_meta?: GraphQLTypes["ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredient"]: {
		_meta?: GraphQLTypes["ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["ProductIngredientLocale"]>,
	internalName: string,
	allergens: Array<GraphQLTypes["Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	localesByLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenConnection"]
};
	["ProductIngredientMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientLocaleOrderBy"]: GraphQLTypes["ProductIngredientLocaleOrderBy"];
	["ProductIngredientOrderBy"]: GraphQLTypes["ProductIngredientOrderBy"];
	["Allergen"]: {
		_meta?: GraphQLTypes["AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"]
};
	["AllergenMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocale"]: {
		_meta?: GraphQLTypes["AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Allergen"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["AllergenLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocaleOrderBy"]: GraphQLTypes["AllergenLocaleOrderBy"];
	["AllergenOrderBy"]: GraphQLTypes["AllergenOrderBy"];
	["AllergenLocalesByLocaleUniqueWhere"]: GraphQLTypes["AllergenLocalesByLocaleUniqueWhere"];
	["AllergenLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenLocaleEdge"]>
};
	["AllergenLocaleEdge"]: {
		node: GraphQLTypes["AllergenLocale"]
};
	["ProductIngredientConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientEdge"]>
};
	["ProductIngredientEdge"]: {
		node: GraphQLTypes["ProductIngredient"]
};
	["FreshingContainer"]: {
		_meta?: GraphQLTypes["FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	config?: GraphQLTypes["GlobalConfig"] | undefined,
	volumeMl: number
};
	["FreshingContainerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	config?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined
};
	["GlobalConfig"]: {
		_meta?: GraphQLTypes["GlobalConfigMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	openingHours?: GraphQLTypes["WeekHours"] | undefined,
	unique: GraphQLTypes["One"],
	workingHours?: GraphQLTypes["WeekHours"] | undefined,
	freshingContainerVolumeMl: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	creditsProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	freshingContainers: Array<GraphQLTypes["FreshingContainer"]>,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingPrepareOrderByOffsetMinutes: number,
	paginateFreshingContainers: GraphQLTypes["FreshingContainerConnection"]
};
	["GlobalConfigMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["FieldMeta"] | undefined
};
	["WeekHours"]: {
		_meta?: GraphQLTypes["WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["WeekHoursMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	mon?: GraphQLTypes["FieldMeta"] | undefined,
	tue?: GraphQLTypes["FieldMeta"] | undefined,
	wed?: GraphQLTypes["FieldMeta"] | undefined,
	thu?: GraphQLTypes["FieldMeta"] | undefined,
	fri?: GraphQLTypes["FieldMeta"] | undefined,
	sat?: GraphQLTypes["FieldMeta"] | undefined,
	sun?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProduct"]: {
		_meta?: GraphQLTypes["VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["VirtualProductMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["FieldMeta"] | undefined
};
	["VatRate"]: {
		_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["VatRateMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["FreshingContainerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FreshingContainerEdge"]>
};
	["FreshingContainerEdge"]: {
		node: GraphQLTypes["FreshingContainer"]
};
	["ProductIngredientLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientLocalesByLocaleUniqueWhere"];
	["ProductIngredientLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientLocaleEdge"]>
};
	["ProductIngredientLocaleEdge"]: {
		node: GraphQLTypes["ProductIngredientLocale"]
};
	["AllergenConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenEdge"]>
};
	["AllergenEdge"]: {
		node: GraphQLTypes["Allergen"]
};
	["BlogLocale"]: {
		_meta?: GraphQLTypes["BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Blog"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["BlogLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	pageName?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Blog"]: {
		_meta?: GraphQLTypes["BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["BlogLocale"]>,
	localesByLocale?: GraphQLTypes["BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogLocaleConnection"]
};
	["BlogMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogLocaleOrderBy"]: GraphQLTypes["BlogLocaleOrderBy"];
	["BlogOrderBy"]: GraphQLTypes["BlogOrderBy"];
	["BlogLocalesByLocaleUniqueWhere"]: GraphQLTypes["BlogLocalesByLocaleUniqueWhere"];
	["BlogLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogLocaleEdge"]>
};
	["BlogLocaleEdge"]: {
		node: GraphQLTypes["BlogLocale"]
};
	["BlogPostLocale"]: {
		_meta?: GraphQLTypes["BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["BlogPost"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["BlogPostLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
		_meta?: GraphQLTypes["BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostLocaleConnection"]
};
	["BlogPostMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostLocaleOrderBy"]: GraphQLTypes["BlogPostLocaleOrderBy"];
	["BlogPostOrderBy"]: GraphQLTypes["BlogPostOrderBy"];
	["CoverOrderBy"]: GraphQLTypes["CoverOrderBy"];
	["MediumOrderBy"]: GraphQLTypes["MediumOrderBy"];
	["VideoOrderBy"]: GraphQLTypes["VideoOrderBy"];
	["ColorPalleteOrderBy"]: GraphQLTypes["ColorPalleteOrderBy"];
	["ContentOrderBy"]: GraphQLTypes["ContentOrderBy"];
	["LinkableOrderBy"]: GraphQLTypes["LinkableOrderBy"];
	["PageLocaleOrderBy"]: GraphQLTypes["PageLocaleOrderBy"];
	["SeoOrderBy"]: GraphQLTypes["SeoOrderBy"];
	["WikiPageOrderBy"]: GraphQLTypes["WikiPageOrderBy"];
	["RedirectOrderBy"]: GraphQLTypes["RedirectOrderBy"];
	["BlogPostLocalesByLocaleUniqueWhere"]: GraphQLTypes["BlogPostLocalesByLocaleUniqueWhere"];
	["BlogPostLocalesByCoverUniqueWhere"]: GraphQLTypes["BlogPostLocalesByCoverUniqueWhere"];
	["BlogPostLocalesByContentUniqueWhere"]: GraphQLTypes["BlogPostLocalesByContentUniqueWhere"];
	["BlogPostLocalesByLinkUniqueWhere"]: GraphQLTypes["BlogPostLocalesByLinkUniqueWhere"];
	["BlogPostLocalesBySeoUniqueWhere"]: GraphQLTypes["BlogPostLocalesBySeoUniqueWhere"];
	["BlogPostLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
		node: GraphQLTypes["BlogPostLocale"]
};
	["Cover"]: {
		_meta?: GraphQLTypes["CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	buttonColors?: GraphQLTypes["ColorPallete"] | undefined
};
	["CoverMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["FieldMeta"] | undefined
};
	["Medium"]: {
		_meta?: GraphQLTypes["MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MediumType"] | undefined,
	colorTheme?: GraphQLTypes["ColorTheme"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined
};
	["MediumMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
		_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined
};
	["VideoMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined
};
	["ColorPallete"]: {
		_meta?: GraphQLTypes["ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["ColorPalleteMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined
};
	["Content"]: {
		_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
		_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByProducts?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
		_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostList"] | undefined,
	links?: GraphQLTypes["LinkList"] | undefined,
	products?: GraphQLTypes["ProductList"] | undefined,
	contentSize?: GraphQLTypes["ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Hero"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined
};
	["ContentReferenceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["FieldMeta"] | undefined,
	misc?: GraphQLTypes["FieldMeta"] | undefined,
	hero?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
		_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined
};
	["LinkMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
		_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined,
	page?: GraphQLTypes["PageLocale"] | undefined,
	wikiPage?: GraphQLTypes["WikiPage"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined
};
	["LinkableMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	wikiPage?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocale"]: {
		_meta?: GraphQLTypes["PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["Page"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	theme?: GraphQLTypes["PageTheme"] | undefined
};
	["PageLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["Page"]: {
		_meta?: GraphQLTypes["PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["PageLocale"]>,
	localesByLocale?: GraphQLTypes["PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["PageLocaleConnection"]
};
	["PageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocalesByLocaleUniqueWhere"]: GraphQLTypes["PageLocalesByLocaleUniqueWhere"];
	["PageLocalesByLinkUniqueWhere"]: GraphQLTypes["PageLocalesByLinkUniqueWhere"];
	["PageLocalesBySeoUniqueWhere"]: GraphQLTypes["PageLocalesBySeoUniqueWhere"];
	["PageLocalesByContentUniqueWhere"]: GraphQLTypes["PageLocalesByContentUniqueWhere"];
	["PageLocalesByCoverUniqueWhere"]: GraphQLTypes["PageLocalesByCoverUniqueWhere"];
	["PageLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageLocaleEdge"]>
};
	["PageLocaleEdge"]: {
		node: GraphQLTypes["PageLocale"]
};
	["Seo"]: {
		_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["WikiPage"]: {
		_meta?: GraphQLTypes["WikiPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	parent?: GraphQLTypes["WikiPage"] | undefined,
	children: Array<GraphQLTypes["WikiPage"]>,
	content?: GraphQLTypes["Content"] | undefined,
	childrenByLink?: GraphQLTypes["WikiPage"] | undefined,
	childrenByChildren?: GraphQLTypes["WikiPage"] | undefined,
	childrenByContent?: GraphQLTypes["WikiPage"] | undefined,
	paginateChildren: GraphQLTypes["WikiPageConnection"]
};
	["WikiPageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	emoji?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined
};
	["WikiPageChildrenByLinkUniqueWhere"]: GraphQLTypes["WikiPageChildrenByLinkUniqueWhere"];
	["WikiPageChildrenByChildrenUniqueWhere"]: GraphQLTypes["WikiPageChildrenByChildrenUniqueWhere"];
	["WikiPageChildrenByContentUniqueWhere"]: GraphQLTypes["WikiPageChildrenByContentUniqueWhere"];
	["WikiPageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WikiPageEdge"]>
};
	["WikiPageEdge"]: {
		node: GraphQLTypes["WikiPage"]
};
	["Redirect"]: {
		_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Linkable"] | undefined
};
	["RedirectMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostList"]: {
		_meta?: GraphQLTypes["BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateItems: GraphQLTypes["BlogPostListItemConnection"]
};
	["BlogPostListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItem"]: {
		_meta?: GraphQLTypes["BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined
};
	["BlogPostListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItemOrderBy"]: GraphQLTypes["BlogPostListItemOrderBy"];
	["BlogPostListOrderBy"]: GraphQLTypes["BlogPostListOrderBy"];
	["BlogPostListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListItemEdge"]>
};
	["BlogPostListItemEdge"]: {
		node: GraphQLTypes["BlogPostListItem"]
};
	["LinkList"]: {
		_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkListItem"]>,
	paginateItems: GraphQLTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
		_meta?: GraphQLTypes["LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: GraphQLTypes["LinkListItemOrderBy"];
	["LinkListOrderBy"]: GraphQLTypes["LinkListOrderBy"];
	["LinkOrderBy"]: GraphQLTypes["LinkOrderBy"];
	["LinkListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
		node: GraphQLTypes["LinkListItem"]
};
	["Hero"]: {
		_meta?: GraphQLTypes["HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["HeroMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["Gallery"]: {
		_meta?: GraphQLTypes["GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GalleryItem"]>,
	paginateItems: GraphQLTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
		_meta?: GraphQLTypes["GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Gallery"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["GalleryItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: GraphQLTypes["GalleryItemOrderBy"];
	["GalleryOrderBy"]: GraphQLTypes["GalleryOrderBy"];
	["GalleryItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
		node: GraphQLTypes["GalleryItem"]
};
	["SocialsAndApps"]: {
		_meta?: GraphQLTypes["SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["SocialsAndAppsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	instagram?: GraphQLTypes["FieldMeta"] | undefined,
	appStore?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGrid"]: {
		_meta?: GraphQLTypes["ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["ProductGridCategory"]>,
	type?: GraphQLTypes["ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["ProductGridCategoryConnection"]
};
	["ProductGridMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridCategory"]: {
		_meta?: GraphQLTypes["ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["ProductGrid"] | undefined,
	items: Array<GraphQLTypes["ProductGridItem"]>,
	paginateItems: GraphQLTypes["ProductGridItemConnection"]
};
	["ProductGridCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItem"]: {
		_meta?: GraphQLTypes["ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	category?: GraphQLTypes["ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["ProductGridItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItemOrderBy"]: GraphQLTypes["ProductGridItemOrderBy"];
	["ProductGridCategoryOrderBy"]: GraphQLTypes["ProductGridCategoryOrderBy"];
	["ProductGridOrderBy"]: GraphQLTypes["ProductGridOrderBy"];
	["ProductGridItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridItemEdge"]>
};
	["ProductGridItemEdge"]: {
		node: GraphQLTypes["ProductGridItem"]
};
	["ProductGridCategoriesByItemsUniqueWhere"]: GraphQLTypes["ProductGridCategoriesByItemsUniqueWhere"];
	["ProductGridItemUniqueWhere"]: GraphQLTypes["ProductGridItemUniqueWhere"];
	["ProductGridCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridCategoryEdge"]>
};
	["ProductGridCategoryEdge"]: {
		node: GraphQLTypes["ProductGridCategory"]
};
	["ProductBannerList"]: {
		_meta?: GraphQLTypes["ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateItems: GraphQLTypes["ProductBannerItemConnection"]
};
	["ProductBannerListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItem"]: {
		_meta?: GraphQLTypes["ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductBannerList"] | undefined,
	banner?: GraphQLTypes["ProductBanner"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	banner?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBanner"]: {
		_meta?: GraphQLTypes["ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	backgroundImage?: GraphQLTypes["Image"] | undefined,
	productImage?: GraphQLTypes["Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductBannerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["FieldMeta"] | undefined,
	productImage?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItemOrderBy"]: GraphQLTypes["ProductBannerItemOrderBy"];
	["ProductBannerListOrderBy"]: GraphQLTypes["ProductBannerListOrderBy"];
	["ProductBannerOrderBy"]: GraphQLTypes["ProductBannerOrderBy"];
	["ProductBannerItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerItemEdge"]>
};
	["ProductBannerItemEdge"]: {
		node: GraphQLTypes["ProductBannerItem"]
};
	["DeliveryRegions"]: {
		_meta?: GraphQLTypes["DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["DeliveryRegionsItemConnection"]
};
	["DeliveryRegionsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRegionsItem"]: {
		_meta?: GraphQLTypes["DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gallery?: GraphQLTypes["ImageList"] | undefined
};
	["DeliveryRegionsItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextList"]: {
		_meta?: GraphQLTypes["TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TextItem"]>,
	paginateItems: GraphQLTypes["TextItemConnection"]
};
	["TextListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
		_meta?: GraphQLTypes["TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["TextList"] | undefined
};
	["TextItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: GraphQLTypes["TextItemOrderBy"];
	["TextListOrderBy"]: GraphQLTypes["TextListOrderBy"];
	["TextItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
		node: GraphQLTypes["TextItem"]
};
	["ImageList"]: {
		_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
		_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: GraphQLTypes["ImageListItemOrderBy"];
	["ImageListOrderBy"]: GraphQLTypes["ImageListOrderBy"];
	["ImageListItemsByImageUniqueWhere"]: GraphQLTypes["ImageListItemsByImageUniqueWhere"];
	["ImageListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
		node: GraphQLTypes["ImageListItem"]
};
	["DeliveryRegionsItemOrderBy"]: GraphQLTypes["DeliveryRegionsItemOrderBy"];
	["DeliveryRegionsOrderBy"]: GraphQLTypes["DeliveryRegionsOrderBy"];
	["DeliveryRegionsItemsByImageUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByImageUniqueWhere"];
	["DeliveryRegionsItemsByTextListUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByTextListUniqueWhere"];
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByGalleryUniqueWhere"];
	["DeliveryRegionsItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsItemEdge"]>
};
	["DeliveryRegionsItemEdge"]: {
		node: GraphQLTypes["DeliveryRegionsItem"]
};
	["ContentReferenceOrderBy"]: GraphQLTypes["ContentReferenceOrderBy"];
	["ContentBlockOrderBy"]: GraphQLTypes["ContentBlockOrderBy"];
	["HeroOrderBy"]: GraphQLTypes["HeroOrderBy"];
	["SocialsAndAppsOrderBy"]: GraphQLTypes["SocialsAndAppsOrderBy"];
	["ContentBlockReferencesByProductsUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByProductsUniqueWhere"];
	["ContentBlockReferencesByProductBannersUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByProductBannersUniqueWhere"];
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByDeliveryRegionsUniqueWhere"];
	["ContentReferenceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
		node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: GraphQLTypes["ContentBlocksByReferencesUniqueWhere"];
	["ContentBlockConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
		node: GraphQLTypes["ContentBlock"]
};
	["TranslationCataloguesIdentifier"]: {
		_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogue"]: {
		_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
		_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueOrderBy"]: GraphQLTypes["TranslationCatalogueOrderBy"];
	["TranslationDomainOrderBy"]: GraphQLTypes["TranslationDomainOrderBy"];
	["TranslationKey"]: {
		_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
		_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: GraphQLTypes["TranslationValueOrderBy"];
	["TranslationKeyOrderBy"]: GraphQLTypes["TranslationKeyOrderBy"];
	["TranslationKeyValuesByCatalogueUniqueWhere"]: GraphQLTypes["TranslationKeyValuesByCatalogueUniqueWhere"];
	["TranslationValueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
		node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: GraphQLTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"];
	["TranslationDomainCataloguesByValuesUniqueWhere"]: GraphQLTypes["TranslationDomainCataloguesByValuesUniqueWhere"];
	["TranslationDomainKeysByIdentifierUniqueWhere"]: GraphQLTypes["TranslationDomainKeysByIdentifierUniqueWhere"];
	["TranslationDomainKeysByValuesUniqueWhere"]: GraphQLTypes["TranslationDomainKeysByValuesUniqueWhere"];
	["TranslationCatalogueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
		node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
		node: GraphQLTypes["TranslationKey"]
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: GraphQLTypes["TranslationCatalogueValuesByKeyUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"];
	["MenuLocale"]: {
		_meta?: GraphQLTypes["MenuLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["SiteMenu"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	items: Array<GraphQLTypes["MenuItem"]>,
	secondaryItems?: GraphQLTypes["LinkList"] | undefined,
	eshopLink?: GraphQLTypes["Link"] | undefined,
	itemsBySubitems?: GraphQLTypes["MenuItem"] | undefined,
	paginateItems: GraphQLTypes["MenuItemConnection"]
};
	["MenuLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryItems?: GraphQLTypes["FieldMeta"] | undefined,
	eshopLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["SiteMenu"]: {
		_meta?: GraphQLTypes["SiteMenuMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["MenuLocale"]>,
	localesByLocale?: GraphQLTypes["MenuLocale"] | undefined,
	localesByItems?: GraphQLTypes["MenuLocale"] | undefined,
	localesBySecondaryItems?: GraphQLTypes["MenuLocale"] | undefined,
	paginateLocales: GraphQLTypes["MenuLocaleConnection"]
};
	["SiteMenuMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLocaleOrderBy"]: GraphQLTypes["MenuLocaleOrderBy"];
	["SiteMenuOrderBy"]: GraphQLTypes["SiteMenuOrderBy"];
	["SiteMenuLocalesByLocaleUniqueWhere"]: GraphQLTypes["SiteMenuLocalesByLocaleUniqueWhere"];
	["SiteMenuLocalesByItemsUniqueWhere"]: GraphQLTypes["SiteMenuLocalesByItemsUniqueWhere"];
	["SiteMenuLocalesBySecondaryItemsUniqueWhere"]: GraphQLTypes["SiteMenuLocalesBySecondaryItemsUniqueWhere"];
	["MenuLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuLocaleEdge"]>
};
	["MenuLocaleEdge"]: {
		node: GraphQLTypes["MenuLocale"]
};
	["MenuItem"]: {
		_meta?: GraphQLTypes["MenuItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	menu?: GraphQLTypes["MenuLocale"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	subitems: Array<GraphQLTypes["MenuSubitem"]>,
	paginateSubitems: GraphQLTypes["MenuSubitemConnection"]
};
	["MenuItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	menu?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	subitems?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuSubitem"]: {
		_meta?: GraphQLTypes["MenuSubitemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MenuSubitemType"] | undefined,
	title?: string | undefined,
	item?: GraphQLTypes["MenuItem"] | undefined,
	links?: GraphQLTypes["MenuLinkList"] | undefined,
	products?: GraphQLTypes["MenuProducts"] | undefined,
	delivery?: GraphQLTypes["MenuDelivery"] | undefined,
	contact?: GraphQLTypes["MenuContact"] | undefined,
	order: number
};
	["MenuSubitemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	contact?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLinkList"]: {
		_meta?: GraphQLTypes["MenuLinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["MenuLinkItem"]>,
	paginateItems: GraphQLTypes["MenuLinkItemConnection"]
};
	["MenuLinkListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLinkItem"]: {
		_meta?: GraphQLTypes["MenuLinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["MenuLinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined
};
	["MenuLinkItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLinkItemOrderBy"]: GraphQLTypes["MenuLinkItemOrderBy"];
	["MenuLinkListOrderBy"]: GraphQLTypes["MenuLinkListOrderBy"];
	["MenuLinkItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuLinkItemEdge"]>
};
	["MenuLinkItemEdge"]: {
		node: GraphQLTypes["MenuLinkItem"]
};
	["MenuProducts"]: {
		_meta?: GraphQLTypes["MenuProductsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	links?: GraphQLTypes["MenuLinkList"] | undefined,
	buttons?: GraphQLTypes["LinkList"] | undefined
};
	["MenuProductsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	buttons?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuDelivery"]: {
		_meta?: GraphQLTypes["MenuDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	text?: string | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined
};
	["MenuDeliveryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuContact"]: {
		_meta?: GraphQLTypes["MenuContactMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	text?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["MenuContactMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuSubitemOrderBy"]: GraphQLTypes["MenuSubitemOrderBy"];
	["MenuItemOrderBy"]: GraphQLTypes["MenuItemOrderBy"];
	["MenuProductsOrderBy"]: GraphQLTypes["MenuProductsOrderBy"];
	["MenuDeliveryOrderBy"]: GraphQLTypes["MenuDeliveryOrderBy"];
	["MenuContactOrderBy"]: GraphQLTypes["MenuContactOrderBy"];
	["MenuSubitemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuSubitemEdge"]>
};
	["MenuSubitemEdge"]: {
		node: GraphQLTypes["MenuSubitem"]
};
	["MenuLocaleItemsBySubitemsUniqueWhere"]: GraphQLTypes["MenuLocaleItemsBySubitemsUniqueWhere"];
	["MenuItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuItemEdge"]>
};
	["MenuItemEdge"]: {
		node: GraphQLTypes["MenuItem"]
};
	["ProductPackingLocaleOrderBy"]: GraphQLTypes["ProductPackingLocaleOrderBy"];
	["ProductLocale"]: {
		_meta?: GraphQLTypes["ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	featureList?: GraphQLTypes["TextList"] | undefined
};
	["ProductLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	featureList?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: GraphQLTypes["ProductLocaleOrderBy"];
	["ProductRecipeLocale"]: {
		_meta?: GraphQLTypes["ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	description?: string | undefined
};
	["ProductRecipeLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductRecipe"]: {
		_meta?: GraphQLTypes["ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	description?: string | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	localesByLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["ProductRecipeLocaleConnection"]
};
	["ProductRecipeMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItem"]: {
		_meta?: GraphQLTypes["ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined
};
	["ProductIngredientItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemOrderBy"]: GraphQLTypes["ProductIngredientItemOrderBy"];
	["ProductRecipeLocaleOrderBy"]: GraphQLTypes["ProductRecipeLocaleOrderBy"];
	["ProductRecipeProductsByCodeUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByCodeUniqueWhere"];
	["ProductRecipeProductsByStocksUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByStocksUniqueWhere"];
	["ProductRecipeProductsByLocalesUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByLocalesUniqueWhere"];
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByVirtualProductUniqueWhere"];
	["ProductRecipeProductsByMetaUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByMetaUniqueWhere"];
	["ProductRecipeProductsByPageUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByPageUniqueWhere"];
	["ProductRecipeLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductRecipeLocalesByLocaleUniqueWhere"];
	["ProductIngredientItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemEdge"]>
};
	["ProductIngredientItemEdge"]: {
		node: GraphQLTypes["ProductIngredientItem"]
};
	["ProductRecipeLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeLocaleEdge"]>
};
	["ProductRecipeLocaleEdge"]: {
		node: GraphQLTypes["ProductRecipeLocale"]
};
	["DeliveryMethodLocale"]: {
		_meta?: GraphQLTypes["DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryMethodLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["FieldMeta"] | undefined,
	processingText?: GraphQLTypes["FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
		_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	localesByLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethod"]: {
		_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["PaymentMethodType"] | undefined,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"]
};
	["PaymentMethodMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodOrderBy"]: GraphQLTypes["DeliveryMethodOrderBy"];
	["DeliveryTimelinePresetOrderBy"]: GraphQLTypes["DeliveryTimelinePresetOrderBy"];
	["DeliveryMethodConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
		node: GraphQLTypes["DeliveryMethod"]
};
	["PaymentMethodOrderBy"]: GraphQLTypes["PaymentMethodOrderBy"];
	["DeliveryZone"]: {
		_meta?: GraphQLTypes["DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["DeliveryZoneType"],
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"]
};
	["DeliveryZoneMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryZoneOrderBy"]: GraphQLTypes["DeliveryZoneOrderBy"];
	["DeliveryTimelinePreset"]: {
		_meta?: GraphQLTypes["DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryTimelinePresetMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocaleOrderBy"]: GraphQLTypes["DeliveryMethodLocaleOrderBy"];
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: GraphQLTypes["DeliveryMethodLocalesByLocaleUniqueWhere"];
	["PaymentMethodConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
		node: GraphQLTypes["PaymentMethod"]
};
	["DeliveryZoneConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryZoneEdge"]>
};
	["DeliveryZoneEdge"]: {
		node: GraphQLTypes["DeliveryZone"]
};
	["DeliveryMethodLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodLocaleEdge"]>
};
	["DeliveryMethodLocaleEdge"]: {
		node: GraphQLTypes["DeliveryMethodLocale"]
};
	["LocaleBusinessCategoriesByRootUniqueWhere"]: GraphQLTypes["LocaleBusinessCategoriesByRootUniqueWhere"];
	["LocaleProductCategoryListsByRootUniqueWhere"]: GraphQLTypes["LocaleProductCategoryListsByRootUniqueWhere"];
	["LocaleProductIngredientsByRootUniqueWhere"]: GraphQLTypes["LocaleProductIngredientsByRootUniqueWhere"];
	["LocaleOrderRecurrencesByRootUniqueWhere"]: GraphQLTypes["LocaleOrderRecurrencesByRootUniqueWhere"];
	["LocaleBlogsByRootUniqueWhere"]: GraphQLTypes["LocaleBlogsByRootUniqueWhere"];
	["LocaleBlogPostsByRootUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByRootUniqueWhere"];
	["LocaleBlogPostsByCoverUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByCoverUniqueWhere"];
	["LocaleBlogPostsByContentUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByContentUniqueWhere"];
	["LocaleBlogPostsByLinkUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByLinkUniqueWhere"];
	["LocaleBlogPostsBySeoUniqueWhere"]: GraphQLTypes["LocaleBlogPostsBySeoUniqueWhere"];
	["LocaleMenusByRootUniqueWhere"]: GraphQLTypes["LocaleMenusByRootUniqueWhere"];
	["LocaleMenusByItemsUniqueWhere"]: GraphQLTypes["LocaleMenusByItemsUniqueWhere"];
	["LocaleMenusBySecondaryItemsUniqueWhere"]: GraphQLTypes["LocaleMenusBySecondaryItemsUniqueWhere"];
	["LocalePagesByRootUniqueWhere"]: GraphQLTypes["LocalePagesByRootUniqueWhere"];
	["LocalePagesByLinkUniqueWhere"]: GraphQLTypes["LocalePagesByLinkUniqueWhere"];
	["LocalePagesBySeoUniqueWhere"]: GraphQLTypes["LocalePagesBySeoUniqueWhere"];
	["LocalePagesByContentUniqueWhere"]: GraphQLTypes["LocalePagesByContentUniqueWhere"];
	["LocalePagesByCoverUniqueWhere"]: GraphQLTypes["LocalePagesByCoverUniqueWhere"];
	["LocaleAllergensByRootUniqueWhere"]: GraphQLTypes["LocaleAllergensByRootUniqueWhere"];
	["LocaleProductPackingsByRootUniqueWhere"]: GraphQLTypes["LocaleProductPackingsByRootUniqueWhere"];
	["LocaleProductsByRootUniqueWhere"]: GraphQLTypes["LocaleProductsByRootUniqueWhere"];
	["LocaleProductsByFeatureListUniqueWhere"]: GraphQLTypes["LocaleProductsByFeatureListUniqueWhere"];
	["LocaleProductRecipesByRootUniqueWhere"]: GraphQLTypes["LocaleProductRecipesByRootUniqueWhere"];
	["LocaleDeliveryMethodsByRootUniqueWhere"]: GraphQLTypes["LocaleDeliveryMethodsByRootUniqueWhere"];
	["ProductPackingLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingLocaleEdge"]>
};
	["ProductPackingLocaleEdge"]: {
		node: GraphQLTypes["ProductPackingLocale"]
};
	["ProductLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
		node: GraphQLTypes["ProductLocale"]
};
	["ProductPackingLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductPackingLocalesByLocaleUniqueWhere"];
	["ProductStock"]: {
		_meta?: GraphQLTypes["ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Store"] | undefined
};
	["ProductStockMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	store?: GraphQLTypes["FieldMeta"] | undefined
};
	["Store"]: {
		_meta?: GraphQLTypes["StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["StoreMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStockOrderBy"]: GraphQLTypes["ProductStockOrderBy"];
	["StoreOrderBy"]: GraphQLTypes["StoreOrderBy"];
	["ProductMetadata"]: {
		_meta?: GraphQLTypes["ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	archived?: GraphQLTypes["FieldMeta"] | undefined,
	available?: GraphQLTypes["FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStocksByStoreUniqueWhere"]: GraphQLTypes["ProductStocksByStoreUniqueWhere"];
	["ProductLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductLocalesByLocaleUniqueWhere"];
	["ProductLocalesByFeatureListUniqueWhere"]: GraphQLTypes["ProductLocalesByFeatureListUniqueWhere"];
	["ProductStockConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductStockEdge"]>
};
	["ProductStockEdge"]: {
		node: GraphQLTypes["ProductStock"]
};
	["ChannelOrderBy"]: GraphQLTypes["ChannelOrderBy"];
	["ChannelConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelEdge"]>
};
	["ChannelEdge"]: {
		node: GraphQLTypes["Channel"]
};
	["ChannelUniqueWhere"]: GraphQLTypes["ChannelUniqueWhere"];
	["PaymentMethodUniqueWhere"]: GraphQLTypes["PaymentMethodUniqueWhere"];
	["VatRateUniqueWhere"]: GraphQLTypes["VatRateUniqueWhere"];
	["VatRateConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VatRateEdge"]>
};
	["VatRateEdge"]: {
		node: GraphQLTypes["VatRate"]
};
	["ProductPackingConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingEdge"]>
};
	["ProductPackingEdge"]: {
		node: GraphQLTypes["ProductPacking"]
};
	["ProductRecipeConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeEdge"]>
};
	["ProductRecipeEdge"]: {
		node: GraphQLTypes["ProductRecipe"]
};
	["StoreConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StoreEdge"]>
};
	["StoreEdge"]: {
		node: GraphQLTypes["Store"]
};
	["TagUniqueWhere"]: GraphQLTypes["TagUniqueWhere"];
	["ImageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
		node: GraphQLTypes["Image"]
};
	["ProductListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListEdge"]>
};
	["ProductListEdge"]: {
		node: GraphQLTypes["ProductList"]
};
	["ProductCategoryListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
		node: GraphQLTypes["ProductCategoryList"]
};
	["LocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
		node: GraphQLTypes["Locale"]
};
	["ProductIngredientRating"]: {
		_meta?: GraphQLTypes["ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Image"] | undefined
};
	["ProductIngredientRatingMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientRatingUniqueWhere"]: GraphQLTypes["ProductIngredientRatingUniqueWhere"];
	["ProductIngredientRatingWhere"]: GraphQLTypes["ProductIngredientRatingWhere"];
	["ProductIngredientRatingOrderBy"]: GraphQLTypes["ProductIngredientRatingOrderBy"];
	["ProductIngredientRatingConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientRatingEdge"]>
};
	["ProductIngredientRatingEdge"]: {
		node: GraphQLTypes["ProductIngredientRating"]
};
	["BlogConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogEdge"]>
};
	["BlogEdge"]: {
		node: GraphQLTypes["Blog"]
};
	["BlogPostConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
		node: GraphQLTypes["BlogPost"]
};
	["ColorPalleteUniqueWhere"]: GraphQLTypes["ColorPalleteUniqueWhere"];
	["ColorPalleteConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ColorPalleteEdge"]>
};
	["ColorPalleteEdge"]: {
		node: GraphQLTypes["ColorPallete"]
};
	["ContentConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
		node: GraphQLTypes["Content"]
};
	["BlogPostListUniqueWhere"]: GraphQLTypes["BlogPostListUniqueWhere"];
	["BlogPostListItemUniqueWhere"]: GraphQLTypes["BlogPostListItemUniqueWhere"];
	["BlogPostListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListEdge"]>
};
	["BlogPostListEdge"]: {
		node: GraphQLTypes["BlogPostList"]
};
	["LinkListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
		node: GraphQLTypes["LinkList"]
};
	["CoverConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CoverEdge"]>
};
	["CoverEdge"]: {
		node: GraphQLTypes["Cover"]
};
	["LinkUniqueWhere"]: GraphQLTypes["LinkUniqueWhere"];
	["LinkConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
		node: GraphQLTypes["Link"]
};
	["LinkableConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
		node: GraphQLTypes["Linkable"]
};
	["MediumUniqueWhere"]: GraphQLTypes["MediumUniqueWhere"];
	["MediumConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumEdge"]>
};
	["MediumEdge"]: {
		node: GraphQLTypes["Medium"]
};
	["VideoUniqueWhere"]: GraphQLTypes["VideoUniqueWhere"];
	["VideoConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
		node: GraphQLTypes["Video"]
};
	["SeoConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
		node: GraphQLTypes["Seo"]
};
	["TranslationDomainConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
		node: GraphQLTypes["TranslationDomain"]
};
	["TranslationCataloguesIdentifierConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
		node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["SocialsAndAppsUniqueWhere"]: GraphQLTypes["SocialsAndAppsUniqueWhere"];
	["SocialsAndAppsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SocialsAndAppsEdge"]>
};
	["SocialsAndAppsEdge"]: {
		node: GraphQLTypes["SocialsAndApps"]
};
	["ProductGridUniqueWhere"]: GraphQLTypes["ProductGridUniqueWhere"];
	["ProductGridCategoryUniqueWhere"]: GraphQLTypes["ProductGridCategoryUniqueWhere"];
	["ProductGridConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridEdge"]>
};
	["ProductGridEdge"]: {
		node: GraphQLTypes["ProductGrid"]
};
	["GalleryUniqueWhere"]: GraphQLTypes["GalleryUniqueWhere"];
	["GalleryItemUniqueWhere"]: GraphQLTypes["GalleryItemUniqueWhere"];
	["GalleryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
		node: GraphQLTypes["Gallery"]
};
	["HeroUniqueWhere"]: GraphQLTypes["HeroUniqueWhere"];
	["HeroConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeroEdge"]>
};
	["HeroEdge"]: {
		node: GraphQLTypes["Hero"]
};
	["SiteMenuConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SiteMenuEdge"]>
};
	["SiteMenuEdge"]: {
		node: GraphQLTypes["SiteMenu"]
};
	["PageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageEdge"]>
};
	["PageEdge"]: {
		node: GraphQLTypes["Page"]
};
	["MenuLinkListUniqueWhere"]: GraphQLTypes["MenuLinkListUniqueWhere"];
	["MenuLinkItemUniqueWhere"]: GraphQLTypes["MenuLinkItemUniqueWhere"];
	["MenuLinkListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuLinkListEdge"]>
};
	["MenuLinkListEdge"]: {
		node: GraphQLTypes["MenuLinkList"]
};
	["MenuProductsUniqueWhere"]: GraphQLTypes["MenuProductsUniqueWhere"];
	["MenuProductsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuProductsEdge"]>
};
	["MenuProductsEdge"]: {
		node: GraphQLTypes["MenuProducts"]
};
	["MenuDeliveryUniqueWhere"]: GraphQLTypes["MenuDeliveryUniqueWhere"];
	["MenuDeliveryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuDeliveryEdge"]>
};
	["MenuDeliveryEdge"]: {
		node: GraphQLTypes["MenuDelivery"]
};
	["MenuContactUniqueWhere"]: GraphQLTypes["MenuContactUniqueWhere"];
	["MenuContactConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuContactEdge"]>
};
	["MenuContactEdge"]: {
		node: GraphQLTypes["MenuContact"]
};
	["ProductBannerUniqueWhere"]: GraphQLTypes["ProductBannerUniqueWhere"];
	["ProductBannerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerEdge"]>
};
	["ProductBannerEdge"]: {
		node: GraphQLTypes["ProductBanner"]
};
	["ProductBannerListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerListEdge"]>
};
	["ProductBannerListEdge"]: {
		node: GraphQLTypes["ProductBannerList"]
};
	["GlobalConfigUniqueWhere"]: GraphQLTypes["GlobalConfigUniqueWhere"];
	["WeekHoursUniqueWhere"]: GraphQLTypes["WeekHoursUniqueWhere"];
	["FreshingContainerUniqueWhere"]: GraphQLTypes["FreshingContainerUniqueWhere"];
	["GlobalConfigConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GlobalConfigEdge"]>
};
	["GlobalConfigEdge"]: {
		node: GraphQLTypes["GlobalConfig"]
};
	["WeekHoursConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WeekHoursEdge"]>
};
	["WeekHoursEdge"]: {
		node: GraphQLTypes["WeekHours"]
};
	["DeliveryZoneUniqueWhere"]: GraphQLTypes["DeliveryZoneUniqueWhere"];
	["DeliveryTimelinePresetConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryTimelinePresetEdge"]>
};
	["DeliveryTimelinePresetEdge"]: {
		node: GraphQLTypes["DeliveryTimelinePreset"]
};
	["VirtualProductConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEdge"]>
};
	["VirtualProductEdge"]: {
		node: GraphQLTypes["VirtualProduct"]
};
	["ProductMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductMetadataEdge"]>
};
	["ProductMetadataEdge"]: {
		node: GraphQLTypes["ProductMetadata"]
};
	["RedirectConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
		node: GraphQLTypes["Redirect"]
};
	["TextListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextListEdge"]>
};
	["TextListEdge"]: {
		node: GraphQLTypes["TextList"]
};
	["DeliveryRegionsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsEdge"]>
};
	["DeliveryRegionsEdge"]: {
		node: GraphQLTypes["DeliveryRegions"]
};
	["ImageListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
		node: GraphQLTypes["ImageList"]
};
	["QueryTransaction"]: {
		getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getSiteMenu?: GraphQLTypes["SiteMenu"] | undefined,
	listSiteMenu: Array<GraphQLTypes["SiteMenu"]>,
	paginateSiteMenu: GraphQLTypes["SiteMenuConnection"],
	getMenuLocale?: GraphQLTypes["MenuLocale"] | undefined,
	listMenuLocale: Array<GraphQLTypes["MenuLocale"]>,
	paginateMenuLocale: GraphQLTypes["MenuLocaleConnection"],
	getMenuItem?: GraphQLTypes["MenuItem"] | undefined,
	listMenuItem: Array<GraphQLTypes["MenuItem"]>,
	paginateMenuItem: GraphQLTypes["MenuItemConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getWikiPage?: GraphQLTypes["WikiPage"] | undefined,
	listWikiPage: Array<GraphQLTypes["WikiPage"]>,
	paginateWikiPage: GraphQLTypes["WikiPageConnection"],
	getMenuSubitem?: GraphQLTypes["MenuSubitem"] | undefined,
	listMenuSubitem: Array<GraphQLTypes["MenuSubitem"]>,
	paginateMenuSubitem: GraphQLTypes["MenuSubitemConnection"],
	getMenuLinkList?: GraphQLTypes["MenuLinkList"] | undefined,
	listMenuLinkList: Array<GraphQLTypes["MenuLinkList"]>,
	paginateMenuLinkList: GraphQLTypes["MenuLinkListConnection"],
	getMenuLinkItem?: GraphQLTypes["MenuLinkItem"] | undefined,
	listMenuLinkItem: Array<GraphQLTypes["MenuLinkItem"]>,
	paginateMenuLinkItem: GraphQLTypes["MenuLinkItemConnection"],
	getMenuProducts?: GraphQLTypes["MenuProducts"] | undefined,
	listMenuProducts: Array<GraphQLTypes["MenuProducts"]>,
	paginateMenuProducts: GraphQLTypes["MenuProductsConnection"],
	getMenuDelivery?: GraphQLTypes["MenuDelivery"] | undefined,
	listMenuDelivery: Array<GraphQLTypes["MenuDelivery"]>,
	paginateMenuDelivery: GraphQLTypes["MenuDeliveryConnection"],
	getMenuContact?: GraphQLTypes["MenuContact"] | undefined,
	listMenuContact: Array<GraphQLTypes["MenuContact"]>,
	paginateMenuContact: GraphQLTypes["MenuContactConnection"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGlobalConfig?: GraphQLTypes["GlobalConfig"] | undefined,
	listGlobalConfig: Array<GraphQLTypes["GlobalConfig"]>,
	paginateGlobalConfig: GraphQLTypes["GlobalConfigConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["Info"]: {
		description?: string | undefined
};
	["Json"]:any;
	["_Schema"]: {
		enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
		name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
		fields: Array<string>
};
	["_Field"]: ModelTypes["_Column"] | ModelTypes["_Relation"];
	["_Column"]: {
		name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: GraphQLTypes["_OnDeleteBehaviour"];
	["_RelationSide"]: GraphQLTypes["_RelationSide"];
	["_OrderByDirection"]: GraphQLTypes["_OrderByDirection"];
	["_OrderBy"]: {
		path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
		name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
		message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
		operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:ModelTypes["_ValidatorArgument"] | ModelTypes["_PathArgument"] | ModelTypes["_LiteralArgument"];
	["_ValidatorArgument"]: {
		validator: number
};
	["_PathArgument"]: {
		path: Array<string>
};
	["_LiteralArgument"]: {
		value?: GraphQLTypes["Json"] | undefined
};
	["_RuleMessage"]: {
		text?: string | undefined
};
	["_Enum"]: {
		name: string,
	values: Array<string>
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getSiteMenu?: GraphQLTypes["SiteMenu"] | undefined,
	listSiteMenu: Array<GraphQLTypes["SiteMenu"]>,
	paginateSiteMenu: GraphQLTypes["SiteMenuConnection"],
	getMenuLocale?: GraphQLTypes["MenuLocale"] | undefined,
	listMenuLocale: Array<GraphQLTypes["MenuLocale"]>,
	paginateMenuLocale: GraphQLTypes["MenuLocaleConnection"],
	getMenuItem?: GraphQLTypes["MenuItem"] | undefined,
	listMenuItem: Array<GraphQLTypes["MenuItem"]>,
	paginateMenuItem: GraphQLTypes["MenuItemConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getWikiPage?: GraphQLTypes["WikiPage"] | undefined,
	listWikiPage: Array<GraphQLTypes["WikiPage"]>,
	paginateWikiPage: GraphQLTypes["WikiPageConnection"],
	getMenuSubitem?: GraphQLTypes["MenuSubitem"] | undefined,
	listMenuSubitem: Array<GraphQLTypes["MenuSubitem"]>,
	paginateMenuSubitem: GraphQLTypes["MenuSubitemConnection"],
	getMenuLinkList?: GraphQLTypes["MenuLinkList"] | undefined,
	listMenuLinkList: Array<GraphQLTypes["MenuLinkList"]>,
	paginateMenuLinkList: GraphQLTypes["MenuLinkListConnection"],
	getMenuLinkItem?: GraphQLTypes["MenuLinkItem"] | undefined,
	listMenuLinkItem: Array<GraphQLTypes["MenuLinkItem"]>,
	paginateMenuLinkItem: GraphQLTypes["MenuLinkItemConnection"],
	getMenuProducts?: GraphQLTypes["MenuProducts"] | undefined,
	listMenuProducts: Array<GraphQLTypes["MenuProducts"]>,
	paginateMenuProducts: GraphQLTypes["MenuProductsConnection"],
	getMenuDelivery?: GraphQLTypes["MenuDelivery"] | undefined,
	listMenuDelivery: Array<GraphQLTypes["MenuDelivery"]>,
	paginateMenuDelivery: GraphQLTypes["MenuDeliveryConnection"],
	getMenuContact?: GraphQLTypes["MenuContact"] | undefined,
	listMenuContact: Array<GraphQLTypes["MenuContact"]>,
	paginateMenuContact: GraphQLTypes["MenuContactConnection"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGlobalConfig?: GraphQLTypes["GlobalConfig"] | undefined,
	listGlobalConfig: Array<GraphQLTypes["GlobalConfig"]>,
	paginateGlobalConfig: GraphQLTypes["GlobalConfigConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined,
	schema?: GraphQLTypes["_Schema"] | undefined
};
	["Country"]: {
	__typename: "Country",
	_meta?: GraphQLTypes["CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string
};
	["CountryMeta"]: {
	__typename: "CountryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
	__typename: "FieldMeta",
	readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]: string;
	["CountryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["CountryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["CountryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CountryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CountryWhere"] | undefined
};
	["UUIDCondition"]: {
		and?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	or?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	not?: GraphQLTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UUID"] | undefined,
	notEq?: GraphQLTypes["UUID"] | undefined,
	in?: Array<GraphQLTypes["UUID"]> | undefined,
	notIn?: Array<GraphQLTypes["UUID"]> | undefined,
	lt?: GraphQLTypes["UUID"] | undefined,
	lte?: GraphQLTypes["UUID"] | undefined,
	gt?: GraphQLTypes["UUID"] | undefined,
	gte?: GraphQLTypes["UUID"] | undefined
};
	["StringCondition"]: {
		and?: Array<GraphQLTypes["StringCondition"]> | undefined,
	or?: Array<GraphQLTypes["StringCondition"]> | undefined,
	not?: GraphQLTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["CountryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderDirection"]: OrderDirection;
	["CountryConnection"]: {
	__typename: "CountryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CountryEdge"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	totalCount: number
};
	["CountryEdge"]: {
	__typename: "CountryEdge",
	node: GraphQLTypes["Country"]
};
	["Channel"]: {
	__typename: "Channel",
	_meta?: GraphQLTypes["ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	code?: GraphQLTypes["ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Tag"]>,
	publicTags: Array<GraphQLTypes["Tag"]>,
	paginateAddTagsOnRegistration: GraphQLTypes["TagConnection"],
	paginatePublicTags: GraphQLTypes["TagConnection"]
};
	["ChannelMeta"]: {
	__typename: "ChannelMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	disabled?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["FieldMeta"] | undefined
};
	["ChannelCode"]: ChannelCode;
	["Tag"]: {
	__typename: "Tag",
	_meta?: GraphQLTypes["TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Channel"]>,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Channel"]>,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["ChannelConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["ChannelConnection"]
};
	["TagMeta"]: {
	__typename: "TagMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["FieldMeta"] | undefined
};
	["Product"]: {
	__typename: "Product",
	_meta?: GraphQLTypes["ProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["ProductStock"]>,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	availability: GraphQLTypes["ProductAvailability"],
	order: number,
	maximumQuantityPerOrder?: number | undefined,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	locales: Array<GraphQLTypes["ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	meta?: GraphQLTypes["ProductMetadata"] | undefined,
	page?: GraphQLTypes["Page"] | undefined,
	stocksByStore?: GraphQLTypes["ProductStock"] | undefined,
	localesByLocale?: GraphQLTypes["ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["ProductStockConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["ProductLocaleConnection"]
};
	["ProductMeta"]: {
	__typename: "ProductMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	stocks?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	availability?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPacking"]: {
	__typename: "ProductPacking",
	_meta?: GraphQLTypes["ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["ProductPackingLocale"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductPackingLocaleConnection"]
};
	["ProductPackingMeta"]: {
	__typename: "ProductPackingMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
	__typename: "Image",
	_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["ImageMeta"]: {
	__typename: "ImageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	alt?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageWhere"] | undefined
};
	["IntCondition"]: {
		and?: Array<GraphQLTypes["IntCondition"]> | undefined,
	or?: Array<GraphQLTypes["IntCondition"]> | undefined,
	not?: GraphQLTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ProductPackingLocale"]: {
	__typename: "ProductPackingLocale",
	_meta?: GraphQLTypes["ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["ProductPacking"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductPackingLocaleMeta"]: {
	__typename: "ProductPackingLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPackingWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	volumeMl?: GraphQLTypes["IntCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	shortName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined,
	isPackedInCardboard?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductPackingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductPackingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductPackingWhere"] | undefined
};
	["ProductPackingLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	shortName?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductPackingWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductPackingLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductPackingLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	businessCategories?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined,
	productIngredients?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined,
	blogs?: GraphQLTypes["BlogLocaleWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	menus?: GraphQLTypes["MenuLocaleWhere"] | undefined,
	pages?: GraphQLTypes["PageLocaleWhere"] | undefined,
	allergens?: GraphQLTypes["AllergenLocaleWhere"] | undefined,
	productPackings?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	productRecipes?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LocaleWhere"] | undefined
};
	["BusinessCategoryLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined
};
	["BusinessCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	recurrences?: GraphQLTypes["OrderRecurrenceWhere"] | undefined,
	specificProducts?: GraphQLTypes["ProductListWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCategoryWhere"] | undefined
};
	["BooleanCondition"]: {
		and?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	or?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	not?: GraphQLTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["OrderRecurrenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderRecurrenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderRecurrenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderRecurrenceWhere"] | undefined
};
	["OrderRecurrenceLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined
};
	["ProductListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductListItemWhere"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductListWhere"] | undefined
};
	["ProductListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductListWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductListItemWhere"] | undefined
};
	["ProductWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	notDiscountable?: GraphQLTypes["BooleanCondition"] | undefined,
	packing?: GraphQLTypes["ProductPackingWhere"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	categories?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	stocks?: GraphQLTypes["ProductStockWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	availability?: GraphQLTypes["ProductAvailabilityCondition"] | undefined,
	order?: GraphQLTypes["FloatCondition"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["IntCondition"] | undefined,
	deliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	locales?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	archivedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductWhere"] | undefined,
	meta?: GraphQLTypes["ProductMetadataWhere"] | undefined,
	page?: GraphQLTypes["PageWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductWhere"] | undefined
};
	["ProductRecipeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	color?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientItemWhere"] | undefined,
	needsPrepping?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined,
	gutTuning?: GraphQLTypes["FloatCondition"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductRecipeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductRecipeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductRecipeWhere"] | undefined
};
	["ProductIngredientItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	internalOrder?: GraphQLTypes["IntCondition"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientItemWhere"] | undefined
};
	["ProductIngredientWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	allergens?: GraphQLTypes["AllergenWhere"] | undefined,
	needsPrepping?: GraphQLTypes["BooleanCondition"] | undefined,
	allowCustomerRating?: GraphQLTypes["BooleanCondition"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientWhere"] | undefined
};
	["ProductIngredientLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined
};
	["AllergenWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["AllergenLocaleWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	code?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["AllergenWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AllergenWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AllergenWhere"] | undefined
};
	["AllergenLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["AllergenWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["AllergenLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AllergenLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AllergenLocaleWhere"] | undefined
};
	["FreshingContainerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	config?: GraphQLTypes["GlobalConfigWhere"] | undefined,
	volumeMl?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["FreshingContainerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FreshingContainerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FreshingContainerWhere"] | undefined
};
	["GlobalConfigWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	orderDeadlineTime?: GraphQLTypes["StringCondition"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["IntCondition"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursWhere"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursWhere"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["IntCondition"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	orderUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductWhere"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	freshingContainers?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	messengerUrl?: GraphQLTypes["StringCondition"] | undefined,
	messengerIosUrl?: GraphQLTypes["StringCondition"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["StringCondition"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["GlobalConfigWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GlobalConfigWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GlobalConfigWhere"] | undefined
};
	["WeekHoursWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	mon?: GraphQLTypes["StringCondition"] | undefined,
	tue?: GraphQLTypes["StringCondition"] | undefined,
	wed?: GraphQLTypes["StringCondition"] | undefined,
	thu?: GraphQLTypes["StringCondition"] | undefined,
	fri?: GraphQLTypes["StringCondition"] | undefined,
	sat?: GraphQLTypes["StringCondition"] | undefined,
	sun?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["WeekHoursWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["WeekHoursWhere"] | undefined> | undefined,
	not?: GraphQLTypes["WeekHoursWhere"] | undefined
};
	["OneCondition"]: {
		and?: Array<GraphQLTypes["OneCondition"]> | undefined,
	or?: Array<GraphQLTypes["OneCondition"]> | undefined,
	not?: GraphQLTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["One"] | undefined,
	notEq?: GraphQLTypes["One"] | undefined,
	in?: Array<GraphQLTypes["One"]> | undefined,
	notIn?: Array<GraphQLTypes["One"]> | undefined,
	lt?: GraphQLTypes["One"] | undefined,
	lte?: GraphQLTypes["One"] | undefined,
	gt?: GraphQLTypes["One"] | undefined,
	gte?: GraphQLTypes["One"] | undefined
};
	["One"]: One;
	["VirtualProductWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["VirtualProductTypeCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	voucherCreditCents?: GraphQLTypes["IntCondition"] | undefined,
	physicalRepresentation?: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	recipientEmail?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["VirtualProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VirtualProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VirtualProductWhere"] | undefined
};
	["VirtualProductTypeCondition"]: {
		and?: Array<GraphQLTypes["VirtualProductTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["VirtualProductTypeCondition"]> | undefined,
	not?: GraphQLTypes["VirtualProductTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VirtualProductType"] | undefined,
	notEq?: GraphQLTypes["VirtualProductType"] | undefined,
	in?: Array<GraphQLTypes["VirtualProductType"]> | undefined,
	notIn?: Array<GraphQLTypes["VirtualProductType"]> | undefined,
	lt?: GraphQLTypes["VirtualProductType"] | undefined,
	lte?: GraphQLTypes["VirtualProductType"] | undefined,
	gt?: GraphQLTypes["VirtualProductType"] | undefined,
	gte?: GraphQLTypes["VirtualProductType"] | undefined
};
	["VirtualProductType"]: VirtualProductType;
	["VirtualProductPhysicalRepresentationTypeCondition"]: {
		and?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	not?: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	notEq?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	in?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationType"]> | undefined,
	notIn?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationType"]> | undefined,
	lt?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	lte?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	gt?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	gte?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined
};
	["VirtualProductPhysicalRepresentationType"]: VirtualProductPhysicalRepresentationType;
	["VatRateWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	ratePermille?: GraphQLTypes["IntCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["VatRateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VatRateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VatRateWhere"] | undefined
};
	["ProductRecipeLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductRecipeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductRecipeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined
};
	["FloatCondition"]: {
		and?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	or?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	not?: GraphQLTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["TagWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	categories?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["ChannelWhere"] | undefined,
	deliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	publicInChannels?: GraphQLTypes["ChannelWhere"] | undefined,
	and?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TagWhere"] | undefined
};
	["ProductCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	parent?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	children?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	color?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	order?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryWhere"] | undefined
};
	["ChannelWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	disabled?: GraphQLTypes["BooleanCondition"] | undefined,
	code?: GraphQLTypes["ChannelCodeCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["TagWhere"] | undefined,
	publicTags?: GraphQLTypes["TagWhere"] | undefined,
	and?: Array<GraphQLTypes["ChannelWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ChannelWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ChannelWhere"] | undefined
};
	["ChannelCodeCondition"]: {
		and?: Array<GraphQLTypes["ChannelCodeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ChannelCodeCondition"]> | undefined,
	not?: GraphQLTypes["ChannelCodeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ChannelCode"] | undefined,
	notEq?: GraphQLTypes["ChannelCode"] | undefined,
	in?: Array<GraphQLTypes["ChannelCode"]> | undefined,
	notIn?: Array<GraphQLTypes["ChannelCode"]> | undefined,
	lt?: GraphQLTypes["ChannelCode"] | undefined,
	lte?: GraphQLTypes["ChannelCode"] | undefined,
	gt?: GraphQLTypes["ChannelCode"] | undefined,
	gte?: GraphQLTypes["ChannelCode"] | undefined
};
	["DeliveryZoneWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	type?: GraphQLTypes["DeliveryZoneTypeCondition"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["TagWhere"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryZoneWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryZoneWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryZoneWhere"] | undefined
};
	["DeliveryZoneTypeCondition"]: {
		and?: Array<GraphQLTypes["DeliveryZoneTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DeliveryZoneTypeCondition"]> | undefined,
	not?: GraphQLTypes["DeliveryZoneTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DeliveryZoneType"] | undefined,
	notEq?: GraphQLTypes["DeliveryZoneType"] | undefined,
	in?: Array<GraphQLTypes["DeliveryZoneType"]> | undefined,
	notIn?: Array<GraphQLTypes["DeliveryZoneType"]> | undefined,
	lt?: GraphQLTypes["DeliveryZoneType"] | undefined,
	lte?: GraphQLTypes["DeliveryZoneType"] | undefined,
	gt?: GraphQLTypes["DeliveryZoneType"] | undefined,
	gte?: GraphQLTypes["DeliveryZoneType"] | undefined
};
	["DeliveryZoneType"]: DeliveryZoneType;
	["DeliveryMethodWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	paymentMethods?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["BooleanCondition"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryMethodWhere"] | undefined
};
	["PaymentMethodWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	blockingDispatch?: GraphQLTypes["BooleanCondition"] | undefined,
	type?: GraphQLTypes["PaymentMethodTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["PaymentMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PaymentMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PaymentMethodWhere"] | undefined
};
	["PaymentMethodTypeCondition"]: {
		and?: Array<GraphQLTypes["PaymentMethodTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["PaymentMethodTypeCondition"]> | undefined,
	not?: GraphQLTypes["PaymentMethodTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["PaymentMethodType"] | undefined,
	notEq?: GraphQLTypes["PaymentMethodType"] | undefined,
	in?: Array<GraphQLTypes["PaymentMethodType"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentMethodType"]> | undefined,
	lt?: GraphQLTypes["PaymentMethodType"] | undefined,
	lte?: GraphQLTypes["PaymentMethodType"] | undefined,
	gt?: GraphQLTypes["PaymentMethodType"] | undefined,
	gte?: GraphQLTypes["PaymentMethodType"] | undefined
};
	["PaymentMethodType"]: PaymentMethodType;
	["DeliveryTimelinePresetWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["DeliveryTimelineTypeCondition"] | undefined,
	duration?: GraphQLTypes["StringCondition"] | undefined,
	window?: GraphQLTypes["StringCondition"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined
};
	["DeliveryTimelineTypeCondition"]: {
		and?: Array<GraphQLTypes["DeliveryTimelineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DeliveryTimelineTypeCondition"]> | undefined,
	not?: GraphQLTypes["DeliveryTimelineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	notEq?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	in?: Array<GraphQLTypes["DeliveryTimelineType"]> | undefined,
	notIn?: Array<GraphQLTypes["DeliveryTimelineType"]> | undefined,
	lt?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	lte?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	gt?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	gte?: GraphQLTypes["DeliveryTimelineType"] | undefined
};
	["DeliveryTimelineType"]: DeliveryTimelineType;
	["DeliveryMethodLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	processingTitle?: GraphQLTypes["StringCondition"] | undefined,
	processingText?: GraphQLTypes["StringCondition"] | undefined,
	processingIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedTitle?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedText?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledTitle?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledText?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	root?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined
};
	["ProductStockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	store?: GraphQLTypes["StoreWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductStockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductStockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductStockWhere"] | undefined
};
	["StoreWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["StoreWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StoreWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StoreWhere"] | undefined
};
	["ProductAvailabilityCondition"]: {
		and?: Array<GraphQLTypes["ProductAvailabilityCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductAvailabilityCondition"]> | undefined,
	not?: GraphQLTypes["ProductAvailabilityCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductAvailability"] | undefined,
	notEq?: GraphQLTypes["ProductAvailability"] | undefined,
	in?: Array<GraphQLTypes["ProductAvailability"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductAvailability"]> | undefined,
	lt?: GraphQLTypes["ProductAvailability"] | undefined,
	lte?: GraphQLTypes["ProductAvailability"] | undefined,
	gt?: GraphQLTypes["ProductAvailability"] | undefined,
	gte?: GraphQLTypes["ProductAvailability"] | undefined
};
	["ProductAvailability"]: ProductAvailability;
	["ProductLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	featureList?: GraphQLTypes["TextListWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductLocaleWhere"] | undefined
};
	["TextListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["TextItemWhere"] | undefined,
	and?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextListWhere"] | undefined
};
	["TextItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["TextListWhere"] | undefined,
	and?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextItemWhere"] | undefined
};
	["DateTimeCondition"]: {
		and?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	not?: GraphQLTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DateTime"] | undefined,
	notEq?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined
};
	["DateTime"]: string;
	["ProductMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	archived?: GraphQLTypes["BooleanCondition"] | undefined,
	available?: GraphQLTypes["BooleanCondition"] | undefined,
	availableQuantity?: GraphQLTypes["IntCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductMetadataWhere"] | undefined
};
	["PageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["PageLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["PageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PageWhere"] | undefined
};
	["PageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	root?: GraphQLTypes["PageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	cover?: GraphQLTypes["CoverWhere"] | undefined,
	theme?: GraphQLTypes["PageThemeCondition"] | undefined,
	and?: Array<GraphQLTypes["PageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PageLocaleWhere"] | undefined
};
	["LinkableWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	page?: GraphQLTypes["PageLocaleWhere"] | undefined,
	wikiPage?: GraphQLTypes["WikiPageWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkableWhere"] | undefined
};
	["BlogPostLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	availableForWeb?: GraphQLTypes["BooleanCondition"] | undefined,
	availableForMobile?: GraphQLTypes["BooleanCondition"] | undefined,
	root?: GraphQLTypes["BlogPostWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	cover?: GraphQLTypes["CoverWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostLocaleWhere"] | undefined
};
	["BlogPostWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostWhere"] | undefined
};
	["CoverWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	primaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	secondaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	buttonColors?: GraphQLTypes["ColorPalleteWhere"] | undefined,
	and?: Array<GraphQLTypes["CoverWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CoverWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CoverWhere"] | undefined
};
	["MediumWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["MediumTypeCondition"] | undefined,
	colorTheme?: GraphQLTypes["ColorThemeCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	video?: GraphQLTypes["VideoWhere"] | undefined,
	and?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MediumWhere"] | undefined
};
	["MediumTypeCondition"]: {
		and?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	not?: GraphQLTypes["MediumTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["MediumType"] | undefined,
	notEq?: GraphQLTypes["MediumType"] | undefined,
	in?: Array<GraphQLTypes["MediumType"]> | undefined,
	notIn?: Array<GraphQLTypes["MediumType"]> | undefined,
	lt?: GraphQLTypes["MediumType"] | undefined,
	lte?: GraphQLTypes["MediumType"] | undefined,
	gt?: GraphQLTypes["MediumType"] | undefined,
	gte?: GraphQLTypes["MediumType"] | undefined
};
	["MediumType"]: MediumType;
	["ColorThemeCondition"]: {
		and?: Array<GraphQLTypes["ColorThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ColorThemeCondition"]> | undefined,
	not?: GraphQLTypes["ColorThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ColorTheme"] | undefined,
	notEq?: GraphQLTypes["ColorTheme"] | undefined,
	in?: Array<GraphQLTypes["ColorTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["ColorTheme"]> | undefined,
	lt?: GraphQLTypes["ColorTheme"] | undefined,
	lte?: GraphQLTypes["ColorTheme"] | undefined,
	gt?: GraphQLTypes["ColorTheme"] | undefined,
	gte?: GraphQLTypes["ColorTheme"] | undefined
};
	["ColorTheme"]: ColorTheme;
	["VideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	src?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	duration?: GraphQLTypes["FloatCondition"] | undefined,
	poster?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VideoWhere"] | undefined
};
	["ColorPalleteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	background?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ColorPalleteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ColorPalleteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ColorPalleteWhere"] | undefined
};
	["ContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	blocks?: GraphQLTypes["ContentBlockWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	json?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	references?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	primaryText?: GraphQLTypes["StringCondition"] | undefined,
	secondaryText?: GraphQLTypes["StringCondition"] | undefined,
	jsonContent?: GraphQLTypes["StringCondition"] | undefined,
	block?: GraphQLTypes["ContentBlockWhere"] | undefined,
	colorPallete?: GraphQLTypes["ColorPalleteWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostListWhere"] | undefined,
	links?: GraphQLTypes["LinkListWhere"] | undefined,
	products?: GraphQLTypes["ProductListWhere"] | undefined,
	contentSize?: GraphQLTypes["ContentSizeCondition"] | undefined,
	misc?: GraphQLTypes["StringCondition"] | undefined,
	hero?: GraphQLTypes["HeroWhere"] | undefined,
	gallery?: GraphQLTypes["GalleryWhere"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsWhere"] | undefined,
	productGrid?: GraphQLTypes["ProductGridWhere"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentReferenceType"] | undefined,
	notEq?: GraphQLTypes["ContentReferenceType"] | undefined,
	in?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	lt?: GraphQLTypes["ContentReferenceType"] | undefined,
	lte?: GraphQLTypes["ContentReferenceType"] | undefined,
	gt?: GraphQLTypes["ContentReferenceType"] | undefined,
	gte?: GraphQLTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]: ContentReferenceType;
	["LinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["LinkTypeCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	externalLink?: GraphQLTypes["StringCondition"] | undefined,
	internalLink?: GraphQLTypes["LinkableWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkWhere"] | undefined
};
	["LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	not?: GraphQLTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["LinkType"] | undefined,
	notEq?: GraphQLTypes["LinkType"] | undefined,
	in?: Array<GraphQLTypes["LinkType"]> | undefined,
	notIn?: Array<GraphQLTypes["LinkType"]> | undefined,
	lt?: GraphQLTypes["LinkType"] | undefined,
	lte?: GraphQLTypes["LinkType"] | undefined,
	gt?: GraphQLTypes["LinkType"] | undefined,
	gte?: GraphQLTypes["LinkType"] | undefined
};
	["LinkType"]: LinkType;
	["BlogPostListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["BlogPostListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostListWhere"] | undefined
};
	["BlogPostListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	caption?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["BlogPostListWhere"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostListItemWhere"] | undefined
};
	["LinkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["LinkListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListWhere"] | undefined
};
	["LinkListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	isPrimary?: GraphQLTypes["BooleanCondition"] | undefined,
	list?: GraphQLTypes["LinkListWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListItemWhere"] | undefined
};
	["ContentSizeCondition"]: {
		and?: Array<GraphQLTypes["ContentSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentSizeCondition"]> | undefined,
	not?: GraphQLTypes["ContentSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentSize"] | undefined,
	notEq?: GraphQLTypes["ContentSize"] | undefined,
	in?: Array<GraphQLTypes["ContentSize"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentSize"]> | undefined,
	lt?: GraphQLTypes["ContentSize"] | undefined,
	lte?: GraphQLTypes["ContentSize"] | undefined,
	gt?: GraphQLTypes["ContentSize"] | undefined,
	gte?: GraphQLTypes["ContentSize"] | undefined
};
	["ContentSize"]: ContentSize;
	["HeroWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	showLogo?: GraphQLTypes["BooleanCondition"] | undefined,
	middleContent?: GraphQLTypes["StringCondition"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["HeroWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeroWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeroWhere"] | undefined
};
	["GalleryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["GalleryItemWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryWhere"] | undefined
};
	["GalleryItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	caption?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["GalleryWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryItemWhere"] | undefined
};
	["SocialsAndAppsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	instagram?: GraphQLTypes["BooleanCondition"] | undefined,
	appStore?: GraphQLTypes["BooleanCondition"] | undefined,
	googlePlay?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["SocialsAndAppsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SocialsAndAppsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SocialsAndAppsWhere"] | undefined
};
	["ProductGridWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	isCarousel?: GraphQLTypes["BooleanCondition"] | undefined,
	categories?: GraphQLTypes["ProductGridCategoryWhere"] | undefined,
	type?: GraphQLTypes["ProductGridTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductGridWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridWhere"] | undefined
};
	["ProductGridCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductGridWhere"] | undefined,
	items?: GraphQLTypes["ProductGridItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductGridCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridCategoryWhere"] | undefined
};
	["ProductGridItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	lead?: GraphQLTypes["StringCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	category?: GraphQLTypes["ProductGridCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductGridItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridItemWhere"] | undefined
};
	["ProductGridTypeCondition"]: {
		and?: Array<GraphQLTypes["ProductGridTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductGridTypeCondition"]> | undefined,
	not?: GraphQLTypes["ProductGridTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductGridType"] | undefined,
	notEq?: GraphQLTypes["ProductGridType"] | undefined,
	in?: Array<GraphQLTypes["ProductGridType"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductGridType"]> | undefined,
	lt?: GraphQLTypes["ProductGridType"] | undefined,
	lte?: GraphQLTypes["ProductGridType"] | undefined,
	gt?: GraphQLTypes["ProductGridType"] | undefined,
	gte?: GraphQLTypes["ProductGridType"] | undefined
};
	["ProductGridType"]: ProductGridType;
	["ProductBannerListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductBannerItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerListWhere"] | undefined
};
	["ProductBannerItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductBannerListWhere"] | undefined,
	banner?: GraphQLTypes["ProductBannerWhere"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSizeCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerItemWhere"] | undefined
};
	["ProductBannerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	beforeTitle?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	button?: GraphQLTypes["LinkWhere"] | undefined,
	backgroundImage?: GraphQLTypes["ImageWhere"] | undefined,
	productImage?: GraphQLTypes["ImageWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerWhere"] | undefined
};
	["ProductBannerItemSizeCondition"]: {
		and?: Array<GraphQLTypes["ProductBannerItemSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductBannerItemSizeCondition"]> | undefined,
	not?: GraphQLTypes["ProductBannerItemSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	notEq?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	in?: Array<GraphQLTypes["ProductBannerItemSize"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductBannerItemSize"]> | undefined,
	lt?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	lte?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	gt?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	gte?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemSize"]: ProductBannerItemSize;
	["DeliveryRegionsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["DeliveryRegionsItemWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryRegionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryRegionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryRegionsWhere"] | undefined
};
	["DeliveryRegionsItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	offerLabel?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["DeliveryRegionsWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	textList?: GraphQLTypes["TextListWhere"] | undefined,
	gallery?: GraphQLTypes["ImageListWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryRegionsItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryRegionsItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryRegionsItemWhere"] | undefined
};
	["ImageListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ImageListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ImageListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListItemWhere"] | undefined
};
	["SeoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	ogTitle?: GraphQLTypes["StringCondition"] | undefined,
	ogDescription?: GraphQLTypes["StringCondition"] | undefined,
	ogImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoWhere"] | undefined
};
	["WikiPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	emoji?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	parent?: GraphQLTypes["WikiPageWhere"] | undefined,
	children?: GraphQLTypes["WikiPageWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	and?: Array<GraphQLTypes["WikiPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["WikiPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["WikiPageWhere"] | undefined
};
	["RedirectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	target?: GraphQLTypes["LinkableWhere"] | undefined,
	and?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RedirectWhere"] | undefined
};
	["PageThemeCondition"]: {
		and?: Array<GraphQLTypes["PageThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["PageThemeCondition"]> | undefined,
	not?: GraphQLTypes["PageThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["PageTheme"] | undefined,
	notEq?: GraphQLTypes["PageTheme"] | undefined,
	in?: Array<GraphQLTypes["PageTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["PageTheme"]> | undefined,
	lt?: GraphQLTypes["PageTheme"] | undefined,
	lte?: GraphQLTypes["PageTheme"] | undefined,
	gt?: GraphQLTypes["PageTheme"] | undefined,
	gte?: GraphQLTypes["PageTheme"] | undefined
};
	["PageTheme"]: PageTheme;
	["ProductCategoryListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductCategoryListItemWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListWhere"] | undefined
};
	["ProductCategoryListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	showRecipeImages?: GraphQLTypes["BooleanCondition"] | undefined,
	showPackingImages?: GraphQLTypes["BooleanCondition"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListItemWhere"] | undefined
};
	["ProductCategoryListLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined
};
	["BlogLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	pageName?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	lead?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BlogWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogLocaleWhere"] | undefined
};
	["BlogWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["BlogLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogWhere"] | undefined
};
	["TranslationCataloguesIdentifierWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined
};
	["TranslationCatalogueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCatalogueWhere"] | undefined
};
	["TranslationDomainWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationDomainWhere"] | undefined
};
	["TranslationKeyWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	contentType?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationKeyWhere"] | undefined
};
	["TranslationContentTypeCondition"]: {
		and?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	not?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["TranslationContentType"] | undefined,
	notEq?: GraphQLTypes["TranslationContentType"] | undefined,
	in?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	notIn?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	lt?: GraphQLTypes["TranslationContentType"] | undefined,
	lte?: GraphQLTypes["TranslationContentType"] | undefined,
	gt?: GraphQLTypes["TranslationContentType"] | undefined,
	gte?: GraphQLTypes["TranslationContentType"] | undefined
};
	["TranslationContentType"]: TranslationContentType;
	["TranslationValueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	value?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationValueWhere"] | undefined
};
	["MenuLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["SiteMenuWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	items?: GraphQLTypes["MenuItemWhere"] | undefined,
	secondaryItems?: GraphQLTypes["LinkListWhere"] | undefined,
	eshopLink?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["MenuLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuLocaleWhere"] | undefined
};
	["SiteMenuWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["MenuLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["SiteMenuWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SiteMenuWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SiteMenuWhere"] | undefined
};
	["MenuItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	menu?: GraphQLTypes["MenuLocaleWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	subitems?: GraphQLTypes["MenuSubitemWhere"] | undefined,
	and?: Array<GraphQLTypes["MenuItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuItemWhere"] | undefined
};
	["MenuSubitemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["MenuSubitemTypeCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	item?: GraphQLTypes["MenuItemWhere"] | undefined,
	links?: GraphQLTypes["MenuLinkListWhere"] | undefined,
	products?: GraphQLTypes["MenuProductsWhere"] | undefined,
	delivery?: GraphQLTypes["MenuDeliveryWhere"] | undefined,
	contact?: GraphQLTypes["MenuContactWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["MenuSubitemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuSubitemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuSubitemWhere"] | undefined
};
	["MenuSubitemTypeCondition"]: {
		and?: Array<GraphQLTypes["MenuSubitemTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["MenuSubitemTypeCondition"]> | undefined,
	not?: GraphQLTypes["MenuSubitemTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["MenuSubitemType"] | undefined,
	notEq?: GraphQLTypes["MenuSubitemType"] | undefined,
	in?: Array<GraphQLTypes["MenuSubitemType"]> | undefined,
	notIn?: Array<GraphQLTypes["MenuSubitemType"]> | undefined,
	lt?: GraphQLTypes["MenuSubitemType"] | undefined,
	lte?: GraphQLTypes["MenuSubitemType"] | undefined,
	gt?: GraphQLTypes["MenuSubitemType"] | undefined,
	gte?: GraphQLTypes["MenuSubitemType"] | undefined
};
	["MenuSubitemType"]: MenuSubitemType;
	["MenuLinkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["MenuLinkItemWhere"] | undefined,
	and?: Array<GraphQLTypes["MenuLinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuLinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuLinkListWhere"] | undefined
};
	["MenuLinkItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["MenuLinkListWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["MenuLinkItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuLinkItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuLinkItemWhere"] | undefined
};
	["MenuProductsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	links?: GraphQLTypes["MenuLinkListWhere"] | undefined,
	buttons?: GraphQLTypes["LinkListWhere"] | undefined,
	and?: Array<GraphQLTypes["MenuProductsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuProductsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuProductsWhere"] | undefined
};
	["MenuDeliveryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsWhere"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsWhere"] | undefined,
	and?: Array<GraphQLTypes["MenuDeliveryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuDeliveryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuDeliveryWhere"] | undefined
};
	["MenuContactWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["MenuContactWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MenuContactWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MenuContactWhere"] | undefined
};
	["Locale"]: {
	__typename: "Locale",
	_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPostLocale"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	menus: Array<GraphQLTypes["MenuLocale"]>,
	pages: Array<GraphQLTypes["PageLocale"]>,
	allergens: Array<GraphQLTypes["AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["ProductPackingLocale"]>,
	products: Array<GraphQLTypes["ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["ProductRecipeLocale"]>,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	menusByRoot?: GraphQLTypes["MenuLocale"] | undefined,
	menusByItems?: GraphQLTypes["MenuLocale"] | undefined,
	menusBySecondaryItems?: GraphQLTypes["MenuLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["PageLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostLocaleConnection"],
	paginateMenus: GraphQLTypes["MenuLocaleConnection"],
	paginatePages: GraphQLTypes["PageLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["ProductRecipeLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["LocaleMeta"]: {
	__typename: "LocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["FieldMeta"] | undefined,
	blogs?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	menus?: GraphQLTypes["FieldMeta"] | undefined,
	pages?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocale"]: {
	__typename: "BusinessCategoryLocale",
	_meta?: GraphQLTypes["BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	title?: string | undefined
};
	["BusinessCategoryLocaleMeta"]: {
	__typename: "BusinessCategoryLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategory"]: {
	__typename: "BusinessCategory",
	_meta?: GraphQLTypes["BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	internalName: string,
	recurrences: Array<GraphQLTypes["OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	paginateLocales: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateRecurrences: GraphQLTypes["OrderRecurrenceConnection"]
};
	["BusinessCategoryMeta"]: {
	__typename: "BusinessCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined
};
	["BusinessCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	specificProducts?: GraphQLTypes["ProductListOrderBy"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined
};
	["ProductCategoryListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined
};
	["LocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["TranslationCataloguesIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderRecurrence"]: {
	__typename: "OrderRecurrence",
	_meta?: GraphQLTypes["OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"]
};
	["OrderRecurrenceMeta"]: {
	__typename: "OrderRecurrenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocale"]: {
	__typename: "OrderRecurrenceLocale",
	_meta?: GraphQLTypes["OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["OrderRecurrenceLocaleMeta"]: {
	__typename: "OrderRecurrenceLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["OrderRecurrenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"] | undefined,
	productIngredients?: GraphQLTypes["ProductIngredientLocaleUniqueWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined,
	blogs?: GraphQLTypes["BlogLocaleUniqueWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	menus?: GraphQLTypes["MenuLocaleUniqueWhere"] | undefined,
	pages?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined,
	allergens?: GraphQLTypes["AllergenLocaleUniqueWhere"] | undefined,
	productPackings?: GraphQLTypes["ProductPackingLocaleUniqueWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined,
	productRecipes?: GraphQLTypes["ProductRecipeLocaleUniqueWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"] | undefined
};
	["BusinessCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locales?: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"] | undefined,
	specificProducts?: GraphQLTypes["ProductListUniqueWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["ProductListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductListItemUniqueWhere"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["ProductListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductCategoryListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductCategoryListItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["ProductCategoryListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductCategoryListLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductIngredientLocaleUniqueWhere"] | undefined
};
	["OrderRecurrenceLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["OrderRecurrenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined
};
	["BlogLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["BlogLocaleUniqueWhere"] | undefined
};
	["BlogPostLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	cover?: GraphQLTypes["CoverUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined
};
	["CoverUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: GraphQLTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["ProductListUniqueWhere"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListUniqueWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsUniqueWhere"] | undefined
};
	["ProductBannerListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductBannerItemUniqueWhere"] | undefined
};
	["ProductBannerItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["DeliveryRegionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["DeliveryRegionsItemUniqueWhere"] | undefined
};
	["DeliveryRegionsItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	textList?: GraphQLTypes["TextListUniqueWhere"] | undefined,
	gallery?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["TextListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TextItemUniqueWhere"] | undefined
};
	["TextItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	page?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined,
	wikiPage?: GraphQLTypes["WikiPageUniqueWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectUniqueWhere"] | undefined
};
	["PageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["PageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["PageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	ogImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["WikiPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	children?: GraphQLTypes["WikiPageUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationCatalogueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationKeyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationValueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["MenuLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["SiteMenuUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	items?: GraphQLTypes["MenuItemUniqueWhere"] | undefined,
	secondaryItems?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["SiteMenuUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["MenuLocaleUniqueWhere"] | undefined
};
	["MenuItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	subitems?: GraphQLTypes["MenuSubitemUniqueWhere"] | undefined
};
	["MenuSubitemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["LinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["LinkListItemUniqueWhere"] | undefined
};
	["LinkListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["AllergenLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["AllergenUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AllergenUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: number | undefined,
	locales?: GraphQLTypes["AllergenLocaleUniqueWhere"] | undefined
};
	["ProductPackingLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductPackingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductPackingLocaleUniqueWhere"] | undefined
};
	["ProductLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["ProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	stocks?: GraphQLTypes["ProductStockUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined,
	meta?: GraphQLTypes["ProductMetadataUniqueWhere"] | undefined,
	page?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["ProductStockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	store?: GraphQLTypes["StoreUniqueWhere"] | undefined
};
	["StoreUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["VirtualProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["ProductMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["ProductRecipeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductRecipeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductRecipeLocaleUniqueWhere"] | undefined
};
	["ProductIngredientItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["DeliveryMethodLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	root?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["DeliveryMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetUniqueWhere"] | undefined,
	locales?: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"] | undefined
};
	["DeliveryTimelinePresetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["OrderRecurrenceLocaleConnection"]: {
	__typename: "OrderRecurrenceLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceLocaleEdge"]>
};
	["OrderRecurrenceLocaleEdge"]: {
	__typename: "OrderRecurrenceLocaleEdge",
	node: GraphQLTypes["OrderRecurrenceLocale"]
};
	["BusinessCategoryConnection"]: {
	__typename: "BusinessCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryEdge"]>
};
	["BusinessCategoryEdge"]: {
	__typename: "BusinessCategoryEdge",
	node: GraphQLTypes["BusinessCategory"]
};
	["ProductList"]: {
	__typename: "ProductList",
	_meta?: GraphQLTypes["ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductListItem"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["ProductListItemConnection"]
};
	["ProductListMeta"]: {
	__typename: "ProductListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItem"]: {
	__typename: "ProductListItem",
	_meta?: GraphQLTypes["ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["ProductListItemMeta"]: {
	__typename: "ProductListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductListOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	notDiscountable?: GraphQLTypes["OrderDirection"] | undefined,
	packing?: GraphQLTypes["ProductPackingOrderBy"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	availability?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["OrderDirection"] | undefined,
	archivedAt?: GraphQLTypes["OrderDirection"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductOrderBy"] | undefined,
	meta?: GraphQLTypes["ProductMetadataOrderBy"] | undefined,
	page?: GraphQLTypes["PageOrderBy"] | undefined
};
	["ProductPackingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	volumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	shortName?: GraphQLTypes["OrderDirection"] | undefined,
	isPackedInCardboard?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	alt?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	color?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	needsPrepping?: GraphQLTypes["OrderDirection"] | undefined,
	gutTuning?: GraphQLTypes["OrderDirection"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["FreshingContainerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	config?: GraphQLTypes["GlobalConfigOrderBy"] | undefined,
	volumeMl?: GraphQLTypes["OrderDirection"] | undefined
};
	["GlobalConfigOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	orderDeadlineTime?: GraphQLTypes["OrderDirection"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["OrderDirection"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursOrderBy"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursOrderBy"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	orderUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductOrderBy"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	messengerUrl?: GraphQLTypes["OrderDirection"] | undefined,
	messengerIosUrl?: GraphQLTypes["OrderDirection"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["OrderDirection"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["OrderDirection"] | undefined
};
	["WeekHoursOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	mon?: GraphQLTypes["OrderDirection"] | undefined,
	tue?: GraphQLTypes["OrderDirection"] | undefined,
	wed?: GraphQLTypes["OrderDirection"] | undefined,
	thu?: GraphQLTypes["OrderDirection"] | undefined,
	fri?: GraphQLTypes["OrderDirection"] | undefined,
	sat?: GraphQLTypes["OrderDirection"] | undefined,
	sun?: GraphQLTypes["OrderDirection"] | undefined
};
	["VirtualProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	voucherCreditCents?: GraphQLTypes["OrderDirection"] | undefined,
	physicalRepresentation?: GraphQLTypes["OrderDirection"] | undefined,
	recipientEmail?: GraphQLTypes["OrderDirection"] | undefined
};
	["VatRateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	ratePermille?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	archived?: GraphQLTypes["OrderDirection"] | undefined,
	available?: GraphQLTypes["OrderDirection"] | undefined,
	availableQuantity?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["PageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductListItemConnection"]: {
	__typename: "ProductListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListItemEdge"]>
};
	["ProductListItemEdge"]: {
	__typename: "ProductListItemEdge",
	node: GraphQLTypes["ProductListItem"]
};
	["ProductCategoryList"]: {
	__typename: "ProductCategoryList",
	_meta?: GraphQLTypes["ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["ProductCategoryListLocaleConnection"]
};
	["ProductCategoryListMeta"]: {
	__typename: "ProductCategoryListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListItem"]: {
	__typename: "ProductCategoryListItem",
	_meta?: GraphQLTypes["ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["ProductCategoryListItemMeta"]: {
	__typename: "ProductCategoryListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
	__typename: "ProductCategory",
	_meta?: GraphQLTypes["ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	parent?: GraphQLTypes["ProductCategory"] | undefined,
	children: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	order: number,
	childrenByCode?: GraphQLTypes["ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateChildren: GraphQLTypes["ProductCategoryConnection"]
};
	["ProductCategoryMeta"]: {
	__typename: "ProductCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	parent?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	color?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryChildrenByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["ProductCategoryChildrenByChildrenUniqueWhere"]: {
		children?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined
};
	["ProductCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	children?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined
};
	["ProductConnection"]: {
	__typename: "ProductConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
	__typename: "ProductEdge",
	node: GraphQLTypes["Product"]
};
	["TagConnection"]: {
	__typename: "TagConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagEdge"]>
};
	["TagEdge"]: {
	__typename: "TagEdge",
	node: GraphQLTypes["Tag"]
};
	["ProductCategoryConnection"]: {
	__typename: "ProductCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
	__typename: "ProductCategoryEdge",
	node: GraphQLTypes["ProductCategory"]
};
	["ProductCategoryListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	showRecipeImages?: GraphQLTypes["OrderDirection"] | undefined,
	showPackingImages?: GraphQLTypes["OrderDirection"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryListLocale"]: {
	__typename: "ProductCategoryListLocale",
	_meta?: GraphQLTypes["ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductCategoryListLocaleMeta"]: {
	__typename: "ProductCategoryListLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductCategoryListItemConnection"]: {
	__typename: "ProductCategoryListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListItemEdge"]>
};
	["ProductCategoryListItemEdge"]: {
	__typename: "ProductCategoryListItemEdge",
	node: GraphQLTypes["ProductCategoryListItem"]
};
	["ProductCategoryListLocaleConnection"]: {
	__typename: "ProductCategoryListLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListLocaleEdge"]>
};
	["ProductCategoryListLocaleEdge"]: {
	__typename: "ProductCategoryListLocaleEdge",
	node: GraphQLTypes["ProductCategoryListLocale"]
};
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessCategoryLocaleConnection"]: {
	__typename: "BusinessCategoryLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryLocaleEdge"]>
};
	["BusinessCategoryLocaleEdge"]: {
	__typename: "BusinessCategoryLocaleEdge",
	node: GraphQLTypes["BusinessCategoryLocale"]
};
	["OrderRecurrenceConnection"]: {
	__typename: "OrderRecurrenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceEdge"]>
};
	["OrderRecurrenceEdge"]: {
	__typename: "OrderRecurrenceEdge",
	node: GraphQLTypes["OrderRecurrence"]
};
	["ProductIngredientLocale"]: {
	__typename: "ProductIngredientLocale",
	_meta?: GraphQLTypes["ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientLocaleMeta"]: {
	__typename: "ProductIngredientLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredient"]: {
	__typename: "ProductIngredient",
	_meta?: GraphQLTypes["ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["ProductIngredientLocale"]>,
	internalName: string,
	allergens: Array<GraphQLTypes["Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	localesByLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenConnection"]
};
	["ProductIngredientMeta"]: {
	__typename: "ProductIngredientMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductIngredientOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductIngredientOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	needsPrepping?: GraphQLTypes["OrderDirection"] | undefined,
	allowCustomerRating?: GraphQLTypes["OrderDirection"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined
};
	["Allergen"]: {
	__typename: "Allergen",
	_meta?: GraphQLTypes["AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"]
};
	["AllergenMeta"]: {
	__typename: "AllergenMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocale"]: {
	__typename: "AllergenLocale",
	_meta?: GraphQLTypes["AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Allergen"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["AllergenLocaleMeta"]: {
	__typename: "AllergenLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["AllergenOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["AllergenOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["AllergenLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AllergenLocaleConnection"]: {
	__typename: "AllergenLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenLocaleEdge"]>
};
	["AllergenLocaleEdge"]: {
	__typename: "AllergenLocaleEdge",
	node: GraphQLTypes["AllergenLocale"]
};
	["ProductIngredientConnection"]: {
	__typename: "ProductIngredientConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientEdge"]>
};
	["ProductIngredientEdge"]: {
	__typename: "ProductIngredientEdge",
	node: GraphQLTypes["ProductIngredient"]
};
	["FreshingContainer"]: {
	__typename: "FreshingContainer",
	_meta?: GraphQLTypes["FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	config?: GraphQLTypes["GlobalConfig"] | undefined,
	volumeMl: number
};
	["FreshingContainerMeta"]: {
	__typename: "FreshingContainerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	config?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined
};
	["GlobalConfig"]: {
	__typename: "GlobalConfig",
	_meta?: GraphQLTypes["GlobalConfigMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	openingHours?: GraphQLTypes["WeekHours"] | undefined,
	unique: GraphQLTypes["One"],
	workingHours?: GraphQLTypes["WeekHours"] | undefined,
	freshingContainerVolumeMl: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	creditsProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	freshingContainers: Array<GraphQLTypes["FreshingContainer"]>,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingPrepareOrderByOffsetMinutes: number,
	paginateFreshingContainers: GraphQLTypes["FreshingContainerConnection"]
};
	["GlobalConfigMeta"]: {
	__typename: "GlobalConfigMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["FieldMeta"] | undefined
};
	["WeekHours"]: {
	__typename: "WeekHours",
	_meta?: GraphQLTypes["WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["WeekHoursMeta"]: {
	__typename: "WeekHoursMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	mon?: GraphQLTypes["FieldMeta"] | undefined,
	tue?: GraphQLTypes["FieldMeta"] | undefined,
	wed?: GraphQLTypes["FieldMeta"] | undefined,
	thu?: GraphQLTypes["FieldMeta"] | undefined,
	fri?: GraphQLTypes["FieldMeta"] | undefined,
	sat?: GraphQLTypes["FieldMeta"] | undefined,
	sun?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProduct"]: {
	__typename: "VirtualProduct",
	_meta?: GraphQLTypes["VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["VirtualProductMeta"]: {
	__typename: "VirtualProductMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["FieldMeta"] | undefined
};
	["VatRate"]: {
	__typename: "VatRate",
	_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["VatRateMeta"]: {
	__typename: "VatRateMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["FreshingContainerConnection"]: {
	__typename: "FreshingContainerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FreshingContainerEdge"]>
};
	["FreshingContainerEdge"]: {
	__typename: "FreshingContainerEdge",
	node: GraphQLTypes["FreshingContainer"]
};
	["ProductIngredientLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientLocaleConnection"]: {
	__typename: "ProductIngredientLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientLocaleEdge"]>
};
	["ProductIngredientLocaleEdge"]: {
	__typename: "ProductIngredientLocaleEdge",
	node: GraphQLTypes["ProductIngredientLocale"]
};
	["AllergenConnection"]: {
	__typename: "AllergenConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenEdge"]>
};
	["AllergenEdge"]: {
	__typename: "AllergenEdge",
	node: GraphQLTypes["Allergen"]
};
	["BlogLocale"]: {
	__typename: "BlogLocale",
	_meta?: GraphQLTypes["BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Blog"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["BlogLocaleMeta"]: {
	__typename: "BlogLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	pageName?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Blog"]: {
	__typename: "Blog",
	_meta?: GraphQLTypes["BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["BlogLocale"]>,
	localesByLocale?: GraphQLTypes["BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogLocaleConnection"]
};
	["BlogMeta"]: {
	__typename: "BlogMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	pageName?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	lead?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["BlogOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogLocaleConnection"]: {
	__typename: "BlogLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogLocaleEdge"]>
};
	["BlogLocaleEdge"]: {
	__typename: "BlogLocaleEdge",
	node: GraphQLTypes["BlogLocale"]
};
	["BlogPostLocale"]: {
	__typename: "BlogPostLocale",
	_meta?: GraphQLTypes["BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["BlogPost"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["BlogPostLocaleMeta"]: {
	__typename: "BlogPostLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
	__typename: "BlogPost",
	_meta?: GraphQLTypes["BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostLocaleConnection"]
};
	["BlogPostMeta"]: {
	__typename: "BlogPostMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	availableForWeb?: GraphQLTypes["OrderDirection"] | undefined,
	availableForMobile?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogPostOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	cover?: GraphQLTypes["CoverOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined
};
	["BlogPostOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["CoverOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	primaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	buttonColors?: GraphQLTypes["ColorPalleteOrderBy"] | undefined
};
	["MediumOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	colorTheme?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	video?: GraphQLTypes["VideoOrderBy"] | undefined
};
	["VideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	src?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	poster?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ColorPalleteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	background?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleOrderBy"] | undefined,
	page?: GraphQLTypes["PageLocaleOrderBy"] | undefined,
	wikiPage?: GraphQLTypes["WikiPageOrderBy"] | undefined,
	redirect?: GraphQLTypes["RedirectOrderBy"] | undefined
};
	["PageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	root?: GraphQLTypes["PageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	cover?: GraphQLTypes["CoverOrderBy"] | undefined,
	theme?: GraphQLTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	ogTitle?: GraphQLTypes["OrderDirection"] | undefined,
	ogDescription?: GraphQLTypes["OrderDirection"] | undefined,
	ogImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["WikiPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	emoji?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	parent?: GraphQLTypes["WikiPageOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["RedirectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	target?: GraphQLTypes["LinkableOrderBy"] | undefined
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["BlogPostLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostLocaleConnection"]: {
	__typename: "BlogPostLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
	__typename: "BlogPostLocaleEdge",
	node: GraphQLTypes["BlogPostLocale"]
};
	["Cover"]: {
	__typename: "Cover",
	_meta?: GraphQLTypes["CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	buttonColors?: GraphQLTypes["ColorPallete"] | undefined
};
	["CoverMeta"]: {
	__typename: "CoverMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["FieldMeta"] | undefined
};
	["Medium"]: {
	__typename: "Medium",
	_meta?: GraphQLTypes["MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MediumType"] | undefined,
	colorTheme?: GraphQLTypes["ColorTheme"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined
};
	["MediumMeta"]: {
	__typename: "MediumMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
	__typename: "Video",
	_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined
};
	["VideoMeta"]: {
	__typename: "VideoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined
};
	["ColorPallete"]: {
	__typename: "ColorPallete",
	_meta?: GraphQLTypes["ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["ColorPalleteMeta"]: {
	__typename: "ColorPalleteMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined
};
	["Content"]: {
	__typename: "Content",
	_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
	__typename: "ContentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
	__typename: "ContentBlock",
	_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByProducts?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
	__typename: "ContentBlockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
	__typename: "ContentReference",
	_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostList"] | undefined,
	links?: GraphQLTypes["LinkList"] | undefined,
	products?: GraphQLTypes["ProductList"] | undefined,
	contentSize?: GraphQLTypes["ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Hero"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined
};
	["ContentReferenceMeta"]: {
	__typename: "ContentReferenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["FieldMeta"] | undefined,
	misc?: GraphQLTypes["FieldMeta"] | undefined,
	hero?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
	__typename: "Link",
	_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined
};
	["LinkMeta"]: {
	__typename: "LinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
	__typename: "Linkable",
	_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined,
	page?: GraphQLTypes["PageLocale"] | undefined,
	wikiPage?: GraphQLTypes["WikiPage"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined
};
	["LinkableMeta"]: {
	__typename: "LinkableMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	wikiPage?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocale"]: {
	__typename: "PageLocale",
	_meta?: GraphQLTypes["PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["Page"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	theme?: GraphQLTypes["PageTheme"] | undefined
};
	["PageLocaleMeta"]: {
	__typename: "PageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["Page"]: {
	__typename: "Page",
	_meta?: GraphQLTypes["PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["PageLocale"]>,
	localesByLocale?: GraphQLTypes["PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["PageLocaleConnection"]
};
	["PageMeta"]: {
	__typename: "PageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["PageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["PageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["PageLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["PageLocaleConnection"]: {
	__typename: "PageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageLocaleEdge"]>
};
	["PageLocaleEdge"]: {
	__typename: "PageLocaleEdge",
	node: GraphQLTypes["PageLocale"]
};
	["Seo"]: {
	__typename: "Seo",
	_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
	__typename: "SeoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["WikiPage"]: {
	__typename: "WikiPage",
	_meta?: GraphQLTypes["WikiPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	parent?: GraphQLTypes["WikiPage"] | undefined,
	children: Array<GraphQLTypes["WikiPage"]>,
	content?: GraphQLTypes["Content"] | undefined,
	childrenByLink?: GraphQLTypes["WikiPage"] | undefined,
	childrenByChildren?: GraphQLTypes["WikiPage"] | undefined,
	childrenByContent?: GraphQLTypes["WikiPage"] | undefined,
	paginateChildren: GraphQLTypes["WikiPageConnection"]
};
	["WikiPageMeta"]: {
	__typename: "WikiPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	emoji?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined
};
	["WikiPageChildrenByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["WikiPageChildrenByChildrenUniqueWhere"]: {
		children?: GraphQLTypes["WikiPageUniqueWhere"] | undefined
};
	["WikiPageChildrenByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["WikiPageConnection"]: {
	__typename: "WikiPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WikiPageEdge"]>
};
	["WikiPageEdge"]: {
	__typename: "WikiPageEdge",
	node: GraphQLTypes["WikiPage"]
};
	["Redirect"]: {
	__typename: "Redirect",
	_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Linkable"] | undefined
};
	["RedirectMeta"]: {
	__typename: "RedirectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostList"]: {
	__typename: "BlogPostList",
	_meta?: GraphQLTypes["BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateItems: GraphQLTypes["BlogPostListItemConnection"]
};
	["BlogPostListMeta"]: {
	__typename: "BlogPostListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItem"]: {
	__typename: "BlogPostListItem",
	_meta?: GraphQLTypes["BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined
};
	["BlogPostListItemMeta"]: {
	__typename: "BlogPostListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	caption?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["BlogPostListOrderBy"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleOrderBy"] | undefined
};
	["BlogPostListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogPostListItemConnection"]: {
	__typename: "BlogPostListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListItemEdge"]>
};
	["BlogPostListItemEdge"]: {
	__typename: "BlogPostListItemEdge",
	node: GraphQLTypes["BlogPostListItem"]
};
	["LinkList"]: {
	__typename: "LinkList",
	_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkListItem"]>,
	paginateItems: GraphQLTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
	__typename: "LinkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
	__typename: "LinkListItem",
	_meta?: GraphQLTypes["LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
	__typename: "LinkListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	isPrimary?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["LinkListOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	externalLink?: GraphQLTypes["OrderDirection"] | undefined,
	internalLink?: GraphQLTypes["LinkableOrderBy"] | undefined
};
	["LinkListItemConnection"]: {
	__typename: "LinkListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
	__typename: "LinkListItemEdge",
	node: GraphQLTypes["LinkListItem"]
};
	["Hero"]: {
	__typename: "Hero",
	_meta?: GraphQLTypes["HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["HeroMeta"]: {
	__typename: "HeroMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["Gallery"]: {
	__typename: "Gallery",
	_meta?: GraphQLTypes["GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GalleryItem"]>,
	paginateItems: GraphQLTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
	__typename: "GalleryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
	__typename: "GalleryItem",
	_meta?: GraphQLTypes["GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Gallery"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["GalleryItemMeta"]: {
	__typename: "GalleryItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	caption?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["GalleryOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined
};
	["GalleryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["GalleryItemConnection"]: {
	__typename: "GalleryItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
	__typename: "GalleryItemEdge",
	node: GraphQLTypes["GalleryItem"]
};
	["SocialsAndApps"]: {
	__typename: "SocialsAndApps",
	_meta?: GraphQLTypes["SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["SocialsAndAppsMeta"]: {
	__typename: "SocialsAndAppsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	instagram?: GraphQLTypes["FieldMeta"] | undefined,
	appStore?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGrid"]: {
	__typename: "ProductGrid",
	_meta?: GraphQLTypes["ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["ProductGridCategory"]>,
	type?: GraphQLTypes["ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["ProductGridCategoryConnection"]
};
	["ProductGridMeta"]: {
	__typename: "ProductGridMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridCategory"]: {
	__typename: "ProductGridCategory",
	_meta?: GraphQLTypes["ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["ProductGrid"] | undefined,
	items: Array<GraphQLTypes["ProductGridItem"]>,
	paginateItems: GraphQLTypes["ProductGridItemConnection"]
};
	["ProductGridCategoryMeta"]: {
	__typename: "ProductGridCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItem"]: {
	__typename: "ProductGridItem",
	_meta?: GraphQLTypes["ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	category?: GraphQLTypes["ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["ProductGridItemMeta"]: {
	__typename: "ProductGridItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	lead?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	category?: GraphQLTypes["ProductGridCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductGridCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductGridOrderBy"] | undefined
};
	["ProductGridOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isCarousel?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductGridItemConnection"]: {
	__typename: "ProductGridItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridItemEdge"]>
};
	["ProductGridItemEdge"]: {
	__typename: "ProductGridItemEdge",
	node: GraphQLTypes["ProductGridItem"]
};
	["ProductGridCategoriesByItemsUniqueWhere"]: {
		items?: GraphQLTypes["ProductGridItemUniqueWhere"] | undefined
};
	["ProductGridItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductGridCategoryConnection"]: {
	__typename: "ProductGridCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridCategoryEdge"]>
};
	["ProductGridCategoryEdge"]: {
	__typename: "ProductGridCategoryEdge",
	node: GraphQLTypes["ProductGridCategory"]
};
	["ProductBannerList"]: {
	__typename: "ProductBannerList",
	_meta?: GraphQLTypes["ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateItems: GraphQLTypes["ProductBannerItemConnection"]
};
	["ProductBannerListMeta"]: {
	__typename: "ProductBannerListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItem"]: {
	__typename: "ProductBannerItem",
	_meta?: GraphQLTypes["ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductBannerList"] | undefined,
	banner?: GraphQLTypes["ProductBanner"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemMeta"]: {
	__typename: "ProductBannerItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	banner?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBanner"]: {
	__typename: "ProductBanner",
	_meta?: GraphQLTypes["ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	backgroundImage?: GraphQLTypes["Image"] | undefined,
	productImage?: GraphQLTypes["Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductBannerMeta"]: {
	__typename: "ProductBannerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["FieldMeta"] | undefined,
	productImage?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductBannerListOrderBy"] | undefined,
	banner?: GraphQLTypes["ProductBannerOrderBy"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductBannerListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductBannerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	beforeTitle?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	button?: GraphQLTypes["LinkOrderBy"] | undefined,
	backgroundImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	productImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["ProductBannerItemConnection"]: {
	__typename: "ProductBannerItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerItemEdge"]>
};
	["ProductBannerItemEdge"]: {
	__typename: "ProductBannerItemEdge",
	node: GraphQLTypes["ProductBannerItem"]
};
	["DeliveryRegions"]: {
	__typename: "DeliveryRegions",
	_meta?: GraphQLTypes["DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["DeliveryRegionsItemConnection"]
};
	["DeliveryRegionsMeta"]: {
	__typename: "DeliveryRegionsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRegionsItem"]: {
	__typename: "DeliveryRegionsItem",
	_meta?: GraphQLTypes["DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gallery?: GraphQLTypes["ImageList"] | undefined
};
	["DeliveryRegionsItemMeta"]: {
	__typename: "DeliveryRegionsItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextList"]: {
	__typename: "TextList",
	_meta?: GraphQLTypes["TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TextItem"]>,
	paginateItems: GraphQLTypes["TextItemConnection"]
};
	["TextListMeta"]: {
	__typename: "TextListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
	__typename: "TextItem",
	_meta?: GraphQLTypes["TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["TextList"] | undefined
};
	["TextItemMeta"]: {
	__typename: "TextItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TextListOrderBy"] | undefined
};
	["TextListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["TextItemConnection"]: {
	__typename: "TextItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
	__typename: "TextItemEdge",
	node: GraphQLTypes["TextItem"]
};
	["ImageList"]: {
	__typename: "ImageList",
	_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
	__typename: "ImageListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
	__typename: "ImageListItem",
	_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
	__typename: "ImageListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ImageListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageListItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageListItemConnection"]: {
	__typename: "ImageListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
	__typename: "ImageListItemEdge",
	node: GraphQLTypes["ImageListItem"]
};
	["DeliveryRegionsItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	offerLabel?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["DeliveryRegionsOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	textList?: GraphQLTypes["TextListOrderBy"] | undefined,
	gallery?: GraphQLTypes["ImageListOrderBy"] | undefined
};
	["DeliveryRegionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryRegionsItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["DeliveryRegionsItemsByTextListUniqueWhere"]: {
		textList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: {
		gallery?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["DeliveryRegionsItemConnection"]: {
	__typename: "DeliveryRegionsItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsItemEdge"]>
};
	["DeliveryRegionsItemEdge"]: {
	__typename: "DeliveryRegionsItemEdge",
	node: GraphQLTypes["DeliveryRegionsItem"]
};
	["ContentReferenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	primaryText?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryText?: GraphQLTypes["OrderDirection"] | undefined,
	jsonContent?: GraphQLTypes["OrderDirection"] | undefined,
	block?: GraphQLTypes["ContentBlockOrderBy"] | undefined,
	colorPallete?: GraphQLTypes["ColorPalleteOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostListOrderBy"] | undefined,
	links?: GraphQLTypes["LinkListOrderBy"] | undefined,
	products?: GraphQLTypes["ProductListOrderBy"] | undefined,
	contentSize?: GraphQLTypes["OrderDirection"] | undefined,
	misc?: GraphQLTypes["OrderDirection"] | undefined,
	hero?: GraphQLTypes["HeroOrderBy"] | undefined,
	gallery?: GraphQLTypes["GalleryOrderBy"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsOrderBy"] | undefined,
	productGrid?: GraphQLTypes["ProductGridOrderBy"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListOrderBy"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	json?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["HeroOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	showLogo?: GraphQLTypes["OrderDirection"] | undefined,
	middleContent?: GraphQLTypes["OrderDirection"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined
};
	["SocialsAndAppsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	instagram?: GraphQLTypes["OrderDirection"] | undefined,
	appStore?: GraphQLTypes["OrderDirection"] | undefined,
	googlePlay?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentBlockReferencesByProductsUniqueWhere"]: {
		products?: GraphQLTypes["ProductListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByProductBannersUniqueWhere"]: {
		productBanners?: GraphQLTypes["ProductBannerListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
		deliveryRegions?: GraphQLTypes["DeliveryRegionsUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
	__typename: "ContentReferenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
	__typename: "ContentReferenceEdge",
	node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
	__typename: "ContentBlockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
	__typename: "ContentBlockEdge",
	node: GraphQLTypes["ContentBlock"]
};
	["TranslationCataloguesIdentifier"]: {
	__typename: "TranslationCataloguesIdentifier",
	_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
	__typename: "TranslationCataloguesIdentifierMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogue"]: {
	__typename: "TranslationCatalogue",
	_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
	__typename: "TranslationCatalogueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
	__typename: "TranslationDomain",
	_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
	__typename: "TranslationDomainMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["TranslationDomainOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined
};
	["TranslationKey"]: {
	__typename: "TranslationKey",
	_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
	__typename: "TranslationKeyMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
	__typename: "TranslationValue",
	_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
	__typename: "TranslationValueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	value?: GraphQLTypes["OrderDirection"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	key?: GraphQLTypes["TranslationKeyOrderBy"] | undefined
};
	["TranslationKeyOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	contentType?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
		catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationValueConnection"]: {
	__typename: "TranslationValueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
	__typename: "TranslationValueEdge",
	node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
		identifier?: string | undefined
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueConnection"]: {
	__typename: "TranslationCatalogueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
	__typename: "TranslationCatalogueEdge",
	node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
	__typename: "TranslationKeyConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
	__typename: "TranslationKeyEdge",
	node: GraphQLTypes["TranslationKey"]
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
		key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
		domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["MenuLocale"]: {
	__typename: "MenuLocale",
	_meta?: GraphQLTypes["MenuLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["SiteMenu"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	items: Array<GraphQLTypes["MenuItem"]>,
	secondaryItems?: GraphQLTypes["LinkList"] | undefined,
	eshopLink?: GraphQLTypes["Link"] | undefined,
	itemsBySubitems?: GraphQLTypes["MenuItem"] | undefined,
	paginateItems: GraphQLTypes["MenuItemConnection"]
};
	["MenuLocaleMeta"]: {
	__typename: "MenuLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryItems?: GraphQLTypes["FieldMeta"] | undefined,
	eshopLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["SiteMenu"]: {
	__typename: "SiteMenu",
	_meta?: GraphQLTypes["SiteMenuMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["MenuLocale"]>,
	localesByLocale?: GraphQLTypes["MenuLocale"] | undefined,
	localesByItems?: GraphQLTypes["MenuLocale"] | undefined,
	localesBySecondaryItems?: GraphQLTypes["MenuLocale"] | undefined,
	paginateLocales: GraphQLTypes["MenuLocaleConnection"]
};
	["SiteMenuMeta"]: {
	__typename: "SiteMenuMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["SiteMenuOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	secondaryItems?: GraphQLTypes["LinkListOrderBy"] | undefined,
	eshopLink?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["SiteMenuOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["SiteMenuLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["SiteMenuLocalesByItemsUniqueWhere"]: {
		items?: GraphQLTypes["MenuItemUniqueWhere"] | undefined
};
	["SiteMenuLocalesBySecondaryItemsUniqueWhere"]: {
		secondaryItems?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["MenuLocaleConnection"]: {
	__typename: "MenuLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuLocaleEdge"]>
};
	["MenuLocaleEdge"]: {
	__typename: "MenuLocaleEdge",
	node: GraphQLTypes["MenuLocale"]
};
	["MenuItem"]: {
	__typename: "MenuItem",
	_meta?: GraphQLTypes["MenuItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	menu?: GraphQLTypes["MenuLocale"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	subitems: Array<GraphQLTypes["MenuSubitem"]>,
	paginateSubitems: GraphQLTypes["MenuSubitemConnection"]
};
	["MenuItemMeta"]: {
	__typename: "MenuItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	menu?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	subitems?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuSubitem"]: {
	__typename: "MenuSubitem",
	_meta?: GraphQLTypes["MenuSubitemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MenuSubitemType"] | undefined,
	title?: string | undefined,
	item?: GraphQLTypes["MenuItem"] | undefined,
	links?: GraphQLTypes["MenuLinkList"] | undefined,
	products?: GraphQLTypes["MenuProducts"] | undefined,
	delivery?: GraphQLTypes["MenuDelivery"] | undefined,
	contact?: GraphQLTypes["MenuContact"] | undefined,
	order: number
};
	["MenuSubitemMeta"]: {
	__typename: "MenuSubitemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	contact?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLinkList"]: {
	__typename: "MenuLinkList",
	_meta?: GraphQLTypes["MenuLinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["MenuLinkItem"]>,
	paginateItems: GraphQLTypes["MenuLinkItemConnection"]
};
	["MenuLinkListMeta"]: {
	__typename: "MenuLinkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLinkItem"]: {
	__typename: "MenuLinkItem",
	_meta?: GraphQLTypes["MenuLinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["MenuLinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined
};
	["MenuLinkItemMeta"]: {
	__typename: "MenuLinkItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuLinkItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["MenuLinkListOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["MenuLinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["MenuLinkItemConnection"]: {
	__typename: "MenuLinkItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuLinkItemEdge"]>
};
	["MenuLinkItemEdge"]: {
	__typename: "MenuLinkItemEdge",
	node: GraphQLTypes["MenuLinkItem"]
};
	["MenuProducts"]: {
	__typename: "MenuProducts",
	_meta?: GraphQLTypes["MenuProductsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	links?: GraphQLTypes["MenuLinkList"] | undefined,
	buttons?: GraphQLTypes["LinkList"] | undefined
};
	["MenuProductsMeta"]: {
	__typename: "MenuProductsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	buttons?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuDelivery"]: {
	__typename: "MenuDelivery",
	_meta?: GraphQLTypes["MenuDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	text?: string | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined
};
	["MenuDeliveryMeta"]: {
	__typename: "MenuDeliveryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuContact"]: {
	__typename: "MenuContact",
	_meta?: GraphQLTypes["MenuContactMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	text?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["MenuContactMeta"]: {
	__typename: "MenuContactMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["MenuSubitemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	item?: GraphQLTypes["MenuItemOrderBy"] | undefined,
	links?: GraphQLTypes["MenuLinkListOrderBy"] | undefined,
	products?: GraphQLTypes["MenuProductsOrderBy"] | undefined,
	delivery?: GraphQLTypes["MenuDeliveryOrderBy"] | undefined,
	contact?: GraphQLTypes["MenuContactOrderBy"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["MenuItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	menu?: GraphQLTypes["MenuLocaleOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["MenuProductsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	links?: GraphQLTypes["MenuLinkListOrderBy"] | undefined,
	buttons?: GraphQLTypes["LinkListOrderBy"] | undefined
};
	["MenuDeliveryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsOrderBy"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsOrderBy"] | undefined
};
	["MenuContactOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["MenuSubitemConnection"]: {
	__typename: "MenuSubitemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuSubitemEdge"]>
};
	["MenuSubitemEdge"]: {
	__typename: "MenuSubitemEdge",
	node: GraphQLTypes["MenuSubitem"]
};
	["MenuLocaleItemsBySubitemsUniqueWhere"]: {
		subitems?: GraphQLTypes["MenuSubitemUniqueWhere"] | undefined
};
	["MenuItemConnection"]: {
	__typename: "MenuItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuItemEdge"]>
};
	["MenuItemEdge"]: {
	__typename: "MenuItemEdge",
	node: GraphQLTypes["MenuItem"]
};
	["ProductPackingLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	shortName?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductPackingOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductLocale"]: {
	__typename: "ProductLocale",
	_meta?: GraphQLTypes["ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	featureList?: GraphQLTypes["TextList"] | undefined
};
	["ProductLocaleMeta"]: {
	__typename: "ProductLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	featureList?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	featureList?: GraphQLTypes["TextListOrderBy"] | undefined
};
	["ProductRecipeLocale"]: {
	__typename: "ProductRecipeLocale",
	_meta?: GraphQLTypes["ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	description?: string | undefined
};
	["ProductRecipeLocaleMeta"]: {
	__typename: "ProductRecipeLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductRecipe"]: {
	__typename: "ProductRecipe",
	_meta?: GraphQLTypes["ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	description?: string | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	localesByLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["ProductRecipeLocaleConnection"]
};
	["ProductRecipeMeta"]: {
	__typename: "ProductRecipeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItem"]: {
	__typename: "ProductIngredientItem",
	_meta?: GraphQLTypes["ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined
};
	["ProductIngredientItemMeta"]: {
	__typename: "ProductIngredientItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	internalOrder?: GraphQLTypes["OrderDirection"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientOrderBy"] | undefined
};
	["ProductRecipeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeProductsByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["ProductRecipeProductsByStocksUniqueWhere"]: {
		stocks?: GraphQLTypes["ProductStockUniqueWhere"] | undefined
};
	["ProductRecipeProductsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined
};
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: {
		virtualProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined
};
	["ProductRecipeProductsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["ProductMetadataUniqueWhere"] | undefined
};
	["ProductRecipeProductsByPageUniqueWhere"]: {
		page?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["ProductRecipeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientItemConnection"]: {
	__typename: "ProductIngredientItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemEdge"]>
};
	["ProductIngredientItemEdge"]: {
	__typename: "ProductIngredientItemEdge",
	node: GraphQLTypes["ProductIngredientItem"]
};
	["ProductRecipeLocaleConnection"]: {
	__typename: "ProductRecipeLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeLocaleEdge"]>
};
	["ProductRecipeLocaleEdge"]: {
	__typename: "ProductRecipeLocaleEdge",
	node: GraphQLTypes["ProductRecipeLocale"]
};
	["DeliveryMethodLocale"]: {
	__typename: "DeliveryMethodLocale",
	_meta?: GraphQLTypes["DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryMethodLocaleMeta"]: {
	__typename: "DeliveryMethodLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["FieldMeta"] | undefined,
	processingText?: GraphQLTypes["FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
	__typename: "DeliveryMethod",
	_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	localesByLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
	__typename: "DeliveryMethodMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethod"]: {
	__typename: "PaymentMethod",
	_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["PaymentMethodType"] | undefined,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"]
};
	["PaymentMethodMeta"]: {
	__typename: "PaymentMethodMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["OrderDirection"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetOrderBy"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryTimelinePresetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	window?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined
};
	["DeliveryMethodConnection"]: {
	__typename: "DeliveryMethodConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
	__typename: "DeliveryMethodEdge",
	node: GraphQLTypes["DeliveryMethod"]
};
	["PaymentMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	blockingDispatch?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryZone"]: {
	__typename: "DeliveryZone",
	_meta?: GraphQLTypes["DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["DeliveryZoneType"],
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"]
};
	["DeliveryZoneMeta"]: {
	__typename: "DeliveryZoneMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryZoneOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryTimelinePreset"]: {
	__typename: "DeliveryTimelinePreset",
	_meta?: GraphQLTypes["DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryTimelinePresetMeta"]: {
	__typename: "DeliveryTimelinePresetMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	processingTitle?: GraphQLTypes["OrderDirection"] | undefined,
	processingText?: GraphQLTypes["OrderDirection"] | undefined,
	processingIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedTitle?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedText?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledTitle?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledText?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	root?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined
};
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PaymentMethodConnection"]: {
	__typename: "PaymentMethodConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
	__typename: "PaymentMethodEdge",
	node: GraphQLTypes["PaymentMethod"]
};
	["DeliveryZoneConnection"]: {
	__typename: "DeliveryZoneConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryZoneEdge"]>
};
	["DeliveryZoneEdge"]: {
	__typename: "DeliveryZoneEdge",
	node: GraphQLTypes["DeliveryZone"]
};
	["DeliveryMethodLocaleConnection"]: {
	__typename: "DeliveryMethodLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodLocaleEdge"]>
};
	["DeliveryMethodLocaleEdge"]: {
	__typename: "DeliveryMethodLocaleEdge",
	node: GraphQLTypes["DeliveryMethodLocale"]
};
	["LocaleBusinessCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["LocaleProductCategoryListsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["LocaleProductIngredientsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["LocaleOrderRecurrencesByRootUniqueWhere"]: {
		root?: GraphQLTypes["OrderRecurrenceUniqueWhere"] | undefined
};
	["LocaleBlogsByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogUniqueWhere"] | undefined
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["LocaleBlogPostsByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleMenusByRootUniqueWhere"]: {
		root?: GraphQLTypes["SiteMenuUniqueWhere"] | undefined
};
	["LocaleMenusByItemsUniqueWhere"]: {
		items?: GraphQLTypes["MenuItemUniqueWhere"] | undefined
};
	["LocaleMenusBySecondaryItemsUniqueWhere"]: {
		secondaryItems?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["LocalePagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["LocalePagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocalePagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocalePagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocalePagesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["LocaleAllergensByRootUniqueWhere"]: {
		root?: GraphQLTypes["AllergenUniqueWhere"] | undefined
};
	["LocaleProductPackingsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined
};
	["LocaleProductsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["LocaleProductsByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["LocaleProductRecipesByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["LocaleDeliveryMethodsByRootUniqueWhere"]: {
		root?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["ProductPackingLocaleConnection"]: {
	__typename: "ProductPackingLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingLocaleEdge"]>
};
	["ProductPackingLocaleEdge"]: {
	__typename: "ProductPackingLocaleEdge",
	node: GraphQLTypes["ProductPackingLocale"]
};
	["ProductLocaleConnection"]: {
	__typename: "ProductLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
	__typename: "ProductLocaleEdge",
	node: GraphQLTypes["ProductLocale"]
};
	["ProductPackingLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductStock"]: {
	__typename: "ProductStock",
	_meta?: GraphQLTypes["ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Store"] | undefined
};
	["ProductStockMeta"]: {
	__typename: "ProductStockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	store?: GraphQLTypes["FieldMeta"] | undefined
};
	["Store"]: {
	__typename: "Store",
	_meta?: GraphQLTypes["StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["StoreMeta"]: {
	__typename: "StoreMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	store?: GraphQLTypes["StoreOrderBy"] | undefined
};
	["StoreOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductMetadata"]: {
	__typename: "ProductMetadata",
	_meta?: GraphQLTypes["ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductMetadataMeta"]: {
	__typename: "ProductMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	archived?: GraphQLTypes["FieldMeta"] | undefined,
	available?: GraphQLTypes["FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStocksByStoreUniqueWhere"]: {
		store?: GraphQLTypes["StoreUniqueWhere"] | undefined
};
	["ProductLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductLocalesByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["ProductStockConnection"]: {
	__typename: "ProductStockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductStockEdge"]>
};
	["ProductStockEdge"]: {
	__typename: "ProductStockEdge",
	node: GraphQLTypes["ProductStock"]
};
	["ChannelOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	disabled?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["ChannelConnection"]: {
	__typename: "ChannelConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelEdge"]>
};
	["ChannelEdge"]: {
	__typename: "ChannelEdge",
	node: GraphQLTypes["Channel"]
};
	["ChannelUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: GraphQLTypes["ChannelCode"] | undefined
};
	["PaymentMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["VatRateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VatRateConnection"]: {
	__typename: "VatRateConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VatRateEdge"]>
};
	["VatRateEdge"]: {
	__typename: "VatRateEdge",
	node: GraphQLTypes["VatRate"]
};
	["ProductPackingConnection"]: {
	__typename: "ProductPackingConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingEdge"]>
};
	["ProductPackingEdge"]: {
	__typename: "ProductPackingEdge",
	node: GraphQLTypes["ProductPacking"]
};
	["ProductRecipeConnection"]: {
	__typename: "ProductRecipeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeEdge"]>
};
	["ProductRecipeEdge"]: {
	__typename: "ProductRecipeEdge",
	node: GraphQLTypes["ProductRecipe"]
};
	["StoreConnection"]: {
	__typename: "StoreConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StoreEdge"]>
};
	["StoreEdge"]: {
	__typename: "StoreEdge",
	node: GraphQLTypes["Store"]
};
	["TagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["ImageConnection"]: {
	__typename: "ImageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
	__typename: "ImageEdge",
	node: GraphQLTypes["Image"]
};
	["ProductListConnection"]: {
	__typename: "ProductListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListEdge"]>
};
	["ProductListEdge"]: {
	__typename: "ProductListEdge",
	node: GraphQLTypes["ProductList"]
};
	["ProductCategoryListConnection"]: {
	__typename: "ProductCategoryListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
	__typename: "ProductCategoryListEdge",
	node: GraphQLTypes["ProductCategoryList"]
};
	["LocaleConnection"]: {
	__typename: "LocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
	__typename: "LocaleEdge",
	node: GraphQLTypes["Locale"]
};
	["ProductIngredientRating"]: {
	__typename: "ProductIngredientRating",
	_meta?: GraphQLTypes["ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Image"] | undefined
};
	["ProductIngredientRatingMeta"]: {
	__typename: "ProductIngredientRatingMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientRatingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductIngredientRatingWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientRatingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientRatingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientRatingWhere"] | undefined
};
	["ProductIngredientRatingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ProductIngredientRatingConnection"]: {
	__typename: "ProductIngredientRatingConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientRatingEdge"]>
};
	["ProductIngredientRatingEdge"]: {
	__typename: "ProductIngredientRatingEdge",
	node: GraphQLTypes["ProductIngredientRating"]
};
	["BlogConnection"]: {
	__typename: "BlogConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogEdge"]>
};
	["BlogEdge"]: {
	__typename: "BlogEdge",
	node: GraphQLTypes["Blog"]
};
	["BlogPostConnection"]: {
	__typename: "BlogPostConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
	__typename: "BlogPostEdge",
	node: GraphQLTypes["BlogPost"]
};
	["ColorPalleteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ColorPalleteConnection"]: {
	__typename: "ColorPalleteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ColorPalleteEdge"]>
};
	["ColorPalleteEdge"]: {
	__typename: "ColorPalleteEdge",
	node: GraphQLTypes["ColorPallete"]
};
	["ContentConnection"]: {
	__typename: "ContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
	__typename: "ContentEdge",
	node: GraphQLTypes["Content"]
};
	["BlogPostListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["BlogPostListItemUniqueWhere"] | undefined
};
	["BlogPostListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["BlogPostListConnection"]: {
	__typename: "BlogPostListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListEdge"]>
};
	["BlogPostListEdge"]: {
	__typename: "BlogPostListEdge",
	node: GraphQLTypes["BlogPostList"]
};
	["LinkListConnection"]: {
	__typename: "LinkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
	__typename: "LinkListEdge",
	node: GraphQLTypes["LinkList"]
};
	["CoverConnection"]: {
	__typename: "CoverConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CoverEdge"]>
};
	["CoverEdge"]: {
	__typename: "CoverEdge",
	node: GraphQLTypes["Cover"]
};
	["LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["LinkConnection"]: {
	__typename: "LinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
	__typename: "LinkEdge",
	node: GraphQLTypes["Link"]
};
	["LinkableConnection"]: {
	__typename: "LinkableConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
	__typename: "LinkableEdge",
	node: GraphQLTypes["Linkable"]
};
	["MediumUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["MediumConnection"]: {
	__typename: "MediumConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumEdge"]>
};
	["MediumEdge"]: {
	__typename: "MediumEdge",
	node: GraphQLTypes["Medium"]
};
	["VideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VideoConnection"]: {
	__typename: "VideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
	__typename: "VideoEdge",
	node: GraphQLTypes["Video"]
};
	["SeoConnection"]: {
	__typename: "SeoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
	__typename: "SeoEdge",
	node: GraphQLTypes["Seo"]
};
	["TranslationDomainConnection"]: {
	__typename: "TranslationDomainConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
	__typename: "TranslationDomainEdge",
	node: GraphQLTypes["TranslationDomain"]
};
	["TranslationCataloguesIdentifierConnection"]: {
	__typename: "TranslationCataloguesIdentifierConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
	__typename: "TranslationCataloguesIdentifierEdge",
	node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["SocialsAndAppsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["SocialsAndAppsConnection"]: {
	__typename: "SocialsAndAppsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SocialsAndAppsEdge"]>
};
	["SocialsAndAppsEdge"]: {
	__typename: "SocialsAndAppsEdge",
	node: GraphQLTypes["SocialsAndApps"]
};
	["ProductGridUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	categories?: GraphQLTypes["ProductGridCategoryUniqueWhere"] | undefined
};
	["ProductGridCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductGridItemUniqueWhere"] | undefined
};
	["ProductGridConnection"]: {
	__typename: "ProductGridConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridEdge"]>
};
	["ProductGridEdge"]: {
	__typename: "ProductGridEdge",
	node: GraphQLTypes["ProductGrid"]
};
	["GalleryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["GalleryItemUniqueWhere"] | undefined
};
	["GalleryItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["GalleryConnection"]: {
	__typename: "GalleryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
	__typename: "GalleryEdge",
	node: GraphQLTypes["Gallery"]
};
	["HeroUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["HeroConnection"]: {
	__typename: "HeroConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeroEdge"]>
};
	["HeroEdge"]: {
	__typename: "HeroEdge",
	node: GraphQLTypes["Hero"]
};
	["SiteMenuConnection"]: {
	__typename: "SiteMenuConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SiteMenuEdge"]>
};
	["SiteMenuEdge"]: {
	__typename: "SiteMenuEdge",
	node: GraphQLTypes["SiteMenu"]
};
	["PageConnection"]: {
	__typename: "PageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageEdge"]>
};
	["PageEdge"]: {
	__typename: "PageEdge",
	node: GraphQLTypes["Page"]
};
	["MenuLinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["MenuLinkItemUniqueWhere"] | undefined
};
	["MenuLinkItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["MenuLinkListConnection"]: {
	__typename: "MenuLinkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuLinkListEdge"]>
};
	["MenuLinkListEdge"]: {
	__typename: "MenuLinkListEdge",
	node: GraphQLTypes["MenuLinkList"]
};
	["MenuProductsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	buttons?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["MenuProductsConnection"]: {
	__typename: "MenuProductsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuProductsEdge"]>
};
	["MenuProductsEdge"]: {
	__typename: "MenuProductsEdge",
	node: GraphQLTypes["MenuProducts"]
};
	["MenuDeliveryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsUniqueWhere"] | undefined
};
	["MenuDeliveryConnection"]: {
	__typename: "MenuDeliveryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuDeliveryEdge"]>
};
	["MenuDeliveryEdge"]: {
	__typename: "MenuDeliveryEdge",
	node: GraphQLTypes["MenuDelivery"]
};
	["MenuContactUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["MenuContactConnection"]: {
	__typename: "MenuContactConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MenuContactEdge"]>
};
	["MenuContactEdge"]: {
	__typename: "MenuContactEdge",
	node: GraphQLTypes["MenuContact"]
};
	["ProductBannerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	button?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ProductBannerConnection"]: {
	__typename: "ProductBannerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerEdge"]>
};
	["ProductBannerEdge"]: {
	__typename: "ProductBannerEdge",
	node: GraphQLTypes["ProductBanner"]
};
	["ProductBannerListConnection"]: {
	__typename: "ProductBannerListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerListEdge"]>
};
	["ProductBannerListEdge"]: {
	__typename: "ProductBannerListEdge",
	node: GraphQLTypes["ProductBannerList"]
};
	["GlobalConfigUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursUniqueWhere"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursUniqueWhere"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateUniqueWhere"] | undefined,
	freshingContainers?: GraphQLTypes["FreshingContainerUniqueWhere"] | undefined
};
	["WeekHoursUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FreshingContainerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["GlobalConfigConnection"]: {
	__typename: "GlobalConfigConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GlobalConfigEdge"]>
};
	["GlobalConfigEdge"]: {
	__typename: "GlobalConfigEdge",
	node: GraphQLTypes["GlobalConfig"]
};
	["WeekHoursConnection"]: {
	__typename: "WeekHoursConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WeekHoursEdge"]>
};
	["WeekHoursEdge"]: {
	__typename: "WeekHoursEdge",
	node: GraphQLTypes["WeekHours"]
};
	["DeliveryZoneUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["DeliveryTimelinePresetConnection"]: {
	__typename: "DeliveryTimelinePresetConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryTimelinePresetEdge"]>
};
	["DeliveryTimelinePresetEdge"]: {
	__typename: "DeliveryTimelinePresetEdge",
	node: GraphQLTypes["DeliveryTimelinePreset"]
};
	["VirtualProductConnection"]: {
	__typename: "VirtualProductConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEdge"]>
};
	["VirtualProductEdge"]: {
	__typename: "VirtualProductEdge",
	node: GraphQLTypes["VirtualProduct"]
};
	["ProductMetadataConnection"]: {
	__typename: "ProductMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductMetadataEdge"]>
};
	["ProductMetadataEdge"]: {
	__typename: "ProductMetadataEdge",
	node: GraphQLTypes["ProductMetadata"]
};
	["RedirectConnection"]: {
	__typename: "RedirectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
	__typename: "RedirectEdge",
	node: GraphQLTypes["Redirect"]
};
	["TextListConnection"]: {
	__typename: "TextListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextListEdge"]>
};
	["TextListEdge"]: {
	__typename: "TextListEdge",
	node: GraphQLTypes["TextList"]
};
	["DeliveryRegionsConnection"]: {
	__typename: "DeliveryRegionsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsEdge"]>
};
	["DeliveryRegionsEdge"]: {
	__typename: "DeliveryRegionsEdge",
	node: GraphQLTypes["DeliveryRegions"]
};
	["ImageListConnection"]: {
	__typename: "ImageListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
	__typename: "ImageListEdge",
	node: GraphQLTypes["ImageList"]
};
	["QueryTransaction"]: {
	__typename: "QueryTransaction",
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getSiteMenu?: GraphQLTypes["SiteMenu"] | undefined,
	listSiteMenu: Array<GraphQLTypes["SiteMenu"]>,
	paginateSiteMenu: GraphQLTypes["SiteMenuConnection"],
	getMenuLocale?: GraphQLTypes["MenuLocale"] | undefined,
	listMenuLocale: Array<GraphQLTypes["MenuLocale"]>,
	paginateMenuLocale: GraphQLTypes["MenuLocaleConnection"],
	getMenuItem?: GraphQLTypes["MenuItem"] | undefined,
	listMenuItem: Array<GraphQLTypes["MenuItem"]>,
	paginateMenuItem: GraphQLTypes["MenuItemConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getWikiPage?: GraphQLTypes["WikiPage"] | undefined,
	listWikiPage: Array<GraphQLTypes["WikiPage"]>,
	paginateWikiPage: GraphQLTypes["WikiPageConnection"],
	getMenuSubitem?: GraphQLTypes["MenuSubitem"] | undefined,
	listMenuSubitem: Array<GraphQLTypes["MenuSubitem"]>,
	paginateMenuSubitem: GraphQLTypes["MenuSubitemConnection"],
	getMenuLinkList?: GraphQLTypes["MenuLinkList"] | undefined,
	listMenuLinkList: Array<GraphQLTypes["MenuLinkList"]>,
	paginateMenuLinkList: GraphQLTypes["MenuLinkListConnection"],
	getMenuLinkItem?: GraphQLTypes["MenuLinkItem"] | undefined,
	listMenuLinkItem: Array<GraphQLTypes["MenuLinkItem"]>,
	paginateMenuLinkItem: GraphQLTypes["MenuLinkItemConnection"],
	getMenuProducts?: GraphQLTypes["MenuProducts"] | undefined,
	listMenuProducts: Array<GraphQLTypes["MenuProducts"]>,
	paginateMenuProducts: GraphQLTypes["MenuProductsConnection"],
	getMenuDelivery?: GraphQLTypes["MenuDelivery"] | undefined,
	listMenuDelivery: Array<GraphQLTypes["MenuDelivery"]>,
	paginateMenuDelivery: GraphQLTypes["MenuDeliveryConnection"],
	getMenuContact?: GraphQLTypes["MenuContact"] | undefined,
	listMenuContact: Array<GraphQLTypes["MenuContact"]>,
	paginateMenuContact: GraphQLTypes["MenuContactConnection"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGlobalConfig?: GraphQLTypes["GlobalConfig"] | undefined,
	listGlobalConfig: Array<GraphQLTypes["GlobalConfig"]>,
	paginateGlobalConfig: GraphQLTypes["GlobalConfigConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["Info"]: {
	__typename: "Info",
	description?: string | undefined
};
	["Json"]: any;
	["_Schema"]: {
	__typename: "_Schema",
	enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
	__typename: "_Entity",
	name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
	__typename: "_UniqueConstraint",
	fields: Array<string>
};
	["_Field"]: {
	__typename:"_Column" | "_Relation",
	name: string,
	type: string,
	nullable?: boolean | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
	['...on _Column']: '__union' & GraphQLTypes["_Column"];
	['...on _Relation']: '__union' & GraphQLTypes["_Relation"];
};
	["_Column"]: {
	__typename: "_Column",
	name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: _OnDeleteBehaviour;
	["_RelationSide"]: _RelationSide;
	["_OrderByDirection"]: _OrderByDirection;
	["_OrderBy"]: {
	__typename: "_OrderBy",
	path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
	__typename: "_Relation",
	name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
	__typename: "_Rule",
	message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
	__typename: "_Validator",
	operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:{
        	__typename:"_ValidatorArgument" | "_PathArgument" | "_LiteralArgument"
        	['...on _ValidatorArgument']: '__union' & GraphQLTypes["_ValidatorArgument"];
	['...on _PathArgument']: '__union' & GraphQLTypes["_PathArgument"];
	['...on _LiteralArgument']: '__union' & GraphQLTypes["_LiteralArgument"];
};
	["_ValidatorArgument"]: {
	__typename: "_ValidatorArgument",
	validator: number
};
	["_PathArgument"]: {
	__typename: "_PathArgument",
	path: Array<string>
};
	["_LiteralArgument"]: {
	__typename: "_LiteralArgument",
	value?: GraphQLTypes["Json"] | undefined
};
	["_RuleMessage"]: {
	__typename: "_RuleMessage",
	text?: string | undefined
};
	["_Enum"]: {
	__typename: "_Enum",
	name: string,
	values: Array<string>
}
    }
export type OrderDirection = "asc" | "desc" | "ascNullsFirst" | "descNullsLast";

export type ChannelCode = "b2b" | "eshop" | "app" | "pos" | "web" | "all" | "legacy";

export type One = "One";

export type VirtualProductType = "Credits" | "Voucher";

export type VirtualProductPhysicalRepresentationType = "None" | "Optional" | "Required";

export type DeliveryZoneType = "Shop" | "Prague" | "CZ" | "Abroad" | "Virtual";

export type PaymentMethodType = "credit" | "stripe" | "cash" | "invoice";

export type DeliveryTimelineType = "asap" | "day" | "halfAnHour";

export type ProductAvailability = "stockDependent" | "available" | "unavailable";

export type MediumType = "image" | "video";

export type ColorTheme = "light" | "dark";

export type ContentReferenceType = "button" | "medium" | "products" | "productBanners" | "links" | "link" | "blogPosts" | "content" | "gallery" | "hero" | "hr" | "marquee" | "productGrid" | "relatedBlogPosts" | "socialsAndApps" | "titleWithImage" | "deliveryRegions";

export type LinkType = "internal" | "external" | "download";

export type ContentSize = "small" | "medium" | "large";

export type ProductGridType = "default" | "carousel" | "smallGrid";

export type ProductBannerItemSize = "small" | "medium" | "large" | "fullWidth";

export type PageTheme = "goodlok" | "childish";

export type TranslationContentType = "textPlain" | "textHtml";

export type MenuSubitemType = "links" | "products" | "delivery" | "contact";

export type _OnDeleteBehaviour = "restrict" | "cascade" | "setNull";

export type _RelationSide = "owning" | "inverse";

export type _OrderByDirection = "asc" | "desc";

export const createZeusVariables = useZeusVariables

export type FragmentResult<K extends keyof GraphQLTypes, Q extends unknown | ((...args: any[]) => unknown)> = InputType<
	GraphQLTypes[K],
	Q extends (...args: any[]) => unknown ? ReturnType<Q> : Q
>