import {
	NotSharedBetweenAuthUsers,
	OrderCreatedHandler,
	SetGoodlokCustomer,
	useGoodlokCartIdentifier,
	useRouterRef,
} from '@goodlok/react-sdk'
import { CheckoutOptions } from '@goodlok/react-sdk/src/components/CartCheckout'
import {
	useTranslate,
	useTranslateWithoutFallback,
} from '@goodlok/react-sdk/src/translations/Translations'
import { notify } from '@goodlok/ui'
import { Collapsible } from 'collapsible-react-component'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import type { BusinessCategoryResult } from '../data/BusinessCategoryFragment'
import { useBusinessCustomerId } from '../hooks/useCustomer'
import { useProductQuantitiesCart } from '../hooks/useProductQuantitiesCart'
import { useResetOrderState } from '../states/order'
import { assertNever } from '../utils/assertNever'
import { AddressFields } from './AddressFields'
import { BusinessCategorySelect } from './BusinessCategorySelect'
import { CategoriesPicker } from './CategoriesPicker'
import { CurrentOrderSummary } from './CurrentOrderSummary'
import { FrequencySelect } from './FrequencySelect'
import { GeneralPreferenceSelect } from './GeneralPreferenceSelect'
import styles from './OrderPage.module.sass'
import { PageTitle } from './PageTitle'
import { PaymentMethods } from './PaymentMethods'
import { SeasonalProductsQuantity } from './SeasonalProductsQuantity'
import { Spacer } from './Spacer'
import { TabbedSpecificProductPickers } from './TabbedSpecificProductPickers'

export enum GeneralPreferenceId {
	Seasonal = 'seasonal',
	Specific = 'specific',
}

const transformToSpecificProductsProductCategories = (
	specificProducts: NonNullable<BusinessCategoryResult>['specificProducts'],
	specificProductsProductCategories: NonNullable<BusinessCategoryResult>['specificProductsProductCategories']
) => {
	const allProducts = specificProducts?.items ?? []
	const rawCategories = specificProductsProductCategories?.items ?? []
	const categories = rawCategories
		.map((category) => ({
			id: category.id,
			name: category.productCategory?.name ?? '',
			products: category.showAllAvailableProducts
				? category.productCategory?.products.map((product, index) => {
						return {
							id: `${product.id}-${index}}`,
							defaultQuantityForOrder: 1,
							product,
						}
				  }) || []
				: allProducts.filter((product) =>
						product.product?.categories.some(
							(otherCategory) => otherCategory.id === category.productCategory?.id
						)
				  ),
			showRecipeImages: category.showRecipeImages,
			showPackingImages: category.showPackingImages,
		}))
		.filter((category) => category.products.length > 0)

	if (categories.length === 0) {
		return undefined
	}
	return {
		name: specificProductsProductCategories?.localesByLocale?.name ?? '',
		categories,
	}
}

export type SeasonalProductCategories = {
	name: string
	categories: NonNullable<BusinessCategoryResult['seasonalProductCategories']>['items']
}

export type SpecificProductsProductCategories = ReturnType<
	typeof transformToSpecificProductsProductCategories
>

export const OrderPage: FunctionComponent<{
	businessCategories: BusinessCategoryResult[]
	defaultBusinessCategory?: BusinessCategoryResult
}> = ({ businessCategories, defaultBusinessCategory }) => {
	return (
		<NotSharedBetweenAuthUsers>
			<OrderPageInner
				businessCategories={businessCategories}
				defaultBusinessCategory={defaultBusinessCategory}
			/>
		</NotSharedBetweenAuthUsers>
	)
}

const OrderPageInner: FunctionComponent<{
	businessCategories: BusinessCategoryResult[]
	defaultBusinessCategory?: BusinessCategoryResult
}> = ({ businessCategories, defaultBusinessCategory }) => {
	const translate = useTranslate()
	const [selectedBusinessCategoryId, setSelectedBusinessCategoryId] = useState<string | null>(null)
	const [selectedFrequencyId, setSelectedFrequencyId] = useState<string | null>(null)
	const [selectedGeneralPreferenceId, setSelectedGeneralPreferenceId] =
		useState<GeneralPreferenceId>(GeneralPreferenceId.Seasonal)

	const [selectedBusinessCategory, setSelectedBusinessCategory] =
		useState<BusinessCategoryResult | null>(null)

	useEffect(() => {
		if (defaultBusinessCategory) {
			setSelectedBusinessCategoryId(defaultBusinessCategory.id)
		}
	}, [defaultBusinessCategory])

	useEffect(() => {
		if (selectedBusinessCategoryId) {
			setSelectedBusinessCategory(
				businessCategories.find((category) => category.id === selectedBusinessCategoryId) || null
			)
		}
	}, [businessCategories, selectedBusinessCategoryId])

	useEffect(() => {
		if (selectedBusinessCategory) {
			setSelectedBusinessCategoryId(selectedBusinessCategory.id)
			if (selectedBusinessCategory.recurrences.length === 1) {
				setSelectedFrequencyId(selectedBusinessCategory.recurrences[0].id)
			}
			if (
				!(
					selectedBusinessCategory.seasonalProductCategories &&
					selectedBusinessCategory.seasonalProductCategories.items.length > 0
				)
			) {
				setSelectedGeneralPreferenceId(GeneralPreferenceId.Specific)
			} else {
				setSelectedGeneralPreferenceId(GeneralPreferenceId.Seasonal)
			}
		}
	}, [selectedBusinessCategory])

	const recurrences = useMemo(() => {
		if (!selectedBusinessCategory) {
			return undefined
		}
		return selectedBusinessCategory.recurrences.map((recurrence) => ({
			id: recurrence.id,
			name: recurrence.localesByLocale?.name ?? '',
		}))
	}, [selectedBusinessCategory])

	const seasonalProductCategories: SeasonalProductCategories | undefined = useMemo(() => {
		if ((selectedBusinessCategory?.seasonalProductCategories?.items.length || 0) === 0) {
			return undefined
		}
		return {
			name: selectedBusinessCategory?.seasonalProductCategories?.localesByLocale?.name ?? '',
			categories: selectedBusinessCategory?.seasonalProductCategories?.items ?? [],
		}
	}, [selectedBusinessCategory?.seasonalProductCategories])

	const specificProductsProductCategories = useMemo(
		() =>
			transformToSpecificProductsProductCategories(
				selectedBusinessCategory?.specificProducts,
				selectedBusinessCategory?.specificProductsProductCategories
			),
		[
			selectedBusinessCategory?.specificProducts,
			selectedBusinessCategory?.specificProductsProductCategories,
		]
	)

	const productPrices = useMemo(() => {
		const prices: { [id: string]: number } = {}

		selectedBusinessCategory?.specificProducts?.items.forEach((item) => {
			if (item.product) {
				prices[item.product.id] =
					item.product.customerPricesByCustomer?.priceCents || item.product.priceCents || 0
			}
		})
		selectedBusinessCategory?.specificProductsProductCategories?.items.forEach((category) => {
			category.productCategory?.products.forEach((product) => {
				prices[product.id] = product.customerPricesByCustomer?.priceCents || product.priceCents || 0
			})
		})
		return prices
	}, [
		selectedBusinessCategory?.specificProducts?.items,
		selectedBusinessCategory?.specificProductsProductCategories?.items,
	])

	const businessCustomerId = useBusinessCustomerId()

	return (
		<SetGoodlokCustomer value={businessCustomerId ? { customerId: businessCustomerId } : null}>
			<PropagateStateToCart />
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<PageTitle>
						{defaultBusinessCategory && defaultBusinessCategory.localesByLocale?.title ? (
							<>{defaultBusinessCategory.localesByLocale?.title}</>
						) : (
							<>{translate('order.mainTitle')}</>
						)}
					</PageTitle>
					{!defaultBusinessCategory && (
						<BusinessCategorySelect
							businessCategories={businessCategories}
							selectedBusinessCategoryId={selectedBusinessCategoryId}
							onChange={(value) => {
								setSelectedBusinessCategoryId(value)
							}}
						/>
					)}
					<Collapsible open={recurrences !== undefined && recurrences.length > 1}>
						<FrequencySelect
							recurrences={recurrences}
							selectedFrequencyId={selectedFrequencyId}
							onChange={(value) => {
								setSelectedFrequencyId(value)
							}}
						/>
					</Collapsible>
					<Collapsible open={selectedFrequencyId !== null}>
						<GeneralPreferenceSelect
							selectedGeneralPreferenceId={selectedGeneralPreferenceId}
							seasonalProductCategories={seasonalProductCategories}
							specificProductsProductCategories={specificProductsProductCategories}
							onChange={(id) => {
								setSelectedGeneralPreferenceId(id as GeneralPreferenceId)
							}}
						/>
					</Collapsible>
					<Collapsible open={selectedGeneralPreferenceId !== null}>
						<Spacer initial={36} />
						{selectedGeneralPreferenceId === GeneralPreferenceId.Seasonal ? (
							<>
								{seasonalProductCategories?.categories && (
									<>
										<CategoriesPicker items={seasonalProductCategories.categories} />
										<SeasonalProductsQuantity />
									</>
								)}
							</>
						) : selectedGeneralPreferenceId === GeneralPreferenceId.Specific ? (
							<TabbedSpecificProductPickers
								categories={specificProductsProductCategories?.categories}
							/>
						) : selectedGeneralPreferenceId === null ? null : (
							assertNever(selectedGeneralPreferenceId)
						)}
						{false && <AddressFields />}
						{false && <PaymentMethods />}
					</Collapsible>
				</div>

				<CurrentOrderSummary productPrices={productPrices} />
			</div>
		</SetGoodlokCustomer>
	)
}

const PropagateStateToCart: FunctionComponent = () => {
	useProductQuantitiesCart()

	return null
}

const _BusinessCustomerCheckout: FunctionComponent = () => {
	const customerId = useBusinessCustomerId()
	const identifier = useGoodlokCartIdentifier(customerId ? { customerId, channel: 'b2b' } : null)

	const router = useRouterRef()
	const resetOrderState = useResetOrderState()
	const tr = useTranslateWithoutFallback()

	const handleSuccess = useCallback<OrderCreatedHandler>(() => {
		resetOrderState()
		notify(tr('order.orderCreatedMessage') ?? 'Objednávka byla vytvořena', 'success')
		router.current.push({ pathname: '/history' })
	}, [resetOrderState, router, tr])

	return (
		<div style={{ marginLeft: 'calc(-1 * var(--global-page-horizontal-spacing, 0)' }}>
			{customerId && (
				<SetGoodlokCustomer value={{ customerId }}>
					<PropagateStateToCart />
					{identifier && <CheckoutOptions identifier={identifier} onSuccess={handleSuccess} />}
				</SetGoodlokCustomer>
			)}
		</div>
	)
}
